import { http } from '@/plugins/axios';

export default {
  methods: {
    async getDataset(id) {
      try {
        const response = await http.get(`dataset/${id}`);
        this.dataset = response.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
  },
};
