<template>
  <div class="rank-config top-gap-sm">
    <v-text-field
      v-model="newRank"
      class="rank-input right-gap-sm inline-middle"
      style="width: 100px"
      type="number"
      variant="outlined"
      color="primary"
      density="compact"
      :placeholder="$t('dataPoints.rank')"
      :min="1"
      @input="addNewRank"
      @focus="$emit('activateDefault')"
    />
    <v-select
      v-model="startOrEnd"
      class="inline-middle"
      variant="outlined"
      color="primary"
      density="compact"
      style="width: 140px; margin-top: -19px;"
      item-title="text"
      item-value="value"
      :items="fromValues"
      :placeholder="$t('dataPoints.from')"
      @input="addNewStart"
      @focus="$emit('activateDefault')"
    />
  </div>
</template>

<script>
export default {
  name: 'RankConfig',

  data() {
    return ({
      newRank: 1,
      newStart: true,
      startOrEnd: 'start',
      fromValues: [
        {
          text: this.$t('dataPoints.from_start'),
          value: 'start'
        },
        {
          text: this.$t('dataPoints.from_end'),
          value: 'end'
        }
      ]
    })
  },

  watch: {
    rank() {
      this.newRank = this.rank;
    },
    fromStart() {
      this.startOrEnd = this.fromStart ? 'start' : 'end';
    },
  },

  mounted() {
    this.newRank = this.rank;
    this.$emit('rankChange', this.newRank);
    this.startOrEnd = this.fromStart ? 'start' : 'end';
  },

  methods: {
    addNewRank() {
      this.$emit('rankChange', this.newRank);
    },

    addNewStart(value) {
      this.newStart = value === 'start';
      this.$emit('startChange', this.newStart);
    },
  },

  props: {
    rank: {
      type: Number,
      required: true,
    },
    fromStart: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['activateDefault', 'rankChange', 'startChange'],
}
</script>
