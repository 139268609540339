<template>
  <div class="training-view d-flex top-gap-lg">
    <ExtractTrainingSteps
      :dataset-id="datasetId"
      @reset="$refs.trainingModels.refreshModels()"
    />
    <TrainingModelsTable
      ref="trainingModels"
      style="width: 45%"
    />
  </div>
</template>

<script>
import TrainingModelsTable from '@/components/extract/elements/Training/TrainingModelsTable';
import ExtractTrainingSteps from "@/components/extract/elements/Training/ExtractTrainingSteps";

export default {
  name: 'ExtractionModelTraining',

  components: {
    TrainingModelsTable,
    ExtractTrainingSteps,
  },

  props: {
    datasetId: {
      type: Number,
      default: -1,
    },
  }
}
</script>
