<template>
  <div
    v-if="$store.getters.loggedInUser.role !== 'orgadmin'"
    style="max-width: 740px"
  >
    <h2 class="text-h2 top-gap-lg">
      {{ $t('docTypes.start_corrections') }}
    </h2>
  </div>
  <div class="extraction-agents-table d-flex align-center justify-space-between">
    <div class="d-flex align-center">
      <v-text-field
        v-model="typeFilter"
        class="filter-items"
        style="width: 280px"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('docTypes.type_name')"
        @keydown.enter="handleEnter"
      />
    </div>
  </div>
  <TableWithFooter
    :loading="!loaded"
    :paginated-items-length="correctionTypes.length"
    :total="totalExtractionAgents"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
  >
    <template #header>
      <v-col cols="8">
        <SortButton
          v-model="sortDesc"
          class="mr-1"
          style="margin-top: -5px"
        />
        {{ $t('corrections.doc_types') }}
      </v-col>
      <v-col style="text-align: center">
        {{ $t('docTypes.docs_to_correct') }}
      </v-col>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in correctionTypes"
          :key="item.id"
          class="table-row fade-in table-row-height clickable"
          style="color: rgb(var(--v-theme-primary))"
          @click="goToDocType(item.id)"
        >
          <v-col cols="8">
            <ItemName
              :key="item.doctype_id"
              class="ml-6"
              style="max-width: calc(100% - 24px)"
              :item="item"
              :editing-allowed="false"
              :clickable="true"
            />
          </v-col>
          <v-col>
            {{ item.nb_prod_files_to_validate }}
          </v-col>
        </v-row>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>

import _ from "lodash";
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import ItemName from '@/components/common/elements/General/ItemName';
import SortButton from '@/components/common/elements/Tables/SortButton';
import { VerifyAPI } from '@/API/extract/VerifyAPI';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';


export default {
  name: 'ExtractionAgentsTable',
  components: {
    SortButton,
    TableWithFooter,
    ItemName,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      typeFilter: '',
      totalExtractionAgents: 0,
      loaded: false,
      itemsPerPage,
      currentPage,
      correctionTypes: [],
    };
  },

  computed: {
    trimmedFilter() {
      return this.typeFilter.trim().toLowerCase();
    },
  },

  watch: {
    sortDesc() {
      this.getExtractionAgents();
    },

    itemsPerPage: async function() {
      this.currentPage = 1;
      await this.getExtractionAgents();
    },

    currentPage: async function() {
      await this.getExtractionAgents();
    },

    trimmedFilter: _.debounce(
      async function() {
        this.currentPage = 1;
        await this.getCorrectionTypes()
      }, 300
    ),
  },

  async mounted() {
    await this.getExtractionAgents();
    this.loaded = true;
  },

  methods: {
    goToDocType(id) {
      this.$router.push({
        name: 'CorrectionDocs',
        params: { id },
      });
    },

    handleEnter() {
      if (this.correctionTypes.length > 0) {
        this.goToDocType(this.correctionTypes[0].id);
      }
    },

    async getExtractionAgents() {
      try {
        this.$store.commit('setLoadingScreen', true);
        const offset = (this.currentPage - 1) * this.itemsPerPage;
        const response = await VerifyAPI.getExtractionAgentsToReview({
          limit: this.itemsPerPage,
          nameFilter: this.trimmedFilter,
          offset,
          sortDesc: this.sortDesc,
        });
        this.correctionTypes = response.data;
        this.totalExtractionAgents = parseInt(response.headers['x-total-count']);
        this.$store.commit('setLoadingScreen', false);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        console.log(error);
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.extraction-agents-table {
  height: 50px;
  margin-top: 28px;
}

</style>