import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/';

export class FolderAPI {
  static getFolder(id) {
    return http.get(`${BASE_PATH}folder/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getTotalFileCount(id) {
    return http.get(`${BASE_PATH}folder/${id}/`)
      .then(response => Promise.resolve(response.data.nb_total_files))
      .catch(error => Promise.reject(error));
  }

  static getProcessedFileCount(id) {
    return http.get(`${BASE_PATH}folder/${id}/`)
      .then(response => Promise.resolve(response.data.nb_processed_files))
      .catch(error => Promise.reject(error));
  }

  static getFolders(parentFolderId = null) {
    return http
      .get(`${BASE_PATH}folder/${parentFolderId ? `?parent_folder_id=${parentFolderId}` : ''}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static createFolder(payload) {
    return http
      .post(`${BASE_PATH}folder/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static updateFolder(id, payload) {
    return http
      .put(`${BASE_PATH}folder/${id}/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteFolder(id) {
    return http
      .delete(`${BASE_PATH}folder/${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static addStore(payload) {
    return http
      .post(`${BASE_PATH}store/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static syncStore(store_id) {
    return http
      .post(`${BASE_PATH}folder/sync`, null, {
        params: {
          store_id,
        }
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
