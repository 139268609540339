<template>
  <TableWithFooter
    class="top-gap"
    :loading="loading"
    :paginated-items-length="pages[currentPage]?.length ?? 0"
    :total="filteredItems.length"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
    include-footer-in-table-card
  >
    <template #header>
      <v-col cols="6">
        <SortButton
          v-model="sortDesc"
          class="mr-1"
          style="margin-top: -5px"
        />
        {{ $t('dataPoints.label_group') }}
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        class="justify-center"
      >
        {{ $t('display') }}
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        class="d-block text-center"
      >
        <p class="my-0 mt-1">
          {{ $t('docTypes.as_table') }}
        </p>
        <v-switch
          v-model="allTableDisplay"
          class="d-flex justify-center mt-1"
          style="bottom: -10px;"
          color="primary"
          density="dense"
          :disabled="disabled"
          @change="updateBatchTableDisplay(pages[currentPage])"
          hide-details
        />
      </v-col>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <div
          v-for="item in pages[currentPage]"
          :key="item.id"
        >
          <v-row
            class="table-row fade-in table-row-height"
            style="border-bottom: 1px solid #eee !important"
          >
            <v-col cols="6">
              <ItemName
                :key="item.id"
                :ref="`name_${item.id}`"
                class="left-gap-lg"
                style="z-index: 202; max-width: calc(100% - 24px)"
                :item="item"
                :clickable="false"
              />
            </v-col>
            <v-col
              v-if="user.role === 'orgadmin'"
              class="justify-center"
            >
              <v-switch
                v-model="item.display_in_review"
                color="primary"
                density="dense"
                :disabled="disabled"
                @change="updateIntermediate(item)"
                hide-details
              />
            </v-col>
            <v-col
              v-if="user.role === 'orgadmin'"
              class="justify-center"
            >
              <v-switch
                v-model="item.table_display"
                color="primary"
                density="dense"
                :disabled="disabled || item.aggregation_method === 'cluster'"
                @change="updateTableDisplay(item)"
                hide-details
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import { http } from '@/plugins/axios';
import { ExtractionGroupAPI } from '@/API/extract/ExtractionGroupAPI';


export default {
  name: 'LabelGroupsReviewTable',

  components: {
    ItemName,
    TableWithFooter,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      itemsPerPage,
      currentPage,
      allTableDisplay: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    sortDesc: {
      get() {
        return this.$store.getters.lgSortDesc;
      },
      set(sortDesc) {
        this.$store.commit('setLgSortDesc', sortDesc);
      },
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    sortDesc(desc) {
      this.$emit('getItems', desc);
    },

    itemsPerPage() {
      this.resetCurrentPage();
    },

    currentPage() {
      this.allTableDisplay = !!this.pages[this.currentPage] && this.pages[this.currentPage].every(item => item.table_display);
    },

    pages() {
      this.allTableDisplay = !!this.pages[this.currentPage] && this.pages[this.currentPage].every(item => item.table_display);
    }
  },

  methods: {
    async updateTableDisplay(group) {
      this.$store.commit('setLoadingScreen', true);
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(async () => {
        try {
          await http.put(`system_2/extraction_groups/${group.id}`,
            { table_display: group.table_display }
          );
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
          this.$store.commit('setSuccessSnackbar', true);
          this.$emit('saveName');
          this.allTableDisplay = this.pages[this.currentPage].every(item => item.table_display)
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
      }, waitTime);
    },

    async updateBatchTableDisplay(groups) {
      this.$store.commit('setLoadingScreen', true);
      try {
        this.$emit('setItems', groups.map(group => ({ ...group, table_display: this.allTableDisplay })));
        const promises = groups.map(group => ExtractionGroupAPI.put(group.id, { table_display: this.allTableDisplay }));
        await Promise.all(promises);
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('saveName');
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async updateIntermediate(group) {
      this.$store.commit('setLoadingScreen', true);
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(async () => {
        try {
          await http.put(`system_2/extraction_groups/${group.id}`,
            { intermediate: !group.display_in_review }
          );
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
          this.$store.commit('setSuccessSnackbar', true);
          this.$emit('saveName');
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
      }, waitTime);
    },

    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['saveName', 'setItems', 'getItems'],
}
</script>
