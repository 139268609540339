/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'model/';

export class ModelAPI {
  static async get(
    offset,
    limit,
    nameFilter = '',
    statusFilter = null,
    sortDesc = true,
    includeError = true,
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          name_filter: nameFilter,
          sort_desc: sortDesc,
          status_filter: statusFilter,
          offset,
          limit,
          include_error: includeError,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async get_progress(model_id, version) {
    return http
      .get(`${BASE_PATH}training_status/${model_id}/${version}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
