<template>
  <div class="files-table">
    <div class="bottom-gap-sm">
      <v-icon
        color="primary"
        class="right-gap-sm"
      >
        fas fa-folder
      </v-icon>
      <HeaderName
        class="inline-middle"
        style="max-width: 600px"
        :item="browseFolder || { name: user.org_name }"
        :editable="false"
        :show-id="false"
      />
      <div
        v-if="!browseFolder"
        class="info-box left-gap inline-middle"
        style="float: right"
      >
        <small>
          <div
            class="inline-middle"
            style="width: 30px"
          >
            <v-icon
              class="info-icon"
              size="16"
            >
              fas fa-info-circle
            </v-icon>
          </div>
          <div
            class="inline-middle"
            style="width: calc(100% - 30px)"
          >
            {{ $t('search.docs.select_synced') }}
          </div>
        </small>
      </div>
    </div>
    <TableActions
      ref="searchTableActions"
      class="mt-0"
      :type="browseFolder && browseFolder.synced ? 'searchSync' : 'search'"
      :number-of-selected="selected.length"
      :create-condition="!!browseFolder"
      @delete-click="handleDelete"
      @create-click="$emit('selectFiles')"
      @sync-click="$emit('sync')"
      @filter-change="(filter) => trimmedFilter = filter"
    />
    <TableWithFooter
      :loading="loading || filesLoading"
      :paginated-items-length="files.length"
      :total="totalFiles"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="3">
          {{ $t('document') }}
        </v-col>
        <v-col cols="2">
          {{ $t('datatable.header.size') }}
        </v-col>
        <v-col cols="3">
          {{ $t('datatable.header.addDate') }}
        </v-col>
        <v-col cols="auto">
          {{ $t('datatable.header.ProcessDate') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedFiles"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap mt-0"
                :style="{opacity: item.synced ? 0.5 : 1}"
                :disabled="item.synced"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col
              v-if="!['uploaded', 'ingested', 'error'].includes(item.status)"
              cols="3"
            >
              <v-icon
                v-if="getExtension(item.name) === 'pdf'"
                class="mr-1"
                color="primary"
                size="18"
              >
                fas fa-file-pdf
              </v-icon>
              <v-icon
                v-else-if="['doc', 'docx', 'docm'].includes(getExtension(item.name))"
                class="mr-1"
                color="primary"
                size="18"
              >
                fas fa-file-word
              </v-icon>
              <v-icon
                v-else-if="['xlsx', 'xlsm'].includes(getExtension(item.name))"
                class="mr-1"
                color="primary"
                size="18"
              >
                fas fa-file-excel
              </v-icon>
              <v-icon
                v-if="getExtension(item.name) === 'txt'"
                class="mr-1"
                color="primary"
                size="18"
              >
                fas fa-file-alt
              </v-icon>
              <ItemName
                :key="item.id"
                style="z-index: 202; width: 95%;"
                type="file"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :editing="editingFile === item.id"
                @save-file-name="(id, newName) => saveName(id, newName)"
                @name-click="openFile(item)"
              />
            </v-col>
            <v-col
              v-else-if="item.status === 'error'"
              cols="3"
            >
              <v-tooltip bottom>
                <template #activator="{ props }">
                  <v-icon
                    class="mr-1"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-exclamation-circle
                  </v-icon>
                  <ItemName
                    :key="item.id"
                    style="max-width: calc(100% - 21px);"
                    type="file"
                    :item="item"
                    :editing-allowed="false"
                    :clickable="false"
                  />
                </template>
                {{ $t('docTypes.doc_processing_failed') }}
              </v-tooltip>
            </v-col>
            <v-col
              v-else
              cols="3"
            >
              <v-tooltip right>
                <template #activator="{ props }">
                  <v-icon
                    class="mr-1"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-spinner fa-pulse
                  </v-icon>
                  <ItemName
                    style="max-width: calc(100% - 21px);"
                    type="file"
                    :item="item"
                    :clickable="false"
                  />
                </template>
                {{ $t('docTypes.being_processed') }}
              </v-tooltip>
            </v-col>
            <v-col cols="2">
              <div v-if="item.size">
                {{ formatSize(item.size) }}
              </div>
              <small v-else>
                —
              </small>
            </v-col>
            <v-col cols="3">
              <small class="gray--text">
                {{ formatDate(item.uploaded_at) }}
              </small>
            </v-col>
            <v-col cols="auto">
              <small
                v-if="item.last_indexed_at"
                class="gray--text"
              >
                {{ formatDate(item.last_indexed_at) }}
              </small>
              <small v-else>
                —
              </small>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('datatable.delete_file')"
      :message="$t('datatable.deleteConfirmation')"
      @confirm="deleteFiles"
      @close="deleteDialog = false"
    />
    <v-dialog
      v-model="progressDialog"
      max-width="400"
      persistent
    >
      <v-card class="dialog-card">
        <h2 class="dialog-title mb-3">
          {{ $t('datatable.deleting_file') }} {{ currentDelete }} / {{ allDelete }}
        </h2>
        <v-progress-linear :value="Math.floor(currentDelete / allDelete * 100)" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import HeaderName from '@/components/common/elements/General/HeaderName';
import ItemName from '@/components/common/elements/General/ItemName';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import TableActions from '@/components/common/elements/Tables/TableActions';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import file_mixin from "@/mixins/file.js";
import format_mixin from '@/mixins/format.js';

import { SearchFileAPI } from '@/API/search/SearchFileAPI';
import { useTableWithFooter } from '@/composables/useTableWithFooter';


export default {
  name: 'SearchFilesTable',

  mixins: [file_mixin, format_mixin],

  components: {
    DeleteDialog,
    ItemName,
    HeaderName,
    TableWithFooter,
    TableActions,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`
    );

    return ({
      sortDesc: true,
      paginatedFiles: [],
      trimmedFilter: '',
      deleteDialog: false,
      progressDialog: false,
      currentDelete: 0,
      allDelete: 0,
      allSelected: false,
      statusCheck: null,
      filesLoading: false,
      forceCompute: Math.random(),
      editingFile: -1,
      itemsPerPage,
      currentPage,
    });
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalFiles / this.itemsPerPage);
    },

    selected() {
      if (this.paginatedFiles.length > 0) {
        return this.paginatedFiles.filter(f => f.selected);
      }
      return [];
    },

    user() {
      return this.$store.getters.loggedInUser;
    },

    searchFolder() {
      return this.$store.getters.searchFolder;
    },

    rootId() {
      return this.$store.getters.rootId;
    },

    browseFolder: {
      get() {
        return this.$store.getters.browseFolder;
      },
      set(value) {
        this.$store.commit('setBrowseFolder', value);
      },
    },

    totalFiles: {
      get() {
        return this.$store.getters.searchTotalFiles;
      },
      set(val) {
        this.$store.commit('setSearchTotalFiles', val);
      },
    },
  },

  watch: {
    browseFolder() {
      this.resetCurrentPage();
      this.getFiles();
    },

    sortDesc() {
      this.getFiles();
    },

    totalFiles(total) {
      if (this.trimmedFilter === '') {
        this.totalFilesDisplay = total;
      }
    },

    deleteDialog(on) {
      if (on) {
        clearInterval(this.statusCheck);
      } else {
        this.checkStatus();
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.checkStatus();
    },

    currentPage() {
      this.unselect();
      this.checkStatus();
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.checkStatus();
      }, 300
    ),

    searchFolder() {
      this.resetCurrentPage();
      this.getFiles();
    },

    rootId() {
      this.resetCurrentPage();
      this.getFiles();
    },
  },

  mounted() {
    this.paginatedFiles = [];
    this.checkStatus();
  },

  unmounted() {
    clearInterval(this.statusCheck);
  },

  methods: {
    getExtension(filename) {
      return filename.split('.').pop().toLowerCase();
    },

    openFile(doc) {
      window.open(doc.url, '_blank');
    },

    checkStatus() {
      this.getFiles((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
      clearInterval(this.statusCheck);
      this.statusCheck = setInterval(() => {
        if (!this.paginatedFiles.some(file => file.status !== 'indexed')) {
          clearInterval(this.statusCheck);
          this.$emit('refresh-folders');
        } else {
          this.getFiles(
            (this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage, false
          );
        }
      }, 3000);
    },

    handleSelect() {
      this.allSelected = this.paginatedFiles.every(f => f.selected);
    },

    toggleSelectAll() {
      this.paginatedFiles.forEach(item => {
        if (!item.synced) {
          item.selected = this.allSelected;
        }
      });
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.unselect();
    },

    handleFilter() {
      if (this.trimmedFilter !== '') {
        this.currentPage = 1;
      }
    },

    handleDelete() {
      this.deleteDialog = true;
    },

    async deleteFile(id) {
      try {
        await SearchFileAPI.delete(id);
      } catch (err) {
        console.log(err);
      }
    },

    async getFiles(offset = 0, limit = this.itemsPerPage, loading = true) {
      if (loading) {
        this.filesLoading = true;
      }
      if (this.browseFolder || this.rootId) {
        try {
          let folderId = this.rootId;
          if (this.browseFolder) {
            folderId = this.browseFolder.id;
          }
          const includeSubfolders = true;
          const [files, totalFiles] = await SearchFileAPI.getFiles(
            folderId,
            offset,
            limit,
            this.trimmedFilter,
            includeSubfolders,
            this.sortDesc,
          );
          this.paginatedFiles = files.map(f => {
            f.selected = false;
            return f;
          });
          this.totalFiles = totalFiles;
          if (this.trimmedFilter === '') {
            this.totalFilesDisplay = this.totalFiles;
          }
        } catch (err) {
          console.log(err);
          clearInterval(this.statusCheck);
        } finally {
          this.filesLoading = false;
        }
      }
    },

    async deleteFiles() {
      const selected = [...this.selected];
      const total = selected.length;
      this.deleteDialog = false;
      this.allDelete = total;
      this.progressDialog = true;
      for (let i = 0; i < total; i++) {
        this.currentDelete = i + 1;
        await this.deleteFile(selected[i].id);
      }
      this.$emit('updateFileNumber', -total);
      await this.setFiles();
      this.progressDialog = false;
      this.allSelected = false;
      this.currentPage = Math.max(1, Math.min(this.currentPage, this.totalPages));
      this.$store.commit('setSuccessMessage', `${this.$t('docTypes.files.deleted')} (${total}).`);
      this.$store.commit('setSuccessSnackbar', true);
    },

    async setFiles() {
      this.$refs.searchTableActions.filter = '';
      this.trimmedFilter = '';
      await this.getFiles((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    unselect(){
      this.allSelected = false;
      this.paginatedFiles.forEach(item => {
        item.selected = false;
      });
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'selectFiles',
    'updateFileNumber',
    'validate',
    'sync',
    'refresh-folders',
  ],
}
</script>

<style lang="scss" scoped>
.info-box {
  background-color: rgb(var(--v-theme-primary-lighten2));
  border-radius: 6px;
  padding: 6px 17px;
  padding-bottom: 10px;
  width: fit-content;

  .info-icon {
    margin-right: 2px;
    top: -1px;
  }
}
</style>