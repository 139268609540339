<template>
  <div v-if="showExplanations">
    <div style="font-size: 10px !important">
      {{ message }}
    </div>
    <div
      v-for="explanation in explanations.filter(br => br)"
      :key="explanation.string"
      style="font-size: 12px !important;"
      :class="{
        'br-feedback-invalid': explanation && !explanation.isValid,
        'br-feedback-valid': explanation && explanation.isValid,
      }"
    >
      {{ explanation.string }}
    </div>
  </div>
  <div v-else>
    {{ message }}
  </div>
</template>
<script>
export default {
  name: 'RuleExplanations',

  props: {
    showExplanations: {
      type: Boolean,
      default: false,
    },
    explanations: {
      type: Array,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.br-feedback-valid {
  color: $valid-base !important;
}

.br-feedback-invalid {
  color: $invalid-base !important;
}
</style>
