<template>
  <div class="py-6">
    <div class="d-flex">
      <div
        v-if="userRole === 'sysadmin'"
        class="mr-6"
      >
        <label
          for="org-select"
          class="mr-4 inline-middle"
        >
          {{ $t('stats.org_select') }}
        </label>
        <v-select
          id="org-select"
          v-model="orgId"
          class="inline-middle mt-0"
          variant="outlined"
          color="primary"
          density="compact"
          item-title="name"
          item-value="id"
          style="width: 200px; margin-top: 0 !important"
          :items="organizations"
        />
      </div>
      <DocTypeSelect
        v-else
        class="inline-middle right-gap-sm"
        @selected-changed="(id) => {docTypeId = id}"
        show-every
      />
      <div
        class="my-auto right-gap"
        style="margin-top: 0px"
      >
        <v-btn
          v-for="(predefinedDate, id) in predefinedDates"
          :key="id"
          @click="predefinedDate.clickAction"
          large
          rounded
        >
          {{ predefinedDate.message }}
        </v-btn>
      </div>
      <v-tooltip bottom>
        <template #activator="{ props }">
          <v-btn
            class="ml-auto"
            color="primary"
            style="height: 36px"
            v-bind="props"
            @click="downloadTracesAggregates"
            rounded
          >
            <v-icon start>
              fas fa-download
            </v-icon>
            {{ $t('traces.download') }}
          </v-btn>
        </template>
        {{ $t("traces.download_detail") }}
      </v-tooltip>
    </div>

    <div class="text-center mt-2">
      <v-btn
        size="x-large"
        color="primary"
        variant="text"
        @click="dateRangeDialog = true"
        rounded
      >
        {{ formatDate(fromDate, userLanguage, false) }} - {{ formatDate(toDate, userLanguage, false) }}
      </v-btn>
    </div>

    <TracesCharts
      v-if="formattedToDate"
      style="margin-top: -20px"
      :current-date="formattedToDate"
      :categories="actions[currentAction] || []"
      :show-charts="showCharts[currentAction]"
      :number-of-days="numberOfDays"
    />
    <DateRangeSelectDialog
      v-if="fromDate && toDate"
      v-model="dateRangeDialog"
      :from-date="fromDate"
      :to-date="toDate"
      @confirm="updateDates"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { formatDate } from '@/utils/classes/Formatting';
import DateRangeSelectDialog from '@/components/extract/elements/Stats/DateRangeSelectDialog';
import TracesCharts from '@/components/extract/elements/Stats/TracesCharts';
import DocTypeSelect from '@/components/extract/elements/DocTypes/DocTypeSelect';

import stats_mixin from '@/mixins/stats.js';

export default {
  name: 'TracesView',

  mixins: [stats_mixin],

  components: {
    DateRangeSelectDialog,
    TracesCharts,
    DocTypeSelect,
  },

  data() {
    return {
      chartKey: 0,
      docTypeId: -1,
      currentAction: 'ingestion',
      weeksInMilliseconds: 6.048e+8,
      monthInMilleseconds: 2.628e+9,
      twoWeeks: 14,
      eightWeeks: 56,
      fromDate: null,
      toDate: null,
      formattedFromDate: null,
      formattedToDate: null,
      timeGranularity: 'week',
      dateRangeDialog: false,
      showCharts: {
        extraction: ['doc_count', 'page_count'],
        ingestion: ['doc_count', 'useful_page_count', 'page_count'],
        upload: ['doc_count', 'size_sum'],
      },
      traces: [],
    };
  },

  computed: {
    predefinedDates() {
      return [
        {
          "clickAction": this.selectLastFourWeeks,
          "message": this.$t('traces.last_four_weeks')
        },
        {
          "clickAction": this.selectLastsixMonths,
          "message": this.$t('traces.six_months')
        },
        {
          "clickAction": this.selectLastYear,
          "message": this.$t('traces.last_year')
        },
      ]
    },

    userLanguage() {
      return this.$store.getters?.loggedInUser?.language;
    },

    actions() {
      return this.traces.reduce((res, item) => {
        const key = item.trace_item.split(':')[1];
        if (!res[key]) {
          res[key] = [];
        }
        res[key].push(item);
        return res;
      }, {});
    },

    numberOfDays() {
      if (!this.fromDate || !this.toDate) {
        return 0;
      }
      return Math.round((this.toDate.getTime() - this.fromDate.getTime()) / (1000 * 3600 * 24) + 1)
    },
  },

  watch: {
    orgId(id, old) {
      if (id === old || !this.formattedFromDate || !this.formattedToDate) {
        return;
      }
      this.getTraces();
    },

    docTypeId(id, old) {
      if (id === old || !this.formattedFromDate || !this.formattedToDate) {
        return;
      }
      this.getTraces();
    },
  },

  mounted() {
    this.selectLastFourWeeks();
  },

  methods: {
    formatDate,

    async getTraces() {
      if (this.numberOfDays <= this.twoWeeks) {
        this.formattedFromDate = this.getDayString(this.fromDate);
        this.formattedToDate = this.getDayString(this.toDate);
        this.timeGranularity = 'day';
      } else if (this.numberOfDays <= this.eightWeeks) {
        this.formattedFromDate = this.getWeekString(this.fromDate);
        this.formattedToDate = this.getWeekString(this.toDate);
        this.timeGranularity = 'week';
      } else {
        this.formattedFromDate = this.getMonthString(this.fromDate);
        this.formattedToDate = this.getMonthString(this.toDate);
        this.timeGranularity = 'month';
      }
      try {
        let params = {
          trace_item: '%doctype:ingestion%',
          time_granularity: this.timeGranularity,
          timestamp_from: this.formattedFromDate,
          timestamp_to: this.formattedToDate,
          object_id: this.docTypeId === -1 ? null : this.docTypeId,
        }
        if (this.userRole === 'sysadmin') {
          params = {
            ...params,
            org_id: this.orgId,
          }
        }
        const { data } = await http.get('auth/api/v1/traces/aggregates/', { params });
        params.trace_item = '%doctype:extraction%';
        const extractionData = await http.get('auth/api/v1/traces/aggregates/', { params });
        for (const item of data) {
          const updateData = extractionData.data.find(ext => ext.timestamp === item.timestamp);
          if (updateData) {
            item.useful_page_count = updateData.useful_page_count;
          }
        }
        this.traces = data;
      } catch (error) {
        console.error(error);
      }
    },

    async downloadTracesAggregates() {
      const params = {};
      let filename = 'traces-aggregates';
      if (this.userRole === 'sysadmin') {
        params.org_id = this.orgId;
        filename += `-orgId-${this.orgId}`;
      }
      try {
        const response = await http.get('auth/api/v1/traces/aggregates/download/', {
          params,
          responseType: 'arraybuffer',
        });
        const url = window.URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel',
        }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      }
    },

    getDayString(date) {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    },

    getWeekString(date) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
      var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
      var weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
      weekNo = String(weekNo).padStart(2, '0');
      return `${date.getUTCFullYear()}-${weekNo}`;
    },

    getMonthString(date) {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    },

    selectLastFourWeeks() {
      this.updateDates(new Date(Date.now() - (this.weeksInMilliseconds * 4)), new Date());
    },

    selectLastsixMonths() {
      this.updateDates(new Date(Date.now() - (this.monthInMilleseconds * 6)), new Date());
    },

    selectLastYear() {
      this.updateDates(new Date(Date.now() - (this.monthInMilleseconds * 12)), new Date());
    },

    updateDates(fromDate, toDate) {
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.getTraces();
    }
  }
}
</script>