/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'files/';

export class FileAPI {
  static async get(
    documentTypeId,
    offset,
    limit,
    nameFilter = '',
    sortDesc = true,
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          document_type_id: documentTypeId,
          name_filter: nameFilter,
          sort_desc: sortDesc,
          offset,
          limit,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async post(doctypeId, file) {
    const formData = new FormData();
    formData.append("file_in", file);
    formData.append("doctype_id", doctypeId);
    const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
    return http
      .post(BASE_PATH, formData, headerConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
