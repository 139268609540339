<template>
  <div class="review-text-area">
    <div
      class="label"
      style="margin-bottom: 5px"
    >
      {{ value.reviewName }}
    </div>
    <div>
      <textarea
        ref="textArea"
        v-model="value.reviewValue"
        class="review-text-area-inner inline-top"
        variant="outlined"
        color="primary"
        :class="{
          'highlighted-border': (
            listHoveredId == value[idName] || viewerHoveredId == value[idName]
          ),
          'focused-border': reviewingId == value[idName],
          'invalid-border': value.reviewStatus === 'pre-invalidated',
          'reviewing-reviewed': canReinit && !loading,
        }"
        :disabled="loading"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @mouseenter="$emit('hover', value)"
        @mouseout="$emit('hover', null)"
        @input="handleInput"
        @keydown.enter="(e) => {
          e.preventDefault();
          $emit('blur');
        }"
      />
      <v-icon
        v-if="loading"
        class="left-gap-sm"
        color="primary"
        size="20"
        style="margin-top: 5px"
      >
        fas fa-spinner fa-pulse
      </v-icon>
      <v-tooltip
        v-if="!loading
          && reviewingId != value[idName]
          && value.reviewStatus === 'prevalidated'"
        location="right"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="small-button prevalidated-button left-gap-sm inline-top"
            style="margin-top: 5px"
            color="primary"
            @click="handleVerify"
          >
            <v-icon size="16">
              fas fa-check
            </v-icon>
          </v-btn>
        </template>
        <RuleExplanations
          :explanations="businessRuleStrings"
          :message="$t('dataPoints.prevalidated_message')"
          show-explanations
        />
      </v-tooltip>
      <v-tooltip
        v-if="!loading
          && reviewingId != value[idName] && value.reviewStatus === 'pre-invalidated'"
        location="right"
      >
        <template #activator="{ props }">
          <v-icon
            v-bind="props"
            class="pre-invalidated-icon left-gap-sm inline-top"
            style="margin-top: 5px"
            size="16"
          >
            fas fa-exclamation-circle
          </v-icon>
        </template>
        <RuleExplanations
          :explanations="businessRuleStrings"
          :message="$t('dataPoints.pre_invalidated_message')"
          show-explanations
        />
      </v-tooltip>
      <v-tooltip
        v-if="!loading && canReinit"
        location="right"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="small-button invalid-button left-gap-sm inline-top"
            style="margin-top: 5px"
            color="primary"
            @click="handleReinit"
          >
            <v-icon size="16">
              fas fa-redo
            </v-icon>
          </v-btn>
        </template>
        {{ $t("dataPoints.revert_message") }}
      </v-tooltip>
      <v-tooltip
        v-if="!loading
          && (reviewingId == value[idName] || value.reviewStatus === 'pending')"
        location="right"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="small-button left-gap-sm inline-top"
            style="margin-top: 5px"
            color="primary"
            @click="handleVerify"
          >
            <v-icon size="16">
              fas fa-check
            </v-icon>
          </v-btn>
        </template>
        {{ $t("dataPoints.to_review_message") }}
      </v-tooltip>
      <v-tooltip
        v-if="!loading
          && reviewingId != value[idName] && value.reviewStatus === 'reviewed'"
        location="right"
      >
        <template #activator="{ props }">
          <v-icon
            v-bind="props"
            class="valid-icon left-gap-sm inline-top"
            style="margin-top: 5px"
            size="16"
          >
            fas fa-check
          </v-icon>
        </template>
        {{ $t("dataPoints.reviewed_message") }}
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import RuleExplanations from '@/components/extract/elements/BusinessRules/RuleExplanations.vue';

export default {
  name: 'ReviewTextArea',

  components: {
    RuleExplanations,
  },

  computed: {
    canReinit() {
      if (this.value[this.idName] == this.reviewingId) {
        return false;
      }
      let value = null;
      if (this.value.data_point_id) {
        value = this.value.value;
      } else {
        value = this.value;
      }
      if (
        this.value.reviewStatus === 'reviewed'
        && value
        && value.location
        && value.page_nb
        && value.value !== ''
        && value.value != this.value.reviewValue
      ) {
        return true;
      }
      return false;
    }
  },

  mounted() {
    this.setTextAreaHeightByInput();
  },

  methods: {
    handleVerify() {
      this.value.reviewChanged = false;
      this.setTextAreaHeightByInput();
      this.$emit('verify');
    },

    handleInput() {
      this.value.reviewChanged = true;
    },

    handleReinit() {
      this.value.reviewChanged = true;
      this.$emit('reinit');
    },

    setTextAreaHeightByInput() {
      const textArea = this.$refs.textArea;
      if (textArea) {
        if (
          (typeof this.value.reviewValue === 'string'
          || this.value.reviewValue instanceof String)
        ) {
          if (textArea.scrollHeight > 58) {
            textArea.style.height = textArea.scrollHeight + 10 + "px";
          }
          if (this.value.reviewValue.includes('\n')) {
            const newHeight = this.value.reviewValue.split('\n').length * 24 + 10;
            if (newHeight > textArea.style.height) {
              textArea.style.height = Math.max(newHeight, textArea.style.height);
            }
          } else {
            textArea.style.height =
              this.value.reviewStatus === 'pre-invalidated' ? '42px' : '40px';
          }
        } else {
          textArea.style.height =
            this.value.reviewStatus === 'pre-invalidated' ? '42px' : '40px';
        }
      }
    },
  },

  props: {
    value: {
      type: Object,
      required: true,
    },

    listHoveredId: {
      type: Number,
      required: true,
    },

    viewerHoveredId: {
      type: Number,
      required: true,
    },

    reviewingId: {
      type: Number,
      required: true,
    },

    idName: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    businessRuleStrings: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['verify', 'reinit', 'focus', 'blur', 'hover'],
}
</script>

<style lang="scss" scoped>
.review-text-area {
  .review-text-area-inner {
    width: calc(100% - 42px) !important;
    min-height: rem(40) !important;
    max-height: none !important;
    border-radius: 4px !important;
    border: 1px solid rgb(var(--v-theme-grey-darken2)) !important;
    background-color: white !important;
    font-family: 'Inter' !important;
    font-size: rem(14) !important;
    font-weight: 400 !important;
    line-height: rem(20) !important;
    letter-spacing: normal !important;
    padding: 9px !important;
    resize: vertical !important;
  }

  .reviewing-reviewed {
    width: calc(100% - 84px) !important;
  }

  .focused-border {
    outline: black 2px solid !important;
  }

  .invalid-border {
    border: $prevalidated-incorrect 2px solid !important;
  }

  .highlighted-border {
    outline: rgb(var(--v-theme-primary)) 2px solid !important;
  }

  .prevalidated-button {
    background-color: $prevalidated-correct !important;
  }

  .valid-icon {
    color: $valid-base !important;
  }

  .invalid-button {
    background-color: $invalid-base !important;
  }


  .pre-invalidated-icon {
    color: $prevalidated-incorrect!important;
  }
}
</style>
