<template>
  <div class="initial-search-upload">
    <h1 class="text-h1">
      {{ $t('welcome.hello') }}
    </h1>
    <h3 class="top-gap-sm">
      {{ $t('search.home.upload_message') }}
    </h3>
    <div class="top-gap-lg d-flex">
      <div
        v-if="loggedInUser.role === 'sysadmin'"
        class="right-gap"
      >
        <div class="label">
          {{ $t('forms.organization') }}
        </div>
        <OrgSelect
          style="margin-top: 6px"
          :selected="selectedOrg"
          @selected-changed="(id) => { selectedOrg = id }"
        />
      </div>
      <div class="right-gap">
        <div class="label">
          {{ $t('forms.folder_name') }}
        </div>
        <v-text-field
          v-model="folderName"
          class="form-input"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('forms.folder_placeholder')"
          :aria-placeholder="$t('forms.folder_placeholder')"
          @keyup.enter="isDisabled || uploadFiles() "
        />
      </div>
      <v-btn
        class="save-button"
        color="primary"
        :disabled="isDisabled"
        @click="uploadFiles"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>
    <FileUploader
      class="top-gap-lg"
      :loading="uploading"
      @files-selected="handleFilesSelect"
    />
  </div>
</template>

<script>
import { FolderAPI } from '@/API/search/FolderAPI';
import { SearchFileAPI } from '@/API/search/SearchFileAPI';
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';

import FileUploader from '@/components/common/elements/Forms/FileUploader';
import OrgSelect from "@/components/common/elements/Organizations/OrgSelect";

export default {
  name: 'InitialSearchUpload',

  components: {
    FileUploader,
    OrgSelect,
  },

  data() {
    return ({
      selectedOrg: -1,
      folderName: '',
      files: [],
      uploading: false,
      pollInterval: 3000,
    });
  },

  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },

    isDisabled() {
      if (this.loggedInUser.role === 'sysadmin' && this.selectedOrg === -1) {
        return true;
      }
      return (this.folderName.trim() === '' || this.files.length === 0);
    },
  },

  methods: {
    handleFilesSelect(files) {
      this.files = files;
      if (!this.isDisabled) {
        this.uploadFiles();
      }
    },

    async createFolder(rootId) {
      try {
        const folderId = await FolderAPI.createFolder({
          name: this.folderName.trim(),
          parent_id: rootId,
        });
        return folderId;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    async getRootId() {
      const orgId = this.loggedInUser.role === 'sysadmin' ? this.selectedOrg : this.loggedInUser.org_id;
      try {
        return await SearchConfigAPI.getRoot(orgId);
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    async startUpload(file, folderId) {
      try {
        await SearchFileAPI.post(file, folderId);
      } catch (error) {
        console.log(error);
      }
    },

    async pollFilesStatus(folderId) {
      try {
        const filesInfo = await SearchFileAPI.getFiles(folderId);
        if (filesInfo[1] > 0 && filesInfo[0].every(f => f.status === 'indexed')) {
          this.uploading = false;
          this.$store.commit('setLoadingScreen', false);
          this.$emit('uploadComplete');
        } else {
          setTimeout(() => {
            this.pollFilesStatus(folderId);
          }, this.pollInterval);
        }
      } catch (error) {
        console.log(error);
        this.uploading = false;
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async uploadFiles() {
      this.$store.commit('setLoadingScreen', true);
      this.uploading = true;
      const rootId = await this.getRootId();
      if (rootId) {
        const folderId = await this.createFolder(rootId);
        const length = this.files.length;
        if (length > 0) {
          if (typeof this.files === 'object') {
            this.files = Object.values(this.files).map(item => item);
          }
          for (let i = 0; i < length; i++) {
            await this.startUpload(this.files[i], folderId);
          }
          this.pollFilesStatus(folderId);
        }
      }
    },
  },

  emits: ['uploadComplete'],
}
</script>

<style lang="scss" scoped>
  .initial-search-upload {
    .form-input {
      width: 300px;
      margin-top: 5px !important;
    }

    .save-button {
      top: 30px;
      height: 40px !important;
      box-shadow: none !important;
    }
  }
</style>
