<template>
  <NotFoundView :go-back-to="-2" />
</template>

<script>
import NotFoundView from '@/components/common/views/404/NotFoundView';

export default {
  name: 'NotFoundViewRedirect',

  components: { NotFoundView },
}
</script>
