<template>
  <div
    class="custom-node"
    :class="{
      selected,
      'lr': direction === 'LR',
      'ud': direction === 'UD',
    }"
  >
    <div
      class="node-icon"
      :class="{
        'initial': node.initial,
        'final': node.final,
        'state': node.action_type == 'state',
        'bindings': node.action_type == 'bindings',
        'python': node.action_type == 'python',
      }"
    >
      <Handle
        v-if="!node.initial"
        type="target"
        class="node-handle incoming"
        :position="direction === 'LR' ? left : top"
      />

      <Handle
        v-if="!node.final"
        type="source"
        class="node-handle outgoing"
        :position="direction === 'LR' ? right : bottom"
      />

      <div
        v-if="node.action_type != 'state'"
        class="text-center"
      >
        <strong>
          {{ node.name || label }}
        </strong>
      </div>

      <div>
        <span
          v-if="node.config && node.config.iterate"
          class="text-center"
          :title="$t('workflows.steps.config.iterate')"
        >
          ⮔
        </span>
      </div>

      <div
        v-if="hovered && status === 'draft'"
        class="close-button"
        @click.stop="$emit('delete')"
      >
        ×
      </div>
    </div>

    <div
      class="text-center"
    >
      <strong v-if="node.action_type == 'state'">
        {{ node.name || node.action }}
      </strong>
      <strong v-else>
        &nbsp; <!-- XXX Prevents nodes collapsing and ensures proper alignment -->
      </strong>
    </div>
  </div>
</template>

<script>
import { Position, Handle } from '@vue-flow/core';

export default {
  name: 'CustomNode',

  components: {
    Handle,
  },

  computed: {
    left() {
      return Position.Left;
    },

    right() {
      return Position.Right;
    },

    top() {
      return Position.Top;
    },

    bottom() {
      return Position.Bottom;
    },

    startNode() {
      return this.type === 'state' && this.isInitial;
    },

    endNode() {
      return this.type === 'state' && this.isFinal;
    },
  },

  props: {
    label: {
      type: String,
      default: '',
    },

    node: {
      type: Object,
      default: null,
    },

    name: {
      type: String,
      default: '',
    },

    selected: {
      type: Boolean,
      default: false,
    },

    status: {
      type: String,
      default: 'draft',
    },

    handles: {
      type: String,
      default: 'both',
    },

    direction: {
      type: String,
      default: 'LR',
    },

    isInitial: {
      type: Boolean,
      default: false,
    },

    isFinal: {
      type: Boolean,
      default: false,
    },
    
    type: {
      type: String,
      default: 'action',
    },

    hovered: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['delete', 'mouseover'],
}
</script>

<style lang="scss" scoped>
.custom-node {
  cursor: pointer;

  font-size: 0.9rem;
}

.close-button {
  position: absolute;
  top: -2px;
  right: 50%;

  line-height: 0px;

  transform: translateX(50%);

  color: rgb(var(--v-theme-primary));

  font-weight: bold;
  font-size: 2em;
}

.node-icon {
  position: relative;

  margin: auto;
  margin-top: 0;
  margin-bottom: 0;

  background-color: white;

  border-color: #666;
  border-style: solid;

  &.state {
    height: 50px;
    width: 50px;

    border-radius: 50%;
    border: 4px double;

    &.initial {
      border-width: 2px;
      border-style: solid;
    }

    &.final {
      border-width: 3px;
      border-style: solid;
    }
  }

  &.bindings,
  &.python {
    min-height: 50px;
    min-width: 125px;

    border-radius: 4px;
    border: 1px solid;

    text-align: center;

    padding: 1em 2em;

    &.iterate {
      padding-bottom: 2.5em;
    }
  }
}

.node-handle {
  background-color: rgb(var(--v-theme-primary));
  border: 0;

  width: 5px;
  height: 5px;
}

.node-handle:hover {
  background-color: rgb(var(--v-theme-primary));

  width: 15px;
  height: 15px;
}

.lr .node-icon.state .node-handle.incoming { margin-left: -4px !important; }
/*.lr .node-icon.state.initial .node-handle.incoming { margin-left: -5px !important; }*/
.lr .node-icon.state.final .node-handle.incoming { margin-left: -4px !important; }

.lr .node-icon.state .node-handle.outgoing { margin-right: -3px !important; }
.lr .node-icon.state.initial .node-handle.outgoing { margin-right: -2px !important; }
/*.lr .node-icon.state.final .node-handle.outgoing { margin-right: -10px !important; } */

.selected .node-icon {
  border-color: rgb(var(--v-theme-primary));
}
</style>
