<template>
  <v-dialog
    v-model="openDialog"
    max-width="700"
    @click:outside="$emit('update:model-value', false)"
    @keydown.esc="$emit('update:model-value', false)"
  >
    <v-card class="dialog-card">
      <v-icon
        style="position: absolute; right: 10px; top: 10px;"
        color="black"
        size="17"
        @click="$emit('update:model-value', false)"
      >
        fas fa-times
      </v-icon>
      <h2 class="dialog-title mb-3">
        {{ $t('traces.select_date_range') }}
      </h2>
      <div class="d-flex">
        <div>
          <div class="label left-gap">
            {{ $t('from') }}
          </div>
          <v-date-picker
            v-model="from"
            color="primary"
            :allowed-dates="d => d <= today"
            hide-header
          />
        </div>
        <div>
          <div class="label left-gap">
            {{ $t('until') }}
          </div>
          <v-date-picker
            v-model="to"
            color="primary"
            :allowed-dates="d => d >= from && d <= today"
            hide-header
          />
        </div>
      </div>
      <div class="mt-8 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="$emit('update:model-value', false)"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            :disabled="from > to"
            @click="$emit('confirm', from, to); $emit('update:model-value', false)"
            block
            rounded
          >
            {{ $t('validate') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DateRangeSelectDialog',

  data() {
    return ({
      today: new Date().getTime(),
      from: null,
      to: null,
      openDialog: false,
    });
  },

  watch: {
    openDialog(open) {
      this.$emit('update:model-value', open);
      this.from = this.fromDate;
      this.to = this.toDate;
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    fromDate: {
      type: Date,
      required: true,
    },

    toDate: {
      type: Date,
      required: true,
    }
  },

  emits: ['confirm', 'update:model-value'],
}
</script>

<style lang="scss" scoped>
  .dialog-button {
    width: 100%;
  }
</style>
