<template>
  <textarea
    ref="editor"
    v-model="editedValue"
    :rows="rows"
    :class="['text-import mt-0', { disabled }]"
    :disabled="disabled"
    @input="$emit('changed', $event.target.value)"
    @keydown.esc="cancelEditing"
    @keydown.enter="savePrompt"
    @blur="savePrompt"
    hide-details
  />
</template>
<script>
export default {
  name: 'TextEditor',

  data() {
    return {
      editedValue: '',
    };
  },

  mounted() {
    this.editedValue = this.text;
  },

  beforeUnmount() {
    if (this.editedValue !== this.text) {
      this.$emit('save', this.editedValue);
    }
  },

  methods: {
    cancelEditing() {
      this.$nextTick(() => {
        this.$refs.editor.blur();
      });
      this.editedValue = this.text;
    },

    savePrompt() {
      this.$nextTick(() => {
        this.$refs.editor.blur();
      });
      this.$emit('save', this.editedValue);
    },
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    rows: {
      type: String,
      default: '8',
    },
  },

  emits: ['save', 'changed'],
};
</script>
<style scoped>
.disabled {
  opacity: 0.5;
}

.v-btn .v-icon::before {
  font-size: 1rem !important;
}

.text-import {
  max-height: none;
  line-height: rem(20);
  font-family: monospace;
  font-weight: 400;
  font-style: italic;
  letter-spacing: normal;
  color: rgb(var(--v-theme-grey-darken4));
  transition: border-color 0.3s ease;
  resize: none;
  border: 1px solid rgb(var(--v-theme-grey-darken2));
  background-color: rgb(var(--v-theme-white));
  border-radius: 4px;
  padding: 8px 16px;
  transition: width 0.3s ease;

  &:hover {
    border: 1px solid rgb(var(--v-theme-grey-darken3));
  }

  &:focus {
    outline: none !important;
    border: 1px solid var(--v-primary-base);
  }
}
</style>
