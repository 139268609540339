<template>
  <div class="classifiers-training d-flex top-gap-lg">
    <ClassifyTrainingSteps @reset="$refs.trainingModels.refreshModels()" />
    <TrainingClassifiersTable
      ref="trainingModels"
      style="width: 45%"
    />
  </div>
</template>

<script>
import TrainingClassifiersTable from "@/components/extract/elements/Training/TrainingClassifiersTable";
import ClassifyTrainingSteps from "@/components/extract/elements/Training/ClassifyTrainingSteps";

export default {
  name: 'ClassifiersTraining',

  components: {
    TrainingClassifiersTable,
    ClassifyTrainingSteps,
  },
}
</script>
