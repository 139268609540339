<template>
  <div class="create-dp-group align-center top-gap">
    <div>
      <div class="label">
        {{ $t('forms.name') }}
      </div>
      <v-text-field
        ref="nameField"
        v-model="DPName"
        style="width: 280px; margin-top: 5px;"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('dataPoints.type_group_name')"
        @keydown.enter="addNew"
      />
      <div
        v-if="tooLong"
        class="name-error bottom-gap"
      >
        {{ $t('dataPoints.long_name_msg') }}
      </div>
    </div>
    <v-radio-group
      v-model="extractionType"
      class="top-gap-sm"
      hide-details
      inline
    >
      <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
        <v-radio
          value="model"
          :label="$t('dataPoints.model_option')"
        />
      </div>
      <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
        <v-radio
          value="generative"
          :label="$t('dataPoints.generative.title')"
        />
      </div>
    </v-radio-group>
    <div
      v-if="extractionType === 'model'"
      style="margin-top: -7px"
    >
      <div class="label">
        {{ $t('dataPoints.model') }}
      </div>
      <CustomSelect
        style="margin-top: 5px"
        :items="customModels"
        :selected="selectedModel"
        @selected-changed="(id) => {selectedModel = id}"
        @get-more="(nameFilter, reset) => getMoreModels(numberOfCustomModels, nameFilter, reset)"
      />
      <v-select
        v-if="selectedModel !== 0"
        v-model="selectedModelVersion"
        class="clickable inline-middle"
        variant="outlined"
        color="primary"
        density="compact"
        style="width: 300px"
        item-title="version"
        :disabled="selectedModel === 0"
        :items="modelVersions"
        :placeholder="$t('models.select_version')"
      />
    </div>
    <div
      v-if="extractionType === 'model'"
      style="margin-top: 13px"
    >
      <div class="label">
        {{ $t('dataPoints.aggregation_method') }}
      </div>
      <v-select 
        v-model="aggregationMethod" 
        class="custom-select inline-middle"
        style="margin-top: 5px"
        variant="outlined"
        color="primary"
        density="compact"
        :items="localMethods"
      />
    </div>
    <div v-if="aggregationMethod === 'cluster'">
      <div class="label">
        {{ $t('dataPoints.number_of_clusters') }}
      </div>
      <div
        class="section"
        style="margin-top: 13px"
      >
        <div
          class="inline-middle radio-box right-gap-sm"
          style="padding-right: 20px"
        >
          <v-checkbox
            v-model="autoDetectClusters"
            class="inline-middle right-gap-sm"
            color="primary"
            style="margin-top: -7px"
            :label="$t('dataPoints.auto_detect')"
          />
        </div>
        <div
          class="inline-middle radio-box"
          style="padding-right: 20px"
          :style="{ opacity: autoDetectClusters ? 0.5 : 1 }"
        >
          <input
            ref="PageField"
            v-model="nbClusters"
            class="config-input-field right-gap-sm accent--text"
            placeholder="0"
            type="number"
            :min="0"
            @input="handleClusterNumbers"
          >
        </div>
      </div>
    </div>
    <v-btn
      class="top-gap-sm"
      color="primary"
      style="box-shadow: none"
      :disabled="incomplete"
      @click="addNew"
      rounded
    >
      {{ $t('save') }}
    </v-btn>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import model_mixin from '@/mixins/model.js';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';

export default {
  name: 'CreateDPGroup',

  mixins: [
    model_mixin,
  ],

  components: {
    CustomSelect,
  },

  data() {
    return ({
      DPName: '',
      aggregationMethod: 'line',
      methods: ['line', 'divided_line', 'column', 'block', 'cluster', 'table_row', 'table_cell'],
      selectedModel: 0,
      autoDetectClusters: true,
      nbClusters: 0,
      extractionType: 'model',
    });
  },

  computed: {
    incomplete() {
      return this.DPName.trim().length < 1
        || this.tooLong
        || this.extractionType === 'model' && (this.selectedModel === 0 || this.selectedModelVersion === null);
    },

    tooLong() {
      return this.DPName.trim().length > 64;
    },

    localMethods() {
      return this.methods.map(method => ({ 
        title: this.$t(`dataPoints.${method}`), 
        value: method 
      }))
    }
  },

  watch: {
    autoDetectClusters(auto) {
      this.nbClusters = auto ? 0 : 1;
    },

    selectedModel() {
      this.modelVersions = [];
      this.selectedModelVersion = null;
      if (this.selectedModel === 0) {
        return;
      }
      const model = this.customModels.find((model) => model.id === this.selectedModel);
      if (!model) {
        return;
      }
      this.modelVersions = model.versions || [];
      this.selectedModelVersion = this.modelVersions.length > 0 ? this.modelVersions[0].version : null;
    }
  },

  mounted() {
    this.getCustomModels();
    setTimeout(() => {
      const nameField = this.$refs[`nameField`];
      if (nameField) {
        nameField.focus();
      }
    }, 10);
  },

  methods: {
    handleClusterNumbers() {
      this.autoDetectClusters = this.nbClusters == 0;
    },

    async addNew() {
      if (!this.incomplete) {
        try {
          this.$store.commit('setLoadingScreen', true);
          let newGroup = {
            name: this.DPName.trim(),
            is_generative: this.extractionType === "generative" ? true : false,
            labels: [],
            description: '',
          };
          newGroup = {
            ...newGroup,
            aggregation_method: this.aggregationMethod,
            nb_clusters: this.nbClusters,
            custom_model_id: this.extractionType === "generative" ? null : this.selectedModel,
            custom_model_version: this.extractionType === "generative" ? null : this.selectedModelVersion,
            value_types: [],
            primary_labels: [],
            display_names: [],
            regex: [],
            forbidden_values: []
          };
          const response = await http.post(
            `system_2/extraction_groups/?document_type_id=${this.$route.params.id}`,
            newGroup,
          );
          newGroup.id = response.data;
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_created'));
          this.$store.commit('setSuccessSnackbar', true);
          this.$emit('create', newGroup);
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },
  },

  emits: ['create'],
}
</script>

<style scoped lang="scss">
.create-dp-group {
  .name-error {
    color: rgb(var(--v-theme-error));
  }

  .config-input-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 50px;
    margin-top: 5px;
    margin-left: 10px;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }

  .custom-select {
    width: 300px;
  }
}
</style>
