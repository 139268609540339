<template>
  <div
    :key="selectedId"
    class="item-select d-flex"
  >
    <div
      class="custom-select noselect"
      :class="{
        clickable: clickable && !disabled,
        disabled,
      }"
      :style="{
        maxWidth: `${maxWidth}px`,
        width,
      }"
      @click="toggleSelectOn"
    >
      <div
        v-if="!(filter && selectOn)"
        class="ellipsis"
        :style="{ maxWidth: `${itemNameMaxWidth}px` }"
      >
        <span 
          :style="{
            'font-style': numberOfItems ? 'normal' : 'italic',
            color: selectedId ? 'black' : 'rgb(var(--v-theme-grey-darken4)',
            opacity: selectedId ? 1 : 0.8,
          }"
        >
          {{ selectedItem[nameField] }}
        </span>
        <v-icon
          v-if="clearable && selectedId"
          class="clear-icon clickable fas fa-times"
          size="13"
          @click="$emit('selectedChanged', null)"
        />
      </div>
      <input
        v-else
        ref="filterInput"
        v-model="rawFilter"
        :style="{ maxWidth: `${itemNameMaxWidth}px` }"
        :placeholder="showPlaceholder && $t(placeholder) || ''"
        @keydown.enter="handleEnter"
        @keydown.esc="handleEscape"
      >
      <v-icon
        class="open-icon"
        size="14"
      >
        fas fa-chevron-down
      </v-icon>
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
        tabindex="0"
        :style="{
          maxHeight: `${maxHeight}px`,
          maxWidth: `${maxWidth}px`,
          width,
        }"
        @focusout="selectOn = false"
        @scroll="handleScroll"
      >
        <div
          v-for="(item, i) in filteredItems"
          :ref="`Option${i}`"
          :key="item[valueField]"
          class="option ellipsis"
          :value="item[valueField]"
          @click="handleSelect(item[valueField])"
        >
          {{ item[nameField] }}
        </div>
        <div
          v-if="trimmedFilter && filteredItems.length === 0"
          class="option no-results"
        >
          <span v-if="showPlaceholder">
            {{ $t('docTypes.no_results') }}
          </span>
          <v-tooltip>
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                class="ml-2"
                size="16"
              >
                fas fa-exclamation-circle
              </v-icon>
            </template>
            {{ $t('docTypes.no_results') }}
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'CustomSelect',

  data() {
    return ({
      selectedId: -1,
      selectOn: false,
      rawFilter: '',
    })
  },

  computed: {
    itemNameMaxWidth() {
      return this.maxWidth - 19;
    },

    lastPos() {
      return this.numberOfItems - 1;
    },

    numberOfItems() {
      return this.filteredItems.length;
    },

    selectedItem() {
      if (!this.selectedId) {
        return {[this.nameField]: this.placeholder, status: ''};
      }
      const item = this.items.find(d => d[this.valueField] === this.selectedId);
      return item || { [this.nameField]: this.$t('docTypes.no_results'), status: '' };
    },

    trimmedFilter() {
      return this.rawFilter.toLowerCase().trim();
    },

    filteredItems() {
      if (this.trimmedFilter) {
        return this.items.filter(item => {          
          return item[this.nameField].toString().toLowerCase().includes(this.trimmedFilter.toLowerCase());
        });
      }
      return this.items;
    },
  },

  watch: {
    selected() {
      this.selectedId = this.selected;
    },

    selectedId(id) {
      setTimeout(() => {
        this.selectOn = false;
      }, 10);
      this.$emit('selectedChanged', id);
    },

    trimmedFilter: _.debounce(function() {
      if (!this.serverFilter) {
        return;
      }
      const reset = true;
      this.$emit('getMore', this.trimmedFilter, reset);
    }, 300),
  },

  mounted() {
    this.selectedId = this.selected;
  },

  methods: {
    handleScroll() {
      const TOP_DIFFERENCE = 300;
      const selectPanel = this.$refs.selectPanel;
      const lastOption = this.$refs[`Option${this.lastPos}`];      
      if (selectPanel && lastOption) {
        const panelTop = selectPanel.getBoundingClientRect().top;
        const lastTop = lastOption[0].getBoundingClientRect().top;
        const lastTopNormalized = lastTop - panelTop;
        if (
          lastTopNormalized < TOP_DIFFERENCE &&
          !this.$store.getters.loadingScreen &&
          !this.loading
        ) {
          const reset = false;
          this.$emit('getMore', this.trimmedFilter, reset);
        }
      }
    },

    handleSelect(model_id) {
      this.selectedId = model_id;
      this.rawFilter = '';
    },

    handleEnter(e) {
      if (this.selectOn) {
        e.stopPropagation();
      }
      this.handleSelect(this.filteredItems[0] && this.filteredItems[0][this.valueField] || 0);
      this.selectOn = false;
    },

    handleEscape(e) {
      if (this.selectOn) {
        e.stopPropagation();
      }
      this.selectOn = false;
      this.rawFilter = '';

    },

    toggleSelectOn() {
      if (this.disabled) {
        return;
      }
      this.selectOn = !this.selectOn;
      if (this.selectOn) {
        this.$nextTick(() => {
          const filterInput = this.$refs.filterInput;
          if (filterInput) {
            filterInput.focus();
          }
        });
      } else {
        this.rawFilter = '';
      }
    }
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    selected: {
      type: [Number, String, null],
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: Boolean,
      default: true,
    },

    nameField: {
      type: String,
      default: 'name',
    },

    valueField: {
      type: String,
      default: 'id',
    },

    maxWidth: {
      type: Number,
      default: 300,
    },

    maxHeight: {
      type: Number,
      default: 285,
    },

    width: {
      type: String,
      default: '100%',
    },

    showPlaceholder: {
      type: Boolean,
      default: true,
    },

    clickable: {
      type: Boolean,
      default: false,
    },

    serverFilter: {
      type: Boolean,
      default: true,
    },

    placeholder: {
      type: String,
      default: 'dataPoints.select_model',
    },

    clearable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['getMore', 'selectedChanged'],
}
</script>

<style lang="scss" scoped>
.item-select {
  .custom-select {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #C8C8C8;
    background-color: white;
    padding: 8px;
    padding-right: 19px;
    font-size: 0.9rem;
    position: relative;

    .open-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      color: rgb(var(--v-theme-dark));
      opacity: var(--v-medium-emphasis-opacity);
    }

    .clear-icon {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      color: rgb(var(--v-theme-grey-darken4));
      opacity: 0.7;
    }

    .select-panel {
      max-width: 300px;
      max-height: 285px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 40px;
      left: -1px;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: solid 1px #C8C8C8;

      .option {
        display: flex;
        justify-content: start;
        align-items: center;
        height: 40px;
        padding: 8px;
        background-color: white !important;
        box-shadow: 0 0 10px 100px white inset;
        color: black !important;

        &.no-results {
          justify-content: center;
          font-style: italic;
          color: rgb(var(--v-theme-grey-darken3)) !important;
        }
      }
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .disabled {
      background-color: #F5F5F5;
      color: #C8C8C8;
      cursor: not-allowed;
    }
  }
}
</style>
