<template>
  <v-tooltip
    class="edit-button"
    bottom
  >
    <template #activator="{ props }">
      <div
        class="clickable edit-icon left-gap-lg"
        v-bind="props"
      >
        <v-icon
          v-if="editing"
          class="edit-icon-icon"
          color="primary"
          @click="$emit('stopEdit')"
        >
          fas fa-check
        </v-icon>
        <v-icon
          v-else
          class="edit-icon-icon"
          color="primary"
          size="16"
          @click="$emit('startEdit')"
        >
          fas fa-pen
        </v-icon>
      </div>
    </template>
    {{
      editing
        ? $t("dataPoints.finish_sgroup_value_editing")
        : $t("dataPoints.start_sgroup_value_editing")
    }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'EditButton',

  props: {
    editing: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['startEdit','stopEdit'],
}
</script>

<style lang="scss" scoped>
.edit-icon {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 50%;
  border: rgb(var(--v-theme-primary)) 1px solid;
  text-align: center;

  .edit-icon-icon {
    margin-top: 7px;
  }
}
</style>