<template><div /></template>

<script>
import { AuthenticationUtils } from '@/utils/AuthenticationUtils';

export default {
  name: 'LogoutView',

  mounted() {
    AuthenticationUtils.logout();
  },
};
</script>
