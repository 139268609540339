<template>
  <div class="training-step">
    <NumberBullet
      :num="step"
      :incomplete="currentStep <= step"
    />
    <div class="inline-middle stepper-title">
      {{ $t('models.train') }}
    </div>
    <div class="stepper-content">
      <div v-if="currentStep === step">
        <v-btn
          color="primary"
          @click="$emit('click-launch')"
          rounded
        >
          <v-icon start>
            fas fa-flask
          </v-icon>
          {{ $t('models.launch_training') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NumberBullet from "@/components/common/elements/General/NumberBullet";

export default {
  name: 'TrainingStep',

  components: {
    NumberBullet,
  },

  props: {
    step: {
      type: Number,
      required: true,
    },

    currentStep: {
      type: Number,
      required: true,
    },
  },

  emits: ['click-launch'],
};
</script>

<style lang="scss" scoped>
.training-step {
  .stepper-title {
    height: 20px;
  }

  .stepper-content {
    padding-top: 10px;
    margin-top: 10px;
  }
}
</style>