/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'auth/api/v1/';

export class OrgsAPI {
  static async getOneOrg(id) {
    return http
      .get(`${BASE_PATH}organizations/${id}/`,
      { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getOrgs(
    limit, offset, nameHas = null, sortBy = 'id', sortDesc = true,
  ) {
    return http
      .get(`${BASE_PATH}organizations/`,
      {
        params: {
          limit,
          offset,
          name_has: nameHas,
          sort_by: sortBy,
          sort_desc: sortDesc,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async getPasswordPolicy(id) {
    return http
      .get(`${BASE_PATH}organizations/${id}/policy/`,
      { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async checkPassword(userId, password) {
    return http
      .post(`${BASE_PATH}organizations/${userId}/check_password/`,
      { password },
      { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async saveNewOrg(newOrg) {
    return http
      .post(`${BASE_PATH}organizations/`, newOrg)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async deleteOrg(id) {
    return http
      .delete(`${BASE_PATH}organizations/${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async editOrg(id, edited) {
    return http
      .put(`${BASE_PATH}organizations/${id}/login-settings/`, edited)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
