<template>
  <div
    class="number-bullet inline-middle right-gap-sm"
    :style="{
      'background-color': incomplete ? 'rgb(var(--v-theme-grey))' : 'rgb(var(--v-theme-primary))',
    }"
  >
    <span v-if="incomplete">
      {{ num }}
    </span>
    <v-icon
      v-else
      size="13"
    >
      fas fa-check
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'NumberBullet',

  props: {
    num: {
      type: Number,
      required: true,
    },

    incomplete: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.number-bullet {
  text-align: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  font-size: 0.8rem;
  line-height: 25px;
}
</style>
