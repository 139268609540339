<template>
  <div class="search-results-llm">
    <div v-if="searchResults.chunks.length > 0">
      <SearchSources
        :favorites="favorites"
        @remove-from-favorites="id => $emit('removeFromFavorites', id)"
      />
      <SearchAnswer />
    </div>
    <div v-else>
      <h4 class="bottom-gap top-gap-sm">
        <v-icon style="margin-right: 5px">
          fas fa-comment-alt
        </v-icon>
        {{ $t('answer') }}
      </h4>
      {{ $t('search.home.no_results') }}
    </div>
  </div>
</template>

<script>
import SearchAnswer from '@/components/search/elements/Home/SearchAnswer';
import SearchSources from '@/components/search/elements/Home/SearchSources';

export default {
  name: 'SearchResultsLLM',

  components: {
    SearchAnswer,
    SearchSources,
  },

  computed: {
    searchResults() {
      return this.$store.getters.searchResults;
    },
  },

  props: {
    favorites: {
      type: Array,
      required: true,
    },
  },

  emits: [ 'removeFromFavorites' ],
}
</script>
