export default {
  data() {
    return {
      addingLanguage: false,
      languageMap: { 'en': this.$t('settings.english'), 'fr': this.$t('settings.french') },
      readingOrderOpts: [
        {
          name: this.$t('docTypes.custom_reading_order.default'),
          default_name: this.$t('docTypes.custom_reading_order.default'),
          value: null,
        },
        {
          name: this.$t('docTypes.custom_reading_order.roma2'),
          default_name: this.$t('docTypes.custom_reading_order.roma2'),
          value: 'roma2',
        },
        {
          name: this.$t('docTypes.custom_reading_order.roma3'),
          default_name: this.$t('docTypes.custom_reading_order.roma3'),
          value: 'roma3',
        },
      ],
    };
  },

  computed: {
    availableLabels() {
      return this.labelMap.labels.map(
        (label, i) => ({
          name: this.labelMap.displayNames[i] || label,
          value: label,
        })
      ).filter(
        (item) => !this.takenLabels.includes(item.value)
      );
    },

    defaultLanguage() {
      return this.allowedLanguages[0];
    },
  }
};
