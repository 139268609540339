<template>
  <div
    class="search-result-image-viewer"
    :style="{
      width: viewerWidth,
      left: viewerLeft,
    }"
  >
    <img
      v-if="image"
      width="100%"
      style="border: 1px lightgrey solid"
      :src="image"
    >
  </div>
</template>

<script>
export default {
  name: 'SearchResultImageViewer',

  computed: {
    image() {
      return this.$store.getters.searchHoveredImage;
    },

    menuOpen() {
      return this.$store.getters.menuOpen;
    },

    menuSize() {
      return this.menuOpen ? 210 : 60;
    },

    filterSize() {
      return this.$store.getters.searchShowFilters ? 420 : 90;
    },

    viewerWidth() {
      return `calc(60vw - ${this.menuSize + this.filterSize + 30}px)`
    },

    viewerLeft() {
      return `calc(40vw + ${this.menuSize + this.filterSize}px)`
    }
  },
}
</script>

<style lang="scss" scoped>
.search-result-image-viewer {
  max-width: 1000px;
  position: fixed;
  top: 30px;
}
</style>