<template>
  <div class="d-flex justify-space-between doc-stepper">
    <div v-if="numberOfFiles > 0">
      <v-icon
        class="right-gap-sm"
        color="primary"
      >
        fas fa-file-alt
      </v-icon>
      <div class="inline-middle font-weight-bold">
        {{ ordinalSelected }} / {{ numberOfFiles }}
      </div>
      <v-card-actions class="inline-middle ml-1 doc-selector">
        <v-btn
          :disabled="ordinalSelected === 1"
          @click="$emit('step', -1)"
          text
        >
          <v-icon
            size="17"
            color="primary"
          >
            fas fa-chevron-circle-left
          </v-icon>
        </v-btn>
        <v-btn
          :disabled="ordinalSelected >= numberOfFiles"
          @click="$emit('step', 1)"
          text
        >
          <v-icon
            size="17"
            color="primary"
          >
            fas fa-chevron-circle-right
          </v-icon>
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocStepper',

  props: {
    numberOfFiles: {
      type: Number,
      required: true,
    },

    ordinalSelected: {
      type: Number,
      required: true,
    },
  },

  emits: ['step'],
};
</script>

<style lang="scss" scoped>
  .doc-stepper {
    margin-top: 20px;
    font-size: 0.9rem;

    .doc-selector {
      position: relative;
      top: 5px;
    }
  }
</style>