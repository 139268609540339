<template>
  <div class="search-filters right-gap-lg">
    <v-card
      v-if="loggedInUser.role === 'sysadmin'"
      class="filter-card elevation-6 bottom-gap"
    >
      <ChevronButton
        v-model="orgsOpen"
        class="filter-chevron"
      />
      <h4>{{ $t('search.filter_by_org') }}</h4>
      <div
        v-if="orgsOpen"
        class="top-gap"
      >
        <OrgSelect
          :selected="$store.getters.searchOrg"
          @selected-changed="(id) => { $store.commit('setSearchOrg', id) }"
          short
        />
      </div>
    </v-card>

    <v-card class="filter-card elevation-6 bottom-gap">
      <ChevronButton
        v-model="searchPage.foldersOpen"
        class="filter-chevron"
      />
      <h4>{{ $t('search.filter_by_folders') }}</h4>
      <div
        v-if="searchPage.foldersOpen"
        class="top-gap"
        style="overflow-x: auto;"
      >
        <div
          v-if="!folders.length"
          style="font-style: italic"
        >
          {{ $t('search.home.no_folders_message') }}
        </div>
        <div
          v-else
          style="width: fit-content"
        >
          <FolderNode
            v-for="folder in folders"
            :key="folder.id"
            class="bottom-gap-sm"
            :folder="folder"
          />
        </div>
      </div>
    </v-card>

    <v-card
      v-if="metaOptionKeys.length > 0"
      class="filter-card elevation-6 bottom-gap"
    >
      <ChevronButton
        v-model="searchPage.metaOpen"
        class="filter-chevron"
      />
      <h4>{{ $t('search.filter_by_meta') }}</h4>
      <div
        v-if="searchPage.metaOpen"
        class="top-gap"
        style="padding-bottom: 20px;"
      >
        <div
          v-for="key in metaOptionKeys"
          :key="key"
          style="position: relative"
          class="top-gap"
        >
          <v-icon
            v-if="searchDisplayFilters[key] !== null"
            class="clickable clear-icon"
            size="13"
            @click="searchDisplayFilters[key] = null"
          >
            fas fa-times
          </v-icon>
          <div
            class="label text-field-label ellipsis"
            style="max-width: 240px"
          >
            {{ key }}
          </div>
          <CustomSelect
            width="240px"
            value-field="value"
            :placeholder="$t('search.select_filter')"
            :items="metaOptions[key].map(option => ({ name: option, value: option }))"
            :selected="searchDisplayFilters[key]"
            @selected-changed="(value) => searchDisplayFilters[key] = value"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import CustomSelect from "@/components/common/elements/Forms/CustomSelect";
import OrgSelect from "@/components/common/elements/Organizations/OrgSelect";
import ChevronButton from "@/components/common/elements/General/ChevronButton";
import FolderNode from "@/components/search/elements/Home/FolderNode";

export default {
  name: 'SearchFilters',

  components: {
    OrgSelect,
    ChevronButton,
    FolderNode,
    CustomSelect,
  },

  data() {
    return ({
      orgsOpen: true,
    });
  },

  computed: {
    metaOptions() {
      return this.$store.getters.searchMetaOptions;
    },

    searchDisplayFilters() {
      return this.$store.getters.searchDisplayFilters;
    },

    metaOptionKeys() {
      return Object.keys(this.metaOptions);
    },

    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },

    searchPage() {
      return this.$store.getters.searchPage;
    },

    folders() {
      return this.$store.getters.searchFolders;
    },

    searchFolder() {
      return this.$store.getters.searchFolder;
    },

    startedSearch() {
      return this.$store.getters.startedSearch;
    },
  },

  watch: {
    searchFolder() {
      if (this.startedSearch) {
        this.$emit('change')
      }
    },

    searchDisplayFilters: {
      handler() {
        this.handleFilter();
      },
      deep: true,
    },
  },

  methods: {
    handleFilter() {      
      const filters = {};
      if (this.startedSearch) {
        Object.keys(this.searchDisplayFilters).forEach(filter => {
          if (this.searchDisplayFilters[filter] !== null) {
            filters[filter] = this.searchDisplayFilters[filter];
          }
        });
        this.$store.commit('setSearchFilters', filters)
        this.$emit('change');
      }
    }
  },

  emits: ['change'],
}
</script>

<style lang="scss" scoped>
 .search-filters {
    width: 300px;

    .filter-card {
      width: 300px;
      overflow: unset !important;
    }

    .filter-chevron {
      position: absolute;
      right: 28px;
      top: 28px;
    }

    .text-field-label {
      margin-bottom: 3px;
    }

    .clear-icon {
      position: absolute;
      right: -15px;
      top: 37px;
    }
  }
</style>
