<template>
  <div class="review-values">
    <div
      v-for="value in values"
      :key="value.reviewKey"
    >
      <v-tooltip
        color="#423F4F"
        location="bottom"
      >
        <template #activator="{ props }">
          <div
            v-if="value.reviewLocation"
            v-bind="props"
            class="highlight-frame"
            :class="{
              'pre-invalidated-dp-highlight': value.data_point_id
                && value.reviewStatus === 'pre-invalidated',
              'prevalidated-dp-highlight': value.data_point_id
                && value.reviewStatus === 'prevalidated',
              'pending-dp-highlight': value.data_point_id
                && value.reviewStatus === 'pending',
              'valid-dp-highlight': value.data_point_id
                && value.reviewStatus === 'reviewed',
              'pre-invalidated-group-highlight': !value.data_point_id
                && value.reviewStatus === 'pre-invalidated',
              'prevalidated-group-highlight': !value.data_point_id
                && value.reviewStatus === 'prevalidated',
              'pending-group-highlight': !value.data_point_id
                && value.reviewStatus === 'pending',
              'valid-group-highlight': !value.data_point_id
                && value.reviewStatus === 'reviewed',
            }"
            :style="{
              left: `${getHighlightX(value)}vh`,
              top: `${getHighlightY(value)}vh`,
              width: `${getWidth(value)}vh`,
              height: `${getHeight(value)}vh`,
            }"
            @mouseenter="$emit('highlight', value)"
            @mouseleave="$emit('highlight', null)"
            @click="$emit('verify', value)"
          />
        </template>
        <div
          class="d-flex flex-column align-center"
          style="color: white"
        >
          <div
            v-if="typeof value.reviewValue === 'string'"
            class="ellipsis"
          >
            <div
              v-for="line in value.reviewValue.split('\n')"
              :key="line"
            >
              {{ line }}
            </div>
          </div>
          <div v-else>
            {{ value.reviewValue }}
          </div>
          <div>
            ({{ value.reviewName }})
          </div>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewValues",

  methods: {
    getHighlightX(value) {
      return value.reviewLocation.x_min * this.pageWidth;
    },

    getHighlightY(value) {
      return value.reviewLocation.y_min * this.pageHeight;
    },

    getWidth(value) {
      return (value.reviewLocation.x_max - value.reviewLocation.x_min) * this.pageWidth;
    },

    getHeight(value) {
      return (value.reviewLocation.y_max - value.reviewLocation.y_min) * this.pageHeight;
    },
  },

  props: {
    values: {
      type: Array,
      default: () => [],
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
  },

  emits: ['highlight', 'verify']
}
</script>

<style lang="scss" scoped>
.highlight-frame {
  position: absolute;
  cursor: pointer;
  border-radius: 2px;
}

.pending-dp-highlight {
  outline: 3px solid $pending-base;
}

.pending-dp-highlight:hover {
  background-color: $pending-transparent;
}

.prevalidated-dp-highlight {
  outline: 3px solid $prevalidated-correct;
}

.prevalidated-dp-highlight:hover {
  background-color: $prevalidated-correct-transparent;
}

.pre-invalidated-dp-highlight {
  outline: 3px solid $prevalidated-incorrect;
}

.pre-invalidated-dp-highlight:hover {
  background-color: $prevalidated-incorrect-transparent;
}

.valid-dp-highlight {
  outline: 3px solid $valid-base;
}

.valid-dp-highlight:hover {
  background-color: $valid-transparent;
}

.pending-group-highlight {
  border-bottom: 3px solid $pending-base;
}

.pending-group-highlight:hover {
  background-color: $pending-transparent;
}

.prevalidated-group-highlight {
  border-bottom: 3px solid $prevalidated-correct;
}

.prevalidated-group-highlight:hover {
  background-color: $prevalidated-correct-transparent;
}

.pre-invalidated-group-highlight {
  border-bottom: 3px solid $prevalidated-incorrect;
}

.pre-invalidated-group-highlight:hover {
  background-color: $prevalidated-incorrect-transparent;
}

.valid-group-highlight {
  border-bottom: 3px solid $valid-base;
}

.valid-group-highlight:hover {
  background-color: $valid-transparent;
}
</style>
