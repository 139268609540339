<template>
  <div class="review-value-highlight">
    <div
      v-if="value && value.subgroupLocation"
      class="highlight-frame group-highlight"
      :style="{
        left: `${getHighlightX(value.subgroupLocation)}vh`,
        top: `${getHighlightY(value.subgroupLocation)}vh`,
        width: `${getWidth(value.subgroupLocation)}vh`,
        height: `${getHeight(value.subgroupLocation)}vh`,
      }"
    />
    <div
      v-if="value && value.location"
      class="highlight-frame"
      :class="{
        'pre-invalidated-dp-highlight': value.data_point_id
          && value.reviewStatus === 'pre-invalidated',
        'prevalidated-dp-highlight': value.data_point_id
          && value.reviewStatus === 'prevalidated',
        'pending-dp-highlight': value.data_point_id
          && value.reviewStatus === 'pending',
        'valid-dp-highlight': value.data_point_id
          && value.reviewStatus === 'reviewed',
        'pre-invalidated-group-highlight': !value.data_point_id
          && value.reviewStatus === 'pre-invalidated',
        'prevalidated-group-highlight': !value.data_point_id
          && value.reviewStatus === 'prevalidated',
        'pending-group-highlight': !value.data_point_id
          && value.reviewStatus === 'pending',
        'valid-group-highlight': !value.data_point_id
          && value.reviewStatus === 'reviewed',
      }"
      :style="{
        left: `${getHighlightX(value.location)}vh`,
        top: `${getHighlightY(value.location)}vh`,
        width: `${getWidth(value.location)}vh`,
        height: `${getHeight(value.location)}vh`,
      }"
    />
  </div>
</template>

<script>
export default {
  name: "ReviewValueHighlight",

  methods: {
    getHighlightX(location) {
      return location.x_min * this.pageWidth;
    },

    getHighlightY(location) {
      return location.y_min * this.pageHeight;
    },

    getWidth(location) {
      return (location.x_max - location.x_min) * this.pageWidth;
    },

    getHeight(location) {
      return (location.y_max - location.y_min) * this.pageHeight;
    },
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.highlight-frame {
  position: absolute;
  cursor: pointer;
  border-radius: 2px;
}

.pending-dp-highlight {
  outline: 3px solid $pending-base;
  background-color: $pending-transparent;
}

.prevalidated-dp-highlight {
  outline: 3px solid $prevalidated-correct;
  background-color: $prevalidated-correct-transparent;
}

.pre-invalidated-dp-highlight {
  outline: 3px solid $prevalidated-incorrect;
  background-color: $prevalidated-incorrect-transparent;
}

.valid-dp-highlight {
  outline: 3px solid $valid-base;
  background-color: $valid-transparent;
}

.pending-group-highlight {
  border-bottom: 3px solid $pending-base;
  background-color: $pending-transparent;
}

.prevalidated-group-highlight {
  border-bottom: 3px solid $prevalidated-correct;
  background-color: $prevalidated-correct-transparent;
}

.pre-invalidated-group-highlight {
  border-bottom: 3px solid $prevalidated-incorrect;
  background-color: $prevalidated-incorrect-transparent;
}

.valid-group-highlight {
  border-bottom: 3px solid $valid-base;
  background-color: $valid-transparent;
}

.group-highlight {
  border: 2px solid $pending-base;
}
</style>
