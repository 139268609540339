<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ props }">
        <v-icon
          color="black"
          size="16"
          v-bind="props"
          @click="showModal = true"
        >
          fa fa-keyboard
        </v-icon>
      </template>
      {{ $t('docViewer.keyboard_shortcuts') }}
    </v-tooltip>
    <v-dialog
      v-model="showModal"
      max-width="500"
    >
      <v-card class="dialog-card">
        <h2 class="dialog-title mb-3">
          {{ $t('docViewer.shortcut_title') }}
        </h2>
        <v-row
          v-for="(shortcut, i) in shortcuts"
          :key="shortcut.keys"
          class="pb-1  mx-0"
          :class="{
            'pt-1': i > 0,
            'row-border': i < shortcuts.length - 1,
          }"
        >
          <v-col
            cols="4"
            class="d-flex align-center px-0"
          >
            <code class="right-gap">
              {{ shortcut.keys }}
            </code>
          </v-col>
          <v-col class="text-body-2 d-flex align-center px-0">
            {{ $t(`models.${shortcut.name}`) }}
          </v-col>
        </v-row>
        <div class="w-100 d-flex justify-end mt-2">
          <v-btn
            color="primary"
            @click="showModal = false"
            rounded
          >
            {{ $t('close') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ShortcutDialog',

  data() {
    return {
      showModal: false,
    };
  },

  props: {
    shortcuts: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
  .toolbox-button {
    display: inline-block;
    vertical-align: top;
    color: white !important;
    margin-left: 10px;
    position: relative;
    top: 3px;
    padding-left: 8px !important;
    padding-right: 8px !important;
    box-shadow: none;
  }

  .row-border {
    border-bottom: 1px solid #CCC;
  }
</style>