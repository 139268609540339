<template>
  <div class="doc-search">
    <v-card
      class="search-bar pa-0"
      width="100%"
      height="100%"
      style="margin-top: -16px; background: none;"
      :class="{ 'search-bar--active': searchOn }"
    >
      <v-text-field
        ref="searchInput"
        v-model="rawSearch"
        variant="outlined"
        color="primary"
        density="compact"
        @keyup.esc="$emit('close')"
        @keyup.enter="goToResultPage"
      />
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import { http } from "@/plugins/axios";

export default {
  name: 'DocSearch',

  data() {
    return ({
      rawSearch: '',
      searchText: '',
      searchResults: [],
    });
  },

  computed: {
    searchResultsPages() {
      return this.searchResults.map(result => result.page_nb);
    },
    searchUrl() {
      const dataset = this.resource === 'entry' ? 'dataset/' : '';
      return `${dataset}${this.resource}/${this.docId}/search/`;
    },
  },

  watch: {
    rawSearch(newValue, old) {
      if (newValue === '' && old !== newValue) {
        this.searchResults = [];
        this.$emit('updatePages');
      }
      this.searchText = newValue;
    },

    searchOn(on) {
      if (on) {
        setTimeout(() => this.$refs.searchInput.focus(), 310)
      } else {
        this.rawSearch = '';
        this.searchResults = [];
      }
    },

    searchText: _.debounce(
      async function() {
        await this.getSearchResults()
      },
    300),
  },

  methods: {
    async getSearchResults() {
      if (!this.loadingThumbs) {

        try {
          this.$emit('loadingChanged', true);
          if (this.docId > 0 && this.searchText.length > 0){
            const { data } = await http.post(this.searchUrl, {
              text: {
                '$contains_i': this.searchText,
              }
            });
            this.searchResults = data;
            this.$emit('updatePages');
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.$emit('loadingChanged', false);
        }
      }
    },

    goToResultPage() {
      if (this.searchResults.length > 0) {
        this.$emit('changePage', this.searchResults[0]);
        setTimeout(() => {
          this.$emit('close');
          this.searchResults = [];
          this.$emit('updatePages')
        }, 150);
      }
    },
  },

  props: {
    searchOn: {
      type: Boolean,
      required: true,
    },

    docId: {
      type: Number,
      required: true,
    },

    resource: {
      type: String,
      default: 'files',
    },

    loadingThumbs: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['updatePages', 'loadingChanged', 'changePage', 'close'],
}
</script>

<style lang="scss" scoped>
.doc-search {
  position: absolute;
  left: calc(91% - 360px);
  top: 0;
  z-index: 1000;
  width: 360px;
  overflow: hidden;
  border-radius: 0.3125rem;
  pointer-events: none;

  .search-bar {
    transition: all 0.4s;
    margin-left: 100%;

    &--active {
      pointer-events: all;
      margin-left: 0%;
    }
  }
}
</style>
