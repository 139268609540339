<template>
  <ClassificationView start-view="training" />
</template>

<script>
import ClassificationView from '@/components/extract/views/Studio/ClassificationView';

export default {
  name: 'ClassificationTrainingView',

  components: {
    ClassificationView,
  },
}
</script>
