<template>
  <div class="classification-view page-padding py-7">
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
      wide
    />
    <ClassifiersView
      v-if="activeTab === 'models'"
      @create-click="activeTab = 'training'"
    />
    <ClassifiersTraining v-if="activeTab === 'training'" />
  </div>
</template>

<script>
import ClassifiersTraining from '@/components/extract/views/Studio/ClassifiersTraining';
import ClassifiersView from '@/components/extract/views/Studio/ClassifiersView';
import PageTabs from '@/components/common/elements/General/PageTabs';

export default {
  name: 'ClassificationView',

  components: {
    ClassifiersView,
    ClassifiersTraining,
    PageTabs,
  },

  data() {
    return ({
      activeTab: 'models',
    });
  },

  computed: {
    tabItems() {
      return [
        {
          title: this.$t('menu.classifiers.title'),
          bigNumber: this.$store.getters.totalClassifiers,
          name: 'models',
        },
        {
          title: this.$t('models.training'),
          bigNumber: this.$store.getters.totalTrainingClassifiers,
          name: 'training',
        }
      ];
    },
  },

  watch: {
    activeTab(tab) {
      if (tab === 'training') {
        this.$router.push({name: 'ClassificationTrainingView'});
      } else if (tab === 'models') {
        this.$router.push({name: 'Classification'});
      }
    }
  },

  created() {
    this.activeTab = this.startView;
  },

  props: {
    startView: {
      type: String,
      default: 'models'
    },
  }
}
</script>
