import { http } from '@/plugins/axios';
import { FileAPI } from '@/API/extract/FileAPI';
import { DataPointAPI } from '@/API/extract/DataPointAPI';

export default {
  data() {
    return ({
      savedChanges: false,
      docLoading: false,
      selectedFile: -1,
      viewFile: {id: -1, pages: []},
      edited: {id: -1},
      activeDP: {},
      infoCategories: [
        'hasText',
        'hasEntities',
        'hasTables',
        'hasLayout',
        'hasForms',
      ],
      baseKey: 10,
    })
  },

  computed: {
    backTarget() {
      return {
        name: 'DocType',
        params: {
          id: this.$route.params.id,
          tab: 'extractors',
        },
      };
    },
  },

  async mounted() {
    await this.initialGetFile();
    this.initialFileSelect();
  },

  methods: {
    async getDataPoint(id) {
      try {
        this.edited = await DataPointAPI.getDataPoint(id);
      } catch (error) {
        console.log(error);
      }
    },

    async getLabelGroup(id) {
      try {
        const response = await http.get(`system_2/extraction_groups/${id}/`);
        this.edited = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async initialGetFile() {
      if (
        this.$store.getters.loggedInUser.role === 'orgadmin' &&
        (
          this.totalFiles < this.totalFilesDisplay ||
          !this.files.length ||
          this.files.some(f => f.status === 'uploaded')
        )
      ) {
        await this.getFiles();
      }
    },

    deHighlightDP() {
      setTimeout(() => {
        this.activeDP = {highlighted: false};
      }, 50);
    },

    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async getInfo(id) {
      try {
        const response = await http.get(`files/${id}/info/`);
        return response.data
      } catch (error) {
        console.log(error);
        return []
      }
    },

    async getFiles(offset = 0, limit = 20) {
      if (offset && offset >= this.totalFiles) {
        return;
      }
      try {
        this.$store.commit("setLoadingScreen", true);
        const response = await FileAPI.get(
          this.$route.params.id, offset, limit,
        );
        if (offset > 0) {
          this.files = [...this.files, ...response.data];
        } else {
          this.files = response.data; 
        }
        this.totalFiles = parseInt(response.headers['x-total-count'], 10);
        this.totalFilesDisplay = this.totalFiles;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    initialFileSelect() {
      if (this.files.length > 0) {
        this.selectFile(this.files[0]);
      }
    },

    async selectFileById(id) {
      const toSelect = this.files.find(file => file.id === id);
      if (toSelect) {
        this.selectFile(toSelect);
      }
    },

    async saveInfo() {
      if (
        this.infoCategories.every(category => !this.viewFile[category])
        && this.viewFile.pages.length > 0
      ) {
        this.viewFile.pages = this.viewFile.pages.sort((a, b) => a.page_nb - b.page_nb);
        const info = await this.getInfo(this.viewFile.id);
        this.viewFile.hasText = false;
        this.viewFile.hasTables = false;
        this.viewFile.hasEntities = false;
        this.viewFile.hasLayout = false;
        this.viewFile.hasForms = false;
        const length = info.length;
        for (let i = 0; i < length; i++) {
          this.saveSystem1Values(i, info[i]);
        }
        this.baseKey += 10;
      }
    },

    saveSystem1Values(i, page) {
      this.viewFile.pages[i].entities = page.entities;
      if (page.entities && Object.keys(page.entities).length > 0) {
        this.viewFile.hasEntities = true;
      }
      this.viewFile.pages[i].layout = page.layouts;
      if (page.layouts && page.layouts.length > 0) {
        this.viewFile.hasLayout = true;
      }
      this.viewFile.pages[i].forms = page.forms;
      if (page.forms && page.forms.length > 0) {
        this.viewFile.hasForms = true;
      }
      this.viewFile.pages[i].tables = page.tables;
      if (page.tables && page.tables.length > 0) {
        this.viewFile.hasTables = true;
      }
      this.viewFile.pages[i].text = page.text;
      if (page.text && page.text.length > 0) {
        this.viewFile.hasText = true;
      }
    },
  }
};
