<template>
  <div class="users-view page-padding py-7">
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
    />
    <UsersTable
      v-if="activeTab === 'users'"
      @total-change="total => totalUsers = total"
    />
    <OrgsTable
      v-if="activeTab === 'organizations'"
      @total-change="total => totalOrgs = total"
    />
  </div>
</template>

<script>
import PageTabs from '@/components/common/elements/General/PageTabs';
import UsersTable from '@/components/common/elements/Users/UsersTable';
import OrgsTable from '@/components/common/elements/Organizations/OrgsTable';

export default {
  name: 'UsersView',

  components: {
    PageTabs,
    UsersTable,
    OrgsTable,
  },

  data() {
    return ({
      activeTab: 'users',
      totalUsers: 0,
      totalGroups: 0,
    });
  },

  computed: {
    product() {
      return this.$store.getters.product;
    },

    totalOrgs: {
      get() {
        return this.$store.getters.totalOrgs;
      },
      set(count) {
        this.$store.commit('setTotalOrgs', count);
      }
    },

    user() {
      const user = this.$store.getters.loggedInUser;
      return user ? user : null;
    },

    userRole() {
      return this.user ? this.user.role : '';
    },

    tabItems() {
      const tabs = [
        {
          title: this.$t('users.title'),
          bigNumber: this.totalUsers,
          name: 'users',
        },
      ];
      if (this.isSysadmin) {
        tabs.push(
          {
            title: this.$t('organizations.title'),
            bigNumber: this.totalOrgs,
            name: 'organizations',
          }
        )
      }
      return tabs;
    },

    isSysadmin() {
      return this.user && this.user.role == 'sysadmin';
    }
  },
}
</script>

<style lang="scss" scoped>
  .users-view {
    height: fit-content;
  }
</style>
