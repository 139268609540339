<template>
  <div
    v-if="section.authorize.includes(user.role)"
    class="d-flex align-items-center"
  >
    <div
      v-if="!expanded"
      class="menu__content menu-icon inline-top"
    >
      <v-tooltip :text="$t(section.translatedName)">
        <template #activator="{ props }">
          <span
            class="mx-0"
            v-bind="props"
          >
            <v-icon
              style="width: fit-content"
              class="left-gap clickable"
              :color="highlighted ? 'primary-lighten2' : 'white'"
              @click="handleSectionOpen"
            >
              {{ section.icon }}
            </v-icon>
          </span>
        </template>
      </v-tooltip>
    </div>
    <div
      v-else
      class="menu__content menu-icon inline-top"
      style="margin-left: -20px"
    >
      <v-icon
        style="width: fit-content"
        class="left-gap clickable"
        :color="highlighted ? 'primary-lighten2' : 'white'"
        @click="handleSectionOpen"
        @mouseover="sectionHovered = true"
        @mouseleave="sectionHovered = false"
      >
        {{ section.icon }}
      </v-icon>
    </div>
    <Transition>
      <div
        v-if="expanded"
        class="bottom-gap inline-top"
      >
        <div
          class="clickable menu menu__content"
          @click="handleSectionOpen"
          @mouseover="sectionHovered = true"
          @mouseleave="sectionHovered = false"
        >
          <v-list-item-title
            class="inline-middle"
            :style="{
              color: highlighted ? 'rgb(var(--v-theme-primary-lighten2))' : 'white',
              'font-weight': $route.path.startsWith('/suite') ? 'bold' : 'normal',
            }"
            :class="{ 'text-uppercase': $route.path.startsWith('/suite') }"
          >
            {{ $t(section.translatedName) }}
          </v-list-item-title>
          <span
            v-if="section.items && section.items.length > 0"
            style="margin-top: -3px"
          >
            <v-icon
              v-if="!section.open"
              class="left-gap-sm"
              size="11"
              :color="$route.path.startsWith(section.route) ? 'primary-lighten2' : 'white'"
            >
              fas fa-chevron-right
            </v-icon>
            <v-icon
              v-else
              class="left-gap-sm"
              size="11"
              :color="$route.path.startsWith(section.route) ? 'primary-lighten2' : 'white'"
            >
              fas fa-chevron-down
            </v-icon>
          </span>
        </div>
        <div
          v-if="expanded && section.open"
          class="top-gap-sm"
        >
          <div
            v-for="(item, j) in section.items"
            :key="j"
          >
            <div v-if="showMenuItem(item)">
              <v-list-item
                v-if="!item.items"
                class="clickable bottom-gap-sm menu"
                :to="{ path: item.route }"
              >
                <v-list-item-title
                  class="inline-middle font-weight-bold"
                  :style="{ color: $route.path.startsWith(item.route) ? 'rgb(var(--v-theme-primary-lighten2))' : 'white' }"
                >
                  {{ $t(item.translatedName) }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                class="clickable bottom-gap-sm menu"
                @click="item.open = !item.open"
              >
                <v-list-item-title
                  class="inline-middle font-weight-bold"
                  :style="{ color: $route.path.startsWith(item.route) ? 'rgb(var(--v-theme-primary-lighten2))' : 'white' }"
                >
                  {{ $t(item.translatedName) }}
                </v-list-item-title>
                <span
                  v-if="item.items && item.items.length > 0"
                  class="inline-middle"
                  style="margin-top: 1px"
                >
                  <v-icon
                    v-if="!item.open"
                    class="left-gap-sm"
                    size="10"
                    :color="$route.path.startsWith(item.route) ? 'primary-lighten2' : 'white'"
                  >
                    fas fa-chevron-right
                  </v-icon>
                  <v-icon
                    v-else
                    class="left-gap-sm"
                    size="10"
                    :color="$route.path.startsWith(item.route) ? 'primary-lighten2' : 'white'"
                  >
                    fas fa-chevron-down
                  </v-icon>
                </span>
              </v-list-item>
            </div>
            <div
              v-if="item.open && user && item.authorize.includes(user.role)"
              class="left-gap"
            >
              <v-list-item
                v-for="(subItem, k) in item.items.filter(s => showMenuItem(s))"
                :key="k"
                class="menu mt-0 bottom-gap-sm"
                :class="{ clickable: !subItem.disabled }"
                :style="{ opacity: subItem.disabled ? 0.6 : 1 }"
              >
                <v-list-item-title
                  v-if="!subItem.disabled"
                  :style="{ color: $route.path.startsWith(subItem.route) ? 'rgb(var(--v-theme-primary-lighten2))' : 'white' }"
                  @click="$router.push(subItem.route)"
                >
                  {{ $t(subItem.translatedName) }}
                </v-list-item-title>
                <div
                  v-else
                  class="menu-item"
                >
                  {{ $t(subItem.translatedName) }}
                </div>
              </v-list-item>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'NavigationSection',

  data() {
    return {
      sectionHovered: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    highlighted() {
      return this.$route.path.startsWith(this.section.route) || this.sectionHovered;
    },
  },

  methods: {
    handleSectionOpen() {
      const section = this.section;
      if (!section.items) {
        this.$router.push(section.route);
        return;
      }
      if (this.expanded) {
        section.open = !section.open;
      } else {
        this.$emit('openSection');
      }
    },

    showMenuItem(item) {
      if (!this.user) {
        return false;
      }
      if (item.authorize && !item.authorize.includes(this.user.role)) {
        return false;
      }
      if (item.needsService) {
        if (!this.$store.getters.serviceStatus[item.needsService].running) {
          return false;
        }
        if (!this.user.products.includes(item.needsService)) {
          return false;
        }
      }
      return true;
    },
  },

  props: {
    section: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['openSection'],
}
</script>

<style lang="scss" scoped>

.v-list-item-title, .menu-item {
  font-family: 'Telegraf';
  font-size: rem(14);
  font-weight: 500;
  line-height: rem(16);
  color: white;
  margin-left: 17px;
  margin-top: 3px;
}

.menu__content {
  display: flex;
  align-items: center;
}

.menu-icon {
  margin-bottom: 21px;
  margin-top: -4px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>