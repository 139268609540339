<template>
  <div class="view-selector inline-middle">
    <v-icon
      v-for="(option, i) in options"
      :key="i"
      class="selector-button inline-middle right-gap clickable"
      :color="modelValue === option.name ? 'primary' : 'default'"
      @click="$emit('update:modelValue', option.name)"
    >
      {{ option.icon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'ViewSelector',

  data() {
    return ({
      options: [
        {name: 'list', icon: 'fas fa-list'},
        {name: 'grid', icon: 'fas fa-th'},
      ]
    });
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  emits: ['update:modelValue'],
}
</script>
