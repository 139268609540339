<template>
  <div class="search-files-message-container">
    <v-icon
      color="primary"
      size="30"
    >
      fas fa-spinner fa-pulse
    </v-icon>
  </div>
</template>
<style lang="scss" scoped>
.search-files-message-container {
  margin-top: 100px;
  text-align: center;
}
</style>
