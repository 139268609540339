import { store } from '@/plugins/store';
import { AuthenticationUtils } from '@/utils/AuthenticationUtils';

class DownloadUtilsClass {

  static download_zip(backendLink) {
    const token = AuthenticationUtils.getToken();

    let backend = store.getters.config.backends['extract'];

    if (backend == '/') {
      backend = window.location.href;
    }

    const url = new URL(backend);

    url.pathname = backendLink;
    url.search = new URLSearchParams({token});

    return url;
  }

  static download(backendLink) {
    window.location = this.download_zip(backendLink);
  }

}

export const DownloadUtils = DownloadUtilsClass;
