<template>
  <v-tooltip
    :disabled="disabled || !showTooltip || showText"
    bottom
  >
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        class="table-action"
        color="primary"
        style="box-shadow: none"
        :disabled="disabled"
        :variant="buttonVariant"
        @click="$emit('click')"
        rounded
      >
        <slot name="prepend-icon">
          <v-icon
            v-if="prependIcon"
            :size="showText && 17 || 20"
            :start="showText"
          >
            {{ prependIcon }}
          </v-icon>
        </slot>
        <span v-if="showText">
          {{ label }}
        </span>

        <v-icon
          v-if="appendIcon"
          size="17"
          end
        >
          {{ appendIcon }}
        </v-icon>
      </v-btn>
    </template>
    {{ label }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'TableAction',

  props: {
    label: {
      type: String,
      required: true,
    },
    buttonVariant: {
      type: String,
      default: 'elevated',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    showText: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
  },

  emits: ['click'],
};
</script>
<style lang="scss" scoped>
.table-action:not(:last-of-type) {
  margin-right: 10px !important;
}
</style>
