export default {
  methods: {
    formatNumber(value) {
      const num = parseInt(value, 10);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    formatDate(dateInfo) {
      const date = new Date(dateInfo);
      date.setTime(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return `${date.toLocaleDateString('en-GB', options)} ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`;
    },

    formatSize(bytes, decimals=1) {
      let prefix;
      for (let unit of ["bytes", "kilo", "mega", "giga"]) {
        prefix = this.$t(`datatable.bytes.${unit}`.toString());
        if (bytes < 1024.0) {
          return `${bytes.toFixed(decimals)} ${this.$tc('datatable.bytes.suffix', bytes < 1024.0 ? 0 : 1, { prefix })}`;
        }
        bytes /= 1024.0;
      }
      return `${bytes.toFixed(1)} ${this.$t('datatables.bytes.tera')}o`;
    },

    getNormalizedValue(value) {
      if (Object.prototype.hasOwnProperty.call(value , 'normalized_value')
        && value.normalized_value
      ) {
        if (value.valid_value) {
          if (value.valid_value !== value.normalized_value) {
            return `(${value.normalized_value})`;
          }
          return '';
        } else if(value.value) {
          if (value.value !== value.normalized_value) {
            return `(${value.normalized_value})`;
          }
          return '';
        }
      }
      return '';
    },

    getValueLabel(groupValue) {
      const labelIndex = this.labelMap.labels.findIndex(label => label === groupValue.label);
      return this.labelMap.displayNames[labelIndex] || groupValue.label;
    },
  },
};
