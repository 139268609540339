<template>
  <v-expansion-panels v-model="expandedPanels">
    <v-expansion-panel
      v-for="objectDpInfo in sortedObjectValues"
      :id="`objectDpValidator-${objectDpInfo.object_dp_id}`" 
      :key="objectDpInfo.object_dp_name"
    >
      <v-expansion-panel-title>
        <div class="object-header">
          {{ objectDpInfo.object_dp_name }}
          <div class="add-object-button">
            <v-tooltip
              color="#423f4f"
              right
            >
              <template #activator="{ props }">
                <div
                  class="clickable add-button"
                  :style="{
                    opacity: addingObject != null ? 0.2 : 1,
                  }"
                  v-bind="props"
                  @click="addObject($event, objectDpInfo.object_dp_id)"
                >
                  <v-icon
                    size="16"
                    class="add-icon"
                  >
                    fas fa-plus
                  </v-icon>
                </div>
              </template>
              <span style="color: #fafafa">
                {{ $t(`dataPoints.add_missing_${objectDpInfo.object_dp_name.toLowerCase()}`) }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-text
        class="object-panel"
      >
        <div 
          v-for="(obj, i) in objectDpInfo.object_values"
          :id="`objectId-${obj.object_id}`"
          :key="i"
          :ref="`${objectDpInfo.object_dp_name}_${i}`"
          class="value-row"
          :class="{'value-row__active': obj == highlightedObject}"
          @mouseenter="handleEnter(obj)"
          @mouseleave="handleLeave(obj)"
        >
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-col
              cols="1"
              class="px-0"
            />
            <v-col
              cols="2"
              class="text-uppercase data-point-name"
            >
              <div>
                Page {{ obj.page_nb }}
              </div>
            </v-col>
            <v-col
              cols="6"
              class="d-flex justify-end pa-0"
            />
            <v-col 
              cols="3"
              class="px-0 d-flex justify-center"
            >
              <v-btn
                v-if="'adding' in obj"
                color="black"
                style="box-shadow: none"
                variant="outlined"
                @click="cancelAddObject(objectDpInfo.object_dp_id)"
                rounded
              >
                {{ $t("cancel") }}
              </v-btn>
              <div 
                v-else
                class="verify-buttons"
              >
                <v-icon
                  color="white"
                  size="17"
                  class="verify-button mr-1"
                  :class="{
                    'verify-button--enabled': obj.status === 'pending',
                    'verify-button--disabled': obj.status === 'valid',
                    'verify-button--incorrect': obj.status === 'invalid',
                  }"
                  @click="handleValidation(obj, 'invalid')"
                >
                  fas fa-times
                </v-icon>
                <v-icon
                  color="white"
                  class="verify-button ml-1"
                  size="17"
                  :class="{
                    'verify-button--enabled': obj.status === 'pending',
                    'verify-button--correct': obj.status === 'valid',
                    'verify-button--disabled': obj.status === 'invalid',
                  }"
                  @click="handleValidation(obj, 'valid')"
                >
                  fas fa-check
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>  

  import { http } from '@/plugins/axios';

  export default {
    name: 'ObjectValidator',
  
    data() {
      return {
        expandedPanels: []
      };
    },

    computed: {
      sortedObjectValues() {
        return this.objectValues.map(outerObj => {
          const sortedObj = { ...outerObj };
          sortedObj.object_values = sortedObj.object_values.sort((a, b) => a.page_nb - b.page_nb);
          return sortedObj;
        });
      }
    },

    watch: {
      highlightedObject() {
        if (
          typeof this.highlightedObject === 'object' && 
          this.highlightedObject !== null && 
          Object.keys(this.highlightedObject).length > 0 &&
          "panelIndex" in this.highlightedObject
        ) {
          this.expandedPanels = [this.highlightedObject.panelIndex];
          this.$nextTick(() => {
            let objectRow = document.getElementById("objectId-"+this.highlightedObject.object_id);
            if (objectRow) {
              objectRow.scrollIntoViewIfNeeded();
            } else {
              console.error("Element not found:", "objectId-"+this.highlightedObject.object_id);
            }
          });
        }
      },
    },

    methods: {
      addObject(event, object_dp_id) {
        event.stopPropagation();
        this.$emit('handleAddObject', object_dp_id);
      },
      cancelAddObject(object_dp_id) {
        this.$emit('cancelAddObject', object_dp_id);
      },

      handleEnter(object) {
        this.$emit('handleObjectEnter', object);
      },
      handleLeave() {
        this.$emit('handleObjectLeave');
      },

      async handleValidation(object, status) {
        try {
          await http.put(
            `system_2/verify/object_value/${object.object_id}`,
            null,
            {
              params: {
                file_id: this.currentFile.id,
                value_status: status,
                noAuth: this.external || false,
                external: this.external || false,
                token: this.external ? this.$route.params.token : null,
              }
            }
          );
          this.$emit('getObjectValues');
        } catch (err) {
          console.log(err);
        }
      },

      closeAllPanels() {
        this.expandedPanels = [];
      }
    },
  
    props: {
      objectValues: {
        type: Array,
        default: () => [],
      },
      highlightedObject: {
        type: Object,
        default: () => {},
      },
      currentFile: {
        type: Object,
        default: () => {},
      }
    },
  
    emits: [
      'cancelAddObject',
      'handleAddObject',
      'handleObjectEnter',
      'handleObjectLeave',
      'getObjectValues'
    ],
  }
</script>



<style lang="scss" scoped>
.object-panel ::v-deep .v-expansion-panel-text__wrapper {
  padding: 0px !important;
}

.value-row {
  box-sizing: border-box;
  padding: 8px;
  border-width: 2px 1px 2px 1px;
  border-style: solid;
  border-color: transparent;
  &:not(.value-row:last-of-type) {
    padding-bottom: 9px;
    border-bottom: 1px solid #aaa;
  }
  &:first-of-type {
    border-top: 2px solid #aaa;
  }
  &__active {
    padding: 8px !important;
    border-width: 2px 1px 2px 1px !important;
    border-style: solid !important;
    border-color: $pending-base !important;
  }
}

.v-row {
  border-width: 0px;
}

.data-point-name {
  padding-left: 0;
  padding-right: 10px;
  font-size: 13px;
  margin-left: -10px;
}

.verify-button {
  cursor: pointer;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;

  &--correct {
    background-color: $valid-base !important;
    cursor: default;
  }

  &--incorrect {
    background-color: $invalid-base !important;
    cursor: default;
  }

  &--enabled {
    background-color: #555 !important;
  }

  &--disabled {
    background-color: #ddd !important;
  }
}


.object-header {
  width: 100%;
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  padding-right: 13px; 
}

.add-object-button {
  position: relative;

  .add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(var(--v-theme-primary)) !important;
    color: rgb(var(--v-theme-primary)) !important;
    border-radius: 50% !important;
    box-shadow: none !important;
    width: 35px !important;
    height: 35px !important;
    line-height: 33px;
    text-align: center !important;
  }
}
</style>