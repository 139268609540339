<template>
  <textarea
    ref="input"
    v-model="inputValue"
    class="group-table-input"
    :style="{ width, height }"
    @keyup.esc="$emit('finish')"
    @input="handleInput"
    @blur="handleBlur"
    @keypress="handleKeyPress"
  />
</template>

<script>
export default {
  name:'GroupTableInput',

  data() {
    return ({
      inputValue: '',
      renderKey: 1,
    })
  },

  mounted() {
    this.inputValue = this.startingValue;
    this.$refs.input.focus();
  },

  methods: {
    handleKeyPress(e) {
      if (e.key === 'Enter') {
        this.$emit('save', this.inputValue);
        this.$emit('finish');
      }
    },

    handleBlur() {
      this.$emit('blur');
    },

    handleInput() {
      this.inputValue = this.inputValue.replace('\n', '');
      this.$emit('save', this.inputValue);
    },
  },

  props: {
    width: {
      type: String,
      required: true,
    },

    height: {
      type: String,
      required: true,
    },

    startingValue: {
      type: String,
      required: true,
    },
  },

  emits: ['save', 'finish', 'blur'],
}
</script>

<style lang="scss" scoped>
.group-table-input {
  border: none !important;
  outline: none !important;
  resize: none !important;
  overflow: hidden !important;
  padding: 0px !important;
  margin-right: -50px !important;
}
</style>