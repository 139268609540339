import { http } from '@/plugins/axios';

const BASE_PATH = 'dataset/label/';

export class LabelAPI {
  static get(datasetId) {
    return http
      .get(`${BASE_PATH}${datasetId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static put(name, newName) {
    return http
      .put(`${BASE_PATH}${name}/`, { name: newName })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static post(datasetId, name) {
    return http
      .post(`${BASE_PATH}${datasetId}/`, { name })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static delete(id, type) {
    return http
      .delete(`${BASE_PATH}${id}/`, { params: { type } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
