<template>
  <div class="text-h2 header-name fade-in">
    <div :style="{ opacity: loaded ? 1 : 0 }">
      <div
        v-if="!rename"
        ref="nameContainer"
        class="d-flex align-center primary--text"
      >
        <v-tooltip
          v-if="tooLong"
          color="#423F4F"
          right
        >
          <template #activator="{ props }">
            <span
              v-bind="props"
              class="ellipsis clickable"
              @click="$emit('nameClick')"
            >
              {{ item.name }}
            </span>
          </template>
          <span style="color: white">
            {{ item.name }}
          </span>
        </v-tooltip>
        <span
          v-else
          ref="headerName"
          @click="$emit('nameClick')"
        >
          {{ item.name }}
        </span>
        <v-tooltip
          v-if="item.id !== -1"
          color="#423F4F"
          right
        >
          <template #activator="{ props }">
            <v-icon
              v-bind="props"
              class="hashtag-icon"
              style="margin-left: 5px;"
              size="15"
            >
              fas fa-hashtag
            </v-icon>
          </template>
          <span style="color: white">
            id: {{ item.id }}
          </span>
        </v-tooltip>
        <v-tooltip
          v-if="editable && user && authorizedRoles.includes(user.role)"
          color="#423F4F"
          right
        >
          <template #activator="{ props }">
            <v-icon
              v-bind="props"
              style="cursor: pointer"
              class="ml-4"
              @click="renameItem"
            >
              fas fa-pen
            </v-icon>
          </template>
          <span style="color: white">
            {{ $t('models.edit_name') }}
          </span>
        </v-tooltip>
      </div>
      <div v-else>
        <input
          ref="headerNameField"
          v-model="newName"
          type="text"
          class="header-name-field primary--text text-h2 mr-1"
          @keydown.enter="saveName"
          @keydown.esc="rename = false"
        >
        <v-icon
          class="mr-1"
          color="primary"
          @click="saveName"
        >
          fas fa-check
        </v-icon>
        <v-icon
          class="mr-1"
          color="primary"
          @click="$emit('cancelNameEdit'); rename = false;"
        >
          fas fa-times
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderName',

  data() {
    return ({
      rename: false,
      newName: '',
      loaded: false,
      authorizedRoles: [
        "orgadmin",
        "sysadmin",
      ],
      windowWidth: window.innerWidth,
    });
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    tooLong() {
      this.windowWidth;
      this.loaded;
      this.$store.getters.menuOpen;
      const headerName = this.$refs.headerName;
      if (headerName) {
        return headerName.offsetWidth > this.maxNameWidth;
      }
      return false;
    },

    maxNameWidth() {
      if (this.maxWidth) {
        return this.maxWidth;
      }
      this.loaded;
      this.windowWidth;
      this.$store.getters.menuOpen;
      const nameContainer = this.$refs.nameContainer;
      if (nameContainer) {
        return nameContainer.offsetWidth - 20;
      }
      return 200;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    if (this.item && this.item.name) {
      this.newName = this.item.name;
    }
    setTimeout(() => {
      this.loaded = true;
    }, 200);
  },

  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    saveName() {
      this.$emit('save', this.newName);
      this.loaded = false;
      this.rename = false;
      setTimeout(() => {
        this.loaded = true;
      }, 200);
    },

    renameItem() {
      this.rename = true;
      this.newName = this.item.name;
      setTimeout(() => this.$refs.headerNameField.focus(), 200);
    },
  },

  props: {
    item: {
      required: true,
      type: Object,
    },

    maxWidth: {
      type: Number,
      default: 0,
    },

    editable: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['nameClick', 'save', 'cancelNameEdit'],
}
</script>

<style lang="scss" scoped>
.header-name {
  white-space: nowrap;

  .hashtag-icon {
    color: #747474 !important;
  }

  .hashtag-icon:hover {
    color: #747474 !important;
  }
  
  .header-name-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;

    &:focus {
      outline: none;
      border-bottom: 1px solid var(--v-primary-base);
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid var(--v-primary-base);
    }
  }
}
</style>
