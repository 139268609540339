<template>
  <div class="emails-review-table d-flex align-center justify-space-between">
    <div class="d-flex align-center">
      <v-text-field
        v-model="emailsToReviewFilter"
        class="emails-subject-filter filter-items"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('emails.email_filter_placeholder')"
      />
    </div>
  </div>
  <TableWithFooter
    :loading="!loaded"
    :paginated-items-length="emailsToReview.length"
    :total="totalEmailsToReview"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
    @sort="onSort"
    sortable
  >
    <template #header>
      <v-col cols="8">
        {{ $t('emails.email_subject') }}
      </v-col>
      <v-col cols="4">
        {{ $t('emails.email_prediction') }}
      </v-col>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <v-row
          v-for="email in emailsToReview"
          :key="email.id"
          class="table-row fade-in table-row-height clickable"
          @click="() => $router.push({ name: 'EmailCorrectionView', params: { emailId: email.id } })"
        >
          <v-col cols="8">
            <ItemName
              :key="email.id"
              name-field="subject"
              class="inline-top"
              :item="email"
            />
          </v-col>
          <v-col cols="4">
            {{ email.prediction?.label }}
          </v-col>
        </v-row>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>

import _ from "lodash";
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter.vue';
import ItemName from '@/components/common/elements/General/ItemName.vue';
import { EmailsAPI } from '@/API/classify/EmailsAPI';
import { useTableWithFooter } from '@/composables/useTableWithFooter';


export default {
  name: 'EmailsReviewTable',
  components: {
    TableWithFooter,
    ItemName,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      descendingSort: true,
      emailsToReviewFilter: '',
      totalEmailsToReview: 0,
      loaded: false,
      itemsPerPage,
      currentPage,
      emailsToReview: [],
    };
  },

  computed: {
    trimmedFilter() {
      return this.emailsToReviewFilter.trim().toLowerCase();
    },
  },

  watch: {
    sortDesc() {
      this.fetchEmails();
    },

    itemsPerPage: function() {
      this.currentPage = 1;
      this.fetchEmails();
    },

    currentPage: function() {
      this.fetchEmails();
    },

    trimmedFilter: _.debounce(
      function() {
        this.currentPage = 1;
        this.fetchEmails()
      }, 300
    ),
  },

  mounted() {
    this.fetchEmails();
  },

  methods: {
    onSort(descendingSort) {
      this.descendingSort = descendingSort;
      this.fetchEmails();
    },

    async fetchEmails() {
      this.loaded = false;
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const response = await EmailsAPI.fetchEmails({
        limit: this.itemsPerPage,
        subject: this.trimmedFilter,
        offset,
        descendingSort: this.descendingSort,
        requireHumanReview: true,
      });
      this.emailsToReview = response.data;
      this.totalEmailsToReview = parseInt(response.headers['x-total-count']);
      this.loaded = true;
    },
  },
}
</script>

<style lang="scss" scoped>

.emails-review-table {
  height: 50px;
  margin-top: 28px;
}

.emails-subject-filter {
  width: 280px;
}

</style>