<template>
  <HeaderName
    :item="{name: email.subject, id: email.id}"
    :editable="false"
  />
  <div class="mt-5">
    <div class="text-subtitle-1">
      {{ `${$t("emails.email_from")}${getEmailAuthor(email)}` }}
    </div>
    <div class="text-subtitle-1">
      {{ `${$t("emails.email_to")}${getEmailReceivers(email)}` }}
    </div>
  </div>
  <div class="mt-10">
    <InfoBox
      v-if="!email.body"
    >
      <p class="empty-body-message">
        {{ $t("emails.email_empty_body") }}
      </p>
    </InfoBox>
    <p
      v-else
      class="email-body__block"
    >
      {{ email.body }}
    </p>
  </div>
</template>

<script>
import HeaderName from '@/components/common/elements/General/HeaderName.vue';
import { getEmailAuthor, getEmailReceivers } from '@/utils/EmailsUtils';
import InfoBox from '@/components/common/elements/General/InfoBox.vue';


export default {
    name: 'EmailComponent',
    components: {
      HeaderName,
      InfoBox,
    },

    methods: {
      getEmailAuthor,
      getEmailReceivers,
    },

    props: {
      email: {
        type: Object,
        required: true,
      },
    },
}
</script>

<style lang="scss" scoped>

.email-body__block {
  background-color: rgb(var(--v-theme-grey-lighten2));
  border-radius: 6px;
  margin: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  overflow: auto;
  padding: 12px;
}

.empty-body-message {
  font-size: 16px;
}

</style>