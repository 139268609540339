<template>
  <div class="label-group-view">
    <div class="divided-config-page-left">
      <div class="d-flex">
        <BackLink :target="backTarget" />
        <DocSelect
          v-if="$store.getters.loggedInUser.role === 'orgadmin'"
          style="margin-left: auto"
          :docs="files"
          :selected="selectedFile"
          @selected-changed="selectFileById"
          @get-more="getFiles(numberOfFiles)"
        />
      </div>
      <div style="padding: 7px 10px 0px 30px">
        <ConfigureDPGroup
          v-if="edited.id > -1"
          class="fade-in"
          :group="edited"
          @updated="isUpdated = true"
          @update-extraction-type="(category) => edited.is_generative = category === 'generative'"
          @update-labels="(labels) => edited.labels = labels"
          @update-description="(description) => edited.description = description"
          @update-table-display="(tableDisplay) => edited.table_display = tableDisplay"
          @name-save="savedChanges = true"
        />
        <CreateDPGroup
          v-else-if="!$route.params.itemId"
          @create="createdGroup"
        />
      </div>
      <div v-if="edited.id > -1">
        <div
          v-if="$store.getters.loggedInUser.role === 'sysadmin'"
          style="padding-left: 30px"
        >
          <v-btn
            style="box-shadow: none"
            color="primary"
            @click="saveGroup"
            rounded
          >
            {{ $t('save') }}
          </v-btn>
        </div>
        <ExtractionResult
          v-if="edited.id > -1"
          ref="extractionResult"
          :values="viewFileHasValues ? viewFile.group_values[edited.id] : []"
          :is-valid="isExtractionValid"
          :fail-message="$t('dataPoints.no_values_extracted')"
          @hover="handleHighlight"
          @hoverout="deHighlightDP"
          @save="saveGroup"
        />
      </div>
    </div>
    <DocViewer
      v-if="$store.getters.loggedInUser.role === 'orgadmin'"
      ref="filesDocViewer"
      style="width: 59%"
      active-tab="default"
      :type="'configureGroup'"
      :document="viewFile"
      :loading="docLoading"
      :viewer-values="viewerValuesAll"
      :has-tables="!!viewFile.hasTables"
      :has-text="!!viewFile.hasText"
      :has-layout="!!viewFile.hasLayout"
      :has-entities="!!viewFile.hasEntities"
      :has-forms="!!viewFile.hasForms"
      :has-values="viewFileHasValues"
      :active-datapoint="activeDP"
      :raw-group-values="viewFile.raw_group_values || []"
      tabs
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import extractor_mixin from '@/mixins/extractor.js';
import feedback_mixin from '@/mixins/feedback.js';
import file_mixin from '@/mixins/file.js';
import model_mixin from '@/mixins/model.js';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import CreateDPGroup from '@/components/extract/views/Extractors/CreateDPGroup.vue';
import ConfigureDPGroup from '@/components/extract/views/Extractors/ConfigureDPGroup.vue';
import DocSelect from '@/components/extract/elements/Documents/DocSelect';
import DocViewer from '@/components/extract/elements/DocViewer/DocViewer.vue';
import ExtractionResult from '@/components/extract/elements/Extractors/ExtractionResult';
import { ExtractionGroupAPI } from '@/API/extract/ExtractionGroupAPI';

export default {
  name: 'LabelGroupView',

  mixins: [
    extractor_mixin,
    feedback_mixin,
    file_mixin,
    model_mixin,
  ],

  components: {
    BackLink,
    CreateDPGroup,
    ConfigureDPGroup,
    DocSelect,
    DocViewer,
    ExtractionResult,
  },

  data() {
    return {
      newGenExtractorId: -1,
      isUpdated: false,
    };
  },

  computed: {
    viewFileHasValues() {
      this.baseKey;
      return (
        !!this.viewFile && !!this.viewFile.group_values && !!this.viewFile.group_values[this.edited.id] && this.viewFile.group_values[this.edited.id].length > 0
      );
    },

    viewerValuesAll() {
      this.baseKey;
      if (!!this.viewFile && !!this.viewFile.group_values && !!this.viewFile.group_values[this.edited.id] && this.viewFile.group_values[this.edited.id].length > 0) {
        return this.viewFile.group_values[this.edited.id];
      }
      return [];
    },

    items: {
      cache: false,
      get: function() {
        if (this.$store.getters.labelGroups[this.$route.params.id]) {
          return this.$store.getters.labelGroups[this.$route.params.id]
        }
        return [];
      },
      set: function(newGroups) {
        this.$store.commit('setLabelGroups', {
          typeId: this.$route.params.id,
          labelGroups: newGroups
        });
      },
    },

    isExtractionValid() {
      return this.edited && this.isUpdated && (
        this.isPrimaryLabelsValid || 
        this.isGenerativeLabelsValid
      );
    },

    isPrimaryLabelsValid() {
      return this.edited.labels && 
             this.edited.labels.some(item => item.isPrimary);
    },

    isGenerativeLabelsValid() {
      return this.edited.labels && 
             this.edited.labels.length > 0 && 
             this.edited.is_generative;
    },
  },

  watch: {
    'edited.is_generative': {
      handler: function(newValue, oldValue) {
        this.isUpdated = oldValue !== undefined && oldValue !== newValue;
      },
    },
    'edited.description': {
      handler: function(newValue, oldValue) {
        this.isUpdated = oldValue !== undefined && oldValue !== newValue;
      },
    },
    'edited.table_display': {
      handler: function(newValue, oldValue) {
        this.isUpdated = oldValue !== undefined && oldValue !== newValue;
      },
    },
  },

  async mounted() {
    if (this.$route.params.itemId) {
      await this.getLabelGroup(this.$route.params.itemId);
      if (this.edited.category === 'generative') {
        this.newGenExtractorId = this.edited.id;
      }
    }
  },

  methods: {
    async getLabelGroup(id) {
      try {
        const group = await ExtractionGroupAPI.getExtractionGroup(id);
        const labelObjects = group.labels.map((label, index) => ({
          name: label,
          displayName: !group.is_generative && group.display_names[index] || '',
          isPrimary: !group.is_generative && group.primary_labels[index],
          valueType: group.value_types[index],
          regex: group.regex[index] || { pattern: '', substitute: '' },
          precision: group.required_precisions[index],
        }));
        group.labels = labelObjects;
        this.edited = group;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    handleHighlight(value) {
      if (this.viewerValuesAll && this.viewerValuesAll.length > 0) {
        this.$refs.filesDocViewer.currentPage = value.page_nb;
        const activeDP = value;
        const id = activeDP.data_point_id || activeDP.id;
        setTimeout(() => {
          this.handleZoomScroll(value.page_nb, id);
          this.activeDP = activeDP;
        }, 50);
      }
    },

    handleZoomScroll(page_nb, id) {
      const docViewer = this.$refs.filesDocViewer;
      if (docViewer) {
        docViewer.currentPage = page_nb;
        const highlights = docViewer.$refs.groupValueHighlights;
        if (highlights) {
          setTimeout(() => {
            let highlight = highlights.$refs[`value${id}`];
            if (!highlight) {
              return;
            }
            if (highlight) {
              highlight.scrollIntoViewIfNeeded();
            }
          }, 100);
        }
      }
    },

    async getFileGroupValues(id) {
      if (id > -1) {
        try {
          const response = await http.get(`files/${id}/groups/`);
          const groupValues = response.data.map((g, index) => {
            for (let i = 0; i < g.subgroups.length; i++) {
              for (let j = 0; j < g.subgroups[i].values.length; j++) {
                g.subgroups[i].values[j].group_index = index;
                g.subgroups[i].values[j].subgroup_index = i;
                g.subgroups[i].values[j].index = j;
                g.subgroups[i].values[j].group_id = g.group_id;
              }
            }
            g.panel = [0];
            return g;
          })
          return groupValues;
        } catch (error) {
          console.log(error);
          return []
        }
      }
      return [];
    },

    checkGroupValues(values) {
      const editedGroupValues = values.find(group => group.group_id === this.edited.id);
      if (editedGroupValues && editedGroupValues.subgroups.length > 0) {
        return editedGroupValues.subgroups;
      }
      return null;
    },

    async getGroupValues() {
      let values = [];
      let editedGroupValues = [];
      values = await this.getFileGroupValues(this.viewFile.id);
      editedGroupValues = this.checkGroupValues(values);
      let retries = 4;
      while (
        retries > 0 &&
        this.edited.id > -1 &&
        this.viewFile.id > -1 &&
        !editedGroupValues
      ) {
        await this.timeout(4000);
        values = await this.getFileGroupValues(this.viewFile.id);
        editedGroupValues = this.checkGroupValues(values);
        retries--;
      }
      if (values.length > 0) {
        this.viewFile.raw_group_values = values;
      }
      if (editedGroupValues) {
        this.viewFile.group_values = {};
        this.viewFile.group_values[this.edited.id] = editedGroupValues;
        this.viewFile = {...this.viewFile};
      }
      this.$store.commit('setLoadingScreen', false);
      this.docLoading = false;
      this.baseKey += 10;
    },

    createdGroup(group) {
      this.edited = group;
      this.newGenExtractorId = group.id;
    },

    async saveGroup() {
      const labelNameArray = [];
      const displayNameArray = [];
      const primaryLabelArray = [];
      const valueTypeArray = [];
      const regexArray = [];
      const precisionArray = [];
      this.edited.labels.forEach((label) => {
        labelNameArray.push(label.name);
        displayNameArray.push(label.displayName);
        primaryLabelArray.push(label.isPrimary);
        valueTypeArray.push(label.valueType);
        regexArray.push(label.regex);
        precisionArray.push(label.precision);
      });
      try {
        if (!this.edited.is_generative && this.edited.labels.every(item => !item.isPrimary)) {
          this.primaryLabelError();
        } else {
          this.$store.commit('setLoadingScreen', true);
          await ExtractionGroupAPI.put(
            this.edited.id,
            {
              ...this.edited,
              labels: labelNameArray,
              display_names: displayNameArray,
              primary_labels: primaryLabelArray,
              value_types: valueTypeArray,
              regex: regexArray,
              required_precisions: precisionArray,
            }
          );
          this.isUpdated = false;
          
          // we need to wait for the values to be extracted
          setTimeout(() => {
            this.getGroupValues();
            this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
            this.$store.commit('setSuccessSnackbar', true);
            this.$refs.extractionResult.saved = true;
            this.$store.commit('setLoadingScreen', false);
          }, 300);
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async selectFile(file) {
      this.viewFile = file;
      this.selectedFile = file.id;
      if (this.edited.id > -1) {
        await this.getGroupValues();
      }
      await this.saveInfo();
      this.viewFile = {...this.viewFile};
    },
  }
}
</script>
