<template>
  <div
    class="back-link clickable bottom-gap"
    @click="handleClick"
  >
    <v-icon
      class="right-gap"
      color="primary"
    >
      fas fa-arrow-left
    </v-icon>
    <h4
      v-if="showText"
      class="inline-middle"
    >
      {{ text === '' ? $t('breadcrumb.extractors') : text }}
    </h4>
  </div>
</template>

<script>
export default {
  name: 'BackLink',

  methods: {
    handleClick() {
      if (!this.target) {
        this.$emit('backClick');
      } else {
        this.$router.push(this.target);
      }
    },
  },

  props: {
    target: {
      type: Object,
      default: () => {},
    },

    text: {
      type: String,
      default: '',
    },

    showText: {
      type: Boolean,
      default: true,
    },

    updatePreviousView: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['backClick'],
}
</script>

<style lang="scss" scoped>
.back-link {
  width: fit-content;

  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }
}
</style>
