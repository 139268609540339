/* istanbul ignore file */

import _axios from 'axios';

const openConfig = async configPath => {
  try {
    const config = await _axios.get(configPath);
    return config.data;
  } catch (error) {
    return null;
  }
};

const passConfigCheck = config => !(!config || !config.backends.extract);

const loadConfig = async () => {
  const config = await openConfig('/data/config.json');
  if (passConfigCheck(config)) {
    return config;
  }
  return false;
};

export default loadConfig;
