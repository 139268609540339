<template>
  <div class="search-recent-files">
    <h4
      v-if="lastFiles.length > 0"
      class="bottom-gap"
    >
      <v-icon style="margin-right: 5px">
        fas fa-file-alt
      </v-icon>
      <span style="position: relative; top: 2px;">
        {{ $t('search.home.recent_files') }}
      </span>
    </h4>
    <v-card
      v-if="lastFiles.length > 0"
      class="bottom-gap d-flex"
    >
      <v-tooltip
        v-for="file in lastFiles"
        :key="file.id"
        top
      >
        <template #activator="{ props }">
          <div
            v-bind="props"
            class="px-2"
            style="width: calc(100% / 5)"
          >
            <a
              class="d-flex flex-column align-items justify-space-between h-100"
              target="_blank"
              :href="file.url"
            >
              <ImageWithFallback
                :img-attributes="{
                  width: '100%',
                  style: 'border: 1px lightgrey solid; flex-shrink: 0;',
                  src: file.image,
                }"
              />
              <div
                class="text-caption ellipsis"
                style="width: 100%;"
              >
                {{ file.name }}
              </div>
            </a>
          </div>
        </template>
        {{ file.name }}
      </v-tooltip>
    </v-card>
  </div>
</template>
<script>
import { SearchAPI } from "@/API/search/SearchAPI";
import ImageWithFallback from "@/components/common/elements/General/ImageWithFallback";

export default {
  name: 'SearchRecentFiles',

  components: {
    ImageWithFallback,
  },

  data() {
    return {
      lastFiles: [],
    };
  },

  computed: {
    searchBackend() {
      return this.$store.getters.config.backends.search;
    },
  },

  mounted() {
    this.getRecentFiles();
  },

  methods: {
    async getRecentFiles() {
      try {
        this.lastFiles = await SearchAPI.getRecentFiles();
      } catch (_) {
        console.log(_);
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.file-history-container {
  width: 100%;
  padding: 5px 10px;
  background-color: white;
  border: 1px solid rgb(var(--v-theme-grey-darken2));
  border-radius: 4px;
  .previous-file {
    color: rgb(var(--v-theme-grey-darken4));
    transition: color .5s cubic-bezier(.4, 0, .2, 1);
    &:hover {
      color: rgb(var(--v-theme-primary));
    }
  }
}
</style>
