<template>
  <div class="performance-view">
    <DocTypeSelect
      class="top-gap"
      @selected-changed="(id) => {docTypeId = id}"
    />
    <v-card class="top-gap-lg mr-3 inline-middle chart-card chart-card-lg">
      <div v-if="hasData && prodStats.nb_documents">
        <ApexChart
          style="margin-left: -47px"
          width="436px"
          type="donut"
          :options="{
            legend: { show: false },
            labels: docStatuses.map(s => $t(`performance.${s.name}`)),
            colors: docStatuses.map(s => s.color),
          }"
          :series="docStatuses.map(s => prodStats[s.name])"
        />
        <div class="text-center chart-card-middle">
          <div
            v-for="(detail, i) in ['documents', 'pages']"
            :key="i"
            class="bottom-gap"
          >
            <div class="text-h3 text-center primary--text">
              {{ formatNumber(prodStats[`nb_${detail}`]) }}
            </div>
            <div>{{ $t(`performance.${detail}`) }}</div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="no-data-text"
      >
        {{ $t('traces.no_data') }}
      </div>
    </v-card>
    <div class="inline-top mr-6">
      <v-card
        v-for="(s, i) in docStatuses.slice(0, 6)"
        :key="i"
        class="chart-detail-card chart-detail-card-sm text-center mb-3"
        :class="{'chart-card-first': i === 0}"
      >
        <v-icon
          v-if="hasData && prodStats[s.name]"
          class="chart-legend-icon"
          :style="{ color: `${s.color} !important` }"
        >
          fas fa-circle
        </v-icon>
        <div style="margin-top: -29px">
          <div class="text-h3 text-center primary--text">
            <span v-if="hasData">
              {{ formatNumber(prodStats[s.name]) }}
            </span>
            <span v-else>
              0
            </span>
          </div>
          <div>
            {{ $t(`performance.${s.name}`) }}
          </div>
        </div>
      </v-card>
    </div>
    <div class="inline-top">
      <div class="inline-top mr-3">
        <v-card
          v-for="(extractor, i) in ['data_point_values', 'extraction_group_values']"
          :key="i"
          class="chart-card chart-card-sm mb-6"
          :class="{'chart-card-first': i === 0}"
        >
          <h3 class="text-h3">
            {{ $t(`performance.${extractor}`) }}
          </h3>
          <ApexChart
            v-if="hasData && extractorOptions.some(o => prodStats.extractors[extractor][o.name])"
            style="margin-left: -26px"
            type="bar"
            height="90%"
            :options="{
              legend: { show: false },
              colors: extractorOptions.map(o => o.color),
              dataLabels: { formatter: extractor === 'data_point_values' ? formatDataPoint : formatGroup },
              plotOptions: { bar: { horizontal: true } },
              yaxis: { labels: { show: false} },
              xaxis: { labels: { formatter: formatExtractorLabel } },
              tooltip: {
                x: { show: false },
                y: { formatter: formatExtractorLabel },
              },
              chart: {
                stacked: true,
                toolbar: { show: false },
              },
            }"
            :series="getExtractorSeries(extractor)"
          />
          <div
            v-else
            class="no-data no-data-card-sm"
          >
            <div class="no-data-text">
              {{ $t('traces.no_data') }}
            </div>
          </div>
        </v-card>
      </div>
      <div class="inline-top">
        <div
          v-for="(extractor, i) in ['data_point_values', 'extraction_group_values']"
          :key="i"
          class="mb-6"
          :class="{'chart-card-first': i === 0}"
        >
          <v-card
            v-for="(option, j) in extractorOptions"
            :key="j"
            class="chart-detail-card chart-detail-card-lg text-center"
            :class="{'mb-3': j === 0}"
            :style="{'margin-top': j === 1 ? '14px' : 'auto'}"
          >
            <v-icon
              v-if="hasData && prodStats.extractors[extractor][option.name]"
              class="chart-legend-icon"
              :style="{ color: `${option.color} !important` }"
            >
              fas fa-circle
            </v-icon>
            <div style="margin-top: -15px">
              <div class="text-h3 text-center primary--text">
                <span v-if="hasData">
                  {{ formatNumber(prodStats.extractors[extractor][option.name]) }}
                </span>
                <span v-else>
                  0
                </span>
              </div>
              <div>{{ $t(`performance.${option.name}`) }}</div>
            </div>
          </v-card>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import VueApexCharts from 'vue3-apexcharts';
import DocTypeSelect from '@/components/extract/elements/DocTypes/DocTypeSelect';

import format_mixin from '@/mixins/format.js';


export default {
  name: 'PerformanceView',

  mixins: [
    format_mixin,
  ],

  components: {
    DocTypeSelect,
    ApexChart: VueApexCharts,
  },

  data() {
    return ({
      prodStats: {},
      docTypeId: -1,
      loaded: false,
      docStatuses: [
        {name: 'to_validate', color: '#6D91FF'},
        {name: 'corrected', color: '#00CAFE'},
        {name: 'accepted', color: '#9985FF'},
        {name: 'discarded', color: '#0F64FF'},
        {name: 'automated', color: '#0000CA'},
        {name: 'failed', color: '#F11A34'},
      ],
      extractorOptions: [
        {name: 'ok', color: '#502BFF'},
        {name: 'corrected', color: '#00CAFE'},
      ]
    })
  },

  computed: {
    hasData() {
      return this.loaded && !!this.prodStats;
    }
  },

  watch: {
    docTypeId(value, old) {
      if (value > -1 && value !== old) {
        this.loaded = false;
        this.getProdStats();
      }
    },
  },

  methods: {
    formatExtractorLabel(value) {
      return parseInt(value, 10);
    },

    getExtractorSeries(extractor) {
      return this. extractorOptions.map(
        o => {
          const data = {};
          data.name = this.$t(`performance.${o.name}`);
          data.data = [this.prodStats.extractors[extractor][o.name]];
          return data;
        }
      );
    },

    async getProdStats() {
      try {
        const { data } = await http.get(`logs/production/?document_type_id=${this.docTypeId}`);
        this.prodStats = data;
        this.loaded = true;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    formatDataPoint(num) {
      const total = this.prodStats.extractors.data_point_values.ok + this.prodStats.extractors.data_point_values.corrected;
      const percent = Math.round(num / total * 100);
      return `${percent}%`; 
    },

    formatGroup(num) {
      const total = this.prodStats.extractors.extraction_group_values.ok + this.prodStats.extractors.extraction_group_values.corrected;
      const percent = Math.round(num / total * 100);
      return `${percent}%`; 
    },
  },
}
</script>

<style lang="scss" scoped>
.performance-view {
  .chart-card {
    width: 400px;
  }

  .chart-card-lg {
    height: 396px;
  }

  .chart-card-sm {
    height: 186px;
  }

  .chart-card-middle {
    position: absolute;
    left: 48px;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
  }

  .chart-detail-card {
    width: 200px;
  }

  .chart-detail-card-sm {
    height: 56px; 
  }

  .chart-detail-card-lg {
    height: 86px
  }

  .chart-card-first {
    margin-top: 30px;
  }

  .chart-legend-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .no-data-card-sm {
    position: relative;
    margin-top: 60px;
  }

  .no-data-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    text-align: center;

    font-family: Telegraf;
    font-weight: bold;
    font-size: 20px;
    color: #BBB;
  }
}
</style>
