import { createStore } from 'vuex';
import { i18n } from './i18n/i18n';

const locale = i18n.global;

export const store = createStore({
  state () {
    return {
      MIN_ENTRIES: 10,
      CLASSIFY_MIN_ENTRIES: 12,
      CLASSIFY_MIN_TOTAL_ENTRIES: 50,
      annotationParams: null,
      config: {},
      copySource: null,
      dpSortDesc: true,
      suiteMenu: [
        {
          translatedName: 'menu.review',
          icon: 'fas fa-user-check',
          route: '/suite/production/review',
          open: false,
          authorize: ['basic'],
        },
        {
          translatedName: 'menu.studio',
          icon: 'fas fa-flask',
          route: '/suite/studio',
          open: true,
          authorize: ['orgadmin', 'sysadmin'],
          items: [
            {
              translatedName: 'menu.datasets',
              open: true,
              authorize: ['orgadmin'],
              items: [
                {
                  translatedName: 'menu.documents_datasets',
                  route: '/suite/studio/datasets',
                },
                {
                  translatedName: 'menu.emails_datasets',
                  route: '/suite/studio/emails_datasets',
                  needsService: 'classify',
                },
              ]
            },
            {
              translatedName: 'menu.models',
              route: '/suite/studio/models',
              open: true,
              authorize: ['orgadmin'],
              items: [
                {
                  translatedName: 'menu.extraction',
                  route: '/suite/studio/models/extraction',
                },
                {
                  translatedName: 'menu.classification',
                  route: '/suite/studio/models/classification',
                  needsService: 'classify',
                },
              ],
            },
            {
              translatedName: 'menu.agents',
              route: '/suite/studio/agents',
              open: true,
              authorize: ['orgadmin', 'sysadmin'],
              items: [
                {
                  translatedName: 'menu.extraction',
                  route: '/suite/studio/agents/extraction',
                },
                {
                  translatedName: 'menu.classification',
                  route: '/suite/studio/agents/classification',
                  needsService: 'classify',
                  disabled: true,
                },
                {
                  translatedName: 'menu.custom',
                  route: '/suite/studio/agents/custom',
                  disabled: true,
                },
              ],
            },
            {
              translatedName: 'menu.workflows.title',
              route: '/suite/studio/workflows',
              authorize: ['orgadmin'],
              needsService: 'workflows',
            },
          ]
        },
        {
          translatedName: 'menu.production',
          icon: 'fas fa-chart-line',
          route: '/suite/production',
          open: false,
          authorize: ['orgadmin'],
          items: [
            {
              translatedName: 'menu.review',
              route: '/suite/production/review',
              authorize: ['orgadmin'],
            },
            {
              translatedName: 'menu.monitoring',
              route: '/suite/production/stats',
              authorize: ['orgadmin'],
            },
          ],
        },
        {
          translatedName: 'menu.settings.title',
          icon: 'fas fa-cog',
          route: '/suite/settings',
          open: false,
          authorize: ['orgadmin', 'sysadmin'],
          items: [
            {
              translatedName: 'menu.general',
              route: '/suite/settings/general',
              authorize: ['orgadmin'],
            },
            {
              translatedName: 'menu.users.title',
              route: '/suite/settings/users',
              authorize: ['orgadmin', 'sysadmin'],
            },
            {
              translatedName: 'menu.bin.title',
              route: '/suite/settings/bin',
              authorize: ['orgadmin'],
            },
          ],
        },
      ],
      lgSortDesc: true,
      menuOpen: true,
      serviceStatus: {
        auth: {
          checked: false,
          running: true
        },
        classify: {
          checked: false,
          running: true,
        },
        extract: {
          checked: false,
          running: true,
        },
        search: {
          checked: false,
          running: true,
        },
        workflows: {
          checked: false,
          running: true,
        },
      },
      version: 'unknown',
      loadingScreen: false,
      snackbar: false,
      successSnackbar: false,
      successMessage: '',
      warningSnackbar: false,
      warningMessage: '',
      errorMessage: locale.t('default_error'),
      fullScreen: false,
      validatorFile: -1,
      validatorZoom: 1,
      docTypesView: 'list',
      customModels: [],
      datasets: [],
      annotationMode: 'text',
      automaticMode: '',
      docTypeModels: [],
      workflowTypes: [],
      entitiesToOptionsMapping: {
        LOC: 'address',
        address_regex: 'address',
      },
      acceptedSearchFiles: '.zip, .pdf, .txt, .doc, .docx, .docm, .xlsx, .xlsm',
      acceptedDocs: '.docx, .doc, .docm, .odt, .ods, .odp, .rtf, .html, .pptx, .ppt, .xls, .xlsx, .xlsm, .json, .csv, .txt',
      acceptedImgs: '.png, .jpg, .jpeg, .tiff, .tif',
      acceptedEmailFiles: '.eml, .txt',
      loggedInUser: null,
      breadcrumb: [],
      documentTypes: {},
      files: {},
      prodFileIndex: -1,
      prodFiles: [],
      prodFilesTotal: 0,
      dataPoints: {},
      labelGroups: {},
      values: {},
      groupValues: {},
      library: [],
      librarySize: 0,
      product: '',
      recitalLibrary: [],
      recitalLibrarySize: 0,
      totalClassifiers: 0,
      totalTrainingClassifiers: 0,
      /* This is used in a table that does not use the new component TableWithFooter
       * yet, it should be removed in the future. */
      itemsPerPage: {
        docTypes: 20,
        newDocType: 20,
        recitalDoctypes: 20,
      },
      /* This is used in a table that does not use the new component TableWithFooter
      * yet, it should be removed in the future. */
      currentPage: {
        docTypes: 1,
        newDocType: 1,
        recitalDoctypes: 1,
      },
      totalItems: {
        correctionDocs: 0,
      },
      nameFilter: {
        correctionDocs: '',
        workflowDocs: '',
      },
      entries: {},
      annotationColors: [
        '#502DFF',
        '#00CAFE',
        '#0F64FF',
        '#9985FF',
        '#0000CA',
        '#6D91FF',
        '#003BCB',
        '#14007A',
        '#05145F',
      ],
      menuGroups: null,
      totalFiles: 0,
      organizations: [],
      selectedDatasetId: -1,
      destinationId: -1,
      totalFilesDisplay: 0,
      totalRulesDisplay: 0,
      totalActiveModels: 0,
      totalActiveModelsDisplay: 0,
      totalTrainingModelsDisplay: 0,
      totalOrgs: 0,
      notifications: [],
      searchString: '',
      searchOrg: -1,
      rootId: null,
      searchPage: {
        metaOpen: true,
        foldersOpen: true,
      },
      browseFilters: {
        folderIds: [],
      },
      allSearchFolders: [],
      searchFolders: [],
      searchFolder: null,
      browseFolder: null,
      startedSearch: false,
      searchTotalFiles: 0,
      searchTotalFilesSet: false,
      searchHoveredImage: null,
      searchResults: {},
      searchAnswer: '',
      searchMetaOptions: {},
      searchFilters: {},
      searchDisplayFilters: {},
      searchShowFilters: true,
      searchFavoriteFolders: [],
      sourceModel: null,
    }
  },
  getters: {
    MIN_ENTRIES: state => state.MIN_ENTRIES,
    CLASSIFY_MIN_ENTRIES: state => state.CLASSIFY_MIN_ENTRIES,
    CLASSIFY_MIN_TOTAL_ENTRIES: state => state.CLASSIFY_MIN_TOTAL_ENTRIES,
    annotationColors: state => state.annotationColors,
    annotationParams: state => state.annotationParams,
    copySource: state => state.copySource,
    dpSortDesc: state => state.dpSortDesc,
    suiteMenu: state => state.suiteMenu,
    version: state => state.version,
    lgSortDesc: state => state.lgSortDesc,
    serviceStatus: state => state.serviceStatus,
    totalClassifiers: state => state.totalClassifiers,
    totalTrainingClassifiers: state => state.totalTrainingClassifiers,
    annotationMode: state => state.annotationMode,
    automaticMode: state => state.automaticMode,
    entries: state => state.entries,
    getSuccessMessage: state => state.successMessage,
    getWarningMessage: state => state.warningMessage,
    errorMessage: state => state.errorMessage,
    loadingScreen: state => state.loadingScreen,
    entityCategories: state => state.entityCategories,
    acceptedFiles: state => `.pdf, ${state.acceptedDocs}, ${state.acceptedImgs}, ${state.acceptedEmailFiles}`,
    acceptedDocDatasetFiles: state => `.pdf, ${state.acceptedDocs}, ${state.acceptedImgs}`,
    acceptedSearchFiles: state => state.acceptedSearchFiles,
    loggedInUser: state => state.loggedInUser,
    config: state => state.config,
    breadcrumb: state => state.breadcrumb,
    documentTypes: state => state.documentTypes,
    workflowTypes: state => state.workflowTypes,
    files: state => state.files,
    prodFileIndex: state => state.prodFileIndex,
    prodFiles: state => state.prodFiles,
    prodFilesTotal: state => state.prodFilesTotal,
    dataPoints: state => state.dataPoints,
    labelGroups: state => state.labelGroups,
    values: state => state.values,
    groupValues: state => state.groupValues,
    menuGroups: state => state.menuGroups,
    entitiesToOptionsMapping: (state) => state.entitiesToOptionsMapping,
    fullScreen: state => state.fullScreen,
    library: state => state.library,
    librarySize: state => state.librarySize,
    recitalLibrary: state => state.recitalLibrary,
    recitalLibrarySize: state => state.recitalLibrarySize,
    itemsPerPage: state => state.itemsPerPage,
    currentPage: state => state.currentPage,
    totalItems: state => state.totalItems,
    nameFilter: state => state.nameFilter,
    validatorFile: state => state.validatorFile,
    validatorZoom: state => state.validatorZoom,
    docTypesView: state => state.docTypesView,
    menuOpen: state => state.menuOpen,
    product: state => state.product,
    customModels: state => state.customModels,
    datasets: state => state.datasets,
    totalFiles: state => state.totalFiles,
    searchTotalFiles: state => state.searchTotalFiles,
    searchTotalFilesSet: state => state.searchTotalFilesSet,
    organizations: state => state.organizations,
    searchString: state => state.searchString,
    searchOrg: state => state.searchOrg,
    rootId: state => state.rootId,
    searchPage: state => state.searchPage,
    browseFilters: state => state.browseFilters,
    searchFolder: state => state.searchFolder,
    browseFolder: state => state.browseFolder,
    allSearchFolders: state => state.allSearchFolders,
    searchFolders: state => state.searchFolders,
    startedSearch: state => state.startedSearch,
    searchResults: state => state.searchResults,
    searchAnswer: state => state.searchAnswer,
    searchMetaOptions: state => state.searchMetaOptions,
    searchFilters: state => state.searchFilters,
    searchDisplayFilters: state => state.searchDisplayFilters,
    searchShowFilters: state => state.searchShowFilters,
    searchHoveredImage: state => state.searchHoveredImage,
    searchFavoriteFolders: state => state.searchFavoriteFolders,
    selectedDatasetId: state => state.selectedDatasetId,
    destinationId: state => state.destinationId,
    totalFilesDisplay: state => state.totalFilesDisplay,
    totalRulesDisplay: state => state.totalRulesDisplay,
    totalActiveModels: state => state.totalActiveModels,
    totalActiveModelsDisplay: state => state.totalActiveModelsDisplay,
    totalTrainingModelsDisplay: state => state.totalTrainingModelsDisplay,
    totalOrgs: state => state.totalOrgs,
    notifications: state => state.notifications,
    sourceModel: state => state.sourceModel,
  },
  mutations: {
    setCopySource(state, payload) {
      state.copySource = payload;
    },
    setDpSortDesc(state, payload) {
      state.dpSortDesc = payload;
    },
    setLgSortDesc(state, payload) {
      state.lgSortDesc = payload;
    },
    setServiceStatusChecked(state, service) {
      state.serviceStatus[service].checked = true;
    },
    setServiceStatusUnreachable(state, service) {
      state.serviceStatus[service].running = false;
    },
    setServiceVersion(state, payload) {
      state.serviceStatus[payload.service].version = payload.version;
    },
    setServiceStatus(state, payload) {
      state.serviceStatus[payload.service].status = payload.status;
    },
    setVersion(state, version) {
      state.version = version;
    },
    setTotalClassifiers(state, payload) {
      state.totalClassifiers = payload;
    },
    setTotalTrainingClassifiers(state, payload) {
      state.totalTrainingClassifiers = payload;
    },
    setMenu(state, payload) {
      state.menuOpen = payload;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setBreadcrumb(state, payload) {
      state.breadcrumb = payload;
    },
    setConfig(state, payload) {
      state.config = payload;
      state.config.loaded = true;
    },
    login(state, user) {
      state.loggedInUser = user;
      localStorage.setItem('language', user.language);
      i18n.locale = user.language;
      i18n.fallbackLocale = user.language;
    },
    logout(state) {
      state.loggedInUser = null;
      state.notifications = [];
      state.prodFileIndex = -1;
      state.prodFilesTotal = 0;
      state.prodFiles = [];
    },
    setSelectedDatasetId(state, id) {
      state.selectedDatasetId = id;
    },
    setDestinationId(state, id) {
      state.destinationId = id;
    },
    setFullScreen(state, payload) {
      state.fullScreen = payload;
    },
    setLoadingScreen(state, payload) {
      state.loadingScreen = payload;
    },
    setSearchString(state, payload) {
      state.searchString = payload;
    },
    setSearchAnswer(state, payload) {
      state.searchAnswer = payload;
    },
    setSearchMetaOptions(state, payload) {
      state.searchMetaOptions = payload;
    },
    setSearchFilters(state, payload) {
      state.searchFilters = payload;
    },
    setSearchDisplayFilters(state, payload) {
      state.searchDisplayFilters = payload;
    },
    setSearchOrg(state, payload) {
      state.searchOrg = payload;
    },
    setRootId(state, payload) {
      state.rootId = payload;
    },
    setSearchPage(state, payload) {
      state.searchPage = payload;
    },
    setBrowseFilters(state, payload) {
      state.browseFilters = payload;
    },
    setSearchFolder(state, payload) {
      state.searchFolder = payload;
    },
    setBrowseFolder(state, payload) {
      state.browseFolder = payload;
    },
    setAllSearchFolders(state, payload) {
      state.allSearchFolders = payload;
    },
    setSearchFolders(state, payload) {
      state.searchFolders = payload;
    },
    setSearchShowFilters(state, payload) {
      state.searchShowFilters = payload;
    },
    setStartedSearch(state, payload) {
      state.startedSearch = payload;
    },
    setSearchResults(state, payload) {
      state.searchResults = payload;
    },
    setSearchHoveredImage(state, payload) {
      state.searchHoveredImage = payload;
    },
    setSearchFavoriteFolders(state, payload) {
      state.searchFavoriteFolders = payload;
    },
    reinitSearch(state) {
      state.startedSearch = false;
      state.searchString = '';
      state.searchFolder = null;
      state.searchFilters = {};
    },
    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
    setWarningMessage(state, payload) {
      state.warningMessage = payload;
    },
    setWarningSnackbar(state, payload) {
      state.warningSnackbar = payload;
    },
    setSuccessSnackbar(state, payload) {
      state.successSnackbar = payload;
    },
    setSuccessMessage(state, payload) {
      state.successMessage = payload;
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload;
    },
    setDocumentType(state, type) {
      state.documentTypes[type.id] = type;
      let types = {};
      types = Object.assign(types, state.documentTypes);
      state.documentTypes = types;
    },
    setDocumentTypes(state, types) {
      state.documentTypes = types;
    },
    setWorkflowTypes(state, workflowTypes) {
      state.workflowTypes = workflowTypes;
    },
    setFiles(state, files) {
      state.files[files.typeId] = files.files;
      let newFiles = {};
      newFiles = Object.assign(newFiles, state.files);
      state.files = newFiles;
    },
    setProdFileIndex(state, index) {
      state.prodFileIndex = index;
    },
    setProdFiles(state, prodFiles) {
      state.prodFiles = prodFiles;
    },
    setProdFilesTotal(state, total) {
      state.prodFilesTotal = total;
    },
    setDataPoints(state, payload) {
      state.dataPoints[payload.typeId] = payload.dataPoints;
    },
    setLabelGroups(state, payload) {
      state.labelGroups[payload.typeId] = payload.labelGroups;
    },
    setValues(state, payload) {
      state.values[payload.fileId] = payload.values;
    },
    setGroupValues(state, payload) {
      state.groupValues[payload.fileId] = payload.values;
    },
    setAllValues(state, payload) {
      state.values = payload;
    },
    setAllGroupValues(state, payload) {
      state.groupValues = payload;
    },
    resetValues(state) {
      state.values = {};
    },
    setAnnotationMode(state, mode) {
      state.annotationMode = mode;
    },
    setAnnotationParams(state, params) {
      state.annotationParams = params;
    },
    setautomaticMode(state, value) {
      state.automaticMode = value;
    },
    setEntries(state, payload) {
      state.entries[payload.modelId] = payload.entries;
      state.entries = { ...state.entries };
    },
    resetEntries(state) {
      state.entries = {};
    },
    setItemsPerPage(state, { key, itemsPerPage }) {
      state.itemsPerPage[key] = itemsPerPage;
    },
    setCurrentPage(state, { key, currentPage }) {
      state.currentPage[key] = currentPage;
    },
    setTotalItems(state, { key, totalItems }) {
      state.totalItems[key] = totalItems;
    },
    setNameFilter(state, { key, nameFilter }) {
      state.nameFilter[key] = nameFilter;
    },
    setLibrary(state, types) {
      state.library = types;
    },
    setLibrarySize(state, size) {
      state.librarySize = size;
    },
    setRecitalLibrary(state, types) {
      state.recitalLibrary = types;
    },
    setRecitalLibrarySize(state, size) {
      state.recitalLibrarySize = size;
    },
    setCustomModels(state, models) {
      state.customModels = models;
    },
    setDatasets(state, datasets) {
      state.datasets = datasets;
    },
    setValidatorFile(state, id) {
      state.validatorFile = id;
    },
    setValidatorZoom(state, zoom) {
      state.validatorZoom = zoom;
    },
    setDocTypesView(state, view) {
      state.docTypesView = view;
    },
    setMenuGroups(state, menu) {
      state.menuGroups = menu;
    },
    setTotalFiles(state, payload) {
      state.totalFiles = payload;
    },
    setSearchTotalFiles(state, payload) {
      state.searchTotalFiles = payload;
      state.searchTotalFilesSet = true;
    },
    setOrganizations(state, payload) {
      state.organizations = payload;
    },
    setTotalFilesDisplay(state, payload) {
      state.totalFilesDisplay = payload;
    },
    setTotalRulesDisplay(state, payload) {
      state.totalRulesDisplay = payload;
    },
    setTotalActiveModels(state, payload) {
      state.totalActiveModels = payload;
    },
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    setSourceModel(state, payload) {
      state.sourceModel = payload;
    },
    setTotalActiveModelsDisplay(state, payload) {
      state.totalActiveModelsDisplay = payload;
    },
    setTotalOrgs(state, payload) {
      state.totalOrgs = payload;
    },
    setTotalTrainingModelsDisplay(state, payload) {
      state.totalTrainingModelsDisplay = payload;
    },
  },
  actions: {},
});
