import { getItemsPerPage, getCurrentPage } from './getters';

const state = () => {
  return {
    itemsPerPage: 20,
    currentPage: 1,
  };
};

const getters = {
  itemsPerPage: getItemsPerPage,
  currentPage: getCurrentPage,
};

const mutations = {
  setItemsPerPage(state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage;
  },

  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },
};

export const pagerModule = {
  name: 'pagerStore',
  namespaced: true,
  state,
  getters,
  mutations,
};
