<template>
  <div class="search-result-settings">
    <NumberStepper
      v-for="(option, i) in [
        'top_doc',
        'top_chunk',
        'length_chunk',
        'coef_ranker_doc',
        'coef_ranker_chunk',
      ]"
      :key="i"
      v-model="config[option]"
      :label="$t(`settings.search.${option}.title`)"
      :max="$options.constants.RANGE_MAP[option].max"
      :min="$options.constants.RANGE_MAP[option].min"
      :disabled="loading || !editingAllowed"
      @slider-update="$emit('update')"
      @text-update="$emit('update')"
    />
  </div>
</template>


<script>
import NumberStepper from '@/components/common/elements/Forms/NumberStepper';

export default {
  name: 'SearchResultSettings',

  components: {
    NumberStepper
  },

  constants: {
    RANGE_MAP: {
      length_chunk: { min: 0, max: 500 },
      top_chunk: { min: 1, max: 10 },
      coef_ranker_chunk: { min: 1, max: 40 },
      top_doc: { min: 1, max: 10 },
      coef_ranker_doc: { min: 1, max: 40 },
    },
  },

  props: {
    config: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    editingAllowed: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['update'],
}
</script>
