/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'system_2/extraction_groups/';

export class ExtractionGroupAPI {
  static get(
    documentTypeId,
    sortDesc = false,
    external = false,
    token = null,
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          document_type_id: documentTypeId,
          sort_desc: sortDesc,
          noAuth: external,
          external,
          token,
        }
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getExtractionGroup(id) {
    return http
      .get(`${BASE_PATH}${id}/`)
      .then(reponse => Promise.resolve(reponse.data))
      .catch(error => Promise.reject(error))
  }

  static post(documentTypeId, payload) {
    return http
      .post(BASE_PATH, payload, {
        params: {
          document_type_id: documentTypeId,
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static put(extractionGroupId, payload) {
    return http
      .put(`${BASE_PATH}${extractionGroupId}/`, payload)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
