<template>
  <div class="annotation-toolbox">
    <div
      v-for="(mode, i) in modes"
      :key="i"
    >
      <v-tooltip bottom>
        <template #activator="{ props }">
          <v-btn
            color="primary"
            class="small-button toolbox-button"
            :style="{ opacity: annotationMode === mode.name ? '1 !important' : '0.5 !important' }"
            :disabled="mode.disabled"
            v-bind="props"
            @click="annotationMode = mode.name"
          >
            <v-icon size="16">
              {{ mode.icon }}
            </v-icon>
          </v-btn>
        </template>
        {{ $t(`models.select_${mode.name}`) }}
      </v-tooltip>
    </div>
    <div
      v-for="(autoMode, j) in automatics"
      :key="j"
    >
      <v-tooltip bottom>
        <template #activator="{ props }">
          <v-btn
            class="small-button toolbox-button"
            color="primary"
            :disabled="autoMode.disabled"
            :style="{ opacity: automaticMode === autoMode.name ? '1 !important' : '0.5 !important' }"
            v-bind="props"
            @click="changeAutoMode(autoMode.name)"
          >
            <v-icon size="16">
              {{ autoMode.icon }}
            </v-icon>
          </v-btn>
        </template>
        {{ $t(`models.select_${autoMode.name}`) }}
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnnotationToolbox",

  computed: {
    modes() {
      return [
        {
          name: 'text',
          icon: 'fas fa-font',
          disabled: false,
        },
        {
          name: 'lines',
          icon: 'fas fa-list-ul',
          disabled: this.labelType === 'location',
        },
      ];
    },

    automatics() {
      return [
        {
          name: 'fixed-section',
          icon: 'fas fa-bullseye',
          disabled: this.labelType === 'location',
        },
        {
          name: 'all-similar',
          icon: 'fas fa-search',
          disabled: this.labelType === 'location',
        },
      ];
    },

    annotationMode: {
      get() {
        return this.$store.getters.annotationMode;
      },
      set(mode) {
        this.$store.commit('setAnnotationMode', mode);
      },
    },

    automaticMode: {
      get() {
        return this.$store.getters.automaticMode;
      },
      set(value) {
        this.$store.commit('setautomaticMode', value);
      },
    },
  },

  watch: {
    annotationMode(mode) {
      if (mode !== 'text') {
        this.automaticMode = '';
      }
    },
  },

  methods: {
    changeAutoMode(autoMode) {
      if (this.automaticMode === autoMode) {
        this.automaticMode = '';
      } else {
        this.automaticMode = autoMode;
        this.annotationMode = 'text';
      }
    }
  },

  props: {
    labelType: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
  .annotation-toolbox {
    text-align: right;
    display: flex;
    align-items: center;
    margin-top: -21px;

    .toolbox-button {
      vertical-align: top;
      color: white !important;
      margin-left: 10px;
      padding-left: 8px !important;
      padding-right: 8px !important;
      box-shadow: none;
    }
  }
</style>
