/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';
import qs from 'querystring';

const BASE_PATH = 'auth/api/v1/';

export class AuthAPI {
  static async login(credentials) {
    return http
      .post(`${BASE_PATH}login/`, qs.stringify(credentials), {
        params: { noAuth: true },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getLoginMethods(org_info) {
    const params = { noAuth: true };
    if (org_info) {
      params.org_info = org_info;
    }

    return http
      .get(`${BASE_PATH}login-methods/`,
      { params, globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
  }

  static async validateToken(provider, queryParams) {
    return http
        .post(`${BASE_PATH}openid/${provider}/validate-token`, null, {
          params: { ...queryParams },
          withCredentials: true,
        })
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error))
  }

  static async refresh(params) {
    return http
      .post(`${BASE_PATH}refresh-token/`, qs.stringify(params), {
        params: { noAuth: true },
        globalErrorHandlingOptOut: true,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async revoke(params) {
    return http
      .post(`${BASE_PATH}revoke/`, qs.stringify(params), {
        params: { noAuth: true },
        globalErrorHandlingOptOut: true,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.resolve(error));
  }

  static async verifyToken() {
    return http
      .post(`${BASE_PATH}verify/`, {}, { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
