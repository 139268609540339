<template>
  <div>
    <p
      id="left-message"
      class="text-h1 white--text pt-10"
    />
  </div>
</template>

<script>
export default {
  name: 'LoginLeftMessage',
};
</script>
