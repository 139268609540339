<template>
  <div class="favorite-folders">
    <v-card class="dialog-card">
      <v-icon
        v-if="type === 'dialog'"
        class="close-button"
        color="black"
        size="17"
        @click="$emit('close')"
      >
        fas fa-times
      </v-icon>
      <h2
        v-if="type === 'dialog'"
        class="dialog-title bottom-gap"
      >
        {{ $t('search.home.add_to_favorite_folder') }}
      </h2>
      <div style="max-height: 300px; overflow-x: auto;">
        <div
          v-for="(folder, i) in folders"
          :key="i"
          class="d-flex align-center bottom-gap-sm"
          @mouseenter="hovered = folder.id"
          @mouseleave="hovered = null"
        >
          <CheckboxEllipsis
            v-model="folder.selected"
            :label="folder.name"
            @update:model-value="handleClick(folder)"
          />
          <div class="ml-1">
            ({{ folder.favorites.length }})
          </div>
          <v-icon
            v-if="hovered === folder.id"
            class="clickable ml-1"
            color="primary"
            size="13"
            @click="deleting = folder.id; deleteDialog = true;"
          >
            fas fa-trash
          </v-icon>
        </div>
      </div>
      <div class="top-gap-sm">
        <div class="label text-field-label">
          {{ $t('search.home.new_favorite_folder') }}
        </div>
        <v-text-field
          v-model="newFolderName"
          class="inline-middle mt-0"
          variant="outlined"
          color="primary"
          density="compact"
          :style="{width: type === 'dialog' ? '70%' : '80%'}"
          @keyup.enter="addFolder"
        />
        <v-btn
          v-if="type === 'dialog'"
          class="left-gap-sm"
          color="primary"
          style="margin-top: -22px"
          @click="addFolder"
        >
          <v-icon>
            fas fa-check
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          class="left-gap-sm small-button"
          color="primary"
          style="margin-top: -22px"
          @click="addFolder"
        >
          <v-icon size="13">
            fas fa-check
          </v-icon>
        </v-btn>
      </div>
      <v-btn
        v-if="type === 'dialog'"
        class="top-gap-sm"
        color="primary"
        :disabled="!folders.some(f => f.selected)"
        @click="addToFavorites"
        block
        rounded
      >
        {{ $t('add') }}
      </v-btn>
    </v-card>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('search.home.delete_favorite_folder')"
      :message="$t('search.home.delete_favorite_folder_message')"
      @confirm="deleteFolder"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import { FavoriteAPI } from '@/API/search/FavoriteAPI';

import CheckboxEllipsis from '@/components/common/elements/General/CheckboxEllipsis';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";

export default {
  name: 'FavoriteFolders',

  components: {
    CheckboxEllipsis,
    DeleteDialog,
  },

  data() {
    return {
      newFolderName: '',
      deleting: null,
      hovered: null,
      deleteDialog: false,
    };
  },

  computed: {
    folders() {
      return this.$store.getters.searchFavoriteFolders;
    },
  },

  methods: {
    async addFolder() {
      try {
        if (!this.newFolderName.trim()) {
          return;
        }
        const folder = await FavoriteAPI.postFolder(this.newFolderName.trim());
        this.folders.push(folder);
        this.$store.commit(
          'setSuccessMessage',
          this.$t('search.home.favorite_folder_created')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (err) {
        console.log(err);
      } finally {
        this.newFolderName = '';
      }
    },

    async addToFavorites() {
      try {
        const folder = this.folders.find(f => f.selected);
        await FavoriteAPI.addToFavorites(this.docId, folder.id);
        this.getFavoriteFolders();
        this.$store.commit(
          'setSuccessMessage',
          this.$t('search.home.added_to_favorites')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('close');
      } catch (err) {
        console.log(err);
      }
    },

    async getFavoriteFolders() {
      try {
        const folders = await FavoriteAPI.getFolders();
        this.$store.commit('setSearchFavoriteFolders', folders);
      } catch (err) {
        console.log(err);
      }
    },

    async deleteFolder() {
      try {
        await FavoriteAPI.deleteFolder(this.deleting);
        this.getFavoriteFolders();
        this.$store.commit(
          'setSuccessMessage',
          this.$t('search.home.favorite_folder_deleted')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.deleteDialog = false;
      } catch (err) {
        console.log(err);
      }
    },

    handleClick(folder) {
      if (folder.selected) {
        this.folders.forEach(f => {
          if (f.id !== folder.id) {
            f.selected = false;
          }
        });
      }
    }
  },

  props: {
    docId: {
      type: Number,
      default: 0,
    },

    type: {
      type: String,
      default: 'default',
    },
  },

  emits: ['close'],

}
</script>

<style lang="scss" scoped>
.dialog-title {
  color: rgb(var(--v-theme-primary)) !important;
  font-weight: 500 !important;
  font-family: 'Telegraf' !important;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
