<template>
  <div class="text-caption header-description text-h4 mb-6">
    <div :style="{ opacity: loaded ? 1 : 0 }">
      <div
        v-if="!redescribe"
        ref="descriptionContainer"
        class="d-flex"
      >
        <v-tooltip
          v-if="tooLong"
          color="#423F4F"
          location="bottom"
        >
          <template #activator="{ props }">
            <span
              class="top-gap-sm ellipsis clickable"
              v-bind="props"
              @click="$emit('descriptionClick')"
            >
              {{ item.description }}
            </span>
          </template>
          <span style="color: white; word-wrap: break-word;">
            {{ item.description }}
          </span>
        </v-tooltip>
        <span
          v-else
          ref="headerDescription"
          class="top-gap-sm clickable"
          @click="$emit('descriptionClick')"
        >
          {{ item.description }}
        </span>
        <v-btn
          v-if="editable && user && authorizedRoles.includes(user.role) && !item.description"
          color="primary top-gap-sm"
          class="small-button"
          @click="redescribeItem"
          rounded
        >
          <v-icon
            size="16"
            start
          >
            fas fa-plus
          </v-icon>
          {{ $t("models.add_description") }}
        </v-btn>
        <v-tooltip
          v-else-if="editable && user && authorizedRoles.includes(user.role)"
          color="#423F4F"
          location="bottom"
        >
          <template #activator="{ props }">
            <v-icon
              style="cursor: pointer"
              class="mt-4 ml-4"
              size="13"
              v-bind="props"
              @click="redescribeItem"
            >
              fas fa-pen
            </v-icon>
          </template>
          <span style="color: white">
            {{ $t("models.edit_description") }}
          </span>
        </v-tooltip>
      </div>
      <div v-else>
        <input
          ref="headerDescriptionField"
          v-model="newDescription"
          type="text"
          class="header-name-field mr-1"
          :style="{ 'width': `${maxDescriptionWidth - 40}px` }"
          @keydown.enter="saveDescription"
          @keydown.esc="redescribe = false"
        >
        <v-btn
          class="mr-1"
          variant="text"
          @click="saveDescription"
        >
          <v-icon size="14">
            fas fa-check
          </v-icon>
        </v-btn>
        <v-btn
          variant="text"
          @click="$emit('cancelDescriptionEdit'); redescribe = false;"
        >
          <v-icon size="14">
            fas fa-times
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: 'ItemDescription',

  data() {
    return ({
      redescribe: false,
      newDescription: '',
      newDescriptionFailureTimeout: null,
      loaded: false,
      authorizedRoles: [
        "orgadmin",
        "sysadmin",
      ],
      windowWidth: window.innerWidth,
    });
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    tooLong() {
      this.windowWidth;
      this.loaded;
      this.$store.getters.menuOpen;
      const headerDescription = this.$refs.headerDescription;
      if (headerDescription) {
        return headerDescription.offsetWidth > this.maxDescriptionWidth;
      }
      return true;
    },

    maxDescriptionWidth() {
      if (this.maxWidth) {
        return this.maxWidth;
      }
      this.loaded;
      this.windowWidth;
      this.$store.getters.menuOpen;
      const descriptionContainer = this.$refs.descriptionContainer;
      if (descriptionContainer) {
        return descriptionContainer.offsetWidth - 20;
      }
      return 200;
    },
  },

  mounted() {
    nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    if (this.item && this.item.description) {
      this.newDescription = this.item.description;
    }

    this.loaded = true;
  },

  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    saveDescription() {
      if (this.newDescription.length > 400) {
        clearTimeout(this.newDescriptionFailureTimeout);
        this.$store.commit("setErrorMessage", this.$t("description_max_length_error"));
        this.$store.commit("setSnackbar", true);
        this.newDescriptionFailureTimeout = setTimeout(
          () => this.$store.commit('setErrorMessage', this.$t('default_error')), 5000
        );
        return
      }
      this.$emit('save', this.newDescription);
      this.loaded = false;
      this.redescribe = false;
      setTimeout(() => {
        this.loaded = true;
      }, 200);
    },

    redescribeItem() {
      this.redescribe = true;
      this.newDescription = this.item.description;
      setTimeout(() => this.$refs.headerDescriptionField.focus(), 200);
    }
  },

  props: {
    item: {
      required: true,
      type: Object,
    },

    maxWidth: {
      type: Number,
      default: 0,
    },

    editable: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['descriptionClick', 'cancelDescriptionEdit', 'save'],
}
</script>

<style lang="scss" scoped>
.header-description {
  white-space: nowrap;
  
  .header-name-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;

    &:focus {
      outline: none;
      border-bottom: 1px solid black;
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid black;
    }
  }

  .small-button {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>