<template>
  <v-dialog
    class="move-emails-dialog"
    :model-value="true"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card class="dialog-card d-flex ga-10">
      <div class="d-flex align-center justify-space-between">
        <h1 class="text-h5 primary--text">
          {{ "Move Emails" }}
        </h1>
        <div>
          <v-icon
            class="close-icon"
            size="16px"
            @click="closeDialog"
          >
            fas fa-times
          </v-icon>
        </div>
      </div>
      <div class="dialog-body">
        <CustomSelect
          name-field="title"
          value-field="value"
          placeholder="emailsDatasets.select_dataset"
          :items="emailsDatasetsItems"
          :selected="selectedDatasetId"
          @selected-changed="onCustomSelectChange"
          @get-more="fetchEmailsDatasets"
          clickable
        />
      </div>
      <div class="d-flex justify-end">
        <v-btn
          class="clickable"
          color="primary"
          :disabled="!selectedDatasetId"
          @click="moveEmails"
          rounded
        >
          {{ $t('confirm') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';
import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI';

export default {
  components: {
    CustomSelect,
  },

  data() {
    return {
      emailDatasets: [],
      selectedDatasetId: null,
      totalDatasets: 0,
      offset: 0,
      limit: 20,
    };
  },

  computed: {
    emailsDatasetsItems() {
      return this.emailDatasets.map((dataset) => ({
        title: dataset.name,
        value: dataset.id,
      }));
    },
  },

  created() {
    this.fetchEmailsDatasets();
  },

  methods: {
    async fetchEmailsDatasets(filter = '', reset = true) {
      if (this.offset > this.totalDatasets) {
        return;
      }

      if (reset) {
        this.offset = 0;
        this.emailDatasets = [];
      } else {
        this.offset += this.limit;
      }

      const response = await EmailsDatasetsAPI.fetchAll({name: filter, offset: this.offset, limit: this.limit});
      const emailsDatasets = response.data.filter((email) => email.id !== this.sourceDatasetId);
      this.emailDatasets = this.emailDatasets.concat(...emailsDatasets);
      this.totalDatasets = parseInt(response.headers['x-total-count'], 10);
    },

    async moveEmails() {
      await EmailsDatasetsAPI.update({datasetId: this.selectedDatasetId, emailsIds: this.idsOfEmailsToMove});
      this.$emit('confirm');
    },

    onCustomSelectChange(value) {
      this.selectedDatasetId = value;
    },

    closeDialog() {
      this.$emit('close');
    },
  },

  props: {
    idsOfEmailsToMove: {
      type: Array,
      default: () => [],
    },
    sourceDatasetId: {
      type: Number,
      required: true,
    },
  },

  emits: ['close', 'confirm'],
};
</script>

<style scoped>

.move-emails-dialog {
  max-width: 750px;

  .v-card {
    top: -200px;
    overflow: unset !important;
  }

  .v-dialog__content {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

</style>