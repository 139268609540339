<template>
  <div class="table-highlights">
    <div 
      v-for="(table_and_cells, index) in tables" 
      :key="index"
    >
      <div>
        <div
          class="highlight-frame"
          :style="{
            'border-color': '#7F00FF',
            'border-width': '7px',
            left: `${getHighlightX(table_and_cells[0].x_min)}vh`,
            top: `${getHighlightY(table_and_cells[0].y_min)}vh`,
            width: `${getHighlightX(table_and_cells[0].x_max - table_and_cells[0].x_min)}vh`,
            height: `${getHighlightY(table_and_cells[0].y_max - table_and_cells[0].y_min)}vh`,
          }"
        />
      </div>
      <div 
        v-for="(cell, subIndex) in table_and_cells[1]" 
        :key="subIndex"
      >
        <div
          class="highlight-frame"
          :style="{
            'border-color': 'red',
            left: `${getHighlightX(cell.x_min)}vh`,
            top: `${getHighlightY(cell.y_min)}vh`,
            width: `${getHighlightX(cell.x_max - cell.x_min)}vh`,
            height: `${getHighlightY(cell.y_max - cell.y_min)}vh`,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableHighlights",

  methods: {
    getHighlightX(x) {
      return x * this.pageWidth;
    },
    getHighlightY(y) {
      return y * this.pageHeight;
    },
  },

  props: {
    tables: {
      type: Array,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.highlight-frame {
  position: absolute;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  opacity: 0.4;
}
</style>