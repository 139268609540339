<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="pa-4">
    <div v-if="!job">
      <v-progress-linear
        class="ma-6"
        indeterminate
      />
    </div>

    <div v-else>
      <BackLink
        class="inline-middle mt-2"
        :text="$t('workflows.jobs.title')"
        :target="{ name: 'Workflows', query: { activeTab: 'jobs' } }"
      /> 

      <div class="float-right">
        <v-btn
          v-if="live"
          class="pa-2 pr-4"
          :title="$t('workflows.jobs.pause')"
          :disabled="!isRunning"
          @click="stopLive()"
        >
          <v-icon
            size="16"
            color="primary"
            class="ma-2"
          >
            fas fa-circle
          </v-icon>
          {{ $t('workflows.jobs.live') }}
        </v-btn>

        <v-btn
          v-else
          class="pa-2 pr-4"
          :title="$t('workflows.jobs.unpause')"
          :disabled="!isRunning"
          @click="startLive()"
        >
          <v-icon
            size="16"
            class="ma-2"
            color="disabled"
          >
            fas fa-circle
          </v-icon>
          {{ $t('workflows.jobs.live') }}
        </v-btn>

        <v-progress-linear
          v-if="loading"
          indeterminate
        />
      </div>

      <div class="d-flex">
        <div class="align-self-end flex-1-1">
          <div class="pa-2">
            <strong>{{ $t('workflows.jobs.id') }}</strong>: {{ job.id }}
          </div>

          <div class="pa-2">
            <strong>{{ $t('workflows.jobs.workflow') }}</strong>:
            <span v-if="workflow">
              {{ workflow.name }}
            </span>
          </div>

          <div class="pa-2">
            <strong>{{ $t('workflows.jobs.current_step') }}</strong>:

            <span v-if="currentStep">
              <em v-if="currentStep.name">
                {{ currentStep.name }}
              </em>
              <em v-else-if="currentStep.action_type == 'python'">
                {{ $t('workflows.steps.code') }}
              </em>
              <em v-else-if="$te('workflows.steps.' + currentStep.action)">
                {{ $t('workflows.steps.' + currentStep.action) }}
              </em>
              <em v-else>
                {{ currentStep.action }}
              </em>
            </span>
          </div>

          <div class="pa-2">
            <strong>{{ $t('workflows.jobs.state') }}</strong>:

            <v-chip
              size="small"
              variant="flat"
              :color="getJobStateColor(job)"
            >
              {{ job.state }}
            </v-chip>
          </div>
        </div>

        <div class="align-self-end flex-1-1">
          <div class="pa-2">
            <strong>{{ $t('workflows.jobs.created_on') }}</strong>: {{ formatDate(job.created_at) }}
          </div>

          <div class="pa-2">
            <strong>{{ $t('workflows.jobs.updated_on') }}</strong>: {{ formatDate(job.updated_at) }}
          </div>
        </div>
      </div>

      <PageTabs
        v-model="activeTab"
        class="my-4"
        :tabs="tabItems"
      />

      <v-sheet class="tab-items elevation-0 pa-4">
        <template v-if="activeTab === 'history'">
          <v-layout>
            <v-navigation-drawer
              v-model="historyDrawer"
              location="right"
              class="pa-2"
              :width="768"
            >
              <v-btn
                @click="historyDrawer = false"
              >
                {{ $t('workflows.history.close') }}
              </v-btn>

              <div v-if="selectedEntry.logs">
                <p class="my-2">
                  <strong>{{ $t('workflows.history.logs') }}</strong>
                </p>

                <pre
                  v-html="highlight(selectedEntry.logs, 'logs')"
                />
              </div>

              <p class="my-2">
                <strong v-if="!selectedEntry.transition_id && !selectedEntry.step_id">
                  {{ $t('workflows.history.initial_data') }}
                </strong>
                <strong v-else>
                  {{ $t('workflows.history.data') }}
                </strong>
              </p>

              <pre
                v-html="highlight(selectedEntry.data)"
              />
            </v-navigation-drawer>

            <v-main min-height="100vh">
              <v-container
                class="pa-0"
                fluid
              >
                <v-row class="table-row table-row-height">
                  <v-col align-self="end">
                    <v-switch
                      v-model="showDetailedHistory"
                      color="primary"
                      :label="$t('workflows.show_detailed_history')"
                    />
                  </v-col>
                </v-row>
                <v-row class="table-row table-row__header table-row-height">
                  <v-col cols="3">
                    {{ $t('workflows.history.timestamp') }}
                  </v-col>
                  <v-col>
                    {{ $t('workflows.history.description') }}
                  </v-col> 
                  <v-col cols="2">
                    {{ $t('workflows.history.status') }}
                  </v-col>
                </v-row>
              </v-container>

              <div
                v-if="!history || history.length == 0"
                class="table-row fade-in table-row-height"
                style="text-align: center; padding-top: 15px;"
              >
                <i> {{ $t('workflows.no_results') }}  </i>
              </div>

              <v-container
                v-else
                class="pa-0"
                fluid
              >
                <v-row
                  v-for="entry in history"
                  :key="entry.id"
                  class="table-row table-row-height hover clickable"
                  :class="{
                    selected: historyDrawer && selectedEntry && selectedEntry.id == entry.id,
                    hidden: !showDetailedHistory && ['skipped', 'followed'].includes(entry.status)
                  }"
                  @click="selectedEntry = entry; historyDrawer = true"
                >
                  <v-col
                    cols="3"
                    class="h-100"
                  >
                    {{ formatDate(entry.created_at) }}
                  </v-col>
                  <v-col
                    class="ga-1"
                  >
                    <span v-if="entry.parent_id">
                      —
                    </span>

                    <span v-if="entry.transition_id">
                      {{ $t('workflows.history.desc_transition') }}
                    </span>

                    <span v-if="entry.step_id">
                      <span v-if="entry.step.action_type == 'state'">
                        {{ $t('workflows.history.desc_state') }}

                        <em>
                          {{ entry.step.name || entry.step.action }}
                        </em>
                      </span>

                      <span v-else>
                        {{ $t('workflows.history.desc_step') }}

                        <em v-if="entry.step.name">
                          {{ entry.step.name }}
                        </em>
                        <em v-else-if="entry.step.action_type == 'python'">
                          {{ $t('workflows.steps.code') }}
                        </em>
                        <em v-else>
                          {{ $t('workflows.steps.' + entry.step.action) }}
                        </em>
                      </span>

                      <span v-if="entry.index !== null">
                        [ {{ entry.index }} ]
                      </span>
                    </span>

                    <span v-else>
                      {{ $t('workflows.history.desc_creation') }}
                    </span>
                  </v-col>
                  <v-col cols="2">
                    <v-chip
                      size="small"
                      variant="flat"
                      :color="getEntryStatusColor(entry)"
                    >
                      {{ entry.status }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-container>
            </v-main>
          </v-layout>
        </template>

        <template v-if="activeTab === 'data'">
          <v-switch
            v-model="showInitialData"
            class="float-right"
            color="primary"
            :label="$t('workflows.show_initial_data')"
          />

          <pre
            v-if="showInitialData"
            v-html="highlight(job.preliminary_data)"
          />

          <pre
            v-else
            v-html="highlight(job.data)"
          />

          <div style="clear: both;" />
        </template>

        <template v-if="activeTab === 'files'">
          <v-container
            class="pa-0 table-row-height"
            fluid
          >
            <v-row class="table-row table-row__header table-row-height">
              <v-col cols="2">
                {{ $t('workflows.files.timestamp') }}
              </v-col>
              <v-col cols="2">
                {{ $t('workflows.files.collection') }}
              </v-col>
              <v-col>
                {{ $t('workflows.files.name') }}
              </v-col>
              <v-col cols="1">
                {{ $t('workflows.files.is_initial') }}
              </v-col>
              <v-col cols="1" />
            </v-row>
          </v-container>

          <div
            v-if="!files || files.length == 0"
            class="table-row fade-in table-row-height"
            style="text-align: center; padding-top: 15px;"
          >
            <i> {{ $t('workflows.no_results') }}  </i>
          </div>

          <v-container
            v-else
            class="pa-0"
            fluid
          >
            <v-row
              v-for="file in files"
              :key="file.id"
              class="table-row table-row-height hover"
            >
              <v-col cols="2">
                {{ formatDate(file.created_at) }}
              </v-col>
              <v-col cols="2">
                {{ file.collection }}
              </v-col>
              <v-col>
                {{ file.name }}
              </v-col>
              <v-col cols="1">
                <span v-if="file.is_preliminary">
                  {{ $t('workflows.files.yes') }}
                </span>
              </v-col>
              <v-col cols="1">
                <v-btn
                  prepend-icon="fas fa-save"
                  @click="downloadJobFile(job, file)"
                >
                  {{ $t('workflows.download') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import hljs from 'highlight.js';

import format_mixin from '@/mixins/format.js';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import PageTabs from '@/components/common/elements/General/PageTabs';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';

import '@/assets/atom-one-dark.css';

hljs.registerLanguage('logs', () => {
  const TIMESTAMP = {
    className: 'comment', begin: '\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}.\\d{1,}'
  };

  const ERROR = {
    className: 'strong', begin: '[\\w]*Error.*'
  };

  const STDOUT = {
    className: 'number', begin: 'stdout>'
  };

  const STDERR = {
    className: 'literal', begin: 'stderr>'
  };

  return {
    name: 'logs',
    case_insensitive: false,
    contains: [
      TIMESTAMP,
      ERROR,
      STDOUT,
      STDERR
    ]
  };
});

export default {
  name: 'JobHistory',
  mixins: [format_mixin],

  components: {
    BackLink,
    PageTabs,
  },
  
  data() {
    let activeTab = 'history';

    if (this.$route.query.activeTab) {
      activeTab = this.$route.query.activeTab;
    }

    return {
      loading: false,

      live: false,

      job: null,
      history: [],
      files: [],
      workflow: null,
      currentStep: null,

      showInitialData: false,
      showDetailedHistory: false,

      historyDrawer: false,
      selectedEntry: {},

      activeTab: activeTab,
      tabItems: [
        { name: 'history', title: this.$t('workflows.jobs.history')},
        { name: 'data', title: this.$t('workflows.jobs.data') },
        { name: 'files', title: this.$t('workflows.jobs.files') },
      ],
    };
  },

  computed: {
    isRunning() {
      return (
        this.currentStep
          && !this.currentStep.final
          && this.job
          && ['done', 'error'].indexOf(this.job.state) === -1
      );
    },
  },

  async mounted() {
    try {
      await this.fetch(this.$route.params.id);

      if (this.history && this.history.length) {
        this.selectedEntry = this.history[this.history.length - 1];
        this.historyDrawer = true;
      }

      if (this.isRunning) {
        await this.startLive();
      }
    } catch (error) {
      error.handleGlobally && error.handleGlobally();
    }
  },

  async unmounted() {
    await this.stopLive();
  },

  methods: {
    async startLive() {
      try {
        await this.fetch(this.job.id);

        if (this.isRunning) {
          this.live = setTimeout(this.startLive, 5000);
        } else {
          this.live = null;
        }
      } catch (error) {
        this.live = null;
      }
    },

    async stopLive() {
      if (this.live) {
        clearTimeout(this.live);
      }

      this.live = null;
    },

    async fetch(id) {
      this.loading = true;

      try {
        await Promise.all([
          this.getJob(id),
          this.getJobHistory(id),
          this.getJobFiles(id)
        ]);

        await this.getWorkflowDefinition();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }

      this.loading = false;
    },

    async getJob(id) {
      this.job = await WorkflowAPI.getJob(id);
    },

    async getJobHistory(id) {
      this.history = await WorkflowAPI.getJobHistory(id);
    },

    async getJobFiles(id) {
      this.files = await WorkflowAPI.getJobFiles(id);
    },

    async getWorkflowDefinition() {
      const [workflow, step] = await Promise.all([
        WorkflowAPI.getWorkflow(this.job.workflow_id),
        WorkflowAPI.getWorkflowStep(this.job.workflow_id, this.job.step_id)
      ]);

      this.workflow = workflow;
      this.currentStep = step;
    },

    async downloadJobFile(job, file) {
      try {
        const data = await WorkflowAPI.downloadJobFile(job.id, file.id)

        const blob = new Blob([data], {
          type: file.content_type
        });

        const url = window.URL.createObjectURL(blob);
        const el = document.createElement('a');

        el.href = url;
        el.setAttribute('download', file.name);

        document.body.appendChild(el);

        el.click();

        document.body.removeChild(el);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    highlight(data, language) {
      if (typeof language === 'undefined') {
        language = 'json';
      }

      if (language == 'json') {
        try {
          data = JSON.stringify(data || null, undefined, 4);
        } catch (exc) {
          data = '// Invalid JSON data';
        }
      } else if (!data) {
        if (language == 'logs') {
          data = '// No logs';
        } else {
          data = '// No data';
        }

        language = 'json';
      }

      return hljs.highlight(data, {language: language || 'json'}).value;
    },

    getJobStateColor(job) {
      const map = {
        done: 'green',
        error: 'red',
      };

      return map[job.state] || 'primary';
    },

    getEntryStatusColor(entry) {
      const map = {
        completed: 'green',
        error: 'red',
        followed: 'green',
        waiting: 'primary',
        running: 'primary',
      };

      return map[entry.status];
    }
  },

  props: {
    showBackLink: {
      type: Boolean,
      required: false,
      default: true
    }
  },
}
</script>

<style lang="scss" scoped>
pre {
  overflow-y: scroll;
  padding: 0.5em;
  padding-left: 1em;
}

.table-row {
  &.hover:hover {
    background-color: rgba(var(--v-theme-primary-lighten2), 0.25);
  }

  &.selected {
    background-color: rgba(var(--v-theme-primary-lighten2), 0.1);
  }

  &.hidden {
    display: none;
  }
}

.tab-items {
  min-height: 50vh;
}
</style>
