<template>
  <v-card
    class="right-gap bottom-gap text-center inline-middle"
  >
    <div class="resource-count-card">
      <div class="count-container">
        <h2
          v-if="count !== -1"
          class="text-h2"
        >
          {{ count }}
        </h2>
        <v-skeleton-loader
          v-else
          class="inline-middle"
          type="heading"
          width="100px"
        />
      </div>
      <div>
        {{ $tc(`home.${name}`, count) }}
      </div>
      <div class="button-container">
        <v-btn
          v-if="count !== -1"
          color="primary"
          variant="outlined"
          @click="onButtonClick"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            {{ buttonIcon }}
          </v-icon>
          {{ buttonLabel }}
        </v-btn>
        <v-skeleton-loader
          v-else
          class="inline-middle"
          type="heading"
          width="170px"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ResourceCountCard',

  methods: {
    onButtonClick() {
      if (this.count === 0) {
        this.$router.push(this.createPath);
      } else {
        this.$router.push(this.configurePath);
      }
    },
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    count: {
      type: Number,
      required: true,
    },

    configurePath: {
      type: String,
      required: true,
    },

    createPath: {
      type: String,
      required: true,
    },

    buttonLabel: {
      type: String,
      required: true,
    },

    buttonIcon: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="scss" scoped>

.resource-count-card {
  width: 300px;
  height: 185px;
  padding-top: 35px !important;

  .count-container {
    height: 41px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

}

</style>
