import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/store/';

export class StoreAPI {
  static getStores() {
    return http.get(BASE_PATH)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  }
}
