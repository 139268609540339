<template>
  <v-dialog
    v-model="openDialog"
    max-width="400"
    @click:outside="$emit('update:model-value', false)"
    @keydown.esc="$emit('update:model-value', false)"
  >
    <FavoriteFolders
      type="dialog"
      :doc-id="docId"
      @close="$emit('update:model-value', false)"
    />
  </v-dialog>
</template>

<script>
import FavoriteFolders from '@/components/search/elements/Home/FavoriteFolders';

export default {
  name: 'FavoriteDialog',

  components: {
    FavoriteFolders,
  },

  data() {
    return {
      openDialog: this.modelValue,
    };
  },

  watch: {
    openDialog(open) {
      this.$emit('update:model-value', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    docId: {
      type: Number,
      required: true,
    },
  },

  emits: ['update:model-value'],

}
</script>

<style lang="scss" scoped>
.dialog-title {
  color: rgb(var(--v-theme-primary)) !important;
  font-weight: 500 !important;
  font-family: 'Telegraf' !important;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
