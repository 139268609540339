<template>
  <div class="emails-dataset-editor page-padding py-7">
    <BackLink
      :text="$t('models.all_datasets')"
      @click="handleBack"
    />
    <div class="emails-dataset-editor__actions top-gap">
      <v-text-field
        v-model="dataset.name"
        class="dataset-name-filter"
        variant="outlined"
        color="primary"
        density="compact"
        :hint="nameValid ? '' : `${$t('forms.name_hint')} ${$t('forms.unique_hint')}`"
        :placeholder="$t('models.type_new_dataset_name')"
        @keydown.enter="saveEmailDataset"
      />
      <v-btn
        color="primary"
        class="left-gap mt-1"
        :disabled="!nameValid"
        @click="saveEmailDataset"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>
    <FileUploader
      :disabled="!nameValid"
      :initials-files="files"
      :supported-extensions="$options.constants.SUPPORTED_EXTENSIONS"
      @files-selected="handleFilesSelect"
    />
  </div>
</template>
<script>

import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI.js';
import FileUploader from '@/components/common/elements/Forms/FileUploader';
import { uploadEmails, SUPPORTED_EXTENSIONS } from './EmailUploadsHelper';
import { displaySnackbarSuccess } from '@/utils/SnackbarUtils';
import BackLink from '@/components/common/elements/Navigation/BackLink';
import { isNameValid } from '@/utils/FormValidation';

export default {
  name: 'EmailsDatasetEditor',

  components: {
    FileUploader,
    BackLink,
  },

  constants: {
    DATASET_NAME_MIN_LENGTH: 5,
    DATASET_NAME_MAX_LENGTH: 64,
    SUPPORTED_EXTENSIONS
  },

  data() {
    return {
      errorMessage: '',
      dataset: {
        name: '',
      },
      files: [],
    };
  },

  computed: {
    nameValid() {
      return isNameValid(this.dataset.name);
    },
  },

  methods: {
    handleBack() {
      this.$router.push({ name: 'EmailsDatasetsView' });
    },

    async saveEmailDataset() {
      if (!this.nameValid) {
        return;
      }
      if (!this.dataset.id) {
        try {
          const response = await EmailsDatasetsAPI.create(this.dataset);
          this.dataset.id = response.data;
          displaySnackbarSuccess(this.$t('emailsDatasets.save_dataset_success'));
        } catch (error) {
          return
        }
      }

      if (this.files) {
        this.uploadEmails();
      }
    },

    async fetchDataset() {
      const response = await EmailsDatasetsAPI.get(this.dataset.id);
      this.dataset = response.data;
    },

    async handleFilesSelect(files) {
      this.files = files;
      await this.saveEmailDataset();
    },

    async uploadEmails() {
      try {
        await uploadEmails(this.dataset.id, this.files);
        this.$router.push({ name: 'EmailsDatasetView', params: { datasetId: this.dataset.id } });
      } catch (error) {
        this.files = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .emails-dataset-editor__actions {
  display: flex;
  align-items: start;

  .dataset-name-filter {
    max-width: 390px;
  }
}

</style>