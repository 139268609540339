import { AuthenticationUtils } from '@/utils/AuthenticationUtils';
import { http } from '@/plugins/axios';
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';

export default {
  methods: {
    async getDocType() {
      try {
        const response = await http.get(
          `system_2/document_type/${this.$route.params.id}/`,
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        const type = response.data;
        this.$store.commit('setDocumentType', type);
      } catch (error) {
        return
      }
    },

    async getDocTypeImage(id) {
      try {
        const response = await http.get(
          `image/thumbnail/document_type/${id}/?token=${AuthenticationUtils.getToken()}`,
          {
            responseType: 'arraybuffer',
            globalErrorHandlingOptOut: true,
          }
        );
        return URL.createObjectURL(new Blob([response.data]));
      } catch (error) {
        return '';
      }
    },

    setLibrary(docTypes, total) {
      this.$store.commit('setLibrary', []);
      this.$store.commit('setLibrary', docTypes);
      this.$store.commit('setLibrarySize', total);
      this.loaded = true;
    },

    async getRecitalDoctypes() {
      const limit = this.$store.getters.itemsPerPage.recitalDoctypes;
      const offset = (this.$store.getters.currentPage.recitalDoctypes - 1) * limit;
      const total = this.$store.getters.recitalLibrarySize;
      if (offset && offset >= total) {
        return;
      }
      try {
        const response = await DocTypeAPI.get(
          limit, offset, null, null, true, true, true,
        );
        let recitalLibrary = response.data;
        const total = parseInt(response.headers['x-total-count'], 10);
        if (offset > 0) {
          recitalLibrary = [...this.$store.getters.recitalLibrary, ...recitalLibrary];
        }
        this.$store.commit('setRecitalLibrary', []);
        this.$store.commit('setRecitalLibrary', recitalLibrary);
        this.$store.commit('setRecitalLibrarySize', total);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getLibrary(asTemplates = false, sortDesc = true) {
      this.thumbLoading = true;
      const key = asTemplates ? 'newDocType' : 'docTypes';
      const limit = this.$store.getters.itemsPerPage[key];
      const offset = (this.$store.getters.currentPage[key] - 1) * limit;
      const total = this.$store.getters.librarySize;
      if (offset && offset >= total) {
        return;
      }
      try {
        const nameFilter = this.trimmedFilter || '';
        const response = await DocTypeAPI.get(
          limit,
          offset,
          nameFilter,
          null,
          asTemplates,
          asTemplates,
          null,
          sortDesc,
        );
        let docTypes = response.data;
        const total = parseInt(response.headers['x-total-count'], 10);
        if (asTemplates) {
          docTypes = [...this.$store.getters.library, ...docTypes];
        }
        this.setLibrary(docTypes, total);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.thumbLoading = false;
      }
    },

    async getLibraryThumbs() {
      const docTypes = this.$store.getters.library;
      const total = this.$store.getters.librarySize;
      this.thumbLoading = true;
      try {
        for (let i = 0; i < total; i++) {
          const docType = docTypes[i];
          if (docType) {
            docType.imageUrl = await this.getDocTypeImage(docTypes[i].id);
          }
        }
        this.setLibrary(docTypes, total);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.thumbLoading = false;
      }
    },

    async getDocTypes(asTemplates = false) {
      await this.getLibrary(asTemplates, this.sortDesc);
      if (!asTemplates) await this.getLibraryThumbs();
    },
  },
};