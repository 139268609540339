/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'config/';

export class ConfigAPI {
  static async get() {
    return http
      .get(BASE_PATH)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async update(config) {
    return http
      .put(BASE_PATH, config)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
