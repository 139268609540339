<template>
  <div class="add-subgroup-button">
    <v-tooltip
      color="#423f4f"
      right
    >
      <template #activator="{ props }">
        <div
          class="clickable add-button"
          :style="{
            opacity: isDisabled ? 0.5 : 1,
          }"
          v-bind="props"
          @click="handleClick"
        >
          <v-icon
            class="add-icon"
            size="16"
          >
            fas fa-plus
          </v-icon>
        </div>
      </template>
      <span style="color: #fafafa">
        {{ $t("dataPoints.add_missing_subgroup_tooltip") }}
      </span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: 'AddSubgroupButton',

  computed: {
    isDisabled() {
      return this.addingGroup === this.group.group_id;
    },
  },

  methods: {
    handleClick() {
      if (!this.isDisabled) {
        this.$emit('addSubgroup', this.group.group_id, this.group.subgroups.length);
      }
    },
  },

  props: {
    addingGroup: {
      type: Number,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },

  emits: ['addSubgroup'],
}
</script>

<style lang="scss" scoped>
.add-subgroup-button {
  position: absolute;
  right: 20px;
  top: 11px;

  .add-button {
    border: 1px solid rgb(var(--v-theme-primary)) !important;
    color: rgb(var(--v-theme-primary)) !important;
    border-radius: 50% !important;
    box-shadow: none !important;
    width: 35px !important;
    height: 35px !important;
    text-align: center !important;

    .add-icon {
      position: relative;
      top: 4px;
    }
  }
}
</style>
