<template>
  <v-card
    class="d-flex align-center justify-space-between dp-card group-header-card"
    :class="{
      'top-gap': !asFooter,
      'header-style': !asFooter,
      'footer-style': asFooter,
    }"
    tile
  >
    <div
      class="ellipsis text-body-2 text-uppercase"
      style="max-width: calc(50%); margin-left: -17px; font-weight: 800; color: rgb(var(--v-theme-grey))"
    >
      <span v-if="!asFooter">
        {{ group.group_name }}
      </span>
    </div>
    <div class="bottom-gap-sm top-gap-sm mr-6">
      <div
        v-if="showVerify"
        style="position: absolute; top: 11px; right: 54px"
      >
        <VerifyButton
          :tooltip-message="allSubgroupsCorrect && !disableVerify ? $t('verification.group_verified') : $t('corrections.verify_group')"
          :correct="allSubgroupsCorrect && !loading && !disableVerify"
          :loading="inVerification || loading"
          :disabled="inVerification || loading || disableVerify"
          :show-tooltip="!disableVerify"
          @verify-click="$emit('verifyAllClick')"
        />
      </div>
      <div
        style="margin-right: -35px;"
        :style="{
          'margin-top': showVerify ? '-35px' : '0',
        }"
      >
        <AddSubgroupButton
          :adding-group="addingGroup"
          :group="group"
          @add-subgroup="(group_id, groups_nb) => $emit('addSubgroup', group_id, groups_nb)"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import VerifyButton from "@/components/extract/elements/Validation/VerifyButton";
import AddSubgroupButton from "@/components/extract/elements/Validation/AddSubgroupButton";

export default {
  name: 'GroupHeader',

  components: {
    VerifyButton,
    AddSubgroupButton,
  },

  props: {
    group: {
      type: Object,
      required: true,
    },

    allSubgroupsCorrect: {
      type: Boolean,
      default: false,
    },

    addingGroup: {
      type: Number,
      required: true,
    },

    inVerification: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disableVerify: {
      type: Boolean,
      default: false,
    },

    showVerify: {
      type: Boolean,
      default: true,
    },

    asFooter: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['addSubgroup', 'addEmptySubgroup', 'verifyAllClick'],
}
</script>

<style lang="scss" scoped>
.dp-card {
  border: 1px solid #aaa;
  border-radius: 0 !important;
}

.group-header-card {
  height: 50px;
  box-shadow: none;
}

.header-style {
  border-bottom: none !important;
  background-color: #e5e5e5 !important;
}

.footer-style {
  border-top: none !important;
}
</style>
