<template>
  <div>
    <div
      class="inline-middle"
      style="width: 65%"
    >
      <small>
        {{ left.title }}
      </small>
      <p class="font-weight-bold">
        {{ left.value }}
      </p>
    </div>
    <div
      v-if="right"
      class="inline-middle"
      style="width: 35%"
    >
      <small>
        {{ right.title }}
      </small>
      <p class="font-weight-bold">
        {{ right.value }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TwoColumnDetails',

  props: {
    left: {
      type: Object,
      required: true,
    },
    right: {
      type: Object,
      required: true,
    }
  },
};
</script>
