<template>
  <div class="single-value-highlights">
    <div v-if="dataPoint && hasLocationData">
      <v-tooltip
        :value="isHighlighted"
        bottom
      >
        <template #activator="{ props }">
          <div
            :ref="`value${dataPoint.data_point_id}`"
            class="highlight-frame"
            :class="{ 'highlight-frame__active': isHighlighted }"
            :style="{
              left: `${getHighlightX(dataPoint)}vh`,
              top: `${getHighlightY(dataPoint)}vh`,
              width: `${getWidth(dataPoint)}vh`,
              height: `${getHeight(dataPoint)}vh`,
            }"
            v-bind="props"
            @mouseover="innerHighlight = true"
            @mouseout="innerHighlight = false"
          />
        </template>
        <div class="d-flex flex-column align-center">
          <div class="highlight-value ellipsis">
            <div
              v-for="line in getValue(dataPoint).split('\n')"
              :key="line"
            >
              {{ line }}
            </div>
          </div>
          <div>
            ({{ dataPoint.data_point_name }})
          </div>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleValueHighlights",

  data() {
    return ({
      innerHighlight: false,
    })
  },

  computed: {
    hasLocationData() {
      const dp = this.dataPoint;
      return (
        dp &&
        dp.value && (
          (dp.value.valid_location && Object.prototype.hasOwnProperty.call(dp.value.valid_location , 'x_max') && this.area(dp.value.valid_location) > 0) ||
          (dp.value.location && Object.prototype.hasOwnProperty.call(dp.value.location , 'x_max') && this.area(dp.value.location) > 0)
        )
      );
    },

    isHighlighted() {
      if (this.innerHighlight) {
        return true;
      }
      if (this.highlighted && Object.keys(this.highlighted).length > 0) {
        if (this.highlighted.highlighted === false) {
          return false;
        }
        return this.highlighted.data_point_id === this.dataPoint.data_point_id;
      }
      return false;
    },
  },

  methods: {
    area(location) {
      return (location.x_max - location.x_min) * (location.y_max - location.y_min);
    },

    getHighlightX(dp) {
      if (dp.value && dp.value.status === 'invalid') {
        return dp.value.valid_location.x_min * this.pageWidth;
      }
      if (dp.value.location) {
        return dp.value.location.x_min * this.pageWidth;
      }
    },

    getHighlightY(dp) {
      if (dp.value && dp.value.status === 'invalid') {
        return dp.value.valid_location.y_min * this.pageHeight;
      }
      if (dp.value.location) {
        return dp.value.location.y_min * this.pageHeight;
      }
    },

    getWidth(dp) {
      if (dp.value && dp.value.status === 'invalid') {
        return (dp.value.valid_location.x_max - dp.value.valid_location.x_min) * this.pageWidth;
      }
      return (dp.value.location.x_max - dp.value.location.x_min) * this.pageWidth;
    },

    getHeight(dp) {
      if (dp.value && dp.value.status === 'invalid') {
        return (dp.value.valid_location.y_max - dp.value.valid_location.y_min) * this.pageHeight;
      }
      return (dp.value.location.y_max - dp.value.location.y_min) * this.pageHeight;
    },

    getValue(dp) {
      if (dp.value.status !== 'invalid') {
        if (dp.value.value) {
            return dp.value.value;
          }
          return '';
      }
      return dp.value.valid_value;
    },
  },

  props: {
    dataPoint: {
      type: Object,
      required: true,
    },
    highlighted: {
      type: Object,
      default: () => {},
    },
    verified: {
      type: Array,
      default: () => [],
    },
    zoom: {
      type: Number,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
    originalWidth: {
      type: Number,
      required: true,
    },
    originalHeight: {
      type: Number,
      required: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.highlight {
  opacity: 0.4;
  position: absolute;
  cursor: pointer;
}

.highlight-frame {
  position: absolute;
  cursor: pointer;
  outline: 3px solid $pending-base;
  border-radius: 2px;
  &__active {
    background-color: $pending-transparent;
  }
}
</style>
