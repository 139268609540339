<template>
  <div class="search-page d-flex">
    <SearchFilters
      v-if="$store.getters.searchShowFilters"
      @change="startSearch"
    />
    <SearchResultsColumn
      :loading="searchLoading"
      :llm-active="llmActive"
      @search="startSearch"
    />
    <SearchResultImageViewer />
  </div>
</template>

<script>
import { SearchAPI } from '@/API/search/SearchAPI';
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';

import SearchFilters from "@/components/search/views/Home/SearchFilters";
import SearchResultsColumn from "@/components/search/views/Home/SearchResultsColumn";
import SearchResultImageViewer from '@/components/search/elements/Home/SearchResultImageViewer';

export default {
  name: 'SearchPage',

  components: {
    SearchFilters,
    SearchResultsColumn,
    SearchResultImageViewer,
  },

  data() {
    return ({
      searchLoading: false,
      llmActive: false,
      isRagRequestInProgress: false,
    });
  },

  created() {
    this.getLLMMode();
  },

  methods: {
    async getLLMMode() {
      this.searchLoading = true;
      try {
        const config = await SearchConfigAPI.get();
        this.llmActive = config.llm_active;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.searchLoading = false;
      }
    },

    async streamResponse(searchString) {
      if (this.isRagRequestInProgress) {
        return;
      }

      this.isRagRequestInProgress = true;
      this.$store.commit('setSearchAnswer', '');
      await SearchAPI.rag(
        searchString,
        this.$store.getters.searchResults.chunks,
        this.$store.getters.searchResults.query_id,
        (data) => {
          this.$store.commit('setSearchAnswer',
            this.$store.getters.searchAnswer + data,
          );
        },
        (errorMessage) => {
          this.$store.commit('setSearchAnswer', errorMessage);
        }
      );
      this.isRagRequestInProgress = false;
    },

    async startSearch() {
      let searchString = this.$store.getters.searchString.trim();
      if (!searchString) {
        return;
      }
      if (this.searchLoading) {
        return;
      }
      this.$store.commit('setStartedSearch', true);
      this.searchLoading = true;
      try {
        const results = await SearchAPI.search(
          searchString,
          this.$store.getters.searchFolder,
          this.$store.getters.searchFilters,
        );
        results.displayChunks = results.chunks.filter((chunk, index, self) =>
          index === self.findIndex((c) => (
            c.meta.file_id === chunk.meta.file_id &&
            c.meta.page === chunk.meta.page
          ))
        );
        this.$store.commit('setSearchResults', results);
        if (this.llmActive && results.chunks.length > 0) {
          this.streamResponse(searchString);
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.searchLoading = false;
      }
    },
  },
}
</script>
