<template>
  <DropDown
    icon="fas fa-highlighter"
    :label="$t('models.annotate')"
    :items="createItems"
    :disabled="numberOfValidFiles === 0"
  />
</template>

<script>

import DropDown from '@/components/common/elements/General/DropDown.vue';

export default {
  name: 'AnnotateButton',
  components: {
    DropDown,
  },

  computed: {
    createItems() {
      return [
        {
          label: this.$t('models.manual_annotation'),
          icon: 'fas fa-highlighter',
          action: this.annotate,
          disabled: false,
        },
        {
          label: this.$t('models.preannotate'),
          icon: 'fas fa-magic',
          action: this.preAnnotate,
          disabled: false,
        },
        {
          label: this.$t('models.remove_preannotation'),
          icon: 'fa fa-eraser',
          action: this.removePreAnnotation,
          disabled: !this.isPreAnnotated,
        },
      ];
    },
  },

  methods: {
    preAnnotate() {
      this.$emit('preAnnotateClick');
    },

    annotate() {
      this.$emit('annotateClick');
    },

    removePreAnnotation() {
      if (this.isPreAnnotated) {
        this.$emit('removePreAnnotationClick');
      }
    },
  },

  props: {
    isPreAnnotated: {
      type: Boolean,
      default: false,
    },

    numberOfValidFiles: {
      type: Number,
      default: 0,
    },
  },

  emits: ['preAnnotateClick', 'annotateClick', 'removePreAnnotationClick'],
}
</script>

<style lang="scss" scoped>
</style>
