<template>
  <div>
    <v-tooltip
      v-for="(entity, i) in entities"
      :key="i"
      top
    >
      <template
        v-if="!!entity.coordinates && !isExtracted(entity)"
        #activator="{ props }"
      >
        <div
          class="entity"
          :style="{
            left: `${getHighlightX(entity.coordinates.x_min)}vh`,
            top: `${getHighlightY(entity.coordinates.y_min)}vh`,
            width: `${getHighlightX(
              entity.coordinates.x_max - entity.coordinates.x_min
            )}vh`,
            height: `${getHighlightY(
              entity.coordinates.y_max - entity.coordinates.y_min
            )}vh`,
          }"
          v-bind="props"
        />
      </template>
      [{{ entity.model }}] {{ entity.name }}
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: 'EntitiesHighlights',

  methods: {
    getHighlightX(x) {
      return x * this.pageWidth;
    },
    getHighlightY(y) {
      return y * this.pageHeight;
    },
    isExtracted(entity) {
      return this.entityCoordinates.some(
        (item) =>
          item.x_min <= entity.coordinates.x_min &&
          item.x_max >= entity.coordinates.x_max &&
          item.y_min <= entity.coordinates.y_min &&
          item.y_max >= entity.coordinates.y_max
      );
    },
  },

  props: {
    entities: {
      type: Array,
      required: true,
    },
    entityCoordinates: {
      type: Array,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.entity {
  position: absolute;
  outline: rgb(var(--v-theme-warning)) solid 3px;
  background-color: rgb(var(--v-theme-warning))44;
}
</style>
