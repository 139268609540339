<template>
  <div class="doc-text">
    <div class="doc-text-box">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DocText",

  props: {
    text: {
      type: String,
      default: '',
    },
  }
}
</script>

<style lang="scss" scoped>
.doc-text {
  position: absolute !important;
  bottom: 0px;
  top: 50px;
  height: fit-content;
  overflow-y: auto;

  .doc-text-box {
    height: fit-content;
    background-color: white;
    padding: 50px;
  }
}
</style>
