<template>
  <div class="chevron-button">
    <v-icon
      v-if="modelValue"
      class="clickable filter-chevron"
      :size="size"
      @click="$emit('update:modelValue', false)"
    >
      fas fa-chevron-down
    </v-icon>
    <v-icon
      v-else
      class="clickable filter-chevron"
      :size="size"
      @click="$emit('update:modelValue', true)"
    >
      fas fa-chevron-right
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'ChevronButton',

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    size: {
      type: Number,
      default: 20,
    },
  },

  emits: ['update:modelValue'],
}
</script>
