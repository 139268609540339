<template>
  <div class="search-other-settings">
    <div
      v-for="(option, i) in ['s3_fallback']"
      :key="i"
      class="d-flex align-center"
    >
      <v-switch
        v-model="config[option]"
        class="flex-grow-0"
        color="primary"
        :disabled="option === 'use_google_ocr' || loading || !editingAllowed"
        @update:model-value="$emit('update')"
        hide-details
        inset
      />
      <div class="left-gap">
        <h4>
          {{ $t(`settings.search.${option}.title`) }} {{ option === 'use_google_ocr' && `(${$t('coming_soon').toLowerCase()})` || '' }}
        </h4>
        <div style="font-size: 0.8rem">
          {{ $t(`settings.search.${option}.message`) }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'SearchOtherSettings',

  props: {
    config: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    editingAllowed: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['update'],
}
</script>
