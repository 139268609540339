<template>
  <div class="block-highlights">
    <div
      v-for="(block, i) in blocks"
      :key="i"
    >
      <div
        class="highlight-frame"
        :style="{
          'border-color': 'red',
          left: `${getHighlightX(block.x_min)}vh`,
          top: `${getHighlightY(block.y_min)}vh`,
          width: `${getHighlightX(block.x_max - block.x_min)}vh`,
          height: `${getHighlightY(block.y_max - block.y_min)}vh`,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockHighlights",

  methods: {
    getHighlightX(x) {
      return x * this.pageWidth;
    },
    getHighlightY(y) {
      return y * this.pageHeight;
    },
  },

  props: {
    blocks: {
      type: Array,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .highlight-frame {
  position: absolute;
  border-width: 3px;
  border-style: solid;
  cursor: pointer;
  opacity: 0.4;
  }
</style>