export const getEmailAuthor = (email) => {
  return email.author.name || email.author.smtp_address;
};

export const getEmailReceivers = (email) => {
  const emailsReceivers = [];
  email.to.forEach((receiver) => {
    emailsReceivers.push(receiver.name || receiver.smtp_address);
  });

  return emailsReceivers.join(', ');
}

