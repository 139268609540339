<template>
  <div class="extraction-path">
    <WhatConfig
      :what="path.what"
      :start-type="what"
      @activate="section => $emit(`activate${section}`)"
    />
    <WhereConfig
      :where="path.where"
      @location-change="(l) => locationOn = l"
    />
    <PageLocationConfig
      v-if="locationOn"
      :coordinates="path.where.page_location"
      @update-coordinates="saveCoordinates"
    />
    <div class="dp-divider" />
    <div class="section">
      <div
        v-if="!rankOn"
        class="inline-middle radio-box right-gap-sm"
      >
        <v-checkbox
          v-model="rankOn"
          style="margin-top: -7px"
          color="primary"
          :disabled="what === 'any'"
          :style="{opacity: what === 'any' ? 0.5 : 1}"
          :label="$t('dataPoints.rank')"
          @update:model-value="handleOtherWhere"
        />
      </div>
    </div>
    <div
      v-if="what !== 'any' && rankOn"
      class="inline-middle radio-box right-gap-sm"
    >
      <v-checkbox
        v-model="rankOn"
        style="margin-top: -7px"
        color="primary"
        :label="$t('dataPoints.rank')"
      />
    </div>
    <RankConfig
      v-if="what !== 'any' && rankOn"
      :rank="rank"
      :from-start="fromStart"
      @rank-change="changeRank"
      @start-change="changeStart"
      @activate-default="$emit('activateDefault')"
    />
    <div
      v-if="tablesOn"
      class="inline-middle radio-box right-gap-sm section"
    >
      <v-checkbox
        v-model="tablesOn"
        style="margin-top: -7px"
        color="primary"
        :label="$t('dataPoints.tables')"
      />
    </div>
  </div>
</template>

<script>
import WhatConfig from '@/components/extract/elements/DataPoints/WhatConfig.vue';
import WhereConfig from '@/components/extract/elements/DataPoints/WhereConfig.vue';
import PageLocationConfig from '@/components/extract/elements/DataPoints/PageLocationConfig.vue';
import RankConfig from '@/components/extract/elements/DataPoints/RankConfig.vue';

export default {
  name: 'ExtractionPath',

  components: {
    WhatConfig,
    WhereConfig,
    PageLocationConfig,
    RankConfig,
  },

  data() {
    return ({
      locationOn: false,
      nearbyOn: false,
      formOn: false,
      layoutOn: false,
      rankOn: false,
      tablesOn: false,
    })
  },

  computed: {
    validPath() {
      return this.isValidPath();
    },

    rowNames() {
      if (this.path.where.table && this.path.where.table.row_names) {
        return this.path.where.table.row_names
      }
      return []
    },

    colNames() {
      if (this.path.where.table && this.path.where.table.col_names) {
        return this.path.where.table.col_names
      }
      return []
    },

    rowNum() {
      if (this.path.where.table && this.path.where.table.row_num) {
        return this.path.where.table.row_num
      }
      return []
    },

    colNum() {
      if (this.path.where.table && this.path.where.table.col_num) {
        return this.path.where.table.col_num
      }
      return []
    },

    tableExactMatch() {
      if (this.path.where.table) {
        return this.path.where.table.exact_match
      }
      return false
    },

    rank() {
      if (this.path.where.rank && this.path.where.rank.rank) {
        return parseInt(this.path.where.rank.rank, 10)
      }
      return 1;
    },

    fromStart() {
      if (this.path.where.rank) {
        return this.path.where.rank.from_start
      }
      return true
    },

    underTitle() {
      if (this.path.where.layout && this.path.where.layout.under_title) {
        return this.path.where.layout.under_title
      }
      return []
    },

    containsText() {
      if (this.path.where.layout && this.path.where.layout.contains_text) {
        return this.path.where.layout.contains_text
      }
      return []
    },

    removeMatch() {
      if (this.path.where.layout) {
        return this.path.where.layout.remove_match
      }
      return true
    },

    tagsConstraint() {
      if (this.path.where.layout) {
        if (this.path.where.layout.only_largest) {
          return 'only_largest'
        }
        if (this.path.where.layout.only_verbosest) {
          return 'only_verbosest'
        }
      }
      return 'all'
    },

    layoutTags() {
      if (this.path.where.layout) {
        return this.path.where.layout.tag
      }
      return []
    },

    formExactMatch() {
      if (this.path.where.form) {
        return this.path.where.form.exact_match
      }
      return false;
    },

    formKeys() {
      if (this.path.where.form) {
        return this.path.where.form.keys
      }
      return []
    },

    sameLine() {
      if (this.path.where.nearby_text) {
        return this.path.where.nearby_text.same_line
      }
      return []
    },

    sameColumn() {
      if (this.path.where.nearby_text) {
        return this.path.where.nearby_text.same_column
      }
      return []
    },

    around() {
      if (this.path.where.nearby_text) {
        return this.path.where.nearby_text.around
      }
      return []
    },

    anyConstraint() {
      return (this.nearbyOn || this.formOn || this.layoutOn || this.rankOn || this.tablesOn) && !(this.nearbyOn && this.formOn && this.layoutOn && this.rankOn && this.tablesOn);
    },

    what() {
      if (this.path.what.entity) {
        return 'entity';
      } else if (this.path.what.regex && !this.path.what.regex.includes('__any__')) {
        return 'regex';
      }
      return 'any';
    },
  },

  watch: {
    validPath() {
      this.path.invalid = !this.validPath;
      this.$emit('validityChange');
    },

    nearbyOn() {
      if (!this.nearbyOn) {
        this.path.where.nearby_text = null;
      }
    },

    formOn() {
      if (!this.formOn) {
        this.path.where.form = null;
      }
    },

    layoutOn() {
      if (!this.layoutOn) {
        this.path.where.layout = null;
      }
    },

    rankOn() {
      if (!this.rankOn) {
        this.path.where.rank = null;
      }
    },

    tablesOn() {
      if (!this.tablesOn) {
        this.path.where.table = null;
      }
    },
  },

  mounted() {
    this.path.invalid = !this.isValidPath();
    this.$emit('validityChange');
    if (this.path.where.nearby_text) {
      this.nearbyOn = true;
    }
    if (this.path.where.form) {
      this.formOn = true;
    }
    if (this.path.where.layout) {
      this.layoutOn = true;
    }
    if (this.path.where.rank) {
      this.rankOn = true;
    }
    if (this.path.where.table) {
      this.tablesOn = true;
    }
  },

  methods: {
    isValidPath() {
      return !(this.path.what.regex && this.path.what.regex.includes('__any__') && !this.path.where.form && !this.path.where.layout && !this.path.where.table)
    },

    handleOtherWhere(value) {
      if (value) {
        this.$emit('activateDefault');
      }
    },

    handleFormOn(value) {
      if (value) {
        this.$emit('activateForms');
      }
    },

    handleLayoutOn(value) {
      if (value) {
        this.$emit('activateLayout');
      }
    },

    handleTablesOn(value) {
      if (value) {
        this.$emit('activateTables');
      }
    },

    deleteRowName(i) {
      this.deleteItem(this.path.where.table.row_names, i);
      this.path.where = {...this.path.where};
    },

    deleteColName(i) {
      this.deleteItem(this.path.where.table.col_names, i);
      this.path.where = {...this.path.where};
    },

    deleteRow() {
      this.path.where.table.row_num = [];
      this.path.where = {...this.path.where};
    },

    deleteCol() {
      this.path.where.table.col_num = [];
      this.path.where = {...this.path.where};
    },

    addRowName(identifier) {
      if (!this.path.where.table) {
        this.path.where.table = {
          row_names: [],
          col_names: [],
          row_num: [],
          col_num: [],
          exact_match: false,
        };
      }
      if (!this.path.where.table.row_names) {
        this.path.where.table.row_names = [];
      }
      this.path.where.table.row_names.push(identifier);
      this.path.where = {...this.path.where};
    },

    addColName(identifier) {
      if (!this.path.where.table) {
        this.path.where.table = {
          row_names: [],
          col_names: [],
          row_num: [],
          col_num: [],
          exact_match: false,
        };
      }
      if (!this.path.where.table.col_names) {
        this.path.where.table.col_names = [];
      }
      this.path.where.table.col_names.push(identifier);
      this.path.where = {...this.path.where};
    },

    addRow(row) {
      if (!this.path.where.table) {
        this.path.where.table = {
          row_names: [],
          col_names: [],
          row_num: [],
          col_num: [],
          exact_match: false,
        };
      }
      this.path.where.table.row_num = row;
      this.$emit('refresh');
    },

    addCol(col) {
      if (!this.path.where.table) {
        this.path.where.table = {
          row_names: [],
          col_names: [],
          row_num: [],
          col_num: [],
          exact_match: false,
        };
      }
      this.path.where.table.col_num = col;
      this.$emit('refresh');
    },

    changeTableExactMatch(value) {
      if (!this.path.where.table) {
        this.path.where.table = {
          exact_match: false,
        };
      }
      this.path.where.table.exact_match = value;
      this.$emit('refresh');
    },

    changeRank(rank) {
      if (!this.path.where.rank) {
        this.path.where.rank = {
          rank: rank,
          from_start: true,
        };
      }
      this.path.where.rank.rank = rank;
      this.$emit('refresh');
    },

    changeStart(state) {
      if (!this.path.where.rank) {
        this.path.where.rank = {
          rank: 1,
          from_start: state,
        };
      }
      this.path.where.rank.from_start = state;
    },

    addTag(tag) {
      if (!this.path.where.layout) {
        this.path.where.layout = {
          tag: [],
          under_title: [],
          contains_text: [],
          remove_match: true,
          only_largest: false,
          only_verbosest: false,
        };
      }
      if (!this.path.where.layout.tag) {
        this.path.where.layout.tag = [];
      }
      this.path.where.layout.tag.push(tag);
      this.path.where = {...this.path.where};
    },

    addUnderTitle(text) {
      if (!this.path.where.layout) {
        this.path.where.layout = {
          tag: [],
          under_title: [],
          contains_text: [],
          remove_match: true,
          only_largest: false,
          only_verbosest: false,
        };
      }
      if (!this.path.where.layout.under_title) {
        this.path.where.layout.under_title = [];
      }
      this.path.where.layout.under_title.push(text);
      this.path.where = {...this.path.where};
    },

    addContainsText(text) {
      if (!this.path.where.layout) {
        this.path.where.layout = {
          tag: [],
          under_title: [],
          contains_text: [],
          remove_match: true,
          only_largest: false,
          only_verbosest: false,
        };
      }
      if (!this.path.where.layout.contains_text) {
        this.path.where.layout.contains_text = [];
      }
      this.path.where.layout.contains_text.push(text);
      this.path.where = {...this.path.where};
    },

    changeRemoveMatch(value) {
      if (!this.path.where.layout) {
        this.path.where.layout = {
          tag: [],
          under_title: [],
          contains_text: [],
          remove_match: true,
          only_largest: false,
          only_verbosest: false,
        };
      }
      this.path.where.layout.remove_match = value;
      this.path.where = {...this.path.where};
    },

    changeTagsConstraint(value) {
      if (!this.path.where.layout) {
        this.path.where.layout = {
          tag: [],
          under_title: [],
          contains_text: [],
          remove_match: true,
          only_largest: false,
          only_verbosest: false,
        };
      }
      if (value === 'all') {
        this.path.where.layout.only_largest = false;
        this.path.where.layout.only_verbosest = false;
      } else if (value === 'only_verbosest') {
        this.path.where.layout.only_largest = false;
        this.path.where.layout.only_verbosest = true;
      } else if (value === 'only_largest') {
        this.path.where.layout.only_largest = true;
        this.path.where.layout.only_verbosest = false;
      }
      this.path.where = {...this.path.where};
    },

    deleteTag(i) {
      this.deleteItem(this.path.where.layout.tag, i);
      this.path.where = {...this.path.where};
    },

    deleteUnderTitle(i) {
      this.deleteItem(this.path.where.layout.under_title, i);
      this.path.where = {...this.path.where};
    },

    deleteContainsText(i) {
      this.deleteItem(this.path.where.layout.contains_text, i);
      this.path.where = {...this.path.where};
    },

    changeExactMatch(value) {
      if (!this.path.where.form) {
        this.path.where.form = {
          keys: [],
          exact_match: false,
        };
      }
      this.path.where.form.exact_match = value;
      this.$emit('refresh');
    },

    addKey(text) {
      if (!this.path.where.form) {
        this.path.where.form = {
          keys: [],
          exact_match: false,
        };
      }
      this.path.where.form.keys.push(text);
      this.path.where = {...this.path.where};
    },

    deleteKey(i) {
      this.deleteItem(this.path.where.form.keys, i);
      this.path.where.form.keys = [...this.path.where.form.keys];
    },

    addSameLine(text) {
      if (!this.path.where.nearby_text) {
        this.path.where.nearby_text = {
          same_line: [],
          same_column: [],
          around: [],
        };
      }
      this.path.where.nearby_text.same_line.push(text);
      this.path.where = {...this.path.where};
    },

    addSameColumn(text) {
      if (!this.path.where.nearby_text) {
        this.path.where.nearby_text = {
          same_line: [],
          same_column: [],
          around: [],
        };
      }
      this.path.where.nearby_text.same_column.push(text);
      this.path.where = {...this.path.where};
    },

    addAround(text) {
      if (!this.path.where.nearby_text) {
        this.path.where.nearby_text = {
          same_line: [],
          same_column: [],
          around: [],
        };
      }
      this.path.where.nearby_text.around.push(text);
      this.path.where = {...this.path.where};
    },

    deleteSameLine(i) {
      this.deleteItem(this.path.where.nearby_text.same_line, i);
      this.path.where.nearby_text.same_line = [...this.path.where.nearby_text.same_line];
    },

    deleteSameColumn(i) {
      this.deleteItem(this.path.where.nearby_text.same_column, i);
      this.path.where.nearby_text.same_column = [...this.path.where.nearby_text.same_column];
    },

    deleteAround(i) {
      this.deleteItem(this.path.where.nearby_text.around, i);
      this.path.where.nearby_text.around = [...this.path.where.nearby_text.around];
    },

    saveCoordinates(coordinates) {
      this.path.where.page_location = coordinates;
      this.path.where = {...this.path.where};
    },

    deleteItem(array, i) {
      array.splice(i, 1);
      this.$emit('refresh');
    },
  },

  props: {
    path: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'activateDefault',
    'validityChange',
    'activateForms',
    'activateLayout',
    'activateTables',
    'refresh',
  ],
}
</script>

<style scoped lang="scss">
.dp-divider {
  width: 250px;
  border-bottom: 1px solid rgb(var(--v-theme-grey-darken2));
  margin-top: 5px;
}

.section {
  margin-top: 15px;
}
</style>