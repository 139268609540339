import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/';

export class FavoriteAPI {
  static getFolders() {
    return http.get(`${BASE_PATH}favorite/folders`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static postFolder(name) {
    return http.post(`${BASE_PATH}favorite/folder/?name=${name}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static addToFavorites(docId, folderId) {
    return http.post(`${BASE_PATH}favorite/favorite/${docId}/${folderId}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static removeFavorite(id) {
    return http.delete(`${BASE_PATH}favorite/favorite/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteFolder(id) {
    return http.delete(`${BASE_PATH}favorite/folder/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
