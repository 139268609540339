<template>
  <div
    class="internal-rule-editor"
    :style="{ opacity: disabled ? 0.5 : 1 }"
  >
    <div class="cursor-tracker editor-box">
      <div
        class="inline-middle"
        style="color: transparent"
      >
        {{ code.substring(0, cursorPos) }}
        <div
          ref="cursorLocationDiv"
          class="cursor-location-div inline-middle"
        />
      </div>
    </div>
    <code
      ref="code"
      class="code-box language-javascript editor-box"
      lang="javascript"
    >
      {{ code }}
    </code>
    <textarea
      ref="textarea"
      class="radio-box code-area editor-box"
      :value="code"
      :spellcheck="false"
      :placeholder="`${$t('eg')} ${placeholder}`"
      @input="handleCodeChange"
      @click="handleCodeClick"
      @keydown.right="handleCodeClick"
      @keydown.left="handleCodeClick"
      @keydown.up="handleUpKey"
      @keydown.down="handleDownKey"
      @blur="handleBlur"
    />
    <div
      v-if="suggest"
      style="position: absolute;"
      :style="{
        left: cursorLeft + 'px',
        top: cursorTop + 'px',
      }"
    >
      <AutoCompleteSelect
        ref="autocomplete"
        style="width: 300px; margin-top: -18px;"
        :suggestions="suggestions"
        :selected="selectedSuggestion"
        @selected-changed="insertSuggestion"
      />
    </div>
    <div style="position: absolute; right: 0px; top: 0px; width: 300px; height: 250px">
      <ExtractorSelect
        v-if="extractorSuggestOptions.length > 0 "
        ref="extractorSelect"
        class="bottom-gap-sm"
        :extractors="extractorSuggestOptions"
        :disabled="!canInsertExtractor"
        @selected-changed="insertExtractor"
      />
      <div
        v-if="!rule.id"
        class="info-box bottom-gap-sm"
      >
        <small v-if="type === 'internal'">
          {{ $t('businessRules.internal_compatible') }}
        </small>
        <small v-if="type === 'subgroup'">
          {{ $t('businessRules.subgroup_info') }}
        </small>
      </div>
      <div
        v-if="codeErrors.length > 0"
        class="info-box"
      >
        <small>
          <ul class="bottom-gap-sm top-gap-sm">
            <li
              v-for="error in codeErrors"
              :key="error"
            >
              {{ $t(`businessRules.errors.${error}`) }}
            </li>
          </ul>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import hljs from 'highlight.js';
import '@/assets/recital_highlights.css';
import { parseJsonLogic } from '@/utils/BusinessRuleUtils';
import AutoCompleteSelect from '@/components/extract/elements/BusinessRules/AutoCompleteSelect';
import ExtractorSelect from '@/components/extract/elements/BusinessRules/ExtractorSelect';

export default {
  name: "InternalRuleEditor",

  components: {
    AutoCompleteSelect,
    ExtractorSelect,
  },

  data() {
    return {
      code: "",
      cursorPos: 0,
      cursorLeft: 0,
      cursorTop: 0,
      updateKey: 0,
      suggest: false,
      suggestions: [],
      selectedSuggestion: "",
      commands: [
        {value: 'ABS', text: 'ABS()', type: 'command', compatibility: ['internal', 'subgroup']},
        {value: 'SUM', text: 'SUM()', type: 'command', compatibility: ['internal']},
      ],
      comparisonError: null,
      syntaxError: null,
      maxCodeLength: 790,
      suggestTimeout: null,
      autocompleteTimeout: null,
      refreshExtractorSuggestions: 1,
    };
  },

  computed: {
    canInsertExtractor() {
      if (this.cursorPos === 0) {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      if (['+', '-', '/', '*', '=', '<', '>', ' ', '('].includes(this.code[this.cursorPos - 1])) {
        return true;
      }
      return false;
    },

    placeholder() {
      if (this.type === 'subgroup') {
        return 'group.LABEL_1 + group.LABEL_2 >= ABS(group.LABEL_3) * 2';
      }
      return '(data_point + SUM(group.LABEL)) * 2 >= ABS(data_point)';
    },

    codeErrors() {
      const errors = [];
      if (this.comparisonError) {
        errors.push(this.comparisonError);
      }
      if (this.syntaxError) {
        errors.push(this.syntaxError);
      }
      return errors;
    },

    extractorSuggestOptions() {
      this.refreshExtractorSuggestions;
      let options = [];
      if (this.type === 'internal') {
        this.dataPoints.forEach(dp => {
          options.push({
            value: dp.name,
            text: dp.name,
            type: 'dp',
          });
        });
        this.filteredGroups.forEach(group => {
          group.labels.forEach(label => {
            options.push({
              value: `${group.name}.${label.name}`,
              text: `${group.name}.${label.name}`,
              type: 'eg',
            });
          });
        });
      } else if (this.type === 'subgroup') {
        this.filteredGroups.forEach(group => {
          group.labels.forEach(label => {
            options.push({
              value: `${group.name}.${label.name}`,
              text: `${group.name}.${label.name}`,
              type: 'eg',
            });
          });
        });
      }
      return options;
    },

    allSuggestOptions() {
      return [
        ...this.extractorSuggestOptions,
        ...this.commands.filter(c => c.compatibility.includes(this.type))
      ];
    },
  },

  watch: {
    dataPoints() {
      this.parseRuleLogic();
    },

    filteredGroups() {
      this.parseRuleLogic();
    },

    type() {
      this.comparisonError = null;
      this.syntaxError = null;
      this.parseRuleLogic();
      this.parseCode(this.code);
    },

    codeErrors: {
      handler() {
        this.$emit('validityChange');
      },
      deep: true
    },

    code(code) {
      if (code.length > this.maxCodeLength) {
        this.code = code.substring(0, this.maxCodeLength);
      } else {
        this.syntaxError = null;
        if (this.type === 'subgroup') {
          const regexOps = ['\\+', '-', '/', '\\*', '\\(', '\\)', '=', '<', '>', '<=', '>='];
          const regexPattern = new RegExp(`([${regexOps.map(op => `\\${op}`).join('')}])`, 'g');
          const codeArray = code.split(regexPattern).filter(part => part.trim() !== '').map(p => p.trim());
          const usedGroups = codeArray.filter(
            part => part.includes('.') && this.filteredGroups.map(g => g.name).includes(part.split('.')[0])
          );
          if (usedGroups.length > 0) {
            const selectedGroupName = usedGroups[0].split('.')[0];
            if (usedGroups.some(g => g.split('.')[0] !== selectedGroupName)) {
              this.syntaxError = 'all_labels_must_be_from_same_group';
              this.rule.logic = {};
              return;
            }
            const selectedGroupId = this.filteredGroups.find(g => g.name === selectedGroupName).id;
            this.rule.extraction_group_id = selectedGroupId;
            this.refreshExtractorSuggestions++;
          }
        }
        this.rule.logic = this.parseCode(code);
      }
      // eslint-disable-next-line no-irregular-whitespace
      if (code.includes('  ')) {
        this.syntaxError = 'multiple_spaces';
        this.rule.logic = {};
      }
    },
  },

  mounted() {
    this.parseRuleLogic();
    hljs.configure({
      languages: ['javascript'],
      ignoreUnescapedHTML: true,
    });
    setTimeout(() => {
      hljs.highlightElement(this.$refs.code);
    }, 0);
    window.addEventListener("keydown", this.handleKeydown);
  },

  unmounted() {
    window.removeEventListener("keydown", this.handleKeydown);
  },

  methods: {
    parseRuleLogic() {
      if (this.rule.logic) {
        this.code = parseJsonLogic(this.rule.logic, 1, this.type, this.dataPoints, this.filteredGroups).slice(1, -1);
        this.cursorPos = this.code.length;
      }
    },

    handleKeydown(event) {
      if (this.suggest) {
        if (event.key === 'Escape') {
          event.preventDefault();
          this.suggest = false;
        }
        if (event.key === 'Tab') {
          event.preventDefault();
          this.insertSuggestion();
        }
      }      
    },

    handleBlur() {
      setTimeout(() => {
        this.suggest = false;
      }, 150);
    },

    setSelectionRange(input, selectionStart) {
      if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(selectionStart, selectionStart);
      }
      else if (input.createTextRange) {
        var range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionStart);
        range.moveStart('character', selectionStart);
        range.select();
      }
    },

    scrollToOption(index) {
      const autocomplete = this.$refs.autocomplete;
      if (autocomplete) {
        autocomplete.$refs[`Option${index}`][0].scrollIntoViewIfNeeded(true);
      }
    },

    handleUpKey(event) {
      this.getCursorPos();
      const autocomplete = this.$refs.autocomplete;
      if (autocomplete) {
        event.preventDefault();
        if (this.suggestions.length > 0) {
          let index = this.suggestions.findIndex(s => s.value === autocomplete.preSelectedText);
          index--;
          index = index < 0 ? this.suggestions.length - 1 : index;
          autocomplete.preSelectedText = this.suggestions[index].text;
          this.scrollToOption(index);
        }
      }
    },

    handleDownKey(event) {
      this.getCursorPos();
      const autocomplete = this.$refs.autocomplete;
      if (autocomplete) {
        event.preventDefault();
        if (this.suggestions.length > 0) {
          let index = this.suggestions.findIndex(s => s.value === autocomplete.preSelectedText);
          index++;
          index = index >= this.suggestions.length ? 0 : index;
          autocomplete.preSelectedText = this.suggestions[index].text;
          this.scrollToOption(index);
        }
      }
    },

    handleCodeClick() {
      this.suggest = false;
      this.getCursorPos();
    },

    getCursorPos() {
      setTimeout(() => {
        this.cursorPos = this.$refs.textarea.selectionStart;
      }, 10);
      setTimeout(() => {
        this.cursorLeft = this.$refs.cursorLocationDiv.offsetLeft;
        this.cursorTop = this.$refs.cursorLocationDiv.offsetTop;
      }, 20);
    },

    getLastword(string, cursorPos) {
      let lastWord = string.substring(0, cursorPos).split(' ').pop().toLowerCase();
      const ops = ['+', '-', '/', '*', '=', '<', '>', '(', ')'];
      for (let op in ops) {
        if (lastWord.includes(ops[op])) {
          lastWord = lastWord.substring(lastWord.indexOf(ops[op]) + 1);
        }
      }
      return lastWord;
    },

    showSuggest() {
      let autocomplete = this.$refs.autocomplete;
      if (autocomplete) {
        autocomplete.selectOn = false;
      }
      const lastWord = this.getLastword(this.code, this.cursorPos);
      const suggestions = this.allSuggestOptions.filter(
          option => option.value.toLowerCase().startsWith(lastWord) && option.value.toLowerCase() !== lastWord
        )
        .sort(
        function(a, b) {
          var textA = a.text.toUpperCase();
          var textB = b.text.toUpperCase();
          if (textA < textB) {
            return -1;
          }
          if (textA > textB) {
            return 1;
          }
          return 0;
        }
      );
      suggestions
      this.suggestions = suggestions;
      if (this.suggestions.length > 0) {
        this.suggest = true;
        this.autocompleteTimeout = setTimeout(() => {
          let autocomplete = this.$refs.autocomplete;
          if (autocomplete) {
            autocomplete.toggleSelectOn();
          }
        }, 50);
      } else {
        this.suggest = false;
      }
    },

    insertExtractor(extractor) {
      let lastWord = this.getLastword(this.code, this.cursorPos);
      let cursorPos = this.cursorPos;
      let code = this.code.substring(0, this.cursorPos);
      let toInsert = extractor.text;
      if (extractor.type === 'eg' && this.type === 'internal') {
        const hasSUM = this.getLastword(this.code, this.cursorPos - lastWord.length) === 'sum(';
        if (!hasSUM) {
          toInsert = `SUM(${toInsert})`;
        }
      }
      code += toInsert;
      code += this.code.substring(this.cursorPos);
      this.code = code;
      this.updateKey++;
      setTimeout(() => {
        this.$refs.extractorSelect.reset();
        let newPos = cursorPos + toInsert.length - lastWord.length;
        this.setSelectionRange(this.$refs.textarea, newPos);
        this.cursorPos = newPos;
        this.$refs.textarea.focus();
        hljs.highlightElement(this.$refs.code);
      }, 50);
    },

    insertSuggestion() {
      let autocomplete = this.$refs.autocomplete;
      if (autocomplete) {
        let lastWord = this.getLastword(this.code, this.cursorPos);
        let suggestion = this.suggestions.find(s => s.text === autocomplete.preSelectedText);
        if (suggestion) {
          let cursorPos = this.cursorPos;
          let code = this.code.substring(0, this.cursorPos - lastWord.length);
          let toInsert = suggestion.text;
          if (suggestion.type === 'eg' && this.type === 'internal') {
            const hasSUM = this.getLastword(this.code, this.cursorPos - lastWord.length) === 'sum(';
            if (!hasSUM) {
              toInsert = `SUM(${toInsert})`;
            }
          }
          code += toInsert;
          code += this.code.substring(this.cursorPos);
          this.code = code;
          this.suggest = false;
          this.updateKey++;
          setTimeout(() => {
            let newPos = cursorPos + toInsert.length - lastWord.length;
            if (suggestion.type === 'command') {
              newPos--;
            }
            this.setSelectionRange(this.$refs.textarea, newPos);
            this.cursorPos = newPos;
            this.$refs.textarea.focus();
            hljs.highlightElement(this.$refs.code);
          }, 50);
        }
      }
    },

    handleCodeChange(event) {
      event
      this.autocompleteTimeout = null;
      this.suggestTimeout = null;
      setTimeout(() => {
        this.suggest = false;
      }, 200);

      let inputString = this.$refs.textarea.value;
      if (event.inputType == 'insertLineBreak') {
        if (this.suggest) {
          this.insertSuggestion();
          return;
        } else {
          inputString = inputString.replace('\n', '');
        }
      }
      const cursorPos = this.cursorPos + 1;

      // eslint-disable-next-line no-irregular-whitespace
      let code = inputString.replace(/[^a-zA-Z0-9*+\-/_()=.<>  ]/g, '');
      let multiSpace = false;
      if (code.includes(' ')) {
        multiSpace = true;
      }

      // eslint-disable-next-line no-irregular-whitespace
      this.code = code.replace(' ', ' ');
      if (multiSpace && cursorPos < code.length) {
        setTimeout(() => {
          this.setSelectionRange(this.$refs.textarea, cursorPos);
          this.updateKey++;
        }, 10);
      }
      if (/^[a-zA-Z_.]$/.test(event.data)) {
        this.suggestTimeout = setTimeout(() => {
          this.showSuggest();
        }, 250);
      } else {
        setTimeout(() => {
          this.suggest = false;
        }, 50);
      }
      this.updateKey++;
      this.handleCodeClick();
      setTimeout(() => {
        hljs.highlightElement(this.$refs.code);
      }, 10);
    },

    getComparison(str) {
      const comparisons = ['=', '>', '<', '<=', '>='];
      const foundComparisons = [];

      for (let i = 0; i < comparisons.length; i++) {
        let indexes = [...str.matchAll(new RegExp(comparisons[i], 'gi'))].map(a => a.index);
        if (indexes.length === 1) {
          foundComparisons.push(comparisons[i]);
        } else if (indexes.length > 1) {
          this.comparisonError = 'too_many_comparisons';
          return '';
        }
      }
      if (foundComparisons.length === 3) {
        if (foundComparisons.includes('<=')) {
          return '<=';
        }
        if (foundComparisons.includes('>=')) {
          return '>=';
        }
      }
      if (foundComparisons.length > 1) {
        this.comparisonError = 'too_many_comparisons';
        return '';
      }
      if (foundComparisons.length === 0) {
        this.comparisonError = 'no_comparisons';
        return ''
      }
      this.comparisonError = null;
      return foundComparisons[0];
    },

    focusTextArea() {
      setTimeout(() => {
        this.$refs.textarea.focus();
      }, 10);
    },

    handlePrecedence(operators, nextToken) {
      const precedence = { '*': 2, '/': 2, '+': 1, '-': 1};

      if (operators.length === 0) {
        return false;
      }
      const lastOperator = operators[operators.length - 1];
      return precedence[lastOperator] >= precedence[nextToken];
    },

    toRPN(tokens) {
      const operators = [];
      const out = [];

      for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];
        if (/[+\-/*]/.test(token)) {
          while (this.handlePrecedence(operators, token)) {
            out.push(operators.pop());
          }
          operators.push(token);
          continue;
        }
        if (token === '(') {
          operators.push(token);
          continue;
        }
        if (token === ')') {
          while (operators[operators.length - 1] !== '(') {
            if (operators.length - 1 === -1) {
              this.syntaxError = 'no_open_parenthesis';
              return [];
            }
            out.push(operators.pop());
          }
          operators.pop();
          continue;
        }
        if (token === '[') {
          operators.push(token);
          continue;
        }
        if (token === ']') {
          if (operators[operators.length - 1] === '[') {
            operators.pop();
            out.push('|');
            if (operators.length > 0) {
              out.push(operators.pop());
            }
          } else {
            while (operators[operators.length - 1] !== '[') {
              if (operators.length - 1 === -1) {
                this.syntaxError = 'no_open_parenthesis';
                return [];
              }
              out.push(operators.pop());
              out.push('|');
            }
          }
          operators.pop();
          continue;
        }
        out.push(token);
      }
      for (let i = operators.length - 1; i >= 0; i--) {
        out.push(operators[i]);
      }

      if (out.includes('[') || out.includes('(')) {
        this.syntaxError = 'no_open_parenthesis';
        return [];
      }
      return out;
    },

    parseCodeSide(str) {
      const ops = ['+', '-', '/', '*'];
      const parentheses = ['(', ')', '[', ']'];
      const allOps = [...ops, ...parentheses];
      const regexOps = ['\\+', '-', '/', '\\*', '\\(', '\\)'];
      const regexPattern = new RegExp(`([${regexOps.map(op => `\\${op}`).join('')}])`, 'g');
      let codeArray = str.split(regexPattern).filter(part => part.trim() !== '').map(p => p.trim());
      for (let i = 0; i < codeArray.length; i++) {
        if (codeArray[i] === 'ABS') {
          codeArray[i] = '';
          let j = i + 1;
          if (j < codeArray.length && codeArray[j] === '(') {
            codeArray[j] = '[';
          }
          let openParenthesis = 1;
          while (j < codeArray.length) {
            if (codeArray[j] === '(') {
              openParenthesis++;
            } else if (codeArray[j] === ')') {
              openParenthesis--;
            }
            if (openParenthesis === 0) {
              codeArray[j] = ']';
              break;
            }
            j++;
          }
        }
      }
      codeArray = codeArray.filter(token => token.trim() !== '')
      codeArray = codeArray.map((token, index) =>
        {
          // eslint-disable-next-line no-irregular-whitespace
          if (token.includes(' ')) {
            this.syntaxError = 'numbers_extractors_need_operator';
            return '';
          }
          if (token === '_') {
            this.syntaxError = 'missing_extractor_name';
            return null;
          }
          if (allOps.includes(token)) {
            if (index === 0 && ops.includes(token)) {
              this.syntaxError = 'operator_at_beginning';
              return '';
            }
            if (index + 1 < codeArray.length) {
              let nextToken = codeArray[index + 1];
              if (ops.includes(token) && ops.includes(nextToken)) {
                this.syntaxError = 'two_operators_in_a_row';
                return '';
              }
            } else if (ops.includes(token)) {
              this.syntaxError = 'operator_at_end';
              return '';
            }
            return token;
          }
          const number = parseFloat(token);
          if (!isNaN(number)) {
            if (index + 1 < codeArray.length) {
              if (codeArray[index + 1] === '(') {
                this.syntaxError = 'numbers_extractors_need_operator';
                return '';
              }
            }
            return number;
          }
          if (token === 'SUM') {
            if (this.type === 'subgroup') {
              this.syntaxError = 'sum_not_allowed';
              return '';
            }
            if (index + 1 < codeArray.length) {
              let nextToken = codeArray[index + 1];
              if (nextToken != '(') {
                this.syntaxError = 'sum_needs_parenthesis';
                return '';
              }
              if (index + 2 < codeArray.length) {
                nextToken = codeArray[index + 2];
                if (nextToken.includes('.')) {
                  const parts = nextToken.split('.');
                  if (parts.length !== 2) {
                    this.syntaxError = 'unrecognized_extractor_name';
                    return '';
                  }
                  const group = this.filteredGroups.find(g => g.name === parts[0]);
                  if (group) {
                    const label = group.labels.find(l => l.name === parts[1]);
                    if (!label) {
                      this.syntaxError = 'unrecognized_label_name';
                      return '';
                    }
                  } else {
                    this.syntaxError = 'unrecognized_group_name';
                    return '';
                  }
                } else {
                  this.syntaxError = 'sum_only_for_groups';
                  return '';
                }
              } else {
                this.syntaxError = 'sum_needs_parenthesis';
                return '';
              }
            } else {
              this.syntaxError = 'sum_needs_parenthesis';
              return '';
            }
            return '';
          }
          if (this.type === 'internal') {
            const dp = this.dataPoints.find(dp => dp.name === token);
            if (dp) {
              return {var: `dp_${dp.id}`};
            }
          }
          if (token.includes('.')) {
            const parts = token.split('.');
            if (parts.length !== 2) {
              this.syntaxError = 'unrecognized_extractor_name';
              return '';
            }
            const group = this.filteredGroups.find(g => g.name === parts[0]);
            if (group) {
              const label = group.labels.find(l => l.name === parts[1]);
              if (label) {
                if (this.type === 'subgroup') {
                  return {var: `eg_${group.id}_${label.name}`};
                }
                if (index > 1) {
                  let prevToken = codeArray[index - 1];
                  if (prevToken === '(') {
                    prevToken = codeArray[index - 2]; 
                    if (prevToken === 'SUM') {
                      return {var: `eg_${group.id}_${label.name}`};
                    } else {
                      this.syntaxError = 'group_needs_sum';
                      return '';
                    }
                  } else {
                    this.syntaxError = 'group_needs_sum';
                    return '';
                  }
                } else {
                  this.syntaxError = 'group_needs_sum';
                  return '';
                }
              } else {
                this.syntaxError = 'unrecognized_label_name';
                return '';
              }
            } else {
              this.syntaxError = 'unrecognized_group_name';
              return '';
            }
          }
          this.syntaxError = 'unrecognized_extractor_name';
          return '';
        }
      ).filter(token => token !== '');
      const RPN = this.toRPN(codeArray);
      return this.parseRPN(RPN);
    },

    parseRPN(RPN) {
      const stack = [];
      for (let i = 0; i < RPN.length; i++) {
        const token = RPN[i];
        if (/[+\-/*]/.test(token)) {
          const right = stack.pop();
          const left = stack.pop();
          stack.push({[token]: [left, right]});
          continue;
        }
        if (token === '|') {
          const right = stack.pop();
          stack.push({'abs': right});
          continue;
        }
        stack.push(token);
      }
      return stack[0];
    },

    parseCode(code) {
      const jsonLogic = {};
      let comparison = this.getComparison(code);
      if (!comparison) {
        this.focusTextArea();
        return jsonLogic;
      }
      const sides = code.split(comparison);
      const left = sides[0].trim();
      const right = sides[1].trim();
      if (!left || !right) {
        this.comparisonError = 'no_left_right';
        this.focusTextArea();
        return jsonLogic;
      }
      if (comparison === '=') {
        comparison = '==';
      }
      this.syntaxError = null;
      jsonLogic[comparison] = [
        this.parseCodeSide(left),
        this.parseCodeSide(right)
      ];
      this.focusTextArea();
      return jsonLogic;
    },
  },

  props: {
    rule: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: 'internal',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    dataPoints: {
      type: Array,
      default: () => [],
    },

    filteredGroups: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['validityChange'],
}
</script>

<style lang="scss" scoped>
.internal-rule-editor {
  position: relative;
  height: 270px;
  width: 1160px;

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: 300px;

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }

  .editor-box {
    word-break: break-all !important;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 850px;
    font-family: monospace;
    font-size: 1.2rem;
    background-color: transparent;
    padding: 10px;
    padding-right: 10px;
    height: 370px;
    overflow: hidden
  }

  .code-box {
    top: 1px !important;
    background-color: rgb(var(--v-theme-grey-lighten2));
  }

  .cursor-location-div {
    width: 1px;
    height: 1px;
  }

  .code-area {
    resize: none;
    color: transparent;
    padding-right: 10px !important;
    caret-color: rgb(var(--v-theme-primary));
  }

  .code-area:focus {
    outline: none !important;
    border: 1px solid rgb(var(--v-theme-primary)) !important;
  }
}
</style>
