<template>
  <div class="settings-view page-padding py-7">
    <BreadcrumbComponent />
    <ProfileSettings />
    <div v-if="user.role === 'orgadmin'">
      <hr class="divider-line settings-body top-gap">
      <GeneralSettings />
    </div>
  </div>
</template>

<script>
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import GeneralSettings from '@/components/extract/elements/Settings/GeneralSettings';
import ProfileSettings from '@/components/common/elements/Settings/ProfileSettings';

export default {
  name: 'SettingsView',

  components: {
    BreadcrumbComponent,
    GeneralSettings,
    ProfileSettings,
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  created() {
    this.setBreadcrumb();
  },

  methods: {
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb', [
        { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
        { title: this.$t('breadcrumb.settings') },
        { title: this.$t('menu.general') },
      ]);
    },
  }
}
</script>

<style lang="scss">
.settings-view {
  .settings-body {
    width: 1024px;

    .settings-left {
      width: 400px;
      padding-right: 30px;
    }

    .settings-right {
      width: 600px;

      .text-field-label {
        margin-bottom: 3px;
      }

      .radio-group-label {
        margin-bottom: -12px;
      }
    }
  }
}
</style>
