<template>
  <div class="datasets-table">
    <BreadcrumbComponent />
    <TableActions
      type="datasets"
      :number-of-selected="selected.length"
      @edit-click="handleEditName"
      @delete-click="handleDeleteButton"
      @create-click="handleCreateButton"
      @download-click="handleDownloadButton"
      @filter-change="(filter) => trimmedFilter = filter"
      @filter-enter="handleEnter"
      @import-click="selectDataset"
    />
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginatedDatasets.length"
      :total="totalDatasets"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          {{ $t('models.dataset') }}
        </v-col>
        <v-col cols="2">
          {{ $t('models.docs') }}
        </v-col>
        <v-col cols="3">
          {{ $t("models.progress") }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="dataset in paginatedDatasets"
            :key="dataset.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="dataset.selected"
                class="left-gap mt-0"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col cols="6">
              <v-icon
                v-if="dataset.status === 'error'"
                class="mr-2"
                color="primary"
                size="16"
              >
                fas fa-exclamation-triangle
              </v-icon>
              <v-icon
                v-if="dataset.status === 'uploading'"
                class="mr-2"
                color="primary"
                size="16"
              >
                fas fa-pulse fa-spinner
              </v-icon>
              <ItemName
                :key="dataset.id"
                :style="{
                  width: ['error', 'uploading'].includes(dataset.status) && 'calc(100% - 24px)' || '100%',
                }"
                :item="dataset"
                :clickable="isActive(dataset)"
                :editing-allowed="isActive(dataset) || !!(dataset.selected)"
                :editing="editingDataset === dataset.id"
                :validator="validateName"
                :hint="`${$t('forms.name_hint')} ${$t('forms.unique_hint')}`"
                @save-file-name="(id, newName) => saveName(id, newName)"
                @name-click="goToDataset(dataset)"
                @cancel-name-edit="editingDataset = -1;"
              />
            </v-col>
            <v-col
              cols="2"
              :class="{
                clickable: isActive(dataset),
                'primary--text': isActive(dataset),
              }"
            >
              <div @click="selectFileOrGoToDataset(dataset)">
                {{ getDocsButtonLabel(dataset) }}
              </div>
            </v-col>
            <v-col
              cols="3"
              :class="{
                clickable: isActive(dataset) && dataset.nb_files !== 0,
                'accent--text': isActive(dataset) && dataset.nb_files !== 0,
              }"
            >
              <div @click="goToAnnotation(dataset)">
                {{ getAnnotationButtonLabel(dataset) }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <FileInput
      ref="uploader"
      @change="uploadFiles"
    />
    <UploadDialog
      v-model="showUpload"
      :title="$t('models.import_dataset')"
      :input="uploadInput"
      :select-btn="$t('models.select_dataset')"
      @change="uploadDataset"
      @close="showUpload = false"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('tables.move_to_bin_title', itemsTranslation)"
      :message="$t('tables.move_to_bin_confirm', itemsTranslation)"
      @confirm="deleteDataset"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { EntryAPI } from '@/API/extract/EntryAPI';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { http } from '@/plugins/axios';
import ItemName from '@/components/common/elements/General/ItemName';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import FileInput from '@/components/common/elements/Forms/FileInput';
import UploadDialog from '@/components/common/elements/Forms/UploadDialog';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import file_mixin from '@/mixins/file.js';
import ui_mixin from '@/mixins/ui.js';
import { isNameValid } from '@/utils/FormValidation';


export default {
  name: 'DatasetsTable',

  mixins: [file_mixin, ui_mixin],

  components: {
    ItemName,
    DeleteDialog,
    TableActions,
    TableWithFooter,
    FileInput,
    UploadDialog,
    SortButton,
    BreadcrumbComponent,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      trimmedFilter: '',
      uploadModel: null,
      selectedFile: null,
      editOn: false,
      editingDataset: -1,
      deleteDialog: false,
      totalDatasets: 0,
      paginatedDatasets: [],
      allSelected: false,
      loading: false,
      forceCompute: Math.random(),
      showUpload: false,
      checkUploadedDataset: null,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    pendingUpload() {
      return this.paginatedDatasets.some(ds => ds.status === 'uploading');
    },

    itemsTranslation() {
      return {items: this.selected.length === 1 ? this.$t('tables.dataset') : this.$t('tables.datasets')}
    },

    selected: {
      get() {
        this.forceCompute;
        if (this.paginatedDatasets.length > 0) {
          return this.paginatedDatasets.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  watch: {
    sortDesc() {
      this.getDatasets();
    },

    totalDatasets(total) {
      if (this.trimmedFilter === '' && !total) {
        this.$emit('create');
      } else if (this.trimmedFilter === '') {
        this.$emit('changeTotal', total);
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getDatasets();
    },

    currentPage(page) {
      this.allSelected = false;
      this.paginatedDatasets.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
      this.getDatasets((page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getDatasets();
      }, 300
    ),
  },

  created() {
    this.uploadInput = {
      label: this.$t('models.dataset_name'),
      placeholder: this.$t('models.type_new_dataset_name'),
      hint: `${this.$t('forms.name_hint')} ${this.$t('forms.unique_hint')}`,
    };
    this.setBreadcrumb();
    this.getDatasets();
  },

  unmounted() {
    clearInterval(this.checkUploadedDataset);
  },

  methods: {
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
          { title: this.$t('menu.studio') },
          { title: this.$t('menu.datasets') },
        ]
      );
    },

    validateName(name) {
      return isNameValid(name);
    },

    handleSelect() {
      this.allSelected = this.paginatedDatasets.every(d => d.selected);
      this.forceCompute = Math.random();
    },

    isActive(dataset) {
      return !['uploading', 'error'].includes(dataset.status);
    },

    checkUploaded() {
      this.getDatasets();
      clearInterval(this.checkUploadedDataset);
      this.checkUploadedDataset = setInterval(() => {
        if (!this.pendingUpload) {
          clearInterval(this.checkUploadedDataset);
        } else {
          this.getDatasets();
        }
      }, 3000);
    },

    toggleSelectAll() {
      this.paginatedDatasets.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
    },

    async getDatasets(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage)
    {
      try {
        this.loading = true;
        const response = await DatasetAPI.get(
          offset, limit, this.trimmedFilter || '', null, this.sortDesc,
        );
        this.paginatedDatasets = response.data;
        this.totalDatasets = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        clearInterval(this.checkUploadedDataset);
      } finally {
        this.loading = false;
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      const datasets = this.paginatedDatasets.map(ds => {
        ds.selected = false;
        return ds;
      });
      this.paginatedDatasets = [...datasets];
    },

    handleEnter() {
      if (this.paginatedDatasets.length > 0) {
        this.goToDataset(this.paginatedDatasets[0])
      }
    },

    async deleteDataset() {
      await Promise.all(this.selected.map(async dataset => {
        try {
          if (this.$store.getters.selectedDatasetId === dataset.id) {
            this.$store.commit('setSelectedDatasetId', -1);
          }
          return await http.delete(`dataset/${dataset.id}`);
        } catch (error) {
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const deletedMessage = this.$t('models.deleted_dataset_message');
      const { currentPage, itemsPerPage} = this;
      await this.getDatasets();
      const lastPage = Math.max(1, Math.ceil(this.totalDatasets / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      this.editOn = false;
      this.$store.commit('setSuccessMessage', deletedMessage);
      this.$store.commit('setSuccessSnackbar', true);
    },

    async startUpload(file) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await EntryAPI.post(this.uploadModel.id, file);
        this.$store.commit('setLoadingScreen', false);
        this.goToDataset(this.uploadModel);
      } catch (error) {
        console.log(error);
        this.$store.commit('setLoadingScreen', false);
      }
    },

    selectDataset() {
      this.showUpload = true;
    },

    async uploadDataset(payload) {
      const { name, zipfile } = payload;
      this.$store.commit('setLoadingScreen', true);
      const formData = new FormData();
      formData.append('dataset_name', name);
      formData.append('zipfile', zipfile);
      try {
        await http.post(
          'dataset/import',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        this.showUpload = false;
        this.checkUploaded();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    selectFile(item) {
      if (!this.isActive(item)) {
        return
      }
      this.uploadModel = item;
      this.$refs.uploader.click()
    },

    goToDataset(dataset) {
      if (!this.isActive(dataset)) {
        return
      }
      this.$emit('openDataset', dataset);
    },

    goToAnnotation(dataset) {
      if (!this.isActive(dataset)) {
        return;
      }
      if (dataset.nb_files === 0) {
        return;
      }
      this.$store.commit('setAnnotationParams', {
        datasetId: dataset.id,
      })
      this.$router.push({
        name: 'Annotation',
        params: {
          id: dataset.id,
        },
      });
    },

    async saveName(id, newName) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(`dataset/${id}`, { name: newName });
          const dataset = this.paginatedDatasets.find(d => d.id === id);
          dataset.name = newName;
          dataset.selected = false;
          this.$store.commit(
            'setSuccessMessage', this.$t('models.updated_dataset_message')
          );
          this.$store.commit('setSuccessSnackbar', true);
          this.editingDataset = -1;
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },

    handleCreateButton() {
      this.$emit('create');
    },

    async handleDownloadButton() {
      try {
        const response = await http.get(`dataset/prepare/${this.selected[0].id}`);
        await this.$store.commit(
          'setSuccessMessage', response.data.filename + ' ' + this.$t('models.prepare_dataset_zip')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    handleDeleteButton() {
      this.deleteDialog = true;
    },

    handleEditName() {
      this.editingDataset = this.selected[0].id;
    },

    getAnnotatedFilesRatio(dataset) {
      return Math.round(100 * dataset.nb_annotated / dataset.nb_files);
    },

    getAnnotationButtonLabel(dataset) {
      if (dataset.nb_files === 0) {
        return '—';
      }
      if (dataset.nb_annotated === 0) {
        return this.$t('models.start_annotating');
      }
      return `${this.getAnnotatedFilesRatio(dataset)}%`
    },

    selectFileOrGoToDataset(dataset) {
      if (dataset.nb_files === 0) {
        return this.selectFile(dataset);
      }
      return this.goToDataset(dataset);
    },

    getDocsButtonLabel(dataset) {
      if (dataset.nb_files === 0) {
        return this.$t('docTypes.add');
      }
      return dataset.nb_files;
    },
  },

  emits: ['create', 'changeTotal', 'openDataset'],
}
</script>
