<template>
  <div class="warning-message fade-in mb-2">
    <v-icon
      class="mr-2"
      style="margin-bottom: 1px;"
      size="14"
    >
      fas fa-info-circle
    </v-icon>
    <small>
      {{ $t('dataPoints.generative.warning') }}
    </small>
  </div>
</template>
<script>
export default {
  name: 'GenerativeWarning',
};
</script>
<style lang="scss" scoped>
.warning-message {
  width: fit-content;
  background-color: rgb(var(--v-theme-primary-lighten2));
  border: 1px solid;
  padding: 8px 10px;
  padding-right: 15px;
  border-radius: 4px;
}
</style>
