<template>
  <v-row
    class="fill-height value-row ma-0"
    align="center"
    justify="center"
    :class="{
      'value-row__active': !corrected && (highlighted || editingCurrent),
      'value-row--invalid__active': corrected && (highlighted || editingCurrent),
    }"
    @mouseenter="handleHover"
    @mouseleave="deHighlight"
    @mousedown="deHighlight"
  >
    <v-col
      v-if="draggable"
      cols="auto"
      class="px-0"
    >
      <v-icon
        style="opacity: 0.5;"
        color="grey"
        size="17"
      >
        fas fa-grip-vertical
      </v-icon>
    </v-col>
    
    <v-col
      cols="2"
      class="text-uppercase data-point-name"
    >
      {{ dataPoint.data_point_name }}
    </v-col>
    <v-col
      class="pa-0"
      cols="6"
    >
      <div
        v-if="!editingCurrent"
        class="value-div pa-1"
      >
        <div v-if="value && (value.status === 'valid' || value.status === 'pending')">
          <div v-if="value.value">
            <div
              v-for="line in value.value.split('\n')"
              :key="line"
            >
              {{ line }}
            </div>
          </div>
          <div v-else>
            N/A
          </div>
          <v-tooltip right>
            <template #activator="{ props }">
              <div
                style="width: fit-content"
                v-bind="props"
              >
                {{ getNormalizedValue(value) }}
              </div>
            </template>
            {{ $t('dataPoints.normalized_value') }}
          </v-tooltip>
        </div>
        <div v-else-if="value && value.status === 'invalid'">
          {{ corrected || 'N/A' }}
          <v-tooltip right>
            <template #activator="{ props }">
              <div
                style="width: fit-content"
                v-bind="props"
              >
                {{ getNormalizedValue(value) }}
              </div>
            </template>
            {{ $t('dataPoints.normalized_value') }}
          </v-tooltip>
          <div
            v-if="value"
            class="corrected-value"
          >
            {{ value && value.value || 'N/A' }}
          </div>
        </div>
        <div v-else>
          N/A
        </div>
      </div>
      <v-text-field
        v-else
        :ref="`input-${dataPoint.data_point_id}`"
        v-model="newValue"
        style="position: relative; top: 11px;"
        variant="outlined"
        color="primary"
        density="compact"
        @keyup.esc="$emit('cancelEdit')"
        @keydown.enter="verifyValue(newValue, true)"
      />
    </v-col>
    <v-col
      class="px-1"
      cols="auto"
    >
      <v-tooltip
        :disabled="!showLocationTooltip"
        right
      >
        <template #activator="{ props }">
          <v-icon
            v-bind="props"
            color="grey"
            size="17"
            :style="{
              opacity: showLocationTooltip && 0.5 || 0,
            }"
          >
            fas fa-info-circle
          </v-icon>
        </template>
        {{ dataPoint.value && dataPoint.value.extra && dataPoint.value.extra.locations && dataPoint.value.extra.locations.length === 0
          ? $t('dataPoints.no_location_was_found')
          : $t('dataPoints.multiple_locations_was_found') }}
      </v-tooltip>
    </v-col>
    <v-col
      cols="3"
      class="px-0"
    >
      <div
        v-if="!editingCurrent"
        class="d-flex justify-center"
      >
        <v-tooltip
          v-if="value.prevalidated"
          right
        >
          <template #activator="{ props }">
            <v-icon
              color="white"
              class="verify-button mr-1"
              size="17"
              :class="{
                'verify-button--disabled': value && value.status === 'valid',
                'verify-button--prevalidated-incorrect': value && value.status === 'invalid',
              }"
              v-bind="props"
              @click="handleCorrection(dataPoint.data_point_id)"
            >
              fas fa-times
            </v-icon>
          </template>
          <div v-if="value && value.status === 'invalid'">
            <div style="font-size: 10px !important">
              {{ $t('dataPoints.prevalidated_message') }}
            </div>
            <div
              v-for="explanation in businessRuleStrings.filter(br => br)"
              :key="explanation.string"
              style="font-size: 12px !important;"
              :class="{
                'br-feedback-invalid': explanation && !explanation.isValid,
                'br-feedback-valid': explanation && explanation.isValid,
              }"
            >
              {{ explanation.string }}
            </div>
          </div>
          <div v-else>
            {{ $t('dataPoints.prevalidated_message') }}
          </div>
        </v-tooltip>
        <v-icon
          v-else
          color="white"
          size="17"
          class="verify-button mr-1"
          :class="{
            'verify-button--enabled': value && value.status === 'pending' || !value,
            'verify-button--disabled': value && value.status === 'valid',
            'verify-button--incorrect': value && value.status === 'invalid',
          }"
          @click="handleCorrection(dataPoint.data_point_id)"
        >
          fas fa-times
        </v-icon>
        <v-tooltip
          v-if="value.prevalidated"
          right
        >
          <template #activator="{ props }">
            <v-icon
              v-bind="props"
              color="white"
              class="verify-button ml-1"
              :class="{
                'verify-button--disabled': value && value.status === 'invalid',
                'verify-button--prevalidated-correct': value && value.status === 'valid',
              }"
              :style="{
                cursor: `${value && value.status === 'invalid' && 'not-allowed !important' || 'pointer'}`,
              }"
              @click="verifyValue((value && value.value) || 'N/A')"
            >
              fas fa-check
            </v-icon>
          </template>
          <div v-if="value && value.status === 'valid'">
            <div style="font-size: 10px !important">
              {{ $t('dataPoints.prevalidated_message') }}
            </div>
            <div
              v-for="explanation in businessRuleStrings.filter(br => br)"
              :key="explanation.string"
              style="font-size: 12px !important;"
              :class="{
                'br-feedback-invalid': explanation && !explanation.isValid,
                'br-feedback-valid': explanation && explanation.isValid,
              }"
            >
              {{ explanation.string }}
            </div>
          </div>
          <div v-else-if="value && value.status === 'invalid' && inCorrections">
            {{ $t('verification.invalid_business_rule') }}
          </div>
          <div v-else>
            {{ $t('dataPoints.prevalidated_message') }}
          </div>
        </v-tooltip>
        <v-icon
          v-else
          color="white"
          class="verify-button ml-1"
          size="17"
          :class="{
            'verify-button--enabled': value && value.status === 'pending' || !value,
            'verify-button--disabled': value && value.status === 'invalid',
            'verify-button--correct': value && value.status === 'valid',
          }"
          @click="verifyValue((value && value.value) || 'N/A')"
        >
          fas fa-check
        </v-icon>
      </div>
      <div
        v-else
        class="d-flex justify-center"
      >
        <v-btn
          color="primary"
          style="width: 88px"
          @click="verifyValue(newValue, true)"
          rounded
        >
          OK
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import format_mixin from "@/mixins/format";

export default {
  name: 'DPValidator',

  mixins: [format_mixin],

  data() {
    return ({
      newValue: '',
      renderKey: 1,
    });
  },

  computed: {
    value: {
      get() {
        return this.dataPoint.value;
      },
    },
    highlighted() {
      return this.activeDatapoint.data_point_id &&
        this.activeDatapoint.data_point_id === this.dataPoint.data_point_id ||
        false;
    },
    corrected() {
      this.renderKey;
      if (this.value && this.value.status === 'invalid') {
        return this.value.valid_value;
      }
      return null;
    },
    editingCurrent: {
      get() {
        return this.dataPoint.data_point_id === this.editing;
      },
      set() {
        return this.editingCurrent;
      }
    },

    inCorrections() {
      return this.$route.name === 'DocCorrections';
    },
    showLocationTooltip() {
      const { value } = this.dataPoint;
      if (value && value.origin !== 'generative') {
        return false;
      }
      return value &&
             value.extra &&
             value.extra.locations &&
             (value.extra.locations.length === 0 || value.extra.locations.length > 1);
    },
  },

  methods: {
    handleCorrection(id) {
      if (this.value && this.value.status === 'invalid') {
        this.$emit('cancelDPVerification', this.dataPoint.data_point_id);
        setTimeout(() => this.renderKey++, 200);
        return;
      }
      this.newValue = (this.value && (this.corrected || this.value.value)) || 'N/A';
      this.$emit('editValue', this.dataPoint);
      setTimeout(() => this.$refs[`input-${id}`].focus(), 100);
    },

    verifyValue(newValue, skipStatusVerification = false) {
      if (!skipStatusVerification && this.value && this.value.status === 'valid') {
        this.$emit('cancelDPVerification', this.dataPoint.data_point_id);
        setTimeout(() => this.renderKey++, 200);
        return;
      }
      newValue = ['N/A', ''].includes(newValue) ? null : newValue;
      let toVerify = { ...this.dataPoint };
      if (this.value && this.value.value && newValue) {
        toVerify.value = {
          value: newValue,
          page_nb: this.value.page_nb,
          location: this.value.location,
        };
      } else if (newValue) {
        toVerify.value = {
          value: newValue,
          page_nb: 1,
          location: {
            x_min: 0,
            x_max: 0,
            y_min: 0,
            y_max: 0,
          },
        };
      } else {
        toVerify.value = null;
      }
      this.$emit('verifyValue', toVerify);
      setTimeout(() => {
        this.renderKey++;
      }, 200);
    },
    handleHover() {
      this.$emit('hover', this.dataPoint);
    },
    deHighlight() {
      if (!this.editingCurrent) {
        this.$emit('deHighlight');
      }
    },
  },

  props: {
    dataPoint: {
      type: Object,
      required: true,
    },

    editing: {
      type: Number,
      required: true,
    },

    activeDatapoint: {
      type: Object,
      default: () => ({}),
    },

    draggable: {
      type: Boolean,
      default: true,
    },

    businessRuleStrings: {
      type: Array,
      default: () => [],
    },
  },

  emits: [
    'cancelEdit',
    'cancelDPVerification',
    'editValue',
    'verifyValue',
    'hover',
    'deHighlight',
  ],
}
</script>
<style lang="scss" scoped>
.value-row {
  box-sizing: border-box;
  padding: 8px;
  border: 2px solid transparent;
  &:not(.value-row:last-of-type) {
    padding-bottom: 9px;
    border-bottom: 1px solid #aaa;
  }
  &__active {
    padding-bottom: 8px !important;
    border: 2px solid $pending-base !important;
  }
  &--invalid__active {
    box-sizing: border-box;
    padding: 8px;
    padding-bottom: 8px !important;
    border: 2px solid $invalid-base !important;
  }
}
.data-point-name {
  padding: 0 10px;
  font-size: 12px;
}

.value-div {
  font-size: 14px;
  line-height: 1.5rem;
}

.verify-button {
  cursor: pointer;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;

  &--correct {
    background-color: $valid-base !important;
    cursor: default;
  }

  &--incorrect {
    background-color: $invalid-base !important;
    cursor: default;
  }

  &--enabled {
    background-color: #555 !important;
  }

  &--disabled {
    background-color: #ddd !important;
  }

  &--prevalidated-correct {
    background-color: $prevalidated-correct !important;
  }

  &--prevalidated-incorrect {
    background-color: $prevalidated-incorrect !important;
  }
}

.corrected-value {
  font-size: 12px;
  color: #aaa;
  text-decoration: line-through;
}

.br-feedback-valid {
  color: $valid-base !important;
}

.br-feedback-invalid {
  color: $invalid-base !important;
}
</style>
