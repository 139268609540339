<template>
  <div
    ref="multipane"
    class="annotation-view"
    @mouseup="endDragging"
  >
    <div
      class="left-field"
      :style="{ width: `${dividerPosition}%` }"
    >
      <div ref="leftFieldInner">
        <div class="top-gap d-flex">
          <div
            class="d-flex align-center mr-10"
            style="width: calc(100% - 300px);"
          >
            <v-icon
              class="clickable right-gap-sm"
              color="primary"
              @click="handleBack"
            >
              fas fa-chevron-left
            </v-icon>
            <ItemName
              class="right-gap-sm"
              style="margin-top: -9px"
              :item="dataset"
              :editing="renaming"
              @name-click="handleBack"
              @save-file-name="saveName"
              @cancel-name-edit="renaming = false"
              @start-name-edit="renaming = true"
              editing-allowed
              show-edit-icon
            />
          </div>
          <v-select
            v-model="labelFilter"
            style="width: 230px; margin-top: -6px;"
            class="inline-middle mt-0"
            variant="outlined"
            color="primary"
            density="compact"
            item-title="text"
            item-value="value"
            :style="{ opacity: labelSelectDisabled ? 0.7 : 1 }"
            :items="labelOptions"
            :disabled="labelSelectDisabled"
            :placeholder="$t('models.filter_docs_by_label')"
            @update:model-value="labelFilterSelect"
            clearable
          >
            <template #clear>
              <v-icon
                class="clickable mr-8"
                style="opacity: 1;"
                size="13"
                @click="labelFilter = null; labelFilterSelect()"
              >
                fas fa-times
              </v-icon>
            </template>
          </v-select>
        </div>
        <DocNavigator
          ref="docNavigatorTop"
          v-model="selectedId"
          class="top-gap"
          type="entry"
          :docs="files"
          :ordinal-selected="ordinalSelected"
          :total-docs="totalEntries"
          :show-number="!labelFilter"
          :show-loading-skeleton="ordinalSelected === 0"
          :deleteable="!dataset.user_id || dataset.user_id === user.id"
          @get-more="getEntries(numberOfFiles)"
          @get-missing="getMissingFiles"
          @delete-click="handleDeleteButton"
          @step="handleDocStep"
          can-copy-filename
        />
        <div
          v-if="files.length > 0"
          class="top-gap"
        >
          <div>
            <v-text-field
              v-model="filter"
              class="inline-middle"
              variant="outlined"
              color="primary"
              density="compact"
              style="width: 300px"
              :placeholder="$t('filter')"
            />
            <div>
              {{ $t('models.label_types.annotation') }}
              <div
                v-if="dataset.advanced"
                class="inline-middle"
              >
                <v-icon
                  v-if="labelType === labelTypes.annotation"
                  class="clickable left-gap-sm"
                  style="margin-top: -1px"
                  size="16"
                  @click="labelType = -1"
                >
                  fas fa-chevron-down
                </v-icon>
                <v-icon
                  v-else
                  class="clickable left-gap-sm"
                  style="margin-top: -2px"
                  size="16"
                  @click="labelType = labelTypes.annotation"
                >
                  fas fa-chevron-right
                </v-icon>
              </div>
            </div>
            <div v-if="labelType === labelTypes.annotation">
              <draggable
                v-model="labels"
                :ghost-class="trimmedFilter === '' ? 'ghost-label' : ''"
                @start="savedLabels = [...labels]; drag = true"
                @end="drag = false"
                @change="handleDragAnnotation"
                disabled
              >
                <div
                  v-for="(label, i) in filteredLabels"
                  :key="i"
                  :ref="`label${label.id}`"
                  class="label-container noselect"
                  :class="{
                    'label-container-active':
                      annotating === label.id ||
                      selectedLabelName === label.name,
                  }"
                  @mouseover="selectLabel(label.name)"
                >
                  <div class="label-name-container">
                    <div
                      v-if="editName !== label.id"
                      class="vertical-centered ellipsis label-name-container-inner"
                    >
                      <v-icon
                        v-if="trimmedFilter === ''"
                        class="right-gap-sm"
                        color="grey"
                        size="17"
                        :style="{opacity: trimmedFilter === '' ? 0.5 : 0}"
                        @mouseover="selectLabel(label.name)"
                      >
                        fas fa-grip-vertical
                      </v-icon>
                      <v-tooltip
                        v-if="smallLabelName"
                        color="#423F4F"
                        right
                      >
                        <template #activator="{ props }">
                          <span
                            class="clickable"
                            :style="{
                              'margin-left': trimmedFilter === '' && '-5px' || '0px',
                            }"
                            v-bind="props"
                          >
                            {{ label.name }}
                          </span>
                        </template>
                        <span style="color: white">
                          {{ label.name }}
                        </span>
                      </v-tooltip>
                      <span v-else>
                        {{ label.name }}
                      </span>
                      <v-tooltip
                        v-if="selectedLabelName === label.name"
                        color="#423F4F"
                        right
                      >
                        <template #activator="{ props }">
                          <v-icon
                            class="clickable edit-name-icon"
                            size="12"
                            v-bind="props"
                            @mouseover="selectLabel(label.name)"
                            @click="startNameEdit(label.id, label.name)"
                          >
                            fas fa-pen
                          </v-icon>
                        </template>
                        <span style="color: white">
                          {{ $t("models.edit_name") }}
                        </span>
                      </v-tooltip>
                    </div>
                    <div
                      v-if="editName === label.id && !smallLabelName"
                      id="labelNameFieldContainer"
                      class="vertical-centered label-name-container-inner"
                    >
                      <input
                        ref="labelNameField"
                        v-model="newNameForLabel"
                        class="label-name-field"
                        type="text"
                        @keydown.enter="saveLabelName"
                        @keydown.esc="editName = -1"
                      >
                      <v-icon
                        class="clickable edit-name-icon"
                        size="12"
                        @mouseover="selectLabel(label.name)"
                        @click="saveLabelName"
                      >
                        fas fa-check
                      </v-icon>
                    </div>
                    <div
                      v-if="editName === label.id && smallLabelName"
                      class="floating-label-field-container"
                    >
                      <input
                        ref="labelNameField"
                        v-model="newNameForLabel"
                        type="text"
                        class="label-name-field"
                        @keydown.enter="saveLabelName"
                        @keydown.esc="editName = -1"
                      >
                      <v-icon
                        class="clickable edit-name-icon"
                        style="top: 6px; right: 5px;"
                        size="12"
                        @mouseover="selectLabel(label.name)"
                        @click="saveLabelName"
                      >
                        fas fa-check
                      </v-icon>
                    </div>
                  </div>
                  <div
                    v-if="
                      currentFile.annotations &&
                        currentFile.annotations[label.name]
                    "
                    class="annotations-container"
                  >
                    <MaxWidthChip
                      v-for="annotation in currentFile.annotations[
                        label.name
                      ]"
                      :ref="`annotationChip${annotation.id}`"
                      :key="annotation.id"
                      :color="getColor(i)"
                      :text-array="annotation.text"
                      @hover="handleZoomScroll(annotation)"
                      @close-click="handleManual(annotation.id, label.name)"
                      closeable
                    />
                  </div>
                  <div class="icon-bg" />
                  <v-icon
                    v-if="selectedLabelName === label.name"
                    class="clickable close-icon"
                    size="12"
                    @click="deleting = label.id; labelDeleteDialog = true;"
                    @mouseover="selectLabel(label.name)"
                  >
                    fas fa-times
                  </v-icon>
                  <div
                    v-if="selectedLabelName === label.name"
                    class="label-buttons"
                  >
                    <div class="vertical-centered">
                      <v-tooltip
                        color="#423F4F"
                        right
                      >
                        <template #activator="{ props }">
                          <div
                            class="clickable edit-icon"
                            v-bind="props"
                            @click="handleAnnotationClick(label.id)"
                          >
                            <v-icon
                              v-if="annotating === label.id"
                              class="vertical-centered"
                              style="margin-top: -1px"
                              color="primary"
                              size="16"
                            >
                              fas fa-check
                            </v-icon>
                            <v-icon
                              v-else
                              class="vertical-centered edit-icon-icon"
                              color="primary"
                              size="16"
                            >
                              fas fa-pen
                            </v-icon>
                          </div>
                        </template>
                        <span style="color: white">
                          {{
                            annotating === label.id
                              ? $t("models.finish_annotation")
                              : $t("models.start_annotation")
                          }}
                        </span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="
                          currentFile.annotations &&
                            currentFile.annotations[label.name] &&
                            currentFile.annotations[label.name].length > 0
                        "
                        color="#423F4F"
                        right
                      >
                        <template #activator="{ props }">
                          <div
                            class="clickable edit-icon top-gap-sm"
                            v-bind="props"
                            @click="deletingAnnotations = label.name; deleteAnnotationsDialog = true;"
                          >
                            <v-icon
                              class="vertical-centered"
                              style="margin-top: -1px"
                              color="primary"
                              size="16"
                            >
                              fas fa-trash
                            </v-icon>
                          </div>
                        </template>
                        <span style="color: white">
                          {{ $t("models.remove_all_annotations") }}
                        </span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <div
                  v-if="trimmedFilter !== '' && filteredLabels.length === 0"
                  class="no-labels"
                >
                  {{ $t('studio.no_labels') }}
                </div>
              </draggable>
              <v-text-field
                v-model="newLabel"
                class="inline-middle right-gap label-field"
                variant="outlined"
                color="primary"
                density="compact"
                :placeholder="$t('models.type_label_name')"
                @keydown.enter="addLabel"
              />
              <v-btn
                class="add-button"
                color="primary"
                style="margin-top: -20px"
                :disabled="trimmedLabel === ''"
                @click="addLabel"
                rounded
              >
                <v-icon
                  size="16"
                  start
                >
                  fas fa-plus
                </v-icon>
                {{ $t("docTypes.add") }}
              </v-btn>
            </div>
          </div>
          <LocationLabelList
            v-if="dataset.advanced"
            ref="locationLabelList"
            :annotating="annotating"
            :annotations="currentFile.locationAnnotations"
            :deleting="deleting"
            :location-labels="locationLabels"
            :selected-label-name="selectedLabelName"
            :filter="filter"
            :label-type="labelType"
            :small-label-name="smallLabelName"
            @calculate-height="calculateHeight"
            @delete-annotations="setDeleteLocationAnnotations"
            @get-location-labels="getLocationLabels"
            @set-annotating="(id) => annotating = id"
            @set-delete="setDeleteLocationLabel"
            @select-label="(name) => selectedLabelName = name"
            @update-annotations="updateAnnotations"
            @change-type="t => labelType = t"
          />
          <DocumentLabels
            v-if="dataset.advanced"
            ref="documentLabelsComponent"
            :file="currentFile"
            :filter="filter"
            @calculate-height="calculateHeight"
            @refresh-file="fileInfo = { ...currentFile }"
            @editing-doc-label="e => editingDocLabel = e"
          />
          <DocNavigator
            ref="docNavigatorBottom"
            v-model="selectedId"
            class="top-gap"
            type="entry"
            :style="{ display: showLower ? 'block' : 'none !important' }"
            :docs="files"
            :ordinal-selected="ordinalSelected"
            :total-docs="totalEntries"
            :show-number="!labelFilter"
            @get-more="getEntries(numberOfFiles)"
            @delete-click="handleDeleteButton"
            @step="handleDocStep"
            deleteable
            can-copy-filename
          />
        </div>
      </div>
    </div>
    <div
      class="divider"
      :style="{
        left: `${dividerPosition}%`
      }"
      @mousedown="startDragging"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192 512"
        style="position:absolute; top: 50%; transform: translateY(-50%); fill: gray;"
      >
        <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V448c0 17.7 14.3 32 32 32s32-14.3 32-32V64zm128 0c0-17.7-14.3-32-32-32s-32 14.3-32 32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V64z" />
      </svg>
    </div>
    <DocViewer
      ref="filesDocViewer"
      style="flex-grow: 1"
      resource-type="entry"
      type="annotation"
      :style="{ width: `${100 - dividerPosition}% !important` }"
      :document="currentFile"
      :text-select="annotating != -1"
      :only-show-labels="onlyShowLabels"
      :labels="docViewerLabels"
      :label-type="['annotation', 'location'][labelType]"
      @update-coordinates="updateCoordinates"
      @delete-annotation="
        (annotation) => handleManual(annotation[0], annotation[1], annotation[2])
      "
      @select-label="handleLabelSelect"
      @change-label="changeLabel"
      @update-annotations="refreshAnnotations"
    />
    <DeleteDialog
      v-model="deleteAnnotationsDialog"
      :title="$t('models.remove_all_annotations')"
      :message="$t('models.remove_annotations_confirmation')"
      @confirm="removeAnnotations"
      @close="ddeleteAnnotationsDialog = false"
    />
    <DeleteDialog
      v-model="labelDeleteDialog"
      :title="$t('models.delete_label')"
      :message="$t('models.label_delete_confirmation')"
      @confirm="deleteLabel"
      @close="labelDeleteDialog = false"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('datatable.deleteFile')"
      :message="$t('datatable.deleteConfirmation')"
      @confirm="deleteFile"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { EntryAPI } from '@/API/extract/EntryAPI';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { AnnotationAPI } from '@/API/extract/AnnotationAPI';
import { LabelAPI } from '@/API/extract/LabelAPI';
import _ from 'lodash';

import annotation_mixin from '@/mixins/annotation';
import model_mixin from '@/mixins/model';
import dataset_mixin from '@/mixins/dataset.js';

import DocViewer from "@/components/extract/elements/DocViewer/DocViewer.vue";
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import DocNavigator from "@/components/extract/elements/Documents/DocNavigator";
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import DocumentLabels from '@/components/extract/elements/Annotations/DocumentLabels';
import ItemName from '@/components/common/elements/General/ItemName';
import LocationLabelList from '@/components/extract/elements/Annotations/LocationLabelList';

export default {
  name: "AnnotationView",

  mixins: [
    annotation_mixin,
    model_mixin,
    dataset_mixin,
  ],

  components: {
    DeleteDialog,
    DocViewer,
    DocNavigator,
    DocumentLabels,
    draggable: VueDraggableNext,
    ItemName,
    LocationLabelList,
    MaxWidthChip,
  },

  data() {
    return {
      labelType: 0,
      labelTypes: {
        annotation: 0,
        location: 1,
      },
      annotating: -1,
      controlPressed: false,
      dataset: {
        id: -1,
        name: "",
      },
      defaultLimit: 20,
      deleteDialog: false,
      deleting: -1,
      deletingAnnotations: '',
      deleteAnnotationsDialog: false,
      deletingLocationItem: false,
      dividerPosition: 40,
      labelDeleteDialog: false,
      drag: false,
      editName: -1,
      editOldName: "",
      files: [],
      filter: "",
      heightKey: 1,
      labelFilter: null,
      labels: [],
      savedLabels: [],
      locationLabels: [],
      maxPanelPercentage: 49.25,
      minLeftPanelWidth: 339,
      mouseDirection: '',
      mouseX: 0,
      newLabel: "",
      newNameForLabel: "",
      offset: 0,
      ordinalSelected: 0,
      renaming: false,
      repositioning: false,
      selectedId: -1,
      selectedLabelName: "",
      showLower: false,
      smallChip: false,
      smallChipWidth: '90px',
      autoChipWidth: 'auto',
      smallLabelName: false,
      smallLabelWidth: '50px',
      autoLabelWidth: 'auto',
      totalEntries: 0,
      lastAnnotations: {action: null, annotations: []},
      lastUndo: {action: null, annotations: []},
      fileInfo: null,
      editingDocLabel: false,
    };
  },

  computed: {
    datasetId() {
      if (this.params && this.params.datasetId) {
        return this.params.datasetId;
      }
      return this.$route.params.id;
    },

    params() {
      return this.$store.getters.annotationParams;
    },

    user() {
      return this.$store.getters.loggedInUser;
    },
  
    currentFile() {
      return this.fileInfo || { id: -1, pages: [] };
    },

    docViewerLabels() {
      if (this.labelType === 0) {
        return this.labels.map((l) => l.name)
      } else if (this.labelType === 1) {
        return this.locationLabels.map((l) => l.name);
      }
      return [];
    },

    labelSelectDisabled() {
      return this.labels.length === 0;
    },

    labelOptions() {
      return this.labels.map(
        l => {
          return {
            text: l.name,
            value: l.name,
          }
        }
      );
    },

    annotationMode: {
      get() {
        return this.$store.getters.annotationMode;
      },
      set(mode) {
        this.$store.commit('setAnnotationMode', mode);
      },
    },

    automaticMode: {
      get() {
        return this.$store.getters.automaticMode;
      },
      set(value) {
        this.$store.commit('setautomaticMode', value);
      },
    },

    filteredLabels() {
      return this.labels.filter(label => label.name.toLowerCase().includes(this.trimmedFilter));
    },

    leftHeight() {
      window.innerHeight;
      this.heightKey;
      const container = this.$refs.leftFieldInner;
      if (container) {
        return container.getBoundingClientRect().height;
      }
      return 0;
    },

    datasets: {
      get() {
        return this.$store.getters.datasets;
      },
      set(datasets) {
        this.$store.commit("setDatasets", datasets);
      },
    },

    models: {
      get() {
        return this.$store.getters.customModels;
      },
      set(models) {
        this.$store.commit("setCustomModels", models);
      },
    },

    trimmedFilter() {
      return this.filter.trim().toLowerCase();
    },

    onlyShowLabels() {
      const labels = [];
      if (this.annotatingLabel !== "") {
        labels.push(this.annotatingLabel);
      }
      if (this.selectedLabelName !== "") {
        labels.push(this.selectedLabelName);
      }
      return labels;
    },

    trimmedNewNameForLabel() {
      return this.newNameForLabel.trim().toUpperCase();
    },

    trimmedLabel() {
      return this.newLabel.trim();
    },

    annotatingLabel() {
      if (this.annotating !== -1) {
        let label;
        if (this.labelType === this.labelTypes.annotation && this.labels.length > 0) {
          label = this.labels.find((l) => l.id === this.annotating);
        } else if (this.labelType === this.labelTypes.location && this.locationLabels.length > 0) {
          label = this.locationLabels.find((l) => l.id === this.annotating)
        }
        if (label) {
          return label.name;
        }
      }
      return "";
    },

    numberOfFiles() {
      return this.files.length;
    },
  },

  watch: {
    selectedId(newId, oldId) {
      if (newId !== oldId && newId > 0) {
        this.selectFile(newId);
      }
    },

    async labelType(type) {
      if (type === this.labelTypes.annotation) {
        this.labels = [];
        await Promise.all([
          this.getDataset(this.datasetId),
          this.getLabels()
        ]);
        if (this.params && this.params.labelFilter) {
          this.labelFilter = this.params.labelFilter;
        }
        if (this.labels.length > 0) {
          this.selectedLabelName = this.labels[0].name;
        }
        this.handleKeys();
      }
      this.handleResize();
      this.annotating = -1;
    },

    filteredLabels() {
      this.calculateHeight();
    },

    locationLabels() {
      this.calculateHeight();
    },

    leftHeight() {
      this.showLower = this.leftHeight >= window.innerHeight;
    },

    ordinalSelected(ordinal) {
      this.handleOrdinalSelected(ordinal);
    },

    menuOpen() {
      return this.$store.getters.menuOpen;
    },
  },

  async mounted() {
    this.$store.commit("setFullScreen", true);
    this.$store.commit('setMenu', false);
    this.labels = [];
    await Promise.all([
      this.getDataset(this.datasetId),
      this.getLabels(),
      this.getLocationLabels()
    ]);
    this.setInitialLabelFilter();
    this.setSelectedLabel();
    await this.getEntries();
    this.setInitialEntry();
    this.handleKeys();
  },

  unmounted() {
    const multipaneRef = this.$refs.multipane;
    if (multipaneRef) {
      multipaneRef.$el.removeEventListener('mousemove', this.getMouseDirection);
    }
    this.stopKeysListener();
    this.ordinalSelected = 0;
    this.selectedId = -1;
  },

  methods: {
    setSelectedLabel() {
      if (this.labels.length > 0) {
        this.selectedLabelName = this.labels[0].name;
      }
    },

    setInitialEntry() {
      if (this.numberOfFiles > 0) {
        if (this.params && this.params.entryId && this.params.entryId > 0) {
          this.selectedId = this.params.entryId;
        } else {
          this.selectedId = this.files[0].id;
        }
      }
    },

    setInitialLabelFilter() {
      if (this.params && this.params.labelFilter) {
        this.labelFilter = this.params.labelFilter;
      }
    },

    startDragging() {
      document.addEventListener('mousemove', this.handleResize);
    },

    endDragging() {
      document.removeEventListener('mousemove', this.handleResize);
    },

    clearSelection() {
      if(document.selection && document.selection.empty) {
        document.selection.empty();
      } else if(window.getSelection) {
          var sel = window.getSelection();
          sel.removeAllRanges();
      }
    },

    getMouseDirection(e) {
      if (e.pageX > this.mouseX) {
        this.mouseDirection = 'right';
      } else if (e.pageX < this.mouseX) {
        this.mouseDirection = 'left';
      }
      this.mouseX = e.pageX;
    },

    resizeDocNav(leftFieldOuter, docNavigatorRef) {
      const {
        docStepper: docStepperRef,
        docSelect: docSelectRef,
      } = docNavigatorRef.$refs;
      if (leftFieldOuter.offsetWidth < 530) {
        docStepperRef.$el.classList.remove('d-flex');
        docStepperRef.$el.classList.add('d-none');
      } else {
        docStepperRef.$el.classList.remove('d-none');
        docStepperRef.$el.classList.add('d-flex');
      }
      const { customSelect } = docSelectRef.$refs;
      if (leftFieldOuter.offsetWidth < 405) {
        docSelectRef.$el.classList.remove('doc-selector');
        docSelectRef.$el.style['max-width'] = '100%';
        customSelect.style.width = 'calc(100% - 80px)';
      } else {
        docSelectRef.$el.classList.add('doc-selector');
        docSelectRef.$el.style['max-width'] = '400px';
        customSelect.style.width = docSelectRef.small ? '275px' : '300px';
      }
    },

    applyWidthToElements(elements, newWidth) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.width = newWidth;
      }
    },

    applyWidthToChipRefs(chipRefs, chipContents, newWidth, small) {
      for (let i = 0; i < chipRefs.length; i++) {
        chipRefs[i].$el.style['width'] = newWidth;
        chipContents[i].style['min-width'] = small ? '90px' : '100px';
        chipRefs[i].tooSmall = small;
      }
    },

    applyMarginToElements(elements, marginKey, margin) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style[marginKey] = margin;
      }
    },

    resizeLabelContainers(leftFieldOuter) {
      const innerContainers = document.getElementsByClassName('label-name-container-inner');
      const containers = document.getElementsByClassName('label-name-container');
      const annotationContainers = document.getElementsByClassName('annotations-container');
      if (leftFieldOuter.offsetWidth < 510) {
        if (innerContainers[0] && innerContainers[0].style.width !== this.smallLabelWidth) {
          this.smallLabelName = true;
          this.applyWidthToElements(containers, this.smallLabelWidth);
          this.applyWidthToElements(innerContainers, this.smallLabelWidth);
          this.applyMarginToElements(
            annotationContainers,
            'margin-left',
            '60px',
          );
          this.applyMarginToElements(
            annotationContainers,
            'margin-right',
            '0px',
          );
        }
      } else {
        if (innerContainers[0] && innerContainers[0].style.width === this.smallLabelWidth) {
          this.smallLabelName = false;
          this.applyWidthToElements(containers, this.autoLabelWidth);
          this.applyWidthToElements(innerContainers, this.autoLabelWidth);
          this.applyMarginToElements(
            annotationContainers,
            'margin-left',
            '210px',
          );
          this.applyMarginToElements(
            annotationContainers,
            'margin-right',
            'auto',
          );
        }
      }
    },

    resizeDocLabels(leftFieldOuter) {
      const small = leftFieldOuter.offsetWidth < 400;
      const textField = document.getElementById('addDocLabelField');
      if (small) {
        textField.classList.remove('inline-middle');
        textField.style.display = 'block';
      } else {
        textField.classList.add('inline-middle');
        textField.style.display = '';
      }
    },

    resizeLocationLabels(leftFieldOuter, maxWidth = 450) {
      const locationLabelList = this.$refs.locationLabelList;
      if (locationLabelList && leftFieldOuter.offsetWidth <= maxWidth) {
        const annotationContainers = document.getElementsByClassName('location-annotations-container');
        nextTick(() => {
          this.applyMarginToElements(
            annotationContainers,
            'margin-left',
            '60px',
          );
        })
      }
    },

    resizeDocViewer: _.throttle((filesDocViewer, mouseDirection) => {
      const zoom = mouseDirection === 'left' ? 0.32 : -0.32;
      filesDocViewer.handleZoom(zoom);
    }, 110),

    handleResize(e = null) {
      if (e) {
        const sidebarWidth = this.menuOpen ? 250 : 61;
        const relativeX = e.pageX - sidebarWidth;
        
        const [parent,] = document.getElementsByClassName('annotation-view');
        const parentWidth = parent.offsetWidth;
        
        const minWidth = this.minLeftPanelWidth + sidebarWidth;
        const percentage = (relativeX / parentWidth) * 100;
        if (
          relativeX >= minWidth
          && percentage <= this.maxPanelPercentage
          && percentage >= 10 && percentage <= 90
        ) {
          this.dividerPosition = percentage;
        }

        const { filesDocViewer } = this.$refs;
        if (filesDocViewer) {
          this.resizeDocViewer(filesDocViewer, this.mouseDirection);
        }
      }

      const [leftFieldOuter,] = document.getElementsByClassName('left-field'); 
      this.resizeDocNav(leftFieldOuter, this.$refs.docNavigatorTop);
      this.resizeDocNav(leftFieldOuter, this.$refs.docNavigatorBottom);
      if (this.labelType >= 0) {
        this.resizeLabelContainers(leftFieldOuter, this.labelType);
        if (this.labelType === this.labelTypes.location) {
          this.resizeLocationLabels(leftFieldOuter);
        }
      }
      this.clearSelection();
    },

    handleDragAnnotation(event) {
      if (this.trimmedFilter === '') {
        this.handleDrag(event);
      } else {
        this.labels = [...this.savedLabels];
      }
    },

    refreshAnnotations() {
      this.getAnnotations();
      this.getLocationAnnotations();
    },

    async handleOrdinalSelected(ordinal) {
      const index = ordinal - 1;
      let file;
      if (ordinal <= this.numberOfFiles) {
        file = this.files[index];
      }
      if (!file || file.type === 'placeholder') {
        await this.getEntries(index);
        file = this.files[index];
      }
      this.selectedId = file.id;
    },

    async saveName(id, newName) {
      const name = newName.trim();
      if (name !== '') {
        try {
          this.$store.commit('setLoadingScreen', true);
          await DatasetAPI.put(id, name);
          this.dataset.name = name;
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.renaming = false;
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },

    handleEditName() {
      this.renaming = true;
    },

    async labelFilterSelect() {
      this.files = [];
      this.selectedId = -1;
      await this.getEntries();
      if (this.numberOfFiles > 0) {
        this.selectedId = this.files[0].id;
      }
      this.clearUndoActions();
    },

    handleLabelSelect(name, labelArray = 'labels') {
      this.selectedLabelName = name;
      const label = this[labelArray].find(l => l.name === name);
      if (label) {
        const labelDivs = this.$refs[`${labelArray.substring(0, labelArray.length - 1)}${label.id}`];
        if (labelDivs) {
          labelDivs[0].scrollIntoViewIfNeeded();
        }
      }
    },

    async selectFile(id) {
      let file;
      let index = this.files.findIndex(f => f.id === id);
      if (index > -1 && this.files[index].pages) {
        file = this.files[index];
      } else {
        [file, index] = await Promise.all([
          this.getEntry(id),
          this.getIndex(id),
        ]);
      }      
      if (file.id !== -1) {
        this.fileInfo = file;
        this.refreshAnnotations();
        this.ordinalSelected = index + 1;
        this.files[index] = file;
      }
      this.clearUndoActions();
    },

    handleDocStep(step) {
      this.ordinalSelected += step;
    },

    selectLabel(name) {
      if (!this.drag && !this.repositioning) {
        this.selectedLabelName = name;
      }
    },

    handleZoomScroll({ page_nb, id }) {
      const docViewer = this.$refs.filesDocViewer;
      if (docViewer) {
        docViewer.currentPage = page_nb;
        const highlights = docViewer.$refs.annotationHighlights;
        if (highlights) {
          const highlightArray = highlights.$refs[`annotation${id}`];
          if (highlightArray && highlightArray.length > 0) {
            const highlight = highlightArray[0];
            highlight.scrollIntoViewIfNeeded();
          }
        }
      }
    },

    async deleteFile() {
      await EntryAPI.delete(this.selectedId);
      this.files = this.files.filter(f => f.id !== this.selectedId);
      this.finishDeletion();
      this.clearUndoActions();
    },

    async finishDeletion() {
      await this.getEntries();
      this.deleteDialog = false;
      if (this.totalEntries > 0) {
        if (this.ordinalSelected > 1) {
          this.ordinalSelected--;
        } else {
          this.selectedId = this.files[0].id;
        }
      } else {
        this.handleBack();
      }
      if (this.labels.length > 0) {
        this.selectedLabelName = this.labels[0].name;
      }
      await this.$store.commit(
        "setSuccessMessage",
        this.$t("models.files_deleted_message")
      );
      this.$store.commit("setSuccessSnackbar", true);
    },

    handleDeleteButton() {
      this.deleteDialog = true;
    },

    stopKeysListener() {
      window.removeEventListener("keydown", this.handleKeydown);
      window.removeEventListener("keyup", this.handleKeyup);
    },

    handleKeys() {
      window.addEventListener("keydown", this.handleKeydown);
      window.addEventListener("keyup", this.handleKeyup);
    },

    handleKeyup(event) {
      if (event.key.toLowerCase() === "control") {
          this.handleAnnotationControlUp(event);
      }
    },

    async handleKeydown(event) {
      switch (event.key.toLowerCase()) {
        case "escape":
          this.stopAnnotating(event);
          break;
        case "enter":
          this.handleAnnotationEnter(event);
          break;
        case " ":
          if (this.$refs.docSearch && !this.$refs.docSearch.on) {
            this.handleAnnotationEnter(event);
          }
          break;
        case "arrowright":
          this.handleAnnotationArrows(1, event);
          break;
        case "arrowdown":
          this.handleAnnotationArrows(1, event);
          break;
        case "tab":
          this.handleAnnotationArrows(1, event);
          break;
        case "arrowleft":
          this.handleAnnotationArrows(-1, event);
          break;
        case "arrowup":
          this.handleAnnotationArrows(-1, event);
          break;
        case "control":
          this.handleAnnotationControl(event);
          break;
        case "m":
          this.handleAnnotationMode(event);
          break;
        case "z":
          this.undo(event);
          break;
        case "y":
          this.redo(event);
          break;
        case "d":
          this.copyFilename(event);
          break;
        default:
          this.controlPressed = false;
          break;
      }
    },

    handleAnnotationControlUp(event) {
      event.preventDefault();
      this.controlPressed = false;
    },

    handleAnnotationControl(event) {
      event.preventDefault();
      this.controlPressed = true;
    },

    getNextOrdinal() {
      let current = this.ordinalSelected;
      if (current === this.totalEntries) {
        current = 0;
      }
      return current + 1;
    },

    handleAnnotationEnter(event) {
      if (this.trimmedNewNameForLabel === "") {
        if (this.controlPressed) {
          event.preventDefault();
          this.ordinalSelected = this.getNextOrdinal();
          this.clearUndoActions();
        } else {
          this.handleAnnotationKey(event);
        }
      }
    },

    handleAnnotationArrows(indicator, event) {
      if (this.controlPressed) {
        event.preventDefault();
        this.$refs.filesDocViewer.nextPage(indicator);
      } else {
        this.annotateNext(indicator, event);
      }
    },

    handleAnnotationKey(event) {
      if (
        !this.controlPressed &&
        !this.editingDocLabel &&
        this.trimmedLabel === "" &&
        this.selectedLabelName !== "" &&
        this.editName === -1
      ) {
        event.preventDefault();
        let label;
        if (this.labelType === this.labelTypes.annotation) {
          label = this.labels.find(
            (l) => l.name === this.selectedLabelName
          )
        } else if (this.labelType === this.labelTypes.location) {
          label = this.locationLabels.find(
            (l) => l.name === this.selectedLabelName
          )
        }
        if (label) {
          this.handleAnnotationClick(label.id);
        }
        this.controlPressed = false;
      }
    },

    handleAnnotationMode(event) {
      if (this.controlPressed) {
        event.preventDefault();
        this.annotationMode = this.annotationMode === 'text' ? 'lines' : 'text';
        this.$store.commit(
          'setSuccessMessage',
          this.$t(
            'models.changed_annotation_mode',
            {
              mode: this.$t(`models.annotation_${this.annotationMode}`)
            })
        );
        this.$store.commit('setSuccessSnackbar', true);
      }
    },
    async undo(event){
      if (this.controlPressed){
        event.preventDefault();
        if (this.lastAnnotations && this.lastAnnotations.action){
          this.lastUndo = await this.handleInverseAction(this.lastAnnotations.action, this.lastAnnotations.annotations);
          await this.getAnnotations();
          this.$refs.filesDocViewer.forceCompute = Math.random();
          this.lastAnnotations = {action: null, annotations: []};
        }
      }
    },

    async copyFilename(event) {
      if (this.controlPressed){
        const navigator = this.$refs.docNavigator;
        if (navigator) {
          const select = navigator.$refs.docSelect;
          if (select) {
            select.copyFilename();
            event.preventDefault();
          }
        }
      }
    },

    async redo(event){
      if (this.controlPressed){
        event.preventDefault();
        if (this.lastUndo && this.lastUndo.action){
          this.lastAnnotations = await this.handleInverseAction(this.lastUndo.action, this.lastUndo.annotations)
          await this.getAnnotations();
          this.$refs.filesDocViewer.forceCompute = Math.random();
          this.lastUndo = {action: null, annotations: []};
        }
      }
    },

    async handleInverseAction(action, annotations) {
      let inverseAction = null;
      switch(action){
        case "delete":
          if (annotations.length){
            for (let annotation of annotations){
              await this.handleCtrl(annotation.id, annotation.label_name);
            }
            inverseAction = 'annotate'
          }
          break;
        case "annotate":
          annotations = await this.annotateBatch(annotations);
          inverseAction = 'delete';
          break;
        default:
          this.controlPressed = false;
          break;
      }
      return {action: inverseAction, annotations: annotations};
    },

    getNextIndex(index, step, numberOfLabels) {
      let incrementedIndex = index + step;
      if (incrementedIndex < 0) {
        incrementedIndex = numberOfLabels - 1;
      }
      return incrementedIndex % numberOfLabels;
    },

    annotateNext(step, event) {
      if (this.selectedLabelName !== "") {
        event.preventDefault();
        let label;
        if (this.labelType === this.labelTypes.location && this.locationLabels.length > 1) {
          const numberOfLabels = this.locationLabels.length;
          const index = this.locationLabels
            .map((l) => l.name)
            .indexOf(this.selectedLabelName);
          const nextIndex = this.getNextIndex(index, step, numberOfLabels);
          label = this.locationLabels[nextIndex];
        } else {
          if (this.labels.length > 1) {
            const numberOfLabels = this.labels.length;
            const index = this.labels
              .map((l) => l.name)
              .indexOf(this.selectedLabelName);
            const nextIndex = this.getNextIndex(index, step, numberOfLabels);
            label = this.labels[nextIndex];
          }
        }
        this.selectedLabelName = label.name;
        let labelBoxArray
        if (this.labelType === this.labelTypes.location) {
          labelBoxArray = this.$refs[`locationLabel${label.id}`];
        } else {
          labelBoxArray = this.$refs[`label${label.id}`];
        }
        if (labelBoxArray && labelBoxArray.length > 0) {
          labelBoxArray[0].scrollIntoViewIfNeeded();
        }
        if (this.annotating !== -1) {
          this.annotating = label.id;
        }
        this.controlPressed = false;
      }
    },

    async changeLabel(change) {
      const id = change[0];
      const label = change[1];
      const oldLabelName = change[2];
      const annotationType = change[3] || 'annotations';
      const annotation = this.currentFile[annotationType][oldLabelName].find(
        (a) => a.id === id
      );
      annotation.label_name = label;
      await this.removeAnnotation(id, oldLabelName, annotationType);
      await this.annotateSingle(annotation);
    },

    handleAnnotationClick(id) {
      if (this.annotating === id) {
        this.annotating = -1;
      } else {
        this.annotating = id;
      }
    },

    stopAnnotating(event) {
      event.preventDefault();
      this.annotating = -1;
      this.controlPressed = false;
    },

    getColor(i) {
      return this.$store.getters.annotationColors[i % 9];
    },

    setDeleteLocationAnnotations(name) {
      this.deletingAnnotations = name;
      this.deletingLocationItem = true;
      this.deleteAnnotationsDialog = true;
    },

    setDeleteLocationLabel(id) {
      this.deletingLocationItem = true;
      this.deleting = id;
      this.labelDeleteDialog = true;
    },

    async removeAnnotations() {
      const label = this.deletingAnnotations;
      let annotations;
      if (!this.deletingLocationItem) {
        annotations = [...this.currentFile.annotations[label]];
      } else {
        annotations = [...this.currentFile.locationAnnotations[label]]
      }
      this.lastAnnotations = {action: 'annotate', annotations: annotations};
      const numberOfAnnotations = annotations.length;
      this.deletingAnnotations = '';
      this.deleteAnnotationsDialog = false;
      for (let i = 0; i < numberOfAnnotations; i++) {
        await this.removeAnnotation(
          annotations[i].id,
          label,
          this.deletingLocationItem && 'locationAnnotations' || 'annotations'
        );
      }
      const finalLength = this.deletingLocationItem ?
        this.currentFile.locationAnnotations[label] : this.currentFile.annotations[label].length
      if (finalLength=== 0) {
        this.$store.commit('setSuccessMessage', this.$t('models.annotations_removed'));
        this.$store.commit('setSuccessSnackbar', true);
      }
    },
    async removeAnnotation(id, label, annotationType = 'annotations'){
      try {
        this.$store.commit("setLoadingScreen", true);
        await AnnotationAPI.delete(
          id,
          annotationType === 'annotations' ? 'annotation' : 'location',
        );
        const index = this.currentFile[annotationType][label]
          .map((a) => a.id)
          .indexOf(id);
        this.currentFile[annotationType][label].splice(index, 1);
        this.currentFile[annotationType] = { ...this.currentFile[annotationType] };
        this.labels = [...this.labels];
        this.$refs.filesDocViewer.forceCompute = Math.random();
        this.calculateHeight();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async handleManual(id, label, annotationType = 'annotations') {
      const annotation = this.currentFile[annotationType][label].find(
        (item) => item.id === id
      );
      await this.removeAnnotation(id, label, annotationType);
      this.lastAnnotations = {action: 'annotate', annotations: [annotation]};
    },

    async handleCtrl(id, label){
      await this.removeAnnotation(id, label);
      if (this.lastAnnotations.annotations.length > 0 && this.lastAnnotations.annotations.find(item => item.id === id)){
          this.lastAnnotations.annotations = this.lastAnnotations.annotations.filter(item => item.id !== id);
        }
    },

    async updateCoordinates(coordinates) {
      if (this.labelType === this.labelTypes.annotation) {
        if (this.annotationMode === 'text') {
          await this.annotateText(coordinates);
        } else if (this.annotationMode === 'lines') {
          await this.annotateLines(coordinates);
        }
      } else if (this.labelType === this.labelTypes.location) {
        const delta_x = coordinates.location.x_max - coordinates.location.x_min;
        const delta_y = coordinates.location.y_max - coordinates.location.y_min;
        if (delta_x > 0.001 && delta_y > 0.001) {
          await this.annotateLocation(coordinates);
        } else {
          this.$refs.filesDocViewer.resetDrawing();
        }
      }
      this.handleResize();
    },

    async annotateLines(coordinates) {
      try {
        const entryId = this.selectedId;
        const response = await EntryAPI.postWords(
          entryId,
          coordinates.page_nb,
          coordinates.location,
        );
        if (response.data && response.data.lines) {
          let annotations = [];
          for (let i = 0; i < response.data.lines.length; i++) {
            annotations.push({
              entry_id: entryId,
              label_name: this.annotatingLabel,
              page_nb: coordinates.page_nb,
              text: [response.data.lines[i].text],
              location: [response.data.lines[i].coordinates],
              words: response.data.lines[i].words,
            });
          }
          const data = await this.annotateBatch(annotations);
          this.lastAnnotations = {action: 'delete', annotations: data};
          await this.getAnnotations();
          this.$refs.filesDocViewer.forceCompute = Math.random();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$refs.filesDocViewer.resetDrawing();
      }
    },

    async annotateText(coordinates) {
      try {
        const entryId = this.selectedId;
        const response = await EntryAPI.postWords(
          entryId,
          coordinates.page_nb,
          coordinates.location,
        );
        if (response.data && response.data.text && response.data.location) {
          let annotation = {
            entry_id: entryId,
            label_name: this.annotatingLabel,
            page_nb: coordinates.page_nb,
            text: [response.data.text],
            location: [response.data.location],
            words: response.data.words,
          };
          const annotations = await this.annotate(annotation);
          this.lastAnnotations = {action: 'delete', annotations: annotations};
          await this.getAnnotations();
          this.$refs.filesDocViewer.forceCompute = Math.random();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$refs.filesDocViewer.resetDrawing();
      }
    },

    async annotateLocation(coordinates) {
      try {
        const entryId = this.selectedId;
        let annotation = {
          entry_id: entryId,
          label_name: this.annotatingLabel,
          page_nb: coordinates.page_nb,
          location: [coordinates.location],
        };
        await this.annotate(annotation);
        // TODO: Add ctrl+z support?

        await this.getLocationAnnotations();
        this.$refs.filesDocViewer.forceCompute = Math.random();
      } catch (error) {
        console.log(error);
      } finally {
        this.$refs.filesDocViewer.resetDrawing();
      }
    },

    async annotate(annotation) {
      if (this.automaticMode) {
          return await this.annotateMultiple(annotation);
      } else {
          return [await this.annotateSingle(annotation)];
      }
    },

    async annotateBatch(annotations) {
      try {
        this.$store.commit("setLoadingScreen", true);
        return await AnnotationAPI.postBatch(annotations);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async annotateSingle(annotation) {
      try {
        this.$store.commit("setLoadingScreen", true);
        const response = await AnnotationAPI.postSingle(
          annotation,
          ['annotation', 'location'][this.labelType]
        );
        annotation.id = response.data;
        if (this.labelType === this.labelTypes.annotation) {
          if (!this.currentFile.annotations) {
            this.currentFile.annotations = {};
          }
          if (!this.currentFile.annotations[annotation.label_name]) {
            this.currentFile.annotations[annotation.label_name] = [];
          }
          this.currentFile.annotations[annotation.label_name].push(annotation);
        } else if (this.labelType === this.labelTypes.location) {
          if (!this.currentFile.locationAnnotations) {
            this.currentFile.locationAnnotations = {};
          }
          if (!this.currentFile.locationAnnotations[annotation.label_name]) {
            this.currentFile.locationAnnotations[annotation.label_name] = [];
          }
          this.currentFile.locationAnnotations[annotation.label_name].push(annotation);
        }
        this.$store.commit("setLoadingScreen", false);
        this.calculateHeight();
        return annotation;
      } catch (error) {
        if (error.response && error.response.status === 409) {
          console.log('HTTP 409 Conflict error on POST annotation ignored.')
        } else {
          this.$store.commit("setSnackbar", true);
          console.log(error);
        }
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async annotateMultiple(annotation) {
      try {
        this.$store.commit("setLoadingScreen", true);
        const newAnnotations = await AnnotationAPI.postMultiple(this.automaticMode, annotation);
        if (!this.currentFile.annotations) {
          this.currentFile.annotations = {};
        }
        let annotations = [];
        for (let newAnnotation of newAnnotations) {
          if (!this.currentFile.annotations[newAnnotation.label_name]) {
            this.currentFile.annotations[newAnnotation.label_name] = [];
          }
          this.currentFile.annotations[newAnnotation.label_name].push(newAnnotation);
          annotations.push(newAnnotation);
        }
        this.$store.commit("setLoadingScreen", false);
        this.calculateHeight();
        return annotations;
      } catch (error) {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async getAnnotations() {
      try {
        this.$store.commit("setLoadingScreen", true);
        const annotations = await AnnotationAPI.get(
          this.currentFile.id,
        );
        const nb_annotated = annotations.length;
        this.currentFile.nb_annotated = nb_annotated;
        this.currentFile.annotations = {};
        for (let i = 0; i < nb_annotated; i++) {
          const annotation = annotations[i];
          if (!this.currentFile.annotations[annotation.label_name]) {
            this.currentFile.annotations[annotation.label_name] = [];
          }
          this.currentFile.annotations[annotation.label_name].push(annotation);
        }
        this.labels = [...this.labels];
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async getLocationAnnotations() {
      try {
        this.$store.commit("setLoadingScreen", true);
        const annotations = await AnnotationAPI.get(
          this.currentFile.id,
          'location'
        );
        const nb_annotated = annotations.length;
        this.currentFile.nb_annotated = nb_annotated;
        this.currentFile.locationAnnotations = {};
        for (let i = 0; i < nb_annotated; i++) {
          const annotation = annotations[i];
          if (!this.currentFile.locationAnnotations[annotation.label_name]) {
            this.currentFile.locationAnnotations[annotation.label_name] = [];
          }
          this.currentFile.locationAnnotations[annotation.label_name].push(annotation);
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    getMissingFiles() {
      const offset = this.files.findIndex(f => f.type && f.type === 'placeholder');
      if (offset > -1) {
        this.getEntries(offset);
      }
    },

    async getIndex(id) {
      try {
        let sortDesc = false;
        if (this.params && this.params.sortDesc) {
          sortDesc = this.params.sortDesc;
        }
        const response = await EntryAPI.get(
          this.datasetId, 0, 1, '', this.labelFilter, 'ingested', sortDesc, id
        );
        return parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        return 0;
      }
    },

    async getEntries(offset = 0, limit = 20) {
      if (offset && offset >= this.totalEntries) {
        return;
      }
      try {
        this.$store.commit("setLoadingScreen", true);
        let sortDesc = false;
        if (this.params && this.params.sortDesc) {
          sortDesc = this.params.sortDesc;
        }
        const response = await EntryAPI.get(
          this.datasetId,
          offset,
          limit,
          '',
          this.labelFilter,
          'ingested',
          sortDesc,
        );
        this.totalEntries = parseInt(response.headers['x-total-count'], 10);
        if (this.numberOfFiles === 0) {
          this.files = Array(this.totalEntries).fill({ id: -1, type: 'placeholder' });
        }
        const start = this.files.slice(0, offset);
        const end = this.files.slice(offset + limit);
        this.files = [...start, ...response.data, ...end];
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async getEntry(id) {
      try {
        this.$store.commit("setLoadingScreen", true);
        const entry = await EntryAPI.getSingle(id);
        this.$store.commit("setLoadingScreen", false);
        return entry;
      } catch (error) {
        this.$store.commit("setLoadingScreen", false);
        return {id: -1, status: 'error'};
      }
    },

    startNameEdit(id, name) {
      this.editName = id;
      this.editOldName = name;
      this.newNameForLabel = name;
      nextTick(() => this.$refs.labelNameField[0].focus());
    },

    updateAnnotations(newName, oldName, annotationType) {
      this.files.forEach((file) => {
        if (!file[annotationType]) {
          file[annotationType] = {};
        }        
        if (file[annotationType][oldName]) {
          file[annotationType][oldName].forEach((annotation) => {
            annotation.label_name = newName;
          });
          file[annotationType][newName] = file[annotationType][oldName];
             
          if (newName !== oldName) {
            delete file[annotationType][oldName];
          }
        }
      });
    },

    updateActions(newName, oldName){
      if (this.lastAnnotations.annotations || this.lastUndo.annotations){
          const lastAnnotationsIndex = this.lastAnnotations.annotations.findIndex((annotation) => annotation.label_name === oldName);
          const lastUndoIndex = this.lastUndo.annotations.findIndex((annotation) => annotation.label_name === oldName);
          if (lastAnnotationsIndex !== -1){
            this.lastAnnotations.annotations[lastAnnotationsIndex].label_name = newName;
          }
          if (lastUndoIndex !== -1){
            this.lastUndo.annotations[lastUndoIndex].label_name = newName;
          }
        }
    },

    async saveLabelName() {
      if (this.trimmedNewNameForLabel !== "") {
        try {
          const oldName = this.editOldName;
          const newName = this.trimmedNewNameForLabel;
          await LabelAPI.put(this.editName, newName);
          this.editName = -1;
          this.newNameForLabel = "";
          this.updateAnnotations(newName, oldName, 'annotations');
          this.updateActions(newName, oldName);
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
        this.getLabels();
      }
    },

    async deleteLabel() {
      try {
        await LabelAPI.delete(
          this.deleting,
          this.deletingLocationItem && 'location' || 'annotation',
        );
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      }
      this.deleting = -1;
      this.labelDeleteDialog = false;
      this.clearUndoActions();
      if (!this.deletingLocationItem) {
        this.getLabels();
      } else {
        this.getLocationLabels();
      }
      this.deletingLocationItem = false;
      this.getAnnotations();
      this.getLocationAnnotations();
    },

    async addLabel() {
      if (this.trimmedLabel !== "") {
        try {
          await LabelAPI.post(this.datasetId, this.trimmedLabel);
          this.newLabel = "";
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
        this.getLabels();
      }
    },

    calculateHeight() {
      nextTick(() => this.heightKey++ );
    },

    async getLabels() {
      this.$store.commit("setLoadingScreen", true);
      this.labels = await LabelAPI.get(this.datasetId);
      this.$store.commit("setLoadingScreen", false);
    },

    handleBack() {
      this.$router.push({
        name: "Dataset",
        params: {
          id: this.$route.params.id,
        },
      });
    },

    clearUndoActions(){
      this.lastAnnotations = {};
      this.lastUndo = {};
    }
  },
};
</script>

<style lang="scss" scoped>
.annotation-view {
  height: 100vh;
  position: relative;
  
  .no-labels {
    font-style: italic;
    text-align: center;
    margin-top: 12px;
  }
  .ghost-label {
    background-color: #dbd4fa !important;

    .icon-bg {
      background-color: #dbd4fa !important;
    }
  }

  .divider {
    height: 100vh;
    width: 6px;
    background: #fff;
    transform: translateX(-3px);
    position: absolute;
    top: 0;
    z-index: 1000;
    cursor: ew-resize;
  }

  .multipane-resizer {
    margin: 0; left: 0;
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 3px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -1.5px;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
    &:hover {
      &:before {
        border-color: #999;
      }
    }
  }

  .left-field {
    height: 100vh;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    overflow: auto;
    width: 41%;
    min-width: 286px;
    position: relatve;
    padding: 20px;
    padding-left: 20px;
    padding-top: 0px;
    -webkit-box-shadow: inset -1px 0px 0px 0px rgb(var(--v-theme-grey-darken2));
    -moz-box-shadow: inset -1px 0px 0px 0px rgb(var(--v-theme-grey-darken2));
    box-shadow: inset -1px 0px 0px 0px rgb(var(--v-theme-grey-darken2));

    .label-field {
      width: 230px;
    }

    .add-button {
      top: 8px;
      box-shadow: none;
    }

    .label-container {
      background-color: white;
      width: 100%;
      margin-top: 15px;
      text-transform: uppercase;
      font-size: 0.7rem;
      padding: 10px;
      position: relative;
      min-height: 100px;
      color: #4c4c4c;
      border: #c8c8c8 2px solid;
      overflow: hidden;
      border-radius: 6px;

      .icon-bg {
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        background-color: white;
        width: 55px;
      }

      .label-buttons {
        position: absolute;
        right: 20px;
        top: 0px;
        bottom: 0px;
      }

      .close-icon {
        position: absolute;
        right: 5px;
        top: 5px;
      }

      .edit-name-icon {
        position: absolute;
        right: 0px;
        top: 1px;
      }

      .edit-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: rgb(var(--v-theme-primary)) 1px solid;
        text-align: center;

        .edit-icon-icon {
          margin-top: -3px;
        }
      }

      .annotations-container {
        padding-top: 10px;
        margin-left: 210px;
        max-width: calc(100% - 270px);
        position: relative;
      }

      .label-name-container {
        position: absolute;
        width: 200px;
        left: 0px;
        top: 0px;
        bottom: 0px;
        padding: 10px;

        .floating-label-field-container {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          border-radius: 5px;
          box-shadow: 2px 2px 5px rgb(var(--v-theme-primary-lighten2));
          padding: 5px;
          background-color: #FFF;
          width: 200px !important;
          z-index: 999;
        }

        .label-name-container-inner,
        .floating-label-field-container {
          position: absolute;
          max-width: 200px;
          padding-right: 20px;

          .label-name-field {
            display: inline-block;
            text-transform: uppercase;
            background-color: #ffffff00;
            padding: 0 !important;
            border: none;
            width: 180px;
            border-bottom: 1px solid rgb(var(--v-theme-primary));

            &:focus {
              outline: none;
              border-bottom: 1px solid rgb(var(--v-theme-primary));
            }

            &:focus-visible {
              outline: none;
              border-bottom: 1px solid rgb(var(--v-theme-primary));
            }
          }
        }
      }
    }

    .label-container-active {
      border: rgb(var(--v-theme-primary)) 2px solid;
    }

    .label-container-success {
      border: green 2px solid;
    }
  }

  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }
}
.v-field.v-field-active.v-field-appended.v-field--center-affix.v-field--dirty.v-field--no-label.v-field--variant-outlined::after {
  right: 30px;
}
</style>
