<template>
  <div class="search-highlights">
    <div
      v-for="(element, i) in words"
      :key="i"
      class="highlight-frame"
      :style="{
        left: `${getHighlightX(element.coordinates) - 0.15}vh`,
        top: `${getHighlightY(element.coordinates) - 0.15}vh`,
        width: `${getWidth(element.coordinates) + 0.3}vh`,
        height: `${getHeight(element.coordinates) + 0.3}vh`,
      }"
    />
  </div>
</template>

<script>
export default {
  name: "SearchHighlights",

  methods: {
    getHighlightX(location) {
      return location.x_min * this.pageWidth;
    },

    getHighlightY(location) {
      return location.y_min * this.pageHeight;
    },

    getWidth(location) {
      return (location.x_max - location.x_min) * this.pageWidth;
    },

    getHeight(location) {
      return (location.y_max - location.y_min) * this.pageHeight;
    },
  },

  props: {
    words: {
      type: Array,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
  }
}
</script>

<style lang="scss" scoped>

.search-highlights{
  position: relative;
  height: 100%;

  .highlight-frame {
    position: absolute;
    cursor: pointer;
    background-color: rgba(245, 185, 65, 0.5);
  }
}
</style>
