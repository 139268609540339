<template>
  <div class="search-answer top-gap-sm">
    <h4 class="bottom-gap">
      <v-icon style="margin-right: 5px">
        fas fa-comment-alt
      </v-icon>
      {{ $t('answer') }}
    </h4>
    <div>
      <v-icon v-if="!answer">
        fas fa-spinner fa-pulse
      </v-icon>
      <div
        class="answer-body"
        @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave"
        v-html="parsedMarkdown"
      />
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
  name: 'SearchAnswer',

  computed: {
    parsedMarkdown() {
      let parsedText = this.markdownRenderer.render(this.answer);
      let foundRefs = 0;

      const referenceRegex = /\[(.*?)\]/g;
      parsedText = parsedText.replace(referenceRegex, (_, content) => {
        const references = content.split(',').map(ref => ref.trim());
        let result = '';
        references.forEach((ref) => {
          const [fileId, pageNumber] = ref.split(':');
          const chunk = this.getChunk(fileId, pageNumber);
          if (chunk) {
            foundRefs++;
            result += `<span class="tooltip image-reference"><a class="link" target="_blank" href="${chunk.url}"><sup data-image-url="${chunk.image}">[${foundRefs}]</sup></a><small class="tooltiptext">${ this.$t('page') } ${ chunk.meta.page } ${ this.$t('search.home.of') } ${chunk.meta.path.split('/').pop()}</small></span>`;
          }
        });
        return result;
      });
      return parsedText;
    },

    searchResults() {
      return this.$store.getters.searchResults;
    },

    answer() {
      return this.$store.getters.searchAnswer;
    },
  },

  created() {
    this.markdownRenderer = new MarkdownIt();
    window.setHoveredImage = this.setHoveredImage.bind(this);
  },

  methods: {
    getChunk(fileId, pageNumber) {
      return this.searchResults.chunks.find(
        chunk => chunk.meta.file_id == fileId && chunk.meta.page == pageNumber
      )
    },

    handleMouseOver(event) {
      if (event.target.tagName === 'SUP' && event.target.dataset.imageUrl) {
        this.setHoveredImage(event.target.dataset.imageUrl);
      } else {
        this.setHoveredImage(null);
      }
    },

    handleMouseLeave() {
      this.setHoveredImage(null);
    },

    setHoveredImage(image) {
      this.$store.commit('setSearchHoveredImage', image);
    },
  },
}
</script>

<style lang="scss" scoped>
sup {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}

::v-deep .tooltip {
  position: relative;
  display: inline-block !important;
}

::v-deep .tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  max-width: 300px;
  color: black;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 2px 4px 3px rgba(#000, 0.03);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .tooltip:hover .tooltiptext {
  visibility: visible;
}

::v-deep .link {
  text-decoration: none !important;
  color: rgb(var(--v-theme-primary)) !important;
  display: inline-block;
}

::v-deep .answer-body {
  display: flex;
  flex-direction: column;
  gap: 20px;

  ol, ul {
    padding-left: 40px;
  }
}

</style>
