<template>
  <h4
    v-if="lastSearches.length > 0"
    class="bottom-gap"
  >
    <v-icon style="margin-right: 5px">
      fas fa-search
    </v-icon>
    <span>
      {{ $t('search.home.recent_searches') }}
    </span>
  </h4>
  <v-card
    v-if="lastSearches.length > 0"
    class="search-history-container bottom-gap"
  >
    <div
      v-for="search in lastSearches"
      :key="search.id"
      class="previous-search font-italic clickable ellipsis"
      @click="repeatSearch(search)"
    >
      <v-icon size="12">
        fas fa-history
      </v-icon>
      <small>
        "{{ search.query }}"
        <span v-if="search.folder">
          <span class="font-weight-bold ml-1">
            {{ `${$t('search.home.in')}${$t('colon')}` }}              
          </span>
          {{ search.folder.name }}
        </span>
        <span v-if="!isEmpty(search.filters)">
          <span class="font-weight-bold ml-1">
            {{ `${$t('search.home.filters')}${$t('colon')}` }}   
          </span>
          {{ Object.keys(search.filters).map(key => `${key}=${search.filters[key]}`).join(', ') }}
        </span>
      </small>
    </div>
  </v-card>
</template>
<script>
import { isEmpty } from "lodash";
import { SearchAPI } from "@/API/search/SearchAPI";

export default {
  name: 'SearchRecentSearches',

  data() {
    return {
      lastSearches: [],
    };
  },

  mounted() {
    this.getQueryHistory();
  },

  methods: {
    isEmpty,

    async getQueryHistory() {
      this.lastSearches = await SearchAPI.getQueryHistory();
    },

    repeatSearch(oldSearch) {
      const { filters, query, folder } = oldSearch;
      this.$store.commit('setStartedSearch', true);
      this.$store.commit('setSearchDisplayFilters', filters);
      this.$store.commit('setSearchString', query);
      this.$store.commit('setSearchFolder', folder && folder.id || null);
      this.$store.commit('setSearchFilters', filters);
      if (this.isEmpty(filters) && !folder) {
        this.$emit('search');
      }
    },
  },

  emits: ['search'],
}
</script>
<style lang="scss" scoped>
.search-history-container {
  .previous-search {
    color: rgb(var(--v-theme-grey-darken4));
    transition: color .5s cubic-bezier(.4, 0, .2, 1);
    &:hover {
      color: rgb(var(--v-theme-primary));
    }
  }
}
</style>
