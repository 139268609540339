import { computed } from 'vue';
import { useStore } from 'vuex';
import { pagerModule } from '@/plugins/store/pager/pagerModule';

export const useTableWithFooter = (tableWithFooterStoreName) => {
  const store = useStore();

  if (!store.state[tableWithFooterStoreName]) {
    store.registerModule(tableWithFooterStoreName, pagerModule);
  }

  return {
    itemsPerPage: computed({
        get() {
          return store.getters[`${tableWithFooterStoreName}/itemsPerPage`];
        },

        set(newItemsPerPage) {
          store.commit(`${tableWithFooterStoreName}/setItemsPerPage`, newItemsPerPage);
        },
    }),

    currentPage: computed({
        get() {
          return store.getters[`${tableWithFooterStoreName}/currentPage`];
        },

        set(newCurrentPage) {
          store.commit(`${tableWithFooterStoreName}/setCurrentPage`, newCurrentPage);
        },
    }),
  };
}
