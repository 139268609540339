export function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

export function datediff(first, second) {        
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}


