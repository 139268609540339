<template>
  <v-dialog
    v-model="openDialog"
    max-width="700"
    @click:outside="$emit('update:model-value', false)"
    @keydown.esc="$emit('update:model-value', false)"
  >
    <v-card class="dialog-card">
      <v-icon
        style="position: absolute; right: 10px; top: 10px;"
        color="black"
        size="17"
        @click="$emit('update:model-value', false)"
      >
        fas fa-times
      </v-icon>
      <h2 class="dialog-title mb-3">
        {{ $t('search.users.get_logs.title') }}
      </h2>
      <div class="d-flex">
        <div>
          <div class="label left-gap">
            {{ $t('from') }}
          </div>
          <v-date-picker
            v-model="from"
            color="primary"
            :allowed-dates="d => d <= today"
            hide-header
          />
        </div>
        <div>
          <div class="label left-gap">
            {{ $t('until') }}
          </div>
          <v-date-picker
            v-model="to"
            color="primary"
            :allowed-dates="d => d >= from && d <= today"
            hide-header
          />
        </div>
      </div>
      <v-checkbox
        v-model="includeAi"
        :label="$t('search.users.get_logs.include_ai')"
      />
      <div class="mt-8 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="$emit('update:model-value', false)"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            :disabled="!to || !from"
            @click="$emit('confirm', [formattedFrom, formattedTo], includeAi)"
            block
            rounded
          >
            {{ $t('search.users.get_logs.button') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GetLogsDialog',

  data() {
    return ({
      today: new Date().getTime(),
      from: null,
      to: null,
      openDialog: false,
      includeAi: true,
    });
  },

  computed: {
    formattedFrom: {
      get() {
        return this.getFormattedDate(this.from);
      },
      set(value) {
        this.setFormattedDate('from', value);
      },
    },

    formattedTo: {
      get() {
        const endDaySeconds = 86399;
        return this.getFormattedDate(this.to, endDaySeconds);
      },
      set(value) {
        this.setFormattedDate('to', value);
      },
    }
  },

  watch: {
    openDialog(open) {
      this.$emit('update:model-value', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  methods: {
    getFormattedDate(date, seconds = 0) {
      return date ? Math.floor(new Date(date).getTime() / 1000 + seconds) : null;
    },

    setFormattedDate(marker, value) {
      if (value) {
        this[marker] = new Date(value).toISOString().slice(0, 10);
      } else {
        this[marker] = null;
      }
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['confirm', 'update:model-value'],
}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}
</style>
