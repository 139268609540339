<template>
  <v-card
    class="node-card right-gap-sm bottom-gap-sm text-center inline-middle noselect"
    :class="{ disabled }"
  >
    <v-skeleton-loader
      v-if="isLoading"
      class="node-card"
      type="ossein"
    />
    <div
      v-else
      class="node-card clickable"
      @click="$emit('add-node')"
    >
      <h4 class="card-title">
        {{ title }}
      </h4>
      <div class="card-tags">
        <v-chip
          v-if="owner"
          class="mr-1 mb-1 small-chip"
          variant="outlined"
          :style="{ 'background-color': getColor(3) }"
        >
          {{ owner }}
        </v-chip>
        <v-chip
          v-if="taskType"
          class="mr-1 mb-1 small-chip"
          variant="outlined"
          :style="{ 'background-color': getColor(2) }"
        >
          {{ $t(`workflows.steps.${taskType}`) }}
        </v-chip>
        <v-chip
          v-for="(supported, supportedIndex) in supports"
          :key="supportedIndex"
          class="mr-1 mb-1 small-chip"
          variant="outlined"
          :style="{ 'background-color': getColor(1) }"
        >
          {{ $t(`workflows.steps.${supported}`) }}
        </v-chip>
        <v-chip
          v-if="type"
          class="mr-1 mb-1 small-chip"
          variant="outlined"
          :style="{ 'background-color': getColor(0) }"
        >
          {{ $t(`workflows.steps.${type}`) }}
        </v-chip>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'NodeCard',

  computed: {
    isLoading() {
      return (this.title || '').endsWith('Loading');
    },
  },

  methods: {
    getColor(num) {
      return `${this.$store.getters.annotationColors[num]}60`;
    }
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    type: {
      type: [String, null],
      required: true,
    },

    taskType: {
      type: [String, null],
      required: true,
    },

    owner: {
      type: [String, null],
      default: null,
    },

    supports: {
      type: Array,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['add-node'],
};
</script>

<style lang="scss" scoped>
.node-card {
  width: 200px;
  height: 185px;
  position: relative;

  .card-title {
    position: absolute;
    top: 10px;
    left: 10px;
    text-align: left;
    width: 150px;
    height: 75px;
    overflow: hidden;
  }

  .card-tags {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    text-align: right;

    .small-chip {
      font-size: 0.7rem;
      height: 20px;
    }
  }
}

.disabled {
  opacity: 0.5 !important;
}
</style>
