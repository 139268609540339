<template>
  <div>
    <ExtractionView
      v-if="$route.params.id"
      :dataset-id="parseInt($route.params.id, 10)"
    />
    <ExtractionView
      v-else
      start-view="training"
    />
  </div>
</template>

<script>
import ExtractionView from '@/components/extract/views/Studio/ExtractionView';

export default {
  name: 'ExtractionTrainingView',

  components: {
    ExtractionView,
  },
}
</script>
