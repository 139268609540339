<template>
  <div
    class="dropdown-container"
  >
    <DropDown
      icon="fas fa-plus"
      :label="$t('datatable.header.add')"
      :items="createItems"
    />
  </div>
</template>

<script>

import DropDown from '@/components/common/elements/General/DropDown.vue';

export default {
  name: 'AddExtractorButton',
  components: {
    DropDown,
  },

  data() {
    return ({
      createItems: [
        {
          label: this.$t('dataPoints.single'),
          icon: 'fas fa-cube',
          action: this.createSingle,
        },
        {
          label: this.$t('dataPoints.group'),
          icon: 'fas fa-cubes',
          iconSize: 17,
          action: this.createGroup,
        },
      ],
    });
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  methods: {
    createFromModel(id) {
      this.$router.push({
        name: 'FromModel',
        params: { id },
      });
    },

    handleEditButton(id) {
      if (this.user.role === 'orgadmin') {
        this.$router.push({
          name: 'datapoints',
          params: { id },
        });
      }
    },

    createSingle() {
      if (this.user.role === 'sysadmin') {
        this.createFromModel(this.$route.params.id);
      } else {
        this.handleEditButton(this.$route.params.id);
      }
    },

    createGroup() {
      this.$router.push({
        name: 'labelgroup',
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
}
</script>

<style lang="scss" scoped>

.dropdown-container {
  position: relative;
  top: -1px;
}

</style>
