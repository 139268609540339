import { DataPointAPI } from '@/API/extract/DataPointAPI';
import { ExtractionGroupAPI } from '@/API/extract/ExtractionGroupAPI';

export async function copyGenerative(extractor, name, agentId) {
  let newId = null;
  if (extractor.type === 'single') {
    newId = await DataPointAPI.post(agentId, name);
    await DataPointAPI.put({
      name,
      category: 'generative',
      id: newId,
    });
  } else if (extractor.type === 'group') {
    newId = await ExtractionGroupAPI.post(agentId, {
      ...extractor,
      name,
      custom_model_id: null,
      custom_model_version: null,
      is_generative: true,
    });
  }
  return newId;
}
