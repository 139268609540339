<template>
  <v-menu
    class="dropdown"
  >
    <template #activator="{ props }">
      <v-btn
        class="primary--text"
        variant="outlined"
        v-bind="props"
        :disabled="disabled"
        rounded
      >
        <v-icon
          v-if="icon"
          size="16"
          start
        >
          {{ icon }}
        </v-icon>
        {{ label }}
        <v-icon
          size="16"
          end
        >
          fas fa-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :class="['clickable', 'dropdown-button', { 'disabled': item.disabled }]"
        @click="item.action()"
      >
        <v-icon
          size="16"
          start
        >
          {{ item.icon }}
        </v-icon>
        {{ item.label }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: 'DropDown',

  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

}
</script>

<style lang="scss" scoped>

.dropdown-button {
  color: rgb(var(--v-theme-primary)) !important;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.8rem !important;
  font-weight: 600;

  &:hover {
    background-color: #f4f5f9;
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

</style>