import notificationMapping from '@/constants/notifications';

export function getNotificationColor(notificationCode) {
  const notification = notificationMapping.get(notificationCode);
  const color = notification && notification.color;

  return color || notificationMapping.get('info').color;
}

export function getNotificationIcon(notificationCode) {
  const notification = notificationMapping.get(notificationCode);
  const icon = notification && notification.icon;

  return icon || notificationMapping.get('info').icon;
}
