import { OrgsAPI } from '@/API/authenticator/OrgsAPI';

export default {
  data() {
    return {
      orgId: -1,
    };
  },

  computed: {
    organizations: {
      get() {
        return this.$store.getters.organizations;
      },
      set(organizations) {
        this.$store.commit('setOrganizations', organizations);
      },
    },

    userRole() {
      return this.$store.getters.loggedInUser.role;
    },
  },

  methods: {
    async getOrganizations() {
      try {
        if (this.organizations.length === 0) {
          const response = await OrgsAPI.getOrgs(100, 0, null, 'name');
          this.organizations = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    if (this.userRole === 'orgadmin') {
      this.orgId = this.$store.getters.loggedInUser.org_id
    } else {
      await this.getOrganizations();
      this.orgId = this.organizations[0].id;
    }
    this.$store.commit('setLoadingScreen', false);
  },
}