<template>
  <div class="regex-info">
    <small>
      <v-icon
        class="info-icon"
        size="16"
      >
        fas fa-info-circle
      </v-icon>
      {{ $t('dataPoints.test_regex') }}
      <a
        href="https://regex101.com/"
        target="_blank"
      >
        https://regex101.com/
      </a>
    </small>
  </div>
</template>

<script>
export default {
  name: 'RegexInfo',
}
</script>

<style lang="scss" scoped>
  .regex-info {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 13px 24px;
    width: calc(100% - 5px);

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }
</style>