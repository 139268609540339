<template>
  <v-dialog
    v-model="openDialog"
    max-width="600"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @keydown.enter="validate()"
  >
    <v-card class="dialog-card">
      <v-icon
        style="position: absolute; right: 10px; top: 10px;"
        color="black"
        size="17"
        @click="$emit('close')"
      >
        fas fa-times
      </v-icon>
      <v-form ref="form">
        <h2 class="dialog-title mb-3">
          {{
            form.provider_id == null 
              ? $t('settings.oidc_providers.add.title') 
              : $t('settings.oidc_providers.update.title') 
          }}
        </h2>
        <div class="label">
          {{ $t('settings.oidc_providers.forms.provider_name') }}
        </div>
        <v-text-field
          v-model="form.provider_name"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="commonFieldRules"
          :error-messages="errorsMessages.provider_name"
          @input="form.provider_name = form.provider_name.replace(/\s/g, '')"
        />
        <div
          class="label"
          style="margin-bottom: 5px"
        >
          {{ $t('settings.oidc_providers.forms.domain') }}
        </div>
        <v-select
          v-model="protocol"
          class="http-select inline-middle"
          variant="outlined"
          color="primary"
          density="compact"
          :items="protocols"
        />
        <v-text-field
          v-model="form.domain"
          class="inline-middle left-gap-sm"
          style="width: 399px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="[...commonFieldRules, isValidUrl]"
          :error-messages="errorsMessages.domain"
        />
        <div class="label">
          {{ $t('settings.oidc_providers.forms.client_id') }}
        </div>
        <v-text-field
          v-model="form.client_id"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="commonFieldRules"
          :error-messages="errorsMessages.client_id"
        />
        <div class="label">
          {{ $t('settings.oidc_providers.forms.client_secret') }}
        </div>
        <v-text-field
          v-model="form.client_secret"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="commonFieldRules"
          :error-messages="errorsMessages.client_secret"
        />
        <div class="form-container mt-3">
          <div
            class="label"
            style="margin-bottom: 5px"
          >
            {{ $t('settings.oidc_providers.forms.remote_config') }}
          </div>
          <div
            v-for="(field, index) in remoteCfg"
            :key="index"
            style="margin-top: -20px"
          >
            <div
              v-if="field.key === 'scope'"
              class="right-gap-sm inline-middle"
              style="width: 210px; margin-top: -15px;"
            >
              Scope:
            </div>
            <v-text-field
              v-else
              v-model="field.key"
              class="mt-0 right-gap-sm inline-middle"
              placeholder="Key"
              variant="outlined"
              color="primary"
              style="width: 210px"
              density="compact"
            />
            <v-text-field
              v-model="field.value"
              class="mt-0 inline-middle"
              placeholder="Value"
              variant="outlined"
              color="primary"
              density="compact"
              clear-icon="fas fa-times"
              style="width: 210px"
              :clearable="field.key !== 'scope'"
              @click:clear="deleteField(index)"
            />
          </div>
          <div style="margin-top: -20px">
            <v-text-field
              v-model="newField.key"
              class="mt-0 right-gap-sm inline-middle"
              style="width: 210px"
              placeholder="Key"
              variant="outlined"
              color="primary"
              density="compact"
              @keydown.enter="addField"
            />
            <v-text-field
              v-model="newField.value"
              class="mt-0 right-gap-sm inline-middle"
              style="width: 210px"
              placeholder="Value"
              variant="outlined"
              color="primary"
              density="compact"
              @keydown.enter="addField"
            />
            <v-btn
              style="box-shadow: none; margin-top: -20px"
              color="primary"
              @click="addField"
              rounded
            >
              <v-icon
                size="16"
                start
              >
                fas fa-plus
              </v-icon>
              {{ $t('add') }}
            </v-btn>
          </div>
        </div>
      </v-form>
      <div class="mt-8 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="resetOidcProvider(); $emit('close');"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            @click="validate()"
            block
            rounded
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { http } from '@/plugins/axios';
import FormRules from '@/utils/classes/FormRules/FormRules';

export default {
  name: 'CreateOidcProvider',

  data() {
    return ({
      commonFieldRules: [FormRules.required],
      isValidUrl: FormRules.isValidUrl,
      valid: true,
      errorsMessages: {},
      openDialog: this.modelValue,
      form: {
        domain: '',
        provider_id: null,
        provider_name: '',
        client_id: '',
        client_secret: '',
      },
      protocol: "https://",
      protocols: ["https://", "http://"],
      remoteCfg: [
        {key: "scope", value: "openid profile email"},
      ],
      newField: {
        key: '',
        value: '',
      },
    });
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },

    editedOidcProvider(provider){
      if (provider) {
        this.form.provider_id = provider.id;
        this.form.provider_name = provider.provider_name;
        this.protocol = provider.domain.startsWith("https://") ? "https://" : "http://"; 
        this.form.domain = provider.domain.replace(/^(https?:\/\/)?/, "");
        this.form.client_id = provider.client_id;
        this.form.client_secret = provider.client_secret;
        this.remoteCfg = this.parseOidcProviderCfg(provider.remote_cfg);
      }
    }
  },

  mounted() {
    this.form.org_id = this.user.org_id;
    this.form.timezone = this.user.timezone;
  },

  methods: {
    validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.createOrUpdateOidcProvider();
      }
    },
    addField() {
      this.remoteCfg.push({...this.newField});
      this.newField = {key: '', value: ''};
    },

    deleteField(index) {
      this.remoteCfg.splice(index, 1);
    },

    createOidcProviderCfg() {
      return this.remoteCfg.reduce((map, obj) => {
        map[obj.key] = obj.value;
        return map;
      }, {});
    },

    resetOidcProvider() {
      this.$refs.form.resetValidation();
      this.commonFieldRules = [FormRules.required];
      this.isValidUrl = FormRules.isValidUrl;
      this.valid = true;
      this.errorsMessages = {};
      this.form = {
        domain: '',
        provider_id: null,
        provider_name: '',
        client_id: '',
        client_secret: '',
      };
      this.remoteCfg = [
        {key: "scope", value: "openid profile email"},
      ];
    },

   parseOidcProviderCfg(OidcProviderCfg) {
     if (typeof OidcProviderCfg !== "undefined") {
       return Object.entries(OidcProviderCfg).map(([key, value]) => ({
         key: key,
         value: value
       }));
     } else {
       return [];
     }
   },

    async createOidcProvider() {
      this.$store.commit('setLoadingScreen', true);
      this.form.domain = this.protocol + this.form.domain.replace(/^(https?:\/\/)?/, '');

      try {
        await http.post('auth/api/v1/openid_providers/', Object.assign({}, this.form, { remote_cfg: this.createOidcProviderCfg()}));
        this.$store.commit('setSuccessMessage', this.$t('settings.oidc_providers.add.success'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
      this.$emit('refresh');
      this.$emit('close');
    },

    async updateOidcProvider() {
      this.$store.commit('setLoadingScreen', true);
      this.form.domain = this.protocol + this.form.domain.replace(/^(https?:\/\/)?/, '');

      try {
        await http.put(`auth/api/v1/openid_providers/${this.form.provider_id}/`, Object.assign({}, this.form, { remote_cfg: this.createOidcProviderCfg()}));
        this.$store.commit('setSuccessMessage', this.$t('settings.oidc_providers.update.success'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
      this.$emit('refresh');
      this.$emit('close');
    },

    async createOrUpdateOidcProvider() {
      if (this.form.provider_id == null) {
        this.createOidcProvider();
      } else {
        this.updateOidcProvider();
      }
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    editedOidcProvider: {
      type: Object,
      required: false,
      default: () => {},
    }
  },

  emits: ['close', 'refresh', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}

.v-application .v-select {
  max-width: 100%;
}

.http-select {
  width: 130px;
  color: rgb(var(--v-theme-grey-darken2)) !important;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
