<template>
  <div class="top-gap-sm search-results-legacy">
    {{ $t('search.home.showing') }}
    <div
      class="results-select clickable"
      :class="{ 'results-select-selected': resultSelect === 'chunks' }"
      @click="resultSelect = 'chunks'"
    >
      {{ searchResults.displayChunks.length }}
      <span v-if="searchResults.displayChunks.length === 1">
        {{ $t('search.home.result') }}
      </span>
      <span v-else>
        {{ $t('search.home.results') }}
      </span>
    </div>
    <span v-if="searchResults.documents.length > 0">
      <span style="margin-right: 5px; margin-left: 5px;">
        {{ $t('search.home.in') }}
      </span>
      <div
        class="results-select clickable"
        :class="{ 'results-select-selected': resultSelect === 'documents' }"
        @click="resultSelect = 'documents'"
      >
        {{ searchResults.documents.length }}
        <span v-if="searchResults.documents.length === 1">
          {{ $t('search.document') }}
        </span>
        <span v-else>
          {{ $t('search.documents') }}
        </span>
      </div>
    </span>
    <v-window v-model="resultSelect">
      <v-window-item value="chunks">
        <div class="top-gap">
          <SearchResult
            v-for="(chunk, i) in searchResults.displayChunks"
            :key="i"
            type="chunk"
            :result="chunk"
            :favorites="favorites"
            @remove-from-favorites="id => $emit('removeFromFavorites', id)"
          />
        </div>
      </v-window-item>
      <v-window-item value="documents">
        <div class="top-gap">
          <SearchResult
            v-for="(document, i) in searchResults.documents"
            :key="i"
            type="document"
            :result="document"
            :favorites="favorites"
            @remove-from-favorites="id => $emit('removeFromFavorites', id)"
          />
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import SearchResult from "@/components/search/views/Home/SearchResult";

export default {
  name: 'SearchResultsLegacy',

  components: {
    SearchResult,
  },

  data() {
    return ({
      resultSelect: 'chunks',
    });
  },

  computed: {
    searchResults() {
      return this.$store.getters.searchResults;
    },
  },

  props: {
    favorites: {
      type: Array,
      required: true,
    },
  },

  emits: [ 'removeFromFavorites' ],
}
</script>

<style lang="scss" scoped>
.results-select {
  display: inline-block;
  font-weight: bold;
}

.results-select-selected {
  border-bottom: 3px solid rgb(var(--v-theme-primary));
}
</style>