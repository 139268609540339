import { http } from '@/plugins/axios';

export default {
  methods: {
    handleDrag(event) {
      this.repositioning = true;
      this.saveLabelPos(event.moved.element.id, event.moved.newIndex);
    },

    async getLabels() {
      try {
        this.$store.commit("setLoadingScreen", true);
        const response = await http.get(
          `dataset/label/${this.datasetId}/`
        );
        this.labels = response.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async getLocationLabels() {
      try {
        this.$store.commit("setLoadingScreen", true);
        const response = await http.get(
          `dataset/label/${this.$route.params.id}/`,
          {
            params: {
              type: 'location',
            },
          }
        );
        this.locationLabels = response.data;
        this.$emit('calculateHeight');
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async saveLabelPos(id, pos) {
      await http.put(`dataset/label/${id}/`, { position: pos });
      await this.getLabels();
      if (this.repositioning) {
        this.repositioning = false;
      }
    },
  },

  emits: ['calculateHeight'],
};