<template>
  <div class="notFoundView">
    <div class="notFoundImage" />
    <div class="notFoundText">
      {{ $t('notFound.misplaced') }}
    </div>
    <div class="notFoundText2">
      {{ $t('notFound.never_existed') }}
    </div>
    <div
      class="notFoundText3"
      @click="$router.go(goBackTo)"
    >
      {{ $t('notFound.back') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundView',

  props: {
    goBackTo: {
      type: Number,
      default: -1,
    },
  }
};
</script>

<style lang="scss" scoped>
.notFoundView {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-weight: bold;
  color: rgb(var(--v-theme-primary));

  .notFoundImage {
    width: 600px;
    height: 400px;
    background-image: url('../../../../assets/images/404.png');
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-position: center center;
  }

  .notFoundText {
    font-size: 2.5rem;
  }

  .notFoundText2 {
    margin-top: 1rem;
    color: rgb(var(--v-theme-primary-lighten1));
    font-size: 1.5rem;
  }

  .notFoundText3 {
    margin-top: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
