import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1/emails/';

export class EmailsAPI {

  static fetchEmail({emailId}) {
    return http
      .get(`${BASE_PATH}${emailId}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static fetchEmails({offset=0, limit=20, descendingSort=true, subject=undefined, requireHumanReview=false}) {
    return http
      .get(`${BASE_PATH}`, {
        params: {
          offset,
          limit,
          sort_desc: descendingSort,
          subject,
          require_human_review: requireHumanReview,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static updateEmail({emailId, feedbackCategoryId, discardReason}) {
    return http
      .patch(`${BASE_PATH}${emailId}`, {
        feedback_category_id: feedbackCategoryId,
        discard_reason: discardReason,
      }, {globalErrorHandlingOptOut: true})
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static deleteEmails({emailsIds}) {
    return http
      .delete(`${BASE_PATH}`, {
        data: {ids: emailsIds},
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

}
