<template>
  <div class="autocomplete-select d-flex">
    <div class="custom-select noselect">
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
      >
        <div
          v-for="(suggestionItem, i) in suggestions"
          :ref="`Option${i}`"
          :key="suggestionItem.text"
          class="option ellipsis"
          :style="{ 'background-color': suggestionItem.text === preSelectedText ? 'rgb(var(--v-theme-primary-lighten2))' : '#f1f2f6' }"
          :value="suggestionItem.value"
          @click="selectSuggestion(suggestionItem)"
          @mouseenter="preSelectedText = suggestionItem.text"
        >
          <v-icon
            v-if="suggestionItem.type === 'dp'"
            color="primary"
            :size="17"
            start
          >
            fas fa-cube
          </v-icon>
          <v-icon
            v-if="suggestionItem.type === 'eg'"
            color="primary"
            :size="17"
            start
          >
            fas fa-cubes
          </v-icon>
          <v-icon
            v-if="suggestionItem.type === 'command'"
            color="primary"
            :size="15"
            start
          >
            fas fa-code
          </v-icon>
          {{ suggestionItem.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoCompleteSelect',

  data() {
    return ({
      selectedText: '',
      preSelectedText: '',
      selectOn: false,
      suggestion: {},
    })
  },

  watch: {
    selected() {
      this.selectedText = this.selected;
    },

    suggestions: {
      handler() {
        this.preSelectedText = this.suggestions[0].text;
      },
      deep: true
    },
  },

  async mounted() {
    this.selectedText = this.selected;
    this.preSelectedText = this.suggestions[0].text;
  },

  methods: {
    toggleSelectOn() {
      this.selectOn = !this.selectOn;
      if (this.selectOn) {
        setTimeout(() => {
          const panel = this.$refs.selectPanel;
          if (panel) {
            panel.focus();
          }
        }, 50);
      }
    },

    selectSuggestion(suggestion) {
      this.preSelectedText = suggestion.text;
      this.suggestion = this.suggestions.find(d => d.text === this.selectedText);
      this.$emit('selectedChanged');
    },
  },

  props: {
    suggestions: {
      type: Array,
      required: true,
    },

    selected: {
      type: String,
      required: true,
    },
  },

  emits: ['selectedChanged'],
}
</script>

<style lang="scss" scoped>
.autocomplete-select {
  .custom-select {
    width: 300px;
    border-radius: 4px;
    padding: 8px;
    padding-right: 30px;
    font-size: 0.9rem;
    position: relative;

    .dataset-name {
      max-width: 250px;
    }
    
    .open-icon {
      position: absolute;
      right: 11px;
      top: 11px;
      color: rgb(var(--v-theme-dark));
      opacity: var(--v-medium-emphasis-opacity);
    }

    .select-panel {
      width: 300px;
      max-height: 280px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: 999;
      background-color: #f1f2f6;
      border-radius: 4px;
      border: solid 1px rgb(var(--v-theme-primary));
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .select-panel .option {
      height: 40px;
      padding: 8px;
      color: black !important;
    }
  }
}
</style>
