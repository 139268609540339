<template>
  <div class="bottom-nav my-8">
    <div style="float: right">
      <v-pagination
        v-model="page"
        class="inline-middle right-gap"
        density="comfortable"
        active-color="primary"
        color="white"
        :length="totalPages"
        :total-visible="7"
        @update:model-value="onPageChange"
      />
      <div class="inline-middle mr-6">
        {{ $t('datatable.footer.perPage') }}
      </div>
      <v-select
        style="width: 100px"
        class="inline-middle mt-0"
        item-title="text"
        item-value="value"
        density="compact"
        variant="outlined"
        color="primary"
        :value="initialItemsPerPage"
        :items="itemsPerPageOptions"
        @update:model-value="onItemsPerPageChange"
      />
    </div>
  </div>
</template>
<script>

export default {
  name: 'TableFooter',

  data() {
    return {
      page: null,
      itemsPerPageOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '100', value: 100 },
      ],
    };
  },

  watch: {
    currentPage() {
      this.page = this.currentPage;
    },
  },

  mounted() {
    this.page = this.currentPage;
  },

  methods: {
    onItemsPerPageChange(val) {
      this.$emit('changeItemsPerPage', val);
    },

    onPageChange(val) {
      this.$emit('changePage', val);
    }
  },

  props: {
    initialItemsPerPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },

  emits: ['changeItemsPerPage', 'changePage'],

}

</script>
<style lang="scss" scoped>

.bottom-nav {
  font-size: 12px;
  width: 100%;
}

</style>
