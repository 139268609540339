/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'production/files/';

export class ProductionFileAPI {
  static async get(
    documentTypeId,
    offset,
    limit,
    nameFilter = '',
    onlyUnprocessed = false,
    sortDesc = true,
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          doctype_id: documentTypeId,
          name_filter: nameFilter,
          only_unprocessed: onlyUnprocessed,
          sort_desc: sortDesc,
          offset,
          limit,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async getSingle(id, withPos = false, externalToken = null) {
    return http
      .get(`${BASE_PATH}${id}`, {
        params: {
          with_review_position: withPos,
          token: externalToken || null,
          noAuth: !!externalToken,
          external: !!externalToken,
        }
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
