import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/task/';

export class SearchTaskAPI {
  static getTasks(offset = 0,  limit = 20, sort_desc = true) {
    return http
      .get(BASE_PATH, {
        params: {
          offset,
          limit,
          sort_desc,
        },
      })
      .then(response => {
        const total = parseInt(response.headers['x-total-count'], 10);
        return Promise.resolve([response.data, total]);
      })
      .catch(error => Promise.reject(error));
  }
}
