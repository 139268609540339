<template>
  <div class="canvas-side">
    <div
      class="outside-page outside-page-right"
      :style="{ width: `${containerSideWidth}px` }"
      @mouseup="$emit('drawEnd')"
      @mousemove="(event) => drawMoveContainer(event, 'right')"
    />
    <div
      class="outside-page outside-page-left"
      :style="{ width: `${containerSideWidth}px` }"
      @mouseup="$emit('drawEnd')"
      @mousemove="(event) => drawMoveContainer(event, 'left')"
    />
    <div
      class="outside-page outside-page-bottom"
      :style="{
        height: `${containerTopHeight}px`,
        width: `${containerWidth}px`,
      }"
      @mouseup="$emit('drawEnd')"
      @mousemove="(event) => drawMoveContainer(event, 'bottom')"
    />
    <div
      class="outside-page outside-page-top"
      :style="{
        height: `${containerTopHeight}px`,
        width: `${containerWidth}px`,
      }"
      @mouseup="$emit('drawEnd')"
      @mousemove="(event) => drawMoveContainer(event, 'top')"
    />
  </div>
</template>

<script>
export default {
  name: 'CanvasSide',

  methods: {
    constrainDrawing(side) {
      if (
        side === 'bottom' && this.rotation === 90 ||
        side === 'top' && this.rotation === 270 ||
        side === 'left' && this.rotation === 180 ||
        side === 'right' && [0, 360].includes(this.rotation)
      ) {
        this.updatedCoordinates.x_max = this.maxX;
      } else if (
        side === 'bottom' && this.rotation === 180 ||
        side === 'top' && [0, 360].includes(this.rotation)  ||
        side === 'left' && this.rotation === 270 ||
        side === 'right' && this.rotation === 90
      ) {
        this.updatedCoordinates.y_min = 0;
      } else if (
        side === 'bottom' && this.rotation === 270 ||
        side === 'top' && this.rotation === 90  ||
        side === 'left' && [0, 360].includes(this.rotation) ||
        side === 'right' && this.rotation === 180
      ) {
        this.updatedCoordinates.x_min = 0;
      } else {
        this.updatedCoordinates.y_max= this.maxY;
      }
    },

    getConstrainedY(event) {
      let newY;
      if ([0, 360, 180].includes(this.rotation)) {
        newY = (event.offsetY - this.containerTopHeight) / this.pageHeight;
        if (this.rotation === 180) {
          newY = (1 - (newY / this.maxY)) * this.maxY;
        }
      } else {
        let YBase = (event.offsetY - ((this.pageContainerHeight - this.displayPageWidth) / 2)) / this.displayPageWidth;
        if (this.rotation === 270) {
          YBase = 1 - YBase;
        }
        newY = YBase * this.maxY;
      }
      if (newY < 0) {
        newY = 0;
      } else if (newY > this.maxY) {
        newY = this.maxY;
      }
      return newY;
    },

    getConstrainedX(event) {
      let newX;
      if ([0, 360, 180].includes(this.rotation)) {
        newX = (event.offsetX - this.containerSideWidth) / this.pageWidth;
        if (this.rotation === 180) {
          newX = (1 - (newX / this.maxX)) * this.maxX;
        }
      } else {
        let XBase = (event.offsetX - ((this.pageContainerWidth - this.displayPageHeight) / 2)) / this.displayPageHeight;
        if (this.rotation === 90) {
          XBase = 1 - XBase;
        }
        newX = XBase * this.maxX;
      }
      if (newX < 0) {
        newX = 0;
      } else if (newX > this.maxX) {
        newX = this.maxX;
      }
      return newX;
    },
  
    drawMoveContainer(event, side) {
      this.constrainDrawing(side);
      if (
        ([0, 180, 360].includes(this.rotation) && ["left", "right"].includes(side))
      ) {
        this.$emit('updateY', this.getConstrainedY(event));
      } else if (
        ([90, 270].includes(this.rotation) && ["left", "right"].includes(side))
      ) {
        this.$emit('updateX', this.getConstrainedY(event));
      } else if (
        ([90, 270].includes(this.rotation) && ["top", "bottom"].includes(side))
      ) {
        this.$emit('updateY', this.getConstrainedX(event));
      } else if (["top", "bottom"].includes(side)) {
        this.$emit('updateX', this.getConstrainedX(event));
      }
    },
  },

  props: {
    containerSideWidth: {
      type: Number,
      required: true,
    },

    containerTopHeight: {
      type: Number,
      required: true,
    },

    containerWidth: {
      type: Number,
      required: true,
    },

    pageContainerHeight: {
      type: Number,
      required: true,
    },

    pageContainerWidth: {
      type: Number,
      required: true,
    },

    pageWidth: {
      type: Number,
      required: true,
    },

    pageHeight: {
      type: Number,
      required: true,
    },

    displayPageWidth: {
      type: Number,
      required: true,
    },

    displayPageHeight: {
      type: Number,
      required: true,
    },
    
    rotation: {
      type: Number,
      required: true,
    },

    updatedCoordinates: {
      type: Object,
      required: true,
    },

    maxX: {
      type: Number,
      required: true,
    },

    maxY: {
      type: Number,
      required: true,
    },
  },

  emits: ['drawEnd', 'updateY', 'updateX'],
}
</script>

<style lang="scss" scoped>
.canvas-side {
  .outside-page {
    position: absolute;
  }

  .outside-page-top {
    top: 0px;
    left: 0px;
  }

  .outside-page-bottom {
    bottom: 0px;
    left: 0px;
  }

  .outside-page-left {
    top: 0px;
    left: 0px;
    bottom: 0px;
  }

  .outside-page-right {
    top: 0px;
    right: 0px;
    bottom: 0px;
  }
}
</style>
