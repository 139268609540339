<template>
  <div class="url-field">
    <v-select
      v-model="protocol"
      class="http-select inline-middle"
      style="margin-top: -23px"
      variant="outlined"
      color="primary"
      density="compact"
      :items="protocols"
    />
    <v-text-field
      v-model="domain"
      class="input-url inline-middle left-gap-sm mt-0"
      style="width: 400px"
      type="url"
      clear-icon="fas fa-times"
      placeholder="www.example.com"
      aria-placeholder="www.example.com"
      variant="outlined"
      color="primary"
      density="compact"
      clearable
    />
  </div>
</template>

<script>
export default {
  name: 'UrlField',

  data() {
    return ({
      protocol: 'http://',
      protocols: ["http://", "https://"],
      domain: '',
      invalidUrl: false,
    });
  },

  watch: {
    protocol() {
      this.constructUrl();
    },

    domain() {
      this.constructUrl();
    },
  },

  mounted() {
    if (this.modelValue && this.modelValue != '') {
      this.handleUrl();
    }
  },

  methods: {
    handleUrl() {
      const { protocol, host } = new URL(this.modelValue);
      const params = this.modelValue.replace(`${protocol}//${host}`, '');
      this.protocol = `${protocol}//`;
      this.domain = `${host}${params}`;
    },

    constructUrl() {
      this.$emit('update:modelValue', `${this.protocol}${this.domain}`);
    }
  },

  props: {
    modelValue: {
      type: String,
      default:"",
    },
  },

  emits: ['update:modelValue'],
}
</script>

<style lang="scss" scoped>
.url-field {
  .http-select {
    width: 104px;
    color: rgb(var(--v-theme-grey-darken2)) !important;
  }

  .error-message {
    color: rgb(var(--v-theme-error));
    font-size: small;
  }
}
</style>