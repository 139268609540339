<template>
  <div class="search-tasks-view page-padding py-7">
    <BreadcrumbComponent />
    <HeaderName
      class="bottom-gap-sm"
      style="max-width: 600px"
      :item="{ name: $t('search.tasks.title') }"
      :editable="false"
      :show-id="false"
    />
    <SearchTasksTable />
  </div>
</template>
<script>
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import HeaderName from '@/components/common/elements/General/HeaderName';
import SearchTasksTable from "@/components/search/elements/Tasks/SearchTasksTable";

export default {
  name: 'SearchTasksView',
  
  components: {
    BreadcrumbComponent,
    HeaderName,
    SearchTasksTable,
  },

  created() {
    this.$store.commit('setBreadcrumb',
      [
        { title: this.$t('breadcrumb.home') },
        { title: this.$t('search.tasks.title') },
      ]
    );
  },


};
</script>
