<template>
  <div class="info-box">
    <small class="d-flex">
      <div class="info-icon">
        <v-icon
          class="vertical-centered"
          size="16"
        >
          fas fa-info-circle
        </v-icon>
      </div>
      <div style="width: calc(100% - 40px)">
        <slot />
      </div>
    </small>
  </div>
</template>

<script>
export default {
  name: 'InfoBox',
}
</script>

<style lang="scss" scoped>
.info-box {
  background-color: rgb(var(--v-theme-primary-lighten2));
  border-radius: 6px;
  padding: 13px 24px;
  width: calc(100% - 5px);

  .info-icon {
    width: 40px;
    position: relative;
    top: -2px;
  }
}
</style>