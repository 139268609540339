<template>
  <div class="verify-button">
    <v-tooltip
      v-if="!disabled"
      location="right"
    >
      <template #activator="{ props }">
        <v-icon
          color="white"
          class="verify-button mr-2"
          size="17"
          :class="{
            'verify-button--incorrect': incorrect,
            'verify-button--enabled': !correct && !prevalidated,
            'verify-button--prevalidated-correct': prevalidated && !correct,
            'verify-button--correct': correct,
          }"
          v-bind="props"
          @click="$emit('verifyClick')"
        >
          {{ loading ? 'fas fa-spinner fa-pulse' : 'fas fa-check' }}
        </v-icon>
      </template>
      <span v-if="tooltipMessage !== ''">
        {{ tooltipMessage }}
      </span>
      <div v-else>
        <div v-if="prevalidated">
          <div style="font-size: 10px !important">
            {{ $t('dataPoints.prevalidated_message') }}
          </div>
          <div
            v-for="explanation in businessRuleStrings.filter(br => br)"
            :key="explanation.string"
            style="font-size: 12px !important;"
            class="br-feedback-valid"
          >
            {{ explanation.string }}
          </div>
        </div>
        <span v-else>
          {{ $t('verification.mark_ok') }}
        </span>
      </div>
    </v-tooltip>
    <v-icon
      v-else
      color="white"
      class="verify-button verify-button--disabled mr-2"
      size="17"
    >
      {{ loading ? 'fas fa-spinner fa-pulse' : 'fas fa-check' }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'VerifyButton',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    prevalidated: {
      type: Boolean,
      default: false,
    },

    correct: {
      type: Boolean,
      default: false,
    },

    incorrect: {
      type: Boolean,
      default: false,
    },

    tooltipMessage: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showTooltip: {
      type: Boolean,
      default: true,
    },

    businessRuleStrings: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['verifyClick'],
}
</script>

<style lang="scss" scoped>
.verify-button {
  cursor: pointer;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;

  &--correct {
    background-color: $valid-base !important;
    cursor: default;
  }

  &--enabled {
    background-color: #555 !important;
  }

  &--disabled {
    background-color: #ddd !important;
    cursor: not-allowed !important;
  }

  &--incorrect {
    background-color: #ddd !important;
  }

  &--prevalidated-correct {
    background-color: $prevalidated-correct !important;
  }
}

.br-feedback-valid {
  color: $valid-base !important;
}

.br-feedback-invalid {
  color: $invalid-base !important;
}
</style>