<template>
  <div>
    <div
      v-for="(token, i) in tokens"
      :key="i"
    >
      <div
        v-if="!isAnnotated(token)"
        class="highlight-frame"
        :style="{
          'border-color': getBorderColor(token),
          left: `${getHighlightX(token.coordinates.x_min)}vh`,
          top: `${getHighlightY(token.coordinates.y_min)}vh`,
          width: `${getHighlightX(
            token.coordinates.x_max - token.coordinates.x_min
          )}vh`,
          height: `${getHighlightY(
            token.coordinates.y_max - token.coordinates.y_min
          )}vh`,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TokenHighlights",

  methods: {
    isAnnotated(token) {
      return this.annotationCoordinates.some(
        (annotation) =>
          annotation.x_min <= token.coordinates.x_min &&
          annotation.x_max >= token.coordinates.x_max &&
          annotation.y_min <= token.coordinates.y_min &&
          annotation.y_max >= token.coordinates.y_max
      );
    },
    getHighlightX(x) {
      return x * this.pageWidth;
    },
    getHighlightY(y) {
      return y * this.pageHeight;
    },
    getBorderColor(token) {
      /* \u2611 is a checkbox with a checkmark, \u2610 is a checkbox without a checkmark */
      if (token.word === '\u2611') {
        return 'red';
      } else if (token.word === '\u2610') {
        return 'violet';
      } else {
        return '#999';
      }
    }
  },

  props: {
    tokens: {
      type: Array,
      required: true,
    },
    annotationCoordinates: {
      type: Array,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.highlight-frame {
  position: absolute;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  opacity: 0.4;
}
</style>
