<template>
  <div class="table-actions top-gap-lg bottom-gap d-flex align-center">
    <v-text-field
      v-if="!['classifiers', 'oidc_providers', 'jobsTable'].includes(type)"
      v-model="filter"
      class="filter-field flex-grow-0"
      variant="outlined"
      color="primary"
      density="compact"
      :class="{
        'right-gap': !['reviewers', 'extractionAgentReview'].includes(type)
      }"
      :placeholder="$t('filter')"
      @input="$emit('filterChange', trimmedFilter)"
      @keydown.enter="$emit('filterEnter')"
      hide-details
    />
    <OrgSelect
      v-if="type === 'users' && user && user.role === 'sysadmin'"
      class="right-gap"
      :selected="selectedOrg"
      @selected-changed="(id) => {selectedOrg = id; $emit('orgChange', id);}"
      show-every
    />
    <CustomSelect
      v-if="type === 'dataset'"
      class="right-gap"
      name-field="text"
      value-field="value"
      width="230px"
      :placeholder="$t('models.filter_by_label')"
      :selected="currentLabel"
      :items="labelOptions"
      :disabled="labelSelectDisabled"
      @selected-changed="(label) => { $emit('labelChange', label); }"
      clearable
    />
    <div
      v-if="
        numberOfSelected === 1 &&
          editCondition &&
          !editNotSupported
      "
      class="right-gap-sm"
    >
      <v-tooltip top>
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              color="primary"
              :disabled="lockActions && ['dataPoints', 'labelGroups'].includes(type)"
              @click="$emit('editClick')"
              rounded
            >
              <v-icon>
                fas fa-pen
              </v-icon>
            </v-btn>
          </div>
        </template>
        {{ $t('datatable.header.edit') }}
      </v-tooltip>
    </div>
    <div
      v-if="numberOfSelected === 1 && editCondition && ['datasets', 'docTypes'].includes(type)"
      class="right-gap-sm"
    >
      <v-tooltip top>
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              color="primary"
              @click="$emit('downloadClick')"
              rounded
            >
              <v-icon>
                fas fa-download
              </v-icon>
            </v-btn>
          </div>
        </template>
        {{ $t('datatable.header.download') }}
      </v-tooltip>
    </div>
    <div
      v-if="numberOfSelected > 0 && !['DPsAndGroups', 'docTypes', 'datasets'].includes(type) && deleteCondition"
      class="right-gap-sm"
    >
      <v-tooltip top>
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              color="primary"
              :disabled="
                lockActions
                  && ['DPsAndGroups', 'dataPoints', 'labelGroups'].includes(type)
              "
              @click="$emit('deleteClick')"
              rounded
            >
              <v-icon>
                fas fa-trash
              </v-icon>
            </v-btn>
          </div>
        </template>
        {{ lockActions && $t('docTypes.doctype_is_locked') || $t('datatable.header.delete', { number: numberOfSelected }) }}
      </v-tooltip>
    </div>
    <div
      v-if="numberOfSelected > 0 && ['docTypes', 'datasets'].includes(type) && deleteCondition"
      class="right-gap-sm"
    >
      <v-tooltip
        v-if="!lockActions"
        top
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <div
              class="v-btn--rounded clickable recycle-button"
              @click="$emit('deleteClick')"
            >
              <RecycleBinIcon color="white" />
            </div>
          </div>
        </template>
        {{ $t('tables.move_to_bin', { number: numberOfSelected }) }}
      </v-tooltip>
      <v-tooltip
        v-else
        top
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              color="primary"
              rounded
              disabled
            >
              <v-icon>
                fas fa-trash
              </v-icon>
            </v-btn>
          </div>
        </template>
        {{ numberOfSelected === 1 ? $t('docTypes.doctype_is_locked') : $t('docTypes.at_least_one_is_locked') }}
      </v-tooltip>
    </div>

    <div
      v-if="numberOfSelected > 0 && type === 'dataset'"
      class="right-gap-sm flex-shrink-0"
    >
      <v-tooltip top>
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              color="primary"
              style="box-shadow: none; height: 34px !important;"
              :disabled="numberOfValidFiles === 0"
              @click="$emit('preAnnotateClick')"
              rounded
            >
              <v-icon size="17">
                fas fa-magic
              </v-icon>
            </v-btn>
          </div>
        </template>
        {{ $t('models.preannotate_selected', { number: numberOfSelected }) }}
      </v-tooltip>
    </div>
    <div
      v-if="['correctionDocs', 'documents'].includes(type) && numberOfSelected > 0 && editCondition"
      class="right-gap-sm"
    >
      <v-tooltip top>
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              color="primary"
              @click="$emit('reprocessClick')"
              rounded
            >
              <v-icon>
                fas fa-redo
              </v-icon>
            </v-btn>
          </div>
        </template>
        {{ $t('datatable.header.reprocess', { number: numberOfSelected }) }}
      </v-tooltip>
    </div>
    <div
      v-if="numberOfSelected === 0 && createCondition && createNotSupported"
    >
      <v-btn
        class="right-gap-sm"
        style="box-shadow: none"
        color="primary"
        variant="outlined"
        @click="$emit('createClick')"
        rounded
      >
        <v-icon
          size="17"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t(`${type}.create`) }}
        <span v-if="type === 'search'">
          {{ $t('search.home.in') }} {{ searchBrowseFolder.name }}
        </span>
      </v-btn>
    </div>
    <div v-if="numberOfSelected === 0 && type === 'users' && $route.path.startsWith('/search')">
      <v-btn
        class="right-gap-sm"
        style="box-shadow: none"
        color="primary"
        @click="$emit('logsClick')"
        rounded
      >
        <v-icon
          size="17"
          start
        >
          fas fa-download
        </v-icon>
        {{ $t('search.users.get_logs.button') }}
      </v-btn>
    </div>
    <div v-if="numberOfSelected === 0 && type === 'searchSync'">
      <v-btn
        class="right-gap-sm"
        style="box-shadow: none"
        color="primary"
        variant="outlined"
        @click="$emit('syncClick')"
        rounded
      >
        <v-icon
          size="17"
          start
        >
          mdi mdi-autorenew
        </v-icon>
        {{ $t('search.home.sync_folder') }}
      </v-btn>
    </div>
    <v-btn
      v-if="numberOfSelected === 0 && ['datasets', 'docTypes'].includes(type)"
      color="primary"
      variant="outlined"
      @click="$emit('importClick')"
      rounded
    >
      <v-icon
        size="16"
        start
      >
        fas fa-upload
      </v-icon>
      {{ $t('import') }}
    </v-btn>
    <div
      v-if="numberOfSelected === 0 && type === 'DPsAndGroups' && !lockActions"
      class="d-flex"
    >
      <AddExtractorButton />
      <ReplaceExtractorModel
        v-if="usedModels.length"
        class="left-gap-sm"
        v-bind="$attrs"
        :used-models="usedModels"
      />
    </div>
    <v-tooltip
      v-else-if="numberOfSelected === 0 && type === 'DPsAndGroups' && lockActions"
      right
    >
      <template #activator="{ props }">
        <div
          v-bind="props"
          class="flex-shrink-0"
        >
          <v-btn
            class="primary--text right-gap-sm"
            style="top: -1px; box-shadow: none"
            variant="outlined"
            rounded
            disabled
          >
            <v-icon
              size="16"
              start
            >
              fas fa-plus
            </v-icon>
            {{ $t('datatable.header.add') }}
            <v-icon
              size="16"
              end
            >
              fas fa-chevron-down
            </v-icon>
          </v-btn>
          <v-btn
            v-if="usedModels.length"
            class="primary--text"
            style="top: -1px; box-shadow: none"
            variant="outlined"
            rounded
            disabled
          >
            <v-icon
              size="16"
              start
            >
              fas fa-sync
            </v-icon>
            {{ $t('datatable.header.replaceExtractorModel') }}
          </v-btn>
        </div>
      </template>
      {{ $t('docTypes.doctype_is_locked') }}
    </v-tooltip>
    <div
      v-if="type === 'dataset' && numberOfSelected === 0"
      class="d-flex align-middle"
    >
      <div class="right-gap-sm">
        <v-btn
          style="box-shadow: none"
          color="primary"
          variant="outlined"
          :disabled="numberOfValidFiles === 0"
          @click="$emit('copyClick')"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-copy
          </v-icon>
          {{ $t('models.copy_dataset') }}
        </v-btn>
      </div>
      <AnnotateButton
        style="height: 35.6px !important;"
        :number-of-valid-files="numberOfValidFiles"
        :is-pre-annotated="isPreAnnotated"
        @pre-annotate-click="$emit('preAnnotateClick')"
        @annotate-click="$emit('annotateClick')"
        @remove-pre-annotation-click="$emit('removePreAnnotationClick')"
      />
    </div>
    <div
      v-if="type === 'documents' && numberOfSelected > 0"
      class="right-gap-sm"
    >
      <v-tooltip top>
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              color="primary"
              @click="$emit('validateClick')"
              rounded
            >
              <v-icon>
                fas fa-magic
              </v-icon>
            </v-btn>
          </div>
        </template>
        {{ $t('datatable.header.auto_validate', { number: numberOfSelected }) }}
      </v-tooltip>
    </div>
    <slot name="actions" />
  </div>
</template>

<script>
import RecycleBinIcon from '@/components/common/elements/Navigation/RecycleBinIcon';
import AddExtractorButton from '@/components/extract/elements/Extractors/AddExtractorButton';
import ReplaceExtractorModel from '@/components/extract/elements/Extractors/ReplaceExtractorModel';
import AnnotateButton from '@/components/extract/elements/Datasets/AnnotateButton';
import OrgSelect from "@/components/common/elements/Organizations/OrgSelect";
import format_mixin from '@/mixins/format.js';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect.vue';

export default {
  name: 'TableActions',

  mixins: [format_mixin],

  components: {
    AddExtractorButton,
    AnnotateButton,
    OrgSelect,
    ReplaceExtractorModel,
    RecycleBinIcon,
    CustomSelect,
  },

  data() {
    return ({
      filter: this.initialFilter,
      selectedOrg: -1,
      selectedAction: '',
    });
  },

  computed: {
    createNotSupported() {
      return ![
        'searchSync',
        'reviewers',
        'extractionAgentReview',
        'correctionDocs',
        'dataPoints',
        'labelGroups',
        'DPsAndGroups',
        'jobsTable'
      ].includes(this.type);
    },

    editNotSupported() {
      return [
        'deleted',
        'DPsAndGroups',
        'correctionDocs',
        'classifiers',
        'search',
        'searchSync',
      ].includes(this.type);
    },

    labelSelectDisabled() {
      return !this.isAnnotated || this.labels.length === 0;
    },

    labelOptions() {
      return this.labels.map(
        l => {
          return {
            text: l.name,
            value: l.name,
          }
        }
      );
    },

    trimmedFilter() {
      return this.filter.trim().toLowerCase();
    },

    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    searchBrowseFolder() {
      return this.$store.getters.browseFolder || { name: '' };
    },
  },

  props: {
    numberOfSelected: {
      type: Number,
      required: true,
    },

    numberOfValidFiles: {
      type: Number,
      default: 0,
    },

    type: {
      type: String,
      required: true,
    },

    editCondition: {
      type: Boolean,
      default: true,
    },

    createCondition: {
      type: Boolean,
      default: true,
    },

    deleteCondition: {
      type: Boolean,
      default: true,
    },

    isAnnotated: {
      type: Boolean,
      default: false,
    },

    isPreAnnotated: {
      type: Boolean,
      default: false,
    },

    labels: {
      type: Array,
      default: () => [],
    },

    currentLabel: {
      type: String,
      default: '',
    },

    lockActions: {
      type: Boolean,
      default: false,
    },

    usedModels: {
      type: Array,
      default: () => [],
    },
    initialFilter: {
      type: String,
      default: '',
    },
  },

  emits: [
    'filterChange',
    'filterEnter',
    'orgChange',
    'labelChange',
    'editClick',
    'downloadClick',
    'deleteClick',
    'preAnnotateClick',
    'reinstateClick',
    'reprocessClick',
    'createClick',
    'deleteAllClick',
    'importClick',
    'copyClick',
    'preAnnotateClick',
    'annotateClick',
    'removePreAnnotationClick',
    'validateClick',
    'selectedChanged',
    'syncClick',
    'logsClick',
  ],
}
</script>

<style lang="scss" scoped>
  .table-actions {
    .filter-field {
      width: 265px;
    }

    .recycle-button {
      background-color: rgb(var(--v-theme-primary));
      height: 34px !important;
      padding: 0 20px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .warning-message {
      padding: 10px;
      color: rgb(var(--v-theme-primary));
      border: solid 1px rgb(var(--v-theme-primary));
      border-radius: 10px;
      background-color: rgb(var(--v-theme-primary-lighten2));
    }
  }
</style>
