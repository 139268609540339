<template>
  <div class="ml-auto">
    <v-select
      v-if="displayCategories"
      v-model="internalValue"
      class="page-type-chip inline-middle"
      width="200"
      item-title="name"
      item-value="id"
      density="compact"
      :items="categories"
      :menu-props="{
        modelValue: openMenu
      }"
      @click="openMenu = !openMenu"
    />
    <v-btn
      class="clickable mx-3"
      color="primary"
      style="box-shadow: none"
      @click="$emit('submit-review')"
      rounded
    >
      <v-icon
        v-if="submittingReview"
        left
      >
        fas fa-spinner fa-pulse
      </v-icon>
      {{ $t('workflows.documents.submit_review') }}
    </v-btn>
    <v-btn
      class="clickable mx-3"
      color="primary"
      style="box-shadow: none"
      @click="showDiscardDialog = !showDiscardDialog"
      rounded
    >
      {{ $t('workflows.documents.discard') }}
    </v-btn>
  </div>
  <DiscardDialog
    v-model="showDiscardDialog"
    :show-dialog="showDiscardDialog"
    :discard-reasons="discardReasons"
    :selected-reason="selectedReason"
    :custom-reason="customReason"
    @update:show-dialog="(value) => showDiscardDialog = value"
    @update:selected-reason="(value) => selectedReason = value"
    @update:custom-reason="(value) => customReason = value"
    @submit-discard-review="$emit('submit-discard-reason', customReason || selectedReason || null)"
  />
</template>
<script>

import DiscardDialog from '@/components/extract/elements/Documents/DiscardDialog';

export default {
  name: 'ClassificationValidation',

  components: {
    DiscardDialog
  },

  data() {
    return {
      openMenu: false,
      showDiscardDialog: false,
      selectedReason: this.defaultDiscardReason,
      customReason: '',
      internalValue: this.modelValue,
    }
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.internalValue = value;
      }
    },

    internalValue(value) {
      this.$emit('update:modelValue', value);
      this.openMenu = false;
    }
  },

  props: {
    modelValue: {
      type: Number,
      required: true
    },

    submittingReview: {
      type: Boolean,
      default: false
    },

    displayCategories: {
      type: Boolean,
      default: true
    },

    discardReasons: {
      type: Array,
      default: () => [],
    },

    defaultDiscardReason: {
      type: String,
      default: '',
    },

    categories: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['update:modelValue', 'submit-review', 'submit-discard-reason'],
}

</script>