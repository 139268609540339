<template>
  <div class="doc-navigator d-flex align-center justify-end">
    <div
      v-if="showLoadingSkeleton"
      class="doc-navigator-content__wrapper"
    >
      <v-skeleton-loader
        type="heading"
        width="140px"
        class="d-flex flex-shrink-0 right-gap-sm"
        style="left: 50px"
      />
      <v-skeleton-loader
        type="heading"
        width="310px"
        class="d-flex flex-shrink-0 right-gap-sm"
        style="left: 20px"
      />
    </div>
    <div
      v-else
      class="doc-navigator-content__wrapper"
    >
      <DocStepper
        ref="docStepper"
        class="d-flex flex-shrink-0 mr-1"
        style="margin-top: 0px"
        :number-of-files="totalDocs"
        :ordinal-selected="ordinalSelected"
        @step="(step) => $emit('step', step)"
      />
      <DocSelect
        ref="docSelect"
        class="d-flex-shrink-0"
        :type="type"
        :docs="docs"
        :selected="modelValue"
        :loading="loading"
        :deleteable="deleteable"
        :can-copy-filename="canCopyFilename"
        :show-number="showNumber"
        :show-icon="false"
        @selected-changed="id => $emit('update:modelValue', id)"
        @delete-click="$emit('deleteClick')"
        @get-more="$emit('getMore')"
        @get-missing="$emit('getMissing')"
        small
      />
    </div>
  </div>
</template>

<script>
import DocSelect from "@/components/extract/elements/Documents/DocSelect";
import DocStepper from "@/components/extract/elements/Documents/DocStepper";

export default {
  name: 'DocNavigator',

  components: {
    DocSelect,
    DocStepper,
  },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },

    docs: {
      type: Array,
      required: true,
    },

    totalDocs: {
      type: Number,
      required: true,
    },

    ordinalSelected: {
      type: Number,
      required: true,
    },

    type: {
      type: String,
      default: 'file',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    deleteable: {
      type: Boolean,
      default: false,
    },

    canCopyFilename: {
      type: Boolean,
      default: false,
    },

    showNumber: {
      type: Boolean,
      default: true,
    },

    showLoadingSkeleton: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'step',
    'deleteClick',
    'getMore',
    'getMissing',
    'update:modelValue',
  ],
}
</script>
<style scoped>

.doc-navigator-content__wrapper {
  display: contents;
}

</style>
