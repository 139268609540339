<template>
  <v-dialog
    v-model="openDialog"
    class="snippet-dialog"
    max-width="800"
    @input="v => $emit('update:modelValue', v)"
    @keydown.esc="$emit('close')"
  >
    <v-card class="dialog-card">
      <h2
        v-if="type === 'docType'"
        class="dialog-title mb-3"
      >
        {{ $t('docTypes.snippet_title') }}
      </h2>
      <h2
        v-if="type === 'classifier'"
        class="dialog-title mb-3"
      >
        URL
      </h2>
      <div style="position: relative">
        <textarea
          class="code-snippet"
          rows="20"
          :value="type === 'classifier' ? classifierUrl : snippetText"
          readonly
          disabled
        />
        <v-tooltip
          color="#423f4f"
          bottom
        >
          <template #activator="{ props }">
            <button
              class="copy-icon"
              v-bind="props"
              @click="handleCopy"
            >
              <i class="far fa-copy" />
            </button>
          </template>
          <span class="white--text">
            {{ $t('copy') }}
          </span>
        </v-tooltip>
      </div>
      <div class="dialog-button-container">
        <v-btn
          color="primary"
          style="font-size: 12px; font-weight: 600; letter-spacing: initial;"
          @click="$emit('close')"
          rounded
        >
          {{ $t('close') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: 'SnippetDialog',

  data() {
    return({
      openDialog: this.modelValue,
    });
  },

	computed: {
    classifierUrl() {
      return `${window.location.protocol}//${window.location.hostname}/api/v1/emails/predict/${this.id}/`;
    },

		snippetText() {
      const text = `import requests

URL_AUTH_SERVER="https://extract.auth.recital.ai/"
URL_SERVER="https://extract.api.recital.ai/"

USER = "${this.$store.getters.loggedInUser.email }"
PASSWORD = "<YOUR PASSWORD>"
FILE_NAME = "/path/to/your/file"

token = requests.post(URL_AUTH_SERVER+'auth/api/v1/login/',
          data={'username': USER,'password': PASSWORD}).json()
headers = {'Authorization':'Bearer '+token['access_token']}
f = open(FILE_NAME,'rb')
files = {'file_in':( f.name, f, 'multipart/form-data')}
file_post = requests.post(f'{URL_SERVER}files/',
              data={'doctype_id': ${this.id} }, files = files, headers=headers)
f.close()
file_id = file_post.json()`;
      return text;
    },
	},

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

	methods: {
		handleCopy() {
      navigator.clipboard.writeText(this.snippetText);
      this.$store.commit(
        'setSuccessMessage', this.$t('docTypes.snippet_copy')
      );
      this.$store.commit('setSuccessSnackbar', true);
    }
	},

	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},

		id: {
			type: String,
			required: true,
		},

    type: {
      type: String,
      default: 'docType',
    },
	},

  emits: ['close', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.code-snippet {
	width: 100%;
	border-radius: 0 !important;
	background-color: #D8D8D8;
	border: 1px solid #999;
	color: #666;
	resize: none;

	font-family: monospace;
	padding: 1rem;
	position: relative;
}

.copy-icon {
	position: absolute;
	top: 2px;
	right: 2px;
	z-index: 205;
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgb(var(--v-theme-primary));
	border: 1px solid rgb(var(--v-theme-primary));

	height: 30px;
	width: 30px;

	background-color: white;
	border-radius: 5px;

	&:active {
		color: white;
		border-color: white;
		background-color: rgb(var(--v-theme-primary));
	}
}

.dialog-button-container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.dialog-title {
  color: rgb(var(--v-theme-primary)) !important;
  font-weight: 500 !important;
  font-family: 'Telegraf' !important;
}
</style>
