<template><div /></template>

<script>
export default {
  name: 'RedirectView',

  mounted() {
    const url = new URL(window.location.href);

    const message = {
      type: 'mailbox-oauth2-redirect-code',
      code: url.searchParams.get('code')
    };

    window.opener.postMessage(message, url.origin);
  }
}
</script>
