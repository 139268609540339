<template>
  <div class="top-gap bottom-gap">
    <small
      v-for="(item, i) in $store.getters.breadcrumb"
      :key="item.title"
    >
      <router-link
        v-if="item.href"
        style="margin-right: 3px; color: black !important"
        :to="item.href"
      >
        {{ item.title }}
      </router-link>
      <span
        v-else
        style="margin-right: 3px"
      >
        {{ item.title }}
      </span>
      <v-icon
        v-if="i < $store.getters.breadcrumb.length - 1"
        style="margin-top: -2px; margin-right: 3px;"
        size="10"
      >
        fas fa-chevron-right
      </v-icon>
    </small>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumb',
};
</script>
