<template>
  <span class="mx-0">
    <RecycleBinIcon
      v-if="icon === 'fas fa-recycle-bin'"
      :active="active"
    />
    <v-icon
      v-else
      color="white"
      style="width: fit-content;"
      :class="{ active }"
    >
      {{ icon }}
    </v-icon>
  </span>
</template>

<script>
import RecycleBinIcon from '@/components/common/elements/Navigation/RecycleBinIcon';

export default {
  name: 'MenuIcon',

  components: {
    RecycleBinIcon,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },
  }
}
</script>
